import BigNumber from 'bignumber.js'
import { BigNumber as BN } from '@ethersproject/bignumber'
import { fromBn } from 'evm-bn'

export function transform(
  value: string,
  precision = 4,
  additionalValues: string[] = []
): string {
  let additionalValuesResult = new BigNumber(0)
  for (const value of additionalValues) {
    additionalValuesResult = additionalValuesResult.plus(
      new BigNumber((value || String(0)).toString().replace(/,/g, ''))
    )
  }

  return BigNumber.sum(
    new BigNumber((value || String(0)).toString().replace(/,/g, '')),
    new BigNumber(additionalValuesResult)
  )
    .decimalPlaces(precision, BigNumber.ROUND_DOWN)
    .toFormat({
      decimalSeparator: '.',
      groupSeparator: ',',
      groupSize: 3,
      fractionGroupSeparator: '',
      fractionGroupSize: 0,
    })
}

export function fromBN(value: string, precision = 18): string {
  return fromBn(
    BN.from((value || String(0)).toString().replace(/,/g, '').split('.')[0]),
    precision
  )
}
