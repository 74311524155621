import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ReactApexChart from 'react-apexcharts'
import { transform } from '../../factory/bigNumber'
import { request } from '../../factory/axios'
import './style.css'

const ChartComponent = () => {
  const { asset } = useSelector((state) => state.assetReducer)
  const [chartBgOffset, setChartBgOffset] = useState(0)
  const [statistics, setStatistics] = useState({
    supplied: [],
    borrowed: [],
    date: [],
  })

  useEffect(() => {
    asset._id && getStatistics(asset._id)
  }, [asset])

  const getStatistics = async (assetId) => {
    const result = await request({
      method: 'get',
      path: `markets/history/${assetId}`,
    })

    const reduce = result.data.data.reduce(
      (acc, item) => {
        acc.supplied.push(+transform(item.totalSupply, 2).split(',').join(''))
        acc.borrowed.push(+transform(item.totalBorrow, 2).split(',').join(''))
        acc.date.push(new Date(item.createdAt.split('T')[0]).getTime())
        return acc
      },
      {
        supplied: [],
        borrowed: [],
        date: [],
      }
    )

    setStatistics(reduce)
  }

  let state = {
    series: [
      {
        name: 'supplied',
        type: 'area',
        data: statistics.supplied,
      },
      {
        name: 'borrowed',
        type: 'line',
        data: statistics.borrowed,
      },
    ],
  }

  let options = {
    chart: {
      type: 'line',
      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
      },
      events: {
        updated: (chartContext, config) => {
          setChartBgOffset(chartContext.w.globals.translateX)
        },
      },
    },
    stroke: {
      width: [1, 1],
      curve: 'smooth',
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
      },
    },
    grid: {
      show: false,
      position: 'back',
      xaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        opacity: 0,
      },
      column: {
        opacity: 1,
      },
      padding: {
        left: 20,
        bottom: 20,
      },
    },
    fill: {
      colors: ['#5493F7', '#53CBC8'],
      opacity: [0.2, 1],
      type: ['gradient', 'solid'],
      gradient: {
        type: 'vertical',
        colorStops: [
          {
            offset: 0,
            color: 'rgb(84, 147, 247)',
            opacity: 0.3,
          },
          {
            offset: 138.76,
            color: 'rgb(39, 117, 202)',
            opacity: 0,
          },
        ],
      },
    },
    labels: statistics.date,
    markers: {
      size: 0,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          colors: '#A8A8B2',
        },
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      max: (max) => (statistics.date.length > 1 ? max : max * 2),
      forceNiceScale: true,
      labels: {
        style: {
          colors: '#A8A8B2',
        },
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      theme: 'dark',
      custom: ({ series, dataPointIndex, w }) => {
        const s = series
        const supplyData = w.globals.initialSeries[0].data[dataPointIndex]
        const borrowData = w.globals.initialSeries[1].data[dataPointIndex]
        return `
          <div class='tooltip-style'>
            <div class='tooltip-style__supply'>Supply: ${supplyData}</div>
            <div class='tooltip-style__borrow'>Borrow: ${borrowData}</div>
          </div>
        `
      },
    },
  }
  return (
    <ReactApexChart
      style={{ backgroundPositionX: chartBgOffset }}
      options={options}
      series={state.series}
      type="line"
      height={197}
      // width={500}
    />
  )
}

export default ChartComponent
