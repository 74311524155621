import React, { useContext, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import xORBIcon from '../../img/xORB.svg'
import Convert from './convert'
import Redeem from './redeem'
import Vesting from './vesting'
import XORBModal from './xORBModal'
import xORBABI from '../../contracts/xORBAbi.abi'
import { Web3Context } from '../../context'
import erc20AbiAbi from '../../contracts/erc20Abi.abi'
import { transform, fromBN } from '../../factory/bigNumber'
import { requestNFT } from '../../factory/axios'
import BigNumber from 'bignumber.js'

import { Contract } from 'web3-eth-contract'

const styles = createUseStyles({
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  mainTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '40px',
    lineHeight: '60px',
    color: '#FFFFFF',
    paddingBottom: 10,
  },
  supTitle: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '32px',
    color: '#A8A8B2',
    width: 780,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    paddingBottom: 40,
  },
  mainBlock: {
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    padding: '30px 0px',
    paddingBottom: 30,
    display: 'block',
    width: '100%',
    fontFamily: 'Poppins',
  },
  overviewWrapper: {
    display: 'flex',
    gap: 30,
  },
  topWrapper: {
    display: 'flex',
    paddingTop: 30,
    gap: 30,
    paddingBottom: 30,
  },
  topCard: {
    width: 320,
    height: 56,
    padding: 20,
    borderRadius: 12,
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.80) 0%, rgba(53, 53, 81, 0.80) 100%)',
    boxShadow: '1px 1px 1px 0px #42426A inset',
    backdropFilter: 'backdrop-filter: blur(25px)',
    flexDirection: 'column',
    display: 'flex',
    gap: 5,
  },
  topCardTitleText: {
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: 'Poppins',
  },
  imgWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  icon: {
    width: 28,
    height: 28,
  },
  mainText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
  },
  bottomWrapper: {
    display: 'flex',
    gap: 30,
    paddingBottom: 30,
  },
  topCardTitleTextWrapper: {},
  '@media (max-width: 1250px)': {
    mainTitle: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '32px',
      lineHeight: '48px',
      color: '#FFFFFF',
      paddingBottom: 10,
    },
    topWrapper: {
      display: 'flex',
      paddingTop: 20,
      gap: 30,
      paddingBottom: 20,
    },
    supTitle: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '29px',
      color: '#A8A8B2',
      width: 700,
    },
    overviewWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
    },
    bottomWrapper: {
      display: 'flex',
      gap: 20,
      paddingBottom: 30,
      flexWrap: 'wrap',
      width: 700,
    },
    topCard: {
      width: '100%',
      height: 56,
      padding: 20,
      borderRadius: 12,
      background:
        'linear-gradient(180deg, rgba(49, 57, 84, 0.80) 0%, rgba(53, 53, 81, 0.80) 100%)',
      boxShadow: '1px 1px 1px 0px #42426A inset',
      backdropFilter: 'backdrop-filter: blur(25px)',
      display: 'flex',
      flexDirection: 'row',
      gap: 100,
    },
    topCardTitleTextWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },
  },
  '@media (max-width: 730px)': {
    mainWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    bottomWrapper: {
      display: 'flex',
      gap: 20,
      paddingBottom: 0,
      flexWrap: 'wrap',
      width: 360,
      justifyContent: 'center',
    },
    mainTitle: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '30px',
      color: '#FFFFFF',
      paddingBottom: 10,
    },
    topWrapper: {
      display: 'flex',
      gap: 20,
      paddingBottom: 20,
      flexWrap: 'wrap',
      width: 360,
      justifyContent: 'center',
    },
    supTitle: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '26px',
      color: '#A8A8B2',
      width: 330,
    },
    topCard: {
      width: 300,
      height: 132 - 40,
      padding: 20,
      borderRadius: 12,
      background:
        'linear-gradient(180deg, rgba(49, 57, 84, 0.80) 0%, rgba(53, 53, 81, 0.80) 100%)',
      boxShadow: '1px 1px 1px 0px #42426A inset',
      backdropFilter: 'backdrop-filter: blur(25px)',
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    topCardTitleTextWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 5,
    },
    mainText: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: 'normal',
      color: '#FFFFFF',
    },
    topCardTitleText: {
      fontWeight: 400,
      color: '#A8A8B2',
      fontSize: '16px',
      lineHeight: '21px',
      fontFamily: 'Poppins',
    },
    icon: {
      width: 24,
      height: 24,
    },
  },
})

const ORBITER_XORB_CONTRACT = process.env.REACT_APP_X_ORBITER_XORB_CONTRACT
const ORB_CONTRACT = process.env.REACT_APP_X_ORB_CONTRACT
const CONVERT_CONTRACT = process.env.REACT_APP_X_ORBITER_CONVERT_CONTRACT
const ORBITER_NFT_API_URL = process.env.REACT_APP_X_ORBITER_NFT_API_URL

const XORB = () => {
  const [open, setOpen] = useState({
    open: false,
    value: '',
  })
  const [convertContract, setConvertContract] = useState<Contract | null>(null)
  const [orbContract, setOrbContract] = useState<Contract | null>(null)
  const [xOrbContract, setXOrbContract] = useState(null)
  const [userXORBBalance, setUserXORBBalance] = useState('0')
  const [userORBBalance, setUserORBBalance] = useState('0')
  const [data, setData] = useState<string | null>(null)
  const [redemption, setRedemption] = useState('0')
  const [loading, setLoading] = useState(true)

  const classes = styles()
  const { user } = useSelector((state: any) => state.userReducer)
  const { web3 } = useContext(Web3Context)

  const isDescktop = window.innerWidth > 1250

  useEffect(() => {
    defineContracts()
  }, [user])

  useEffect(() => {
    if (convertContract) {
      getBalance()
    }
  }, [convertContract])

  useEffect(() => {
    if (user.address) {
      requestNFT({
        method: 'get',
        url: `${ORBITER_NFT_API_URL}/x-orb/${user.address}`,
      })
        .then((req) => {
          setData(req.data.redemptions)
          setRedemption(new BigNumber(req.data.totalRedemption).toFixed(0))
        })
        .finally(() => setLoading(false))
    }
  }, [user.address])

  const defineContracts = async () => {
    if (!web3.eth) return

    const convertContract: Contract = new web3.eth.Contract(
      xORBABI,
      CONVERT_CONTRACT
    )
    setConvertContract(convertContract)
    const orbContract: Contract = new web3.eth.Contract(
      erc20AbiAbi,
      ORB_CONTRACT
    )
    setOrbContract(orbContract)

    const xOrbContract = new web3.eth.Contract(
      erc20AbiAbi,
      ORBITER_XORB_CONTRACT
    )
    setXOrbContract(xOrbContract)
  }

  const getBalance = async () => {
    try {
      let ORBresult = '0'

      if (orbContract) {
        ORBresult = await orbContract.methods.balanceOf(user.address).call()
      }

      setUserORBBalance(fromBN(ORBresult, 18).toString())

      const xORBresult = await (xOrbContract as unknown as Contract).methods
        .balanceOf(user.address)
        .call()

      setUserXORBBalance(fromBN(xORBresult, 18).toString())
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.topWrapper}>
        {!isDescktop ? (
          <div className={classes.topCard}>
            <div className={classes.topCardTitleTextWrapper}>
              <span className={classes.topCardTitleText}>Total xORB</span>
              <div className={classes.imgWrapper}>
                <img className={classes.icon} src={xORBIcon} alt="" />
                <span className={classes.mainText}>
                  {transform(userXORBBalance, 4, [fromBN(redemption, 18)])}
                </span>
              </div>
            </div>
            <div className={classes.topCardTitleTextWrapper}>
              <span className={classes.topCardTitleText}>Available xORB</span>
              <div className={classes.imgWrapper}>
                <img className={classes.icon} src={xORBIcon} alt="" />
                <span className={classes.mainText}>
                  {transform(userXORBBalance, 4)}
                </span>
              </div>
            </div>
            <div className={classes.topCardTitleTextWrapper}>
              <span className={classes.topCardTitleText}>Redeeming xORB</span>
              <div className={classes.imgWrapper}>
                <img className={classes.icon} src={xORBIcon} alt="" />
                <span className={classes.mainText}>
                  {transform(fromBN(redemption, 18), 4)}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={classes.topCard}>
              <span className={classes.topCardTitleText}>Total xORB</span>
              <div className={classes.imgWrapper}>
                <img className={classes.icon} src={xORBIcon} alt="" />
                <span className={classes.mainText}>
                  {transform(userXORBBalance, 4, [fromBN(redemption, 18)])}
                </span>
              </div>
            </div>
            <div className={classes.topCard}>
              <span className={classes.topCardTitleText}>Available xORB</span>
              <div className={classes.imgWrapper}>
                <img className={classes.icon} src={xORBIcon} alt="" />
                <span className={classes.mainText}>
                  {transform(userXORBBalance, 4)}
                </span>
              </div>
            </div>
            <div className={classes.topCard}>
              <span className={classes.topCardTitleText}>Redeeming xORB</span>
              <div className={classes.imgWrapper}>
                <img className={classes.icon} src={xORBIcon} alt="" />
                <span className={classes.mainText}>
                  {transform(fromBN(redemption, 18), 4)}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={classes.bottomWrapper}>
        <Convert
          convertContract={convertContract}
          orbBalance={userORBBalance}
          getBalance={getBalance}
        />
        <Redeem
          userXORBBalance={userXORBBalance}
          convertContract={convertContract}
          getBalance={getBalance}
          orbContract={xOrbContract}
          setData={setData}
          setRedemption={setRedemption}
        />
        <Vesting
          setOpen={setOpen}
          data={data}
          loading={loading}
          getBalance={getBalance}
          convertContract={convertContract}
          setData={setData}
          setRedemption={setRedemption}
        />
      </div>
      {open.open && (
        <XORBModal
          open={open}
          setOpen={setOpen}
          getBalance={getBalance}
          convertContract={convertContract}
          setData={setData}
          setRedemption={setRedemption}
        />
      )}
    </div>
  )
}

export default XORB
