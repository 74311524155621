import React, { useContext, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import ButtonIcon from '../../img/RepayModal.svg'
import closeIcon from '../../img/close-circle.svg'
import cEthAbi from '../../contracts/cEthAbi.abi'
import cErcAbi from '../../contracts/cErcAbi.abi'
import { fromBn, toBn } from 'evm-bn'
import { Web3Context } from '../../context'
import { useDispatch, useSelector } from 'react-redux'
import { transform } from '../../factory/bigNumber'
import erc20Abi from '../../contracts/erc20Abi.abi'
import {
  deleteNotifications,
  setNotifications,
} from '../../redux/notifications'
import { Asset } from '../Assets/Assets'
import refetch from '../../utils/Refetch'
import { commify, numberToStringInput, positionHealthColor } from '../../utils'
import { Tooltip } from 'antd'
import usePositionHealth from 'src/hooks/usePositionHealth'
import uniqid from 'uniqid'
import BigNumber from 'bignumber.js'
import bigDecimal from 'js-big-decimal'

const styles = createUseStyles({
  modalBg: {
    width: '100%',
    background: 'rgb(0 0 0 / 70%)',
    height: '100vh',
    position: 'fixed',
    top: 0,
    zIndex: 15,
  },
  collateralModal: {
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    backdropFilter: 'blur(25px)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    padding: [30, 20, 20],
    position: 'absolute',
    marginLeft: 'auto',
    width: 560,
    marginRight: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    borderRadius: '12px',
    cursor: 'default',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '30px',
    color: '#FFFFFF',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  inputDescription: {},
  inputWrapper: {
    backgroundColor: '#1F1F2D',
    borderRadius: 12,
    width: '100%',
    marginBottom: 8,
    boxSizing: 'border-box',
    padding: [10, 15],
  },
  inputDescriptionText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
  },
  cursorDisable: {
    cursor: 'not-allowed',
  },
  inputPlaceholderText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    whiteSpace: 'nowrap',
    width: '100%',
    backgroundColor: '#1F1F2D',
    border: 'none',
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 400,
    color: '#fff',

    '&:placeholder': {
      color: '#7D7E83',
    },
  },
  infoText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#A8A8B2',
  },
  withdrawMainTextBlock: {
    paddingTop: 20,
  },
  withdrawTextBlock: {
    paddingTop: 20,
  },
  withdrawTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  withdrawSubText: {
    color: '#A8A8B2',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  withdrawMainText: {
    extend: 'withdrawSubText',
    color: '#FFFFFF',
    fontWeight: 600,
  },
  positionHealth: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 5,
  },
  red: {
    width: 4,
    height: 14,
    backgroundColor: '#E2006E',
    borderRadius: '52px',
    marginRight: 5,
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  hr: {
    backgroundColor: '#A8A8B2',
    width: 1,
    height: 16,
  },
  depositSelectWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  depositSelectButton: {
    fontFamily: 'Poppins',
    cursor: 'pointer',
    backgroundColor: '#1F1F2D',
    width: 52,
    height: 22,
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 42,
  },
  borrowInformationBlock: {
    paddingTop: 20,
  },
  borrowInformationText: {
    color: '#A8A8B2',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
  },
  borrowInformationTextYellow: {
    color: '#FBC928',
  },
  borrowInformationCheckBoxWrapper: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 20,
    alignItems: 'center',
    gap: 10,
  },
  borrowInformationCheckBoxText: {
    color: '#5493F7',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
  },
  borrowInformationCheckBox: {
    width: 21,
    height: 21,
  },
  depositButton: {
    backgroundColor: '#5493F7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0',
    width: '320px',
    margin: '40px auto 0',
    border: 'none',
    borderRadius: 12,
    cursor: 'pointer',
  },
  depositButtonDisable: {
    extend: 'depositButton',
    backgroundColor: '#44445B',
    cursor: 'not-allowed',
  },
  depositButtonText: {
    fontFamily: 'Poppins',
    paddingLeft: 10,
    lineHeight: '27px',
    fontSize: '18px',
    fontWeight: 600,
    color: '#FFFFFF',
  },
  tooltipWithoutRewards: {
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  noTexttooltipItemWithoutRewards: {
    extend: 'tooltipItemWithoutRewards',
    justifyContent: 'center',
  },
  '@media (max-width: 730px)': {
    tooltipWithRewards: {
      width: '165px',
      padding: '12px 16px',
      background: '#1F1F2D',
      borderRadius: '12px',
      height: '100%',
    },
    tooltipWithRewardsLong: {
      extend: 'tooltipWithRewards',
      minWidth: '165px',
      width: '100%',
    },
    tooltipItem: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '4px',
    },
    tooltipItemText: {
      color: '#A8A8B2',
      fontWeight: 400,
      fontSize: '14px',
    },
    tooltipItemTextLong: {
      extend: 'tooltipItemText',
      marginRight: '10px',
    },
    tooltipItemNumber: {
      color: '#FFFFFF',
      fontWeight: 500,
      fontSize: '14px',
    },
    tooltipItemTextSign: {
      color: '#5493F7',
      fontWeight: 500,
      marginRight: '4px',
    },
    tooltipLine: {
      width: '100%',
      height: '1px',
      borderBottom: 'none',
      border: 'none',
      backgroundColor: '#4B567D',
      marginTop: '8px',
      marginBottom: '8px',
    },
    tooltipItemTotal: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '0px',
    },
    tooltipWithoutRewards: {
      padding: '12px 16px',
      background: '#1F1F2D',
      borderRadius: '12px',
      height: '100%',
    },
    tooltipItemWithoutRewards: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '0px',
    },
    tooltipWithoutRewardsLong: {
      extend: 'tooltipWithoutRewards',
      // minWidth: '165px',
      // width: '100%',
    },

    lastTooltipItem: {
      extend: 'tooltipItem',
      marginBottom: '0px',
    },
    lastTooltipItemLong: {
      extend: 'tooltipItemLong',
      marginBottom: '0px',
    },
    noTexttooltipItemWithoutRewards: {
      extend: 'tooltipItemWithoutRewards',
      justifyContent: 'center',
    },
    collateralModal: {
      width: 300,
    },
    title: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '27px',
    },
    depositButton: {
      width: 300,
    },
    depositButtonDisable: {
      width: 300,
    },
  },
})

interface Props {
  openRepayModal: {
    open: boolean
    token: any
  }
  setOpenRepayModal: (a: { open: boolean; token: any }) => void
}

const RepayModal: React.FC<Props> = ({ openRepayModal, setOpenRepayModal }) => {
  const [inputValue, setInputValue] = useState('')
  const [tokenContract, setTokenContract] = useState(null)
  const [marketContract, setMarketContract] = useState(null)
  const [isMainToken, setIsMainToken] = useState(false)
  const [tokenBalance, setTokenBalance] = useState<any>('')
  const [tokenAllowance, setTokenAllowance] = useState('')

  const { web3 } = useContext(Web3Context)
  const { user } = useSelector((state: any) => state.userReducer)
  const { data: assets } = useSelector((state: any) => state.assetsReducer)
  const { userBalances } = useSelector(
    (state: any) => state.userBalancesReducer
  )
  const { asset } = useSelector((state: any) => state.assetReducer)

  const repayAsset = assets.find(
    (asset: any) => asset._id === openRepayModal.token.token._id
  )

  const { percentage, coefficient } = usePositionHealth(
    'repay',
    inputValue,
    userBalances.totalBorrowed,
    userBalances.totalCollateral,
    repayAsset.lastPrice
  )

  const zeroInInput = !(inputValue.replace(/[\s,]/g, '') == 0)

  const infinity =
    '115792089237316195423570985008687907853269984665640564039457.584007913129639935'

  const dispatch = useDispatch()

  const checkAllowance = async () => {
    try {
      const result = await tokenContract.methods
        .allowance(user.address, repayAsset.oTokenAddress)
        .call()

      setTokenAllowance(fromBn(result, repayAsset.tokenDecimal).toString())
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!repayAsset._id) return

    setIsMainToken(!repayAsset.tokenAddress)

    defineContracts(repayAsset, !repayAsset.tokenAddress)
    setInputValue('')
  }, [])

  useEffect(() => {
    if (!tokenContract || !user.address) return

    getTokenBalance()
    !isMainToken && checkAllowance()
  }, [tokenContract, user.address])

  const repayNotification = {
    textInfoApproval: `You need to grant access to your ${repayAsset.symbol} funds to Orbiter One Protocol before completing your payment. Confirm the fund approval in your wallet.`,
    textErrorApproval:
      'You denied the transaction request in your wallet. Please resubmit your transaction.',
    textSubmittedApproval: `You submitted your approval to grant Orbiter One Protocol access to your ${repayAsset.symbol} funds. This process might take a few minutes to complete.`,
    textSuccessApproval: 'Complete your transaction in your wallet.',

    textSuccessRepay:
      'Your repayment is complete and your balances have been updated.',
    textErrorRepay:
      'You denied the transaction request in your wallet. Please resubmit your transaction.',
    textInfoRepay:
      'Your payment is in progress; this process might take a couple of minutes.\n' +
      'Please confirm your payment in your wallet.',
    textSubmittedRepay:
      'Your payment has been submitted. Your payment might take a couple of minutes to complete.',
  }

  const classes = styles()

  const disabled = !tokenBalance || tokenBalance === '0'

  const disableButton =
    !!inputValue &&
    +inputValue.replace(/[\s,]/g, '') !== 0 &&
    +inputValue.toString().replace(/[\s,]/g, '') <=
      openRepayModal.token.value &&
    +inputValue.toString().replace(/[\s,]/g, '') <= +tokenBalance

  const approveAndDeposit =
    (+inputValue.replace(/[\s,]/g, '') > +tokenAllowance && !isMainToken) ||
    tokenAllowance === '0'

  const defineContracts = async (asset: Asset, isMainToken: boolean) => {
    const tokenContract = new web3.eth.Contract(erc20Abi, asset.tokenAddress)
    setTokenContract(tokenContract)

    const marketContract = new web3.eth.Contract(
      isMainToken ? cEthAbi : cErcAbi,
      asset.oTokenAddress
    )
    setMarketContract(marketContract)
  }

  const handleCloseModal = () => {
    const body = document.body
    body.style.height = ''
    body.style.overflowY = ''
    setOpenRepayModal({ ...openRepayModal, open: false })
  }

  const getTokenBalance = async () => {
    try {
      let result

      if (isMainToken) {
        result = await web3.eth.getBalance(user.address)
      } else {
        result = await tokenContract.methods.balanceOf(user.address).call()
      }
      setTokenBalance(fromBn(result, repayAsset.tokenDecimal).toString())
    } catch (error) {
      console.log(error)
    }
  }

  const repayWithoutMainToken = async (amount: any) => {
    const transactionsId = uniqid()

    dispatch(
      setNotifications({
        type: 'info',
        text: repayNotification.textInfoRepay,
        link: null,
      })
    )

    let gasPrice = await web3.eth.getGasPrice()

    gasPrice = +gasPrice
    gasPrice += gasPrice

    let gasLimit = await marketContract.methods
      .repayBorrow(toBn(amount, repayAsset.tokenDecimal).toString())
      .estimateGas({
        from: user.address,
      })

    gasLimit = +gasLimit
    gasLimit += gasLimit

    const repayResult = await marketContract.methods
      .repayBorrow(toBn(amount, repayAsset.tokenDecimal).toString())
      .send({
        from: user.address,
        gasLimit: web3.utils.toHex(Math.round(gasLimit)),
        gasPrice: web3.utils.toHex(Math.round(gasPrice)),
      })
      .on(
        'transactionHash',
        (hash: string) =>
          hash &&
          dispatch(
            setNotifications({
              type: 'info',
              text: repayNotification.textSubmittedRepay,
              link: hash,
              id: transactionsId,
              isPending: true,
            })
          )
      )
    dispatch(deleteNotifications(transactionsId))
    setTimeout(() => {
      refetch(dispatch, user.address, asset)
    }, 5000)
    dispatch(
      setNotifications({
        type: 'success',
        text: repayNotification.textSuccessRepay,
        link: repayResult.transactionHash,
      })
    )
    console.log('repayResult', repayResult)
    checkAllowance()
  }

  const repay = async (amount: any) => {
    const approveId = uniqid()
    const transactionsId = uniqid()

    handleCloseModal()

    try {
      if (isMainToken) {
        dispatch(
          setNotifications({
            type: 'info',
            text: repayNotification.textInfoRepay,
            link: null,
          })
        )
        let gasPrice = await web3.eth.getGasPrice()

        gasPrice = +gasPrice
        gasPrice += gasPrice

        let gasLimit = await marketContract.methods.repayBorrow().estimateGas({
          from: user.address,
        })

        gasLimit = +gasLimit
        gasLimit += gasLimit

        const repayResult = await marketContract.methods
          .repayBorrow()
          .send({
            from: user.address,
            gasLimit: web3.utils.toHex(Math.round(gasLimit)),
            gasPrice: web3.utils.toHex(Math.round(gasPrice)),
            value: toBn(amount.toString(), repayAsset.tokenDecimal).toString(),
          })
          .on(
            'transactionHash',
            (hash: string) =>
              hash &&
              dispatch(
                setNotifications({
                  type: 'info',
                  text: repayNotification.textSubmittedRepay,
                  link: hash,
                  id: transactionsId,
                  isPending: true,
                })
              )
          )
        dispatch(deleteNotifications(transactionsId))
        dispatch(
          setNotifications({
            type: 'success',
            text: repayNotification.textSuccessRepay,
            link: repayResult.transactionHash,
          })
        )
        setTimeout(() => {
          refetch(dispatch, user.address, asset)
        }, 5000)
      } else {
        if (
          +inputValue.replace(/[\s,]/g, '') > +tokenAllowance &&
          !isMainToken
        ) {
          dispatch(
            setNotifications({
              type: 'info',
              text: repayNotification.textInfoApproval,
              link: null,
            })
          )
          let gasPrice = await web3.eth.getGasPrice()

          gasPrice = +gasPrice
          gasPrice += gasPrice

          let gasLimit = await marketContract.methods
            .approve(
              repayAsset.oTokenAddress,
              toBn(
                `${inputValue.replace(/[\s,]/g, '')}`,
                repayAsset.tokenDecimal
              ).toString()
            )
            .estimateGas({
              from: user.address,
            })

          gasLimit = +gasLimit
          gasLimit += gasLimit

          const approveResult = await tokenContract.methods
            .approve(
              repayAsset.oTokenAddress,
              toBn(`${infinity}`, repayAsset.tokenDecimal).toString()
            )
            .send({
              from: user.address,
              gasLimit: web3.utils.toHex(Math.round(gasLimit)),
              gasPrice: web3.utils.toHex(Math.round(gasPrice)),
            })
            .on(
              'transactionHash',
              (hash: string) =>
                hash &&
                dispatch(
                  setNotifications({
                    type: 'info',
                    text: repayNotification.textSubmittedApproval,
                    link: hash,
                    id: approveId,
                    isPending: true,
                  })
                )
            )
          dispatch(deleteNotifications(approveId))
          dispatch(
            setNotifications({
              type: 'success',
              text: repayNotification.textSuccessApproval,
              link: approveResult.transactionHash,
            })
          )

          setTimeout(() => {
            dispatch(
              setNotifications({
                type: 'info',
                text: repayNotification.textInfoRepay,
                link: null,
              })
            )
          }, 1000)

          console.log('approveResult', approveResult)
          checkAllowance()
          await repayWithoutMainToken(amount)
        } else {
          await repayWithoutMainToken(amount)
        }
      }
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: repayNotification.textErrorRepay,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const enterPercentInInput = (percent: number) => {
    if (+tokenBalance > +openRepayModal.token.value) {
      const availableToRepayAssetBN = new BigNumber(openRepayModal.token.value)

      // @ts-ignore
      setInputValue(
        commify(bigDecimal.multiply(availableToRepayAssetBN, percent))
      )
    } else {
      const availableToRepayAssetWallet = new BigNumber(tokenBalance)
      setInputValue(
        commify(bigDecimal.multiply(availableToRepayAssetWallet, percent))
      )
    }
  }

  return (
    <div
      className={classes.modalBg}
      onClick={(e) => {
        e.target === e.currentTarget && handleCloseModal()
      }}
    >
      <div className={classes.collateralModal}>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>
            Repay your borrowed {openRepayModal.token.token.symbol}
          </div>
          <div>
            <img
              className={classes.closeIcon}
              onClick={handleCloseModal}
              src={closeIcon}
              alt=""
            />
          </div>
        </div>
        <div className={classes.inputWrapper}>
          <div className={classes.inputDescription}>
            <div className={classes.inputDescriptionText}>Repay</div>
          </div>
          <div className={classes.inputDescription}>
            {disabled ? (
              <span
                className={[
                  classes.inputPlaceholderText,
                  classes.cursorDisable,
                ].join(' ')}
              >
                You have no <a>{repayAsset.symbol}</a>
              </span>
            ) : (
              <input
                type="text"
                className={classes.inputPlaceholderText}
                placeholder={'Enter the amount'}
                style={{ outline: 'none' }}
                value={inputValue}
                onChange={(e) =>
                  setInputValue(
                    `${commify(
                      `${e.target.value
                        .replace(/[^0-9.]/g, '')
                        .replace(/(\..*)\./g, '$1')}`
                    )}`
                  )
                }
              />
            )}
          </div>
        </div>
        {!disabled ? (
          <div className={classes.depositSelectWrapper}>
            <div
              className={classes.depositSelectButton}
              onClick={() => enterPercentInInput(0.25)}
            >
              25%
            </div>
            <div
              className={classes.depositSelectButton}
              onClick={() => enterPercentInInput(0.5)}
            >
              50%
            </div>
            <div
              className={classes.depositSelectButton}
              onClick={() => enterPercentInInput(0.75)}
            >
              75%
            </div>
            <div
              className={classes.depositSelectButton}
              onClick={() => enterPercentInInput(1)}
            >
              MAX
            </div>
          </div>
        ) : null}
        <div className={classes.withdrawTextBlock}>
          <div className={classes.withdrawTextWrapper}>
            <div className={classes.withdrawSubText}>Current Health</div>
            <div className={classes.withdrawMainText}>
              <div className={classes.positionHealth}>
                <div className={classes.wrap}>
                  <div
                    className={classes.red}
                    style={{
                      backgroundColor: positionHealthColor(
                        +userBalances.positionHealth.percentage
                      ),
                    }}
                  ></div>
                  <div>
                    {Math.round(userBalances.positionHealth.coefficient * 100) /
                      100}
                  </div>
                </div>
                <div className={classes.hr}></div>
                <div>
                  {Math.round(userBalances.positionHealth.percentage * 100) /
                    100}
                  %
                </div>
              </div>
            </div>
          </div>
          <div className={classes.withdrawTextBlock}>
            {/*<div className={classes.withdrawTextWrapper}>*/}
            {/*  <span className={classes.withdrawSubText}>*/}
            {/*    Currently Allowance({openRepayModal.token.token.symbol})*/}
            {/*  </span>*/}
            {/*  <span className={classes.withdrawMainText}>*/}
            {/*    {+tokenAllowance > 10000000 ? 'Infinite' : +tokenAllowance}*/}
            {/*  </span>*/}
            {/*</div>*/}
            <div className={classes.withdrawTextWrapper}>
              <span className={classes.withdrawSubText}>
                Currently Holdings ({openRepayModal.token.token.symbol})
              </span>
              <span className={classes.withdrawMainText}>
                <Tooltip
                  overlayInnerStyle={{ boxShadow: 'none' }}
                  color="none"
                  placement="top"
                  title={
                    <div className={classes.tooltipWithoutRewards}>
                      <div className={classes.noTexttooltipItemWithoutRewards}>
                        {/* <span className={classes.tooltipItemText}>Balance</span> */}
                        <span>
                          {commify(transform(tokenBalance, 6)) || '0'}
                        </span>
                      </div>
                    </div>
                  }
                >
                  {transform(tokenBalance)}
                </Tooltip>
              </span>
            </div>
          </div>
          <div className={classes.withdrawTextBlock}>
            <div className={classes.withdrawTextWrapper}>
              <span className={classes.withdrawSubText}>
                Currently Borrowed ({openRepayModal.token.token.symbol})
              </span>
              <span className={classes.withdrawMainText}>
                <Tooltip
                  overlayInnerStyle={{ boxShadow: 'none' }}
                  color="none"
                  placement="top"
                  title={
                    <div className={classes.tooltipWithoutRewards}>
                      <div className={classes.noTexttooltipItemWithoutRewards}>
                        {/* <span className={classes.tooltipItemText}>Balance</span> */}
                        <span>
                          {commify(transform(openRepayModal.token.value, 6))}
                        </span>
                      </div>
                    </div>
                  }
                >
                  {transform(openRepayModal.token.value)}
                </Tooltip>
              </span>
            </div>
            <div className={classes.withdrawTextWrapper}>
              <span className={classes.withdrawSubText}>
                Currently Borrowed (USD)
              </span>
              <span className={classes.withdrawMainText}>
                <Tooltip
                  overlayInnerStyle={{ boxShadow: 'none' }}
                  color="none"
                  placement="top"
                  title={
                    <div className={classes.tooltipWithoutRewards}>
                      <div className={classes.noTexttooltipItemWithoutRewards}>
                        {/* <span className={classes.tooltipItemText}>Balance</span> */}
                        <span>
                          {commify(
                            transform(
                              (
                                openRepayModal.token.value *
                                repayAsset.lastPrice
                              ).toString(),
                              6
                            )
                          )}
                        </span>
                      </div>
                    </div>
                  }
                >
                  {transform(openRepayModal.token.value * repayAsset.lastPrice)}
                </Tooltip>
              </span>
            </div>
          </div>
          <div className={classes.withdrawTextBlock}>
            <div className={classes.withdrawTextWrapper}>
              <span className={classes.withdrawSubText}>
                Borrowed after repay ({openRepayModal.token.token.symbol})
              </span>
              <span className={classes.withdrawMainText}>
                <Tooltip
                  overlayInnerStyle={{ boxShadow: 'none' }}
                  color="none"
                  placement="top"
                  title={
                    <div className={classes.tooltipWithoutRewards}>
                      <div className={classes.noTexttooltipItemWithoutRewards}>
                        {/* <span className={classes.tooltipItemText}>Balance</span> */}
                        <span>
                          {!!inputValue &&
                          +inputValue.replace(/[\s,]/g, '') <=
                            +openRepayModal.token.value &&
                          zeroInInput
                            ? commify(
                                transform(
                                  numberToStringInput(
                                    openRepayModal.token.value -
                                      inputValue
                                        .toString()
                                        .replace(/[\s,]/g, '')
                                  ),
                                  6
                                )
                              )
                            : 'N/A'}
                        </span>
                      </div>
                    </div>
                  }
                >
                  {!!inputValue &&
                  +inputValue.replace(/[\s,]/g, '') <=
                    +openRepayModal.token.value &&
                  zeroInInput
                    ? transform(
                        openRepayModal.token.value -
                          inputValue.replace(/[\s,]/g, '')
                      )
                    : 'N/A'}
                </Tooltip>
              </span>
            </div>
            <div className={classes.withdrawTextWrapper}>
              <span className={classes.withdrawSubText}>
                Borrowed after repay (USD)
              </span>
              <span className={classes.withdrawMainText}>
                <Tooltip
                  overlayInnerStyle={{ boxShadow: 'none' }}
                  color="none"
                  placement="top"
                  title={
                    <div className={classes.tooltipWithoutRewards}>
                      <div className={classes.noTexttooltipItemWithoutRewards}>
                        {/* <span className={classes.tooltipItemText}>Balance</span> */}
                        <span>
                          {!!inputValue &&
                          +inputValue.replace(/[\s,]/g, '') <=
                            +openRepayModal.token.value &&
                          zeroInInput
                            ? commify(
                                transform(
                                  numberToStringInput(
                                    (openRepayModal.token.value -
                                      inputValue.replace(/[\s,]/g, '')) *
                                      repayAsset.lastPrice
                                  ),
                                  6
                                )
                              )
                            : 'N/A'}
                        </span>
                      </div>
                    </div>
                  }
                >
                  {!!inputValue &&
                  +inputValue.replace(/[\s,]/g, '') <=
                    +openRepayModal.token.value &&
                  zeroInInput
                    ? transform(
                        numberToStringInput(
                          (openRepayModal.token.value -
                            inputValue.replace(/[\s,]/g, '')) *
                            repayAsset.lastPrice
                        )
                      )
                    : 'N/A'}
                </Tooltip>
              </span>
            </div>
            <div className={classes.withdrawTextWrapper}>
              <span className={classes.withdrawSubText}>
                Health after repay
              </span>
              <div className={classes.withdrawMainText}>
                {inputValue && zeroInInput ? (
                  <div className={classes.positionHealth}>
                    <div className={classes.wrap}>
                      <div
                        className={classes.red}
                        style={{
                          backgroundColor: positionHealthColor(percentage),
                        }}
                      ></div>
                      <div>{coefficient}</div>
                    </div>
                    <div className={classes.hr}></div>
                    <div>{percentage}%</div>
                  </div>
                ) : (
                  'N/A'
                )}
              </div>
            </div>
          </div>
        </div>
        <button
          disabled={!disableButton}
          onClick={() => repay(inputValue.toString().replace(/[\s,]/g, ''))}
          className={
            disableButton ? classes.depositButton : classes.depositButtonDisable
          }
        >
          <img src={ButtonIcon} />
          <span className={classes.depositButtonText}>
            {approveAndDeposit ? 'APPROVE & REPAY' : 'REPAY'}{' '}
            {openRepayModal.token.token.symbol}
          </span>
        </button>
      </div>
    </div>
  )
}

export default RepayModal
