import { useState, useEffect } from 'react'

export default function useWindowSize() {
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return {
    currentWidth: width,
    isDesktop: width > 1250,
    isTablet: width > 730,
  }
}
