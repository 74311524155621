import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface assetState {
  data: {
    supplied: [
      {
        token: {
          _id: string
          name: string
          symbol: string
          image: string
          apy: string
          tokenDecimal: number
        }
        collateral: false
        value: string
      }
    ]
    borrowed: [
      {
        token: {
          _id: string
          name: string
          symbol: string
          image: string
          apy: string
          tokenDecimal: number
        }
        collateral: false
        value: string
      }
    ]
  }
}

const initialState: assetState = {
  data: {
    supplied: [
      {
        token: {
          _id: '',
          name: '',
          symbol: '',
          image: '',
          apy: '',
          tokenDecimal: 0,
        },
        collateral: false,
        value: '',
      },
    ],
    borrowed: [
      {
        token: {
          _id: '',
          name: '',
          symbol: '',
          image: '',
          apy: '',
          tokenDecimal: 0,
        },
        collateral: false,
        value: '',
      },
    ],
  },
}

export const userAssetsSlice = createSlice({
  name: 'userAssets',
  initialState,
  reducers: {
    setUserAssets: (state, action: PayloadAction<any>) => {
      state.data = action.payload
    },
  },
})

export const { setUserAssets } = userAssetsSlice.actions

export default userAssetsSlice.reducer
