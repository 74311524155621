import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface transactionsState {
  data: {
    page: 0
    pages: 0
    countItems: 0
    entities: [
      {
        token: {
          _id: string
          name: string
          symbol: string
          image: string
          color: string
        }
        txHash: string
        event: string
        status: true
        data: {}
        createdAt: string
      }
    ]
  }
}

const initialState: transactionsState = {
  data: {
    page: 0,
    pages: 0,
    countItems: 0,
    entities: [
      {
        token: {
          _id: '',
          name: '',
          symbol: '',
          image: '',
          color: '',
        },
        txHash: '',
        event: '',
        status: true,
        data: {},
        createdAt: '',
      },
    ],
  },
}

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setTransactions: (state, action: PayloadAction<any>) => {
      state.data = action.payload
    },
  },
})
export const { setTransactions } = transactionsSlice.actions

export default transactionsSlice.reducer
