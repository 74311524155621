import React, { useContext, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import info from '../../img/NFT/info-circle.svg'
import coinIcon from '../../img/coin.svg'
import { requestNFT } from '../../factory/axios'
import { useDispatch, useSelector } from 'react-redux'
import { commify, sortRewordsAssets, totalIncentives } from '../../utils'
import { transform } from '../../factory/bigNumber'
import uniqid from 'uniqid'
import {
  deleteNotifications,
  setNotifications,
} from '../../redux/notifications'
import { claimNotification } from '../../utils/notificationTexts'
import nftStakingContract from '../../contracts/nft-staking.abi'
import { Web3Context } from '../../context'
import Skeleton from 'react-loading-skeleton'
import { setOpenWalletModal } from '../../redux/loading'
import { Tooltip } from 'antd'
import { toBn } from 'evm-bn'
import stakingIcon from '../../img/NFT/stakeNftIcon.svg'

const styles = createUseStyles({
  titleText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
  },
  subTitleText: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
    paddingBottom: 5,
  },
  mainText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 30,
  },
  greenText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#53CBC8',
  },
  mainBlock: {
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    boxSizing: 'border-box',
    padding: '20px',
    display: 'block',
    width: 750,
    fontFamily: 'Poppins',
    marginBottom: 30,
  },
  documentsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    cursor: 'pointer',
  },
  overviewInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 40,
    paddingRight: 91,
  },
  totalWrapper: {},
  stakedWrapper: {},
  // rewardsWrapper: {
  //   width: 140
  // },
  claimButton: {
    width: 340,
    height: 60,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    cursor: 'not-allowed',
    backgroundColor: '#4B567D',
    borderRadius: 12,
    border: 'none',
    gap: 10,

    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#FFFFFF',
  },
  activeClaimButton: {
    extend: 'claimButton',
    cursor: 'pointer',
    backgroundColor: '#5493F7',
  },
  orbWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  orbIcon: {
    width: 18,
    height: 18,
  },
  rewardsWrapper: {},
  buttonWrapper: {
    marginLeft: 'auto',
  },
  card: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
  },
  toolTipWrapper: {
    background: '#1F1F2D',
    borderRadius: 12,
    padding: [12, 16, 7],
    width: 162,
  },
  tooltipLine: {
    width: '100%',
    height: '1px',
    borderBottom: 'none',
    border: 'none',
    backgroundColor: '#4B567D',
    marginTop: '8px',
    marginBottom: '8px',
  },
  tooltipItemTextSign: {
    color: '#5493F7',
    fontWeight: 500,
    marginRight: '4px',
  },
  tooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 5,
  },
  tooltipMainText: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
  },
  tooltipSubText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
  },
  pointer: {
    cursor: 'pointer',
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  claimButtonWrapper: {
    display: 'flex',
    marginTop: 26,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  stakingNftIcon: {
    width: 54,
    height: 54,
    paddingBottom: 5,
  },
  stakingNftIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  '@media (max-width: 1250px)': {
    mainBlock: {
      marginBottom: 20,
      width: '100%',
    },
    titleText: {
      fontSize: '18px',
      lineHeight: '27px',
    },
    totalWrapper: {
      marginRight: 115,
    },
    stakedWrapper: {
      marginRight: 115,
    },
    overviewInfoWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 30,
      flexWrap: 'wrap',
      marginBottom: 20,
      paddingRight: 30,
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    claimButton: {
      width: 300,
      marginBottom: 5,
    },
    activeClaimButton: {
      width: 300,
      marginBottom: 5,
    },
  },
  '@media (max-width: 730px)': {
    overviewInfoWrapper: {
      gap: 10,
      flexDirection: 'column',
      justifyContent: 'start',
      paddingRight: 0,
    },
    card: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    rewardsWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    stakedWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      marginRight: 0,
    },
    totalWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      marginRight: 0,
    },
    subTitleText: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#A8A8B2',
      paddingBottom: 0,
    },
    titleText: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '27px',
      color: '#FFFFFF',
    },
    mainText: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#FFFFFF',
    },
    claimButton: {
      marginTop: 10,
      marginBottom: 0,
    },
    activeClaimButton: {
      marginTop: 10,
      marginBottom: 0,
    },
    mainBlock: {
      marginBottom: 20,
    },
    pointer: {
      cursor: 'pointer',
      display: 'flex',
      gap: 8,
      flexDirection: 'row-reverse',
      alignItems: 'center',
    },
    iconWrapper: {
      gap: 8,
    },
  },
})

const ORBITER_NFT_API_URL = process.env.REACT_APP_X_ORBITER_NFT_API_URL
const ORBITER_NFT_STAKING_CONTRACT_ADDRESS =
  process.env.REACT_APP_X_NFT_STAKING_CONTRACT

interface Props {
  loading: boolean
  stakingInfo: any
  rewards: any
  getReward: (a: string) => any
  setLoadingORB: (a: boolean) => void
  setRewards: (a: any) => void
  loadingORB: boolean
}

const StakingOverview: React.FC<Props> = ({
  loading,
  stakingInfo,
  getReward,
  rewards,
  setLoadingORB,
  loadingORB,
  setRewards,
}) => {
  const classes = styles()
  const [contract, setContract] = useState(null)

  const { web3 } = useContext(Web3Context)

  const { user } = useSelector((state: any) => state.userReducer)

  const dispatch = useDispatch()

  const isMobile = window.innerWidth <= 730

  // const isDisabled = rewards > 0

  useEffect(() => {
    if (!user.connected) {
      setLoadingORB(false)
    }
    if (user.address) {
      defineContracts()
    }
  }, [user.address])

  const defineContracts = async () => {
    const contract = new web3.eth.Contract(
      nftStakingContract,
      ORBITER_NFT_STAKING_CONTRACT_ADDRESS
    )
    setContract(contract)
  }

  const claim = async () => {
    const transactionsId = uniqid()

    dispatch(
      setNotifications({
        type: 'info',
        text: claimNotification.textInfoClaim,
        link: null,
      })
    )
    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await contract.methods.claimRewards().estimateGas({
        from: user.address,
      })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      const result = await contract.methods
        .claimRewards()
        .send({
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
          from: user.address,
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: claimNotification.textSubmittedClaim,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          text: claimNotification.textSuccessClaim,
          link: result.transactionHash,
        })
      )
      requestNFT({
        method: 'get',
        url: `${ORBITER_NFT_API_URL}/user-nft/staking-rewards/${user.address}`,
      }).then((req) => setRewards(req.data.rewards))
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: claimNotification.textErrorClaim,
          link: null,
        })
      )
      console.log('error', error)
    }
  }

  const getTotalAPR = () => {
    return stakingInfo?.assetsData?.reduce(
      (accumulator, item) => +accumulator + item.APY,
      [0]
    )
  }

  const isDisabled = () => {
    return rewards?.reduce(
      (accumulator, item) => +accumulator + +item.reward,
      [0]
    )
  }

  const filterRewards = (rewards: any) => {
    return rewards.filter(
      (el: any) => el.symbol === 'ORB' || el.symbol === 'xORB'
    )
  }

  return (
    <div className={classes.mainBlock}>
      <div className={classes.titleWrapper}>
        <div className={classes.titleText}>Rewards</div>
      </div>
      <div className={classes.overviewInfoWrapper}>
        {isMobile ? (
          <div className={classes.card}>
            <div className={classes.subTitleText}>Stake NFT APR</div>
            {!stakingInfo ? (
              <Skeleton
                baseColor="#4B567D"
                highlightColor="#5B6796"
                width="100px"
                height="20px"
                count={1}
                borderRadius="12px"
              />
            ) : (
              <Tooltip
                overlayInnerStyle={{ boxShadow: 'none' }}
                color="none"
                className={[classes.subTitleText, classes.pointer].join(' ')}
                title={
                  <div className={classes.toolTipWrapper}>
                    {filterRewards(stakingInfo?.assetsData).map((el, index) => {
                      return (
                        <div className={classes.tooltipItem}>
                          <div className={classes.tooltipSubText}>
                            {index !== 0 && (
                              <span className={classes.tooltipItemTextSign}>
                                +
                              </span>
                            )}
                            {el.symbol} APR
                          </div>
                          <div className={classes.tooltipMainText}>
                            {transform(el.APY, 2)}%
                          </div>
                        </div>
                      )
                    })}
                    <hr className={classes.tooltipLine} />
                    <div className={classes.tooltipItem}>
                      <div className={classes.tooltipSubText}>Total APR</div>
                      <div className={classes.tooltipMainText}>
                        {transform(getTotalAPR(), 2)}%
                      </div>
                    </div>
                  </div>
                }
              >
                <div className={classes.mainText}>
                  {transform(getTotalAPR(), 2)}%
                </div>
                <img src={info} alt="" />
              </Tooltip>
            )}
          </div>
        ) : null}
        {user.connected
          ? rewards
            ? filterRewards(rewards).map((el: any) => {
                return (
                  <div className={classes.card}>
                    <div className={classes.subTitleText}>
                      {el.symbol} Rewards
                    </div>
                    <div className={classes.iconWrapper}>
                      <img className={classes.orbIcon} src={el.image} alt="" />
                      <div className={classes.mainText}>
                        {commify(transform(el.reward, 4))}
                      </div>
                    </div>
                  </div>
                )
              })
            : [1, 2, 3, 4].map((el) => {
                return (
                  <div key={el} className={classes.card}>
                    <Skeleton
                      baseColor="#4B567D"
                      highlightColor="#5B6796"
                      width="100px"
                      height="20px"
                      count={1}
                      borderRadius="12px"
                    />
                    <Skeleton
                      baseColor="#4B567D"
                      highlightColor="#5B6796"
                      width="100px"
                      height="20px"
                      count={1}
                      borderRadius="12px"
                    />
                  </div>
                )
              })
          : null}
      </div>
      <div className={classes.claimButtonWrapper}>
        {isMobile ? null : (
          <div className={classes.card}>
            <div className={classes.stakingNftIconWrapper}>
              <img className={classes.stakingNftIcon} src={stakingIcon} />
              <div>
                <div className={classes.subTitleText}>Stake NFT APR</div>
                {!stakingInfo ? (
                  <Skeleton
                    baseColor="#4B567D"
                    highlightColor="#5B6796"
                    width="100px"
                    height="20px"
                    count={1}
                    borderRadius="12px"
                  />
                ) : (
                  <Tooltip
                    overlayInnerStyle={{ boxShadow: 'none' }}
                    color="none"
                    className={[classes.subTitleText, classes.pointer].join(
                      ' '
                    )}
                    title={
                      <div className={classes.toolTipWrapper}>
                        {filterRewards(stakingInfo?.assetsData).map((el) => {
                          return (
                            <div className={classes.tooltipItem}>
                              <div className={classes.tooltipSubText}>
                                {el.symbol !== 'ORB' && (
                                  <span className={classes.tooltipItemTextSign}>
                                    +
                                  </span>
                                )}
                                {el.symbol} APR
                              </div>
                              <div className={classes.tooltipMainText}>
                                {transform(el.APY, 2)}%
                              </div>
                            </div>
                          )
                        })}
                        <hr className={classes.tooltipLine} />
                        <div className={classes.tooltipItem}>
                          <div className={classes.tooltipSubText}>
                            Total APR
                          </div>
                          <div className={classes.tooltipMainText}>
                            {transform(getTotalAPR(), 2)}%
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <div className={classes.mainText}>
                      {transform(getTotalAPR(), 2)}%
                    </div>
                    <img src={info} alt="" />
                  </Tooltip>
                )}
              </div>
            </div>{' '}
          </div>
        )}
        {user.connected ? (
          <button
            onClick={() => claim()}
            disabled={!isDisabled()}
            className={
              isDisabled() ? classes.activeClaimButton : classes.claimButton
            }
          >
            <img src={coinIcon} alt="" />
            <div>CLAIM ALL REWARDS</div>
          </button>
        ) : (
          <button
            className={classes.activeClaimButton}
            onClick={() => dispatch(setOpenWalletModal(true))}
          >
            CONNECT WALLET
          </button>
        )}
      </div>
    </div>
  )
}
export default StakingOverview
