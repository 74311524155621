import React from 'react'
import { createUseStyles } from 'react-jss'
import infoIcon from '../../img/info-circle.svg'
// @ts-ignore
import Marquee from 'react-double-marquee'

const styles = createUseStyles({
  testNet: {
    width: '100%',
    height: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#202348',
    position: 'absolute',
    top: 105,
    gap: 10,
  },
  text: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    whiteSpace: 'nowrap',
  },
  yellowText: {
    color: '#FBC928',
  },
  '@media (max-width: 1250px)': {},
  '@media (max-width: 730px)': {
    testNet: {
      height: 50,
    },
    text: {
      fontSize: '14px',
      whiteSpace: 'wrap',
      textAlign: 'center',
    },
  },
})

const VoteBaner = () => {
  const classes = styles()

  return (
    <div className={classes.testNet}>
      <img src={infoIcon} alt="" />
      <div className={classes.text}>
        The platform is in BETA stage,
        {window.innerWidth < 450 ? <br /> : null}
        working only in the <span className={classes.yellowText}>TESTNET</span>
      </div>
    </div>
  )
}

export default VoteBaner
