import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import closeIcon from '../../img/close-circle.svg'
import { useDispatch } from 'react-redux'

import { Swap } from '@swing.xyz/ui'
import { createPortal } from 'react-dom'
import { setIsOpen } from 'src/redux/swingSwapModal'

const styles = createUseStyles({
  modalBg: {
    width: '100%',
    background: 'rgb(0 0 0 / 70%)',
    minHeight: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 15,
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: '8px',
    },

    '&::-webkit-scrollbar-track': {
      background: '#4B567D',
      borderRadius: 10,
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#5493F7',
      borderRadius: 10,
    },
  },
  claimModal: {
    padding: [30, 30, 0, 30],
    boxSizing: 'border-box',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    borderRadius: '12px',
    minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100vh',
    width: 400,
    background: 'transparent',
    border: 'none',
  },
  closeIcon: {
    position: 'absolute',
    right: 5,
    top: 10,
    width: 24,
    height: 24,
    cursor: 'pointer',
    zIndex: 100,
  },
  '@media (max-width: 400px)': {
    claimModal: {
      width: 350,
    },
  },
})

interface Props {
  onClose: () => void
}

const SwapModal: React.FC<Props> = ({ onClose }) => {
  const classes = styles()
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(setIsOpen(false))
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  })

  return (
    <>
      {createPortal(
        <div className={classes.modalBg}>
          <div className={classes.claimModal}>
            <img
              className={classes.closeIcon}
              onClick={() => {
                closeModal()
              }}
              src={closeIcon}
              alt=""
            />

            <Swap projectId="orbiter-one" />
          </div>
        </div>,
        document.body
      )}
    </>
  )
}

export default SwapModal
