import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import noConnectedImg from '../../img/NFT/emoji-sad.svg'
import { requestNFT } from '../../factory/axios'
import { useDispatch, useSelector } from 'react-redux'
import { INft, setUserGalleryNfts, setUserNfts } from '../../redux/userNfts'
import NFTGalleryCard from '../NFTGalleryCard/NFTGalleryCard'
import Lottie from 'lottie-react'
import animationData from '../../lotties/notification'

const styles = createUseStyles({
  mainNftWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: 140,
  },
  galleryImg: {
    width: 262,
    height: 262,
  },
  galleryImgWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 30,
    paddingBottom: 60,
    width: 1140,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  galleryTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '40px',
    lineHeight: '60px',
    color: '#FFFFFF',
    paddingBottom: 40,
  },
  mainSubText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '32px',
    color: '#A8A8B2',
    paddingBottom: 40,
  },
  mainButton: {
    background: '#5493F7',
    borderRadius: 12,
    width: 225,
    height: 60,
    border: 'none',
    cursor: 'pointer',

    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#FFFFFF',
  },
  noConnectedSubText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#A8A8B2',
  },
  noConnectedWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,
  },
  noConnectedImg: {
    width: 60,
    height: 60,
  },
  '@media (max-width: 1250px)': {
    mainNftWrapper: {
      paddingBottom: 90,
    },
    galleryTitle: {
      fontSize: '32px',
      lineHeight: '48px',
      color: '#FFFFFF',
      paddingBottom: 30,
    },
    galleryImg: {
      width: 152,
      height: 152,
    },
    galleryImgWrapper: {
      paddingBottom: 40,
      width: 700,
    },
  },
  '@media (max-width: 730px)': {
    galleryImgWrapper: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      gap: 20,
      paddingBottom: 60,
      width: 340,
      flexWrap: 'wrap',
    },
    galleryTitle: {
      fontSize: '26px',
      lineHeight: '39px',
    },
  },
})

interface Props {
  open: boolean
  setOpen: (a: boolean) => void
}
const ORBITER_NFT_API_URL = process.env.REACT_APP_X_ORBITER_NFT_API_URL
const NFTGallery: React.FC<Props> = ({ open, setOpen }) => {
  const classes = styles()
  const { user } = useSelector((state: any) => state.userReducer)
  const { nftGallery } = useSelector((state: any) => state.userNftsReducer)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    if (user.address) {
      requestNFT({
        method: 'get',
        url: `${ORBITER_NFT_API_URL}/user-nft/list/${user.address}?limit=99999`,
      })
        .then((req) => dispatch(setUserGalleryNfts(req.data.result)))
        .finally(() => setLoading(false))
    }
  }, [user.address])

  return (
    <div className={classes.mainNftWrapper}>
      <div className={classes.galleryTitle}>Gallery</div>
      <div>
        <div className={classes.galleryImgWrapper}>
          {user.connected ? (
            loading ? (
              <div className={classes.galleryImg}>
                <Lottie {...defaultOptions} height={42} width={42} />
              </div>
            ) : nftGallery.length && user.connected ? (
              nftGallery.map((item: INft, index: number) => (
                <NFTGalleryCard
                  key={index}
                  img={item.imageLink}
                  title={item.name}
                  bg={item.bg}
                  helmet={item.helmet}
                  face={item.face}
                  tokenId={item.tokenId}
                  intercom={item.intercom}
                  skin={item.skin}
                />
              ))
            ) : (
              <div className={classes.noConnectedWrapper}>
                <img
                  className={classes.noConnectedImg}
                  src={noConnectedImg}
                  alt=""
                />
                <div className={classes.noConnectedSubText}>
                  No cats to display
                </div>
              </div>
            )
          ) : (
            <div className={classes.noConnectedWrapper}>
              <img
                className={classes.noConnectedImg}
                src={noConnectedImg}
                alt=""
              />
              <div className={classes.noConnectedSubText}>
                No cats to display
              </div>
            </div>
          )}
        </div>
      </div>
      <button onClick={() => setOpen(!open)} className={classes.mainButton}>
        Mint NFT
      </button>
    </div>
  )
}

export default NFTGallery
