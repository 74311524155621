import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { setOpen } from 'src/redux/claimModal'
import arrow from '../../img/HeaderArrowDown.svg'
import { useDispatch, useSelector } from 'react-redux'
import { moonBaseAlfaNFT } from '../../utils/chainConfig'
import { setIsOpen } from 'src/redux/swingSwapModal'

const actualApiFor = process.env.REACT_APP_X_ORBITER_ACTIVE_API_FOR

const styles = createUseStyles({
  navMenu: {
    backgroundColor: 'rgba(15, 14, 20, 1)',
    backdropFilter: 'blur(25px)',
    padding: '30px 30px 0',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    color: '#A8A8B2',
    fontSize: 16,
    lineHeight: '24px',
    position: 'absolute',
    right: 0,
    pointerEvents: 'all',
    zIndex: 2,
    height: '100%',
    width: 360,
    boxSizing: 'border-box',
  },
  scrollWrapper: {
    height: '70vh',
    overflowX: 'hidden',
  },
  menu: {
    paddingBottom: 20,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Poppins',
    color: '#5493F7',
    textTransform: 'uppercase',
  },
  smallNavMenu: {
    top: 0,
  },
  nav: {
    textDecoration: 'none',
    color: 'inherit',
    paddingBottom: 20,
    paddingTop: 20,
    display: 'flex',
    cursor: 'pointer',
    // gap: 10,
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  isDisabled: {
    color: '#545151',
    cursor: 'not-allowed',
    opacity: '0.5',
    textDecoration: 'none',
    paddingBottom: 20,
    paddingTop: 20,
    gap: 10,
    display: 'flex',
  },
  navDropdown: {
    extend: 'nav',
    paddingTop: 0,
    paddingBottom: 0,
    flexDirection: 'column',
    '& $navHiddenBlock': {},
    '&:hover $navHiddenBlock': {
      display: 'flex',
    },
    '&:hover $dropdownArrow': {
      transform: 'rotate(-180deg)',
    },
  },
  dropdownArrow: {},
  navDropdownMore: {
    extend: 'nav',
    paddingTop: 0,
    paddingBottom: 0,
    flexDirection: 'column',
    '& $navHiddenBlock': {},
    '&:hover $navHiddenBlock': {
      display: 'flex',
    },
  },
  navHiddenBlock: {
    color: '#A8A8B2',
    display: 'none',
    gap: 20,
    flexDirection: 'column',
    paddingBottom: 20,
  },
  bridgeWrapper: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    paddingBottom: 20,
    paddingTop: 20,
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  navHiddenLink: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    textDecoration: 'none',
    color: 'inherit',
    background: 'transparent',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    '&:hover': {
      color: '#FFFFFF',
    },
    '&:hover $dropDownHr': {
      backgroundColor: '#fff',
    },
  },
  activeText: {
    color: '#FFFFFF',
  },
  dropDownHr: {
    height: 4,
    width: 4,
    borderRadius: 32,
    backgroundColor: '#A8A8B2',
  },
  hr: {
    width: 4,
    height: 20,
    backgroundColor: '#5493F7',
    marginRight: '10px',
  },
  hrForEarn: {
    extend: 'hr',
    marginRight: '0px',
  },
  navHr: {
    width: 300,
    height: 1,
    backgroundColor: '#4B567D',
  },
  modalBlackdrop: {
    top: '106px',
    width: '100%',
    height: '100vh',
    zIndex: '2',
    position: 'fixed',
    background: 'rgb(0 0 0 / 70%)',
  },
  smallModalBlackdrop: {
    extend: 'modalBlackdrop',
    top: '81px',
  },
  faucet: {
    extend: 'nav',
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  testnet: {
    textDecoration: 'none',
    paddingBottom: 30,
    display: 'flex',
    color: 'red',
    cursor: 'default',
  },
  navWrapper: {
    pointerEvents: 'none',
    position: 'relative',
    height: '100%',
    margin: '0 auto',
  },
  crosChainText: {
    paddingTop: 10,
    color: '#6170A8',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    lineHeight: '21px',
  },
  '@media (max-width: 730px)': {
    navWrapper: {
      width: 360,
    },
  },
})

const NavMenu = ({ smallHeader, handleOpen }: any) => {
  const classes = styles()
  const [openBridge, setOpenBridge] = useState(false)
  const [openSwap, setOpenSwap] = useState(false)
  const { user } = useSelector((state: any) => state.userReducer)

  const dispatch = useDispatch()

  const handleOpenBridge = () => {
    setOpenBridge(!openBridge)
  }

  const handleOpenSwap = () => {
    setOpenSwap(!openSwap)
  }

  const openSwingWidget = () => {
    handleOpen()
    dispatch(setIsOpen(true))
  }

  return (
    <div
      className={
        smallHeader ? classes.smallModalBlackdrop : classes.modalBlackdrop
      }
      onClick={(e) => e.target === e.currentTarget && handleOpen()}
    >
      <div className={classes.navWrapper}>
        <div
          className={[
            classes.navMenu,
            smallHeader && classes.smallNavMenu,
          ].join(' ')}
        >
          <div className={classes.menu}>Menu</div>
          <div className={classes.scrollWrapper}>
            <div className={classes.nav}>
              <div
                className={window.location.pathname === '/' ? classes.hr : ''}
              ></div>
              <div
                className={
                  window.location.pathname === '/' ? classes.activeText : ''
                }
              >
                {' '}
                <Link
                  className={classes.navHiddenLink}
                  onClick={() => handleOpen()}
                  to="/"
                >
                  Dashboard
                </Link>{' '}
              </div>
            </div>
            <div className={classes.navHr}></div>

            <div onClick={openSwingWidget} className={classes.bridgeWrapper}>
              Swap
            </div>
            <div className={classes.navHr}></div>

            <Link
              className={[
                classes.nav,
                window.location.pathname === '/xORB' && classes.activeText,
              ].join(' ')}
              to="/xORB"
            >
              <div
                className={
                  window.location.pathname === '/xORB' ? classes.hr : ''
                }
              ></div>
              xORB
            </Link>
            <div className={classes.navHr}></div>
            <Link
              className={[
                classes.nav,
                window.location.pathname === '/farms' && classes.activeText,
              ].join(' ')}
              onClick={() => handleOpen()}
              to="/farms"
            >
              <div
                className={
                  window.location.pathname === '/farms' ? classes.hr : ''
                }
              ></div>
              Farms
            </Link>
            <div className={classes.navHr}></div>

            <div className={openBridge ? classes.navDropdown : ''}>
              <div
                onClick={() => handleOpenBridge()}
                className={classes.bridgeWrapper}
              >
                <div>NFT</div>
                <img className={classes.dropdownArrow} src={arrow} alt="" />
              </div>
              <div className={classes.navHiddenBlock}>
                <Link to={'/whiskers'} className={classes.navHiddenLink}>
                  <div className={classes.dropDownHr}></div>
                  Mint Whisker
                </Link>
                <Link to={'/staking'} className={classes.navHiddenLink}>
                  <div className={classes.dropDownHr}></div>
                  Stake Whiskers
                </Link>
              </div>
            </div>
            <div className={classes.navHr}></div>

            {!moonBaseAlfaNFT ? (
              <a href="https://app.orbiter.one/lottery" className={classes.nav}>
                Lottery
              </a>
            ) : (
              <div className={classes.isDisabled}>Lottery</div>
            )}
            <div className={classes.navHr}></div>

            <div className={openBridge ? classes.navDropdown : ''}>
              <div
                onClick={() => handleOpenBridge()}
                className={classes.bridgeWrapper}
              >
                <div>Bridge</div>
                <img className={classes.dropdownArrow} src={arrow} alt="" />
              </div>
              <div className={classes.navHiddenBlock}>
                <a
                  href=" https://app.orionbridge.xyz/"
                  className={classes.navHiddenLink}
                  target="_blank"
                >
                  <div className={classes.dropDownHr}></div> Orion
                  Bridge&nbsp;(ORB)
                </a>
                <a
                  href="https://apps.moonbeam.network/moonbeam"
                  className={classes.navHiddenLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className={classes.dropDownHr}></div>
                  XCM Transfer
                </a>
              </div>
            </div>
            <div className={classes.navHr}></div>

            {user.connected && (
              <>
                <div
                  onClick={() => {
                    handleOpen()
                    dispatch(setOpen(true))
                  }}
                  className={classes.nav}
                >
                  Rewards
                </div>
                <div className={classes.navHr}></div>
              </>
            )}
            {/* <div className={classes.navHr}></div>
            <Link
              className={[
                classes.nav,
                window.location.pathname === '/staking' && classes.activeText,
              ].join(' ')}
              to="/staking"
            >
              <div
                className={
                  window.location.pathname === '/staking' ? classes.hr : ''
                }
              ></div>
              Stake&nbsp;NFT
            </Link> */}
            {/*<div className={openBridgeMore ? classes.navDropdownMore : ''}>*/}
            {/*    <>*/}
            {/*      <div*/}
            {/*        onClick={() => handleOpenBridgeMore()}*/}
            {/*        className={[classes.bridgeWrapper].join(' ')}*/}
            {/*      >*/}
            {/*        <div>Earn</div>*/}
            {/*        <img src={arrow} alt="" />*/}
            {/*      </div>*/}
            {/*      <div className={classes.navHiddenBlock}>*/}
            {/*        <Link to={'/staking'} className={classes.navHiddenLink}>*/}
            {/*          <div className={classes.dropDownHr}></div>*/}
            {/*          Staking*/}
            {/*        </Link>*/}
            {/*      </div>*/}
            {/*    </>*/}
            {/*</div>*/}
            {actualApiFor === 'dev' && (
              <>
                <div className={classes.navHr}></div>
                <div
                  className={[
                    classes.nav,
                    window.location.pathname === '/faucet' &&
                      classes.activeText,
                  ].join(' ')}
                >
                  <div
                    className={
                      window.location.pathname === '/faucet' ? classes.hr : ''
                    }
                  ></div>

                  <Link
                    to="/faucet"
                    className={[
                      classes.faucet,
                      window.location.pathname === '/faucet' &&
                        classes.activeText,
                    ].join(' ')}
                    onClick={() => handleOpen()}
                  >
                    Faucet
                  </Link>
                </div>
              </>
            )}
            <div className={classes.navHr}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavMenu
