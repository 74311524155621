import React from 'react'
import { createUseStyles } from 'react-jss'
import noConnectdImg from '../../img/NFT/emoji-sad.svg'
import { setOpenWalletModal } from '../../redux/loading'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const styles = createUseStyles({
  titleText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mainBlock: {
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    boxSizing: 'border-box',
    padding: '20px',
    paddingBottom: 76,
    display: 'block',
    width: '100%',
    fontFamily: 'Poppins',
  },
  nftCardWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    paddingTop: 30,
    flexDirection: 'column',
    width: 1140,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  nftCard: {
    width: 252,
    height: 467,
    backgroundColor: '#1E1F28',
    borderRadius: 12,
  },
  nftImg: {
    width: 252,
    height: 252,
    borderRadius: [12, 12, 0, 0],
  },
  nftDiscription: {
    height: 215,
    padding: 15,
    paddingTop: 9,
  },
  nftCardNumber: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#AAAAAA',
    marginBottom: 8,
  },
  nftCardName: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#FFFFFF',
  },
  hr: {
    margin: [20, 0],
    height: 2,
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    width: 222,
  },
  stakingDateWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  stakingDateWhiteText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
  },
  stakingDateGreyText: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
  },
  button: {
    marginTop: 25,
    backgroundColor: '#5493F7',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    borderRadius: 12,
    width: 225,
    height: 60,
    border: 'none',
    cursor: 'pointer',
  },
  noConnectedMainText: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
  },
  noConnectedImg: {
    width: 36,
    paddingBottom: 12,
  },
  noConnectedSubText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
  },
  '@media (max-width: 1250px)': {
    nftCardWrapper: {
      width: 660,
    },
  },
  '@media (max-width: 730px)': {
    nftCardWrapper: {
      width: 310,
    },
    mainBlock: {
      background:
        'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
      boxShadow: 'inset 1px 1px 1px #42426A',
      backdropFilter: 'blur(25px)',
      borderRadius: 12,
      boxSizing: 'border-box',
      padding: [20, 15],
      display: 'block',
      width: '100%',
      fontFamily: 'Poppins',
    },
  },
})

const MyNFTSNoConnected = () => {
  const { user } = useSelector((state) => state.userReducer)
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate('/whiskers')
  }

  const classes = styles()
  const dispatch = useDispatch()
  return (
    <div className={classes.mainBlock}>
      <div className={classes.titleWrapper}>
        <div className={classes.titleText}>My NFTs</div>
      </div>
      <div className={classes.nftCardWrapper}>
        <img className={classes.noConnectedImg} src={noConnectdImg} alt="" />
        <div className={classes.noConnectedMainText}>
          There's nothing here yet
        </div>
        {user.connected ? null : (
          <div className={classes.noConnectedSubText}>
            Connect wallet to be able to stake your NFTs
          </div>
        )}
        {user.connected ? (
          <button className={classes.button} onClick={() => handleNavigate()}>
            Buy NFTs
          </button>
        ) : (
          <button
            className={classes.button}
            onClick={() => dispatch(setOpenWalletModal(true))}
          >
            Connect wallet
          </button>
        )}
      </div>
    </div>
  )
}
export default MyNFTSNoConnected
