import React from 'react'
import Slider from 'react-slick'
import './style.css'

const AssetsSliderWrap = ({ children }) => {
  const settings = {
    dots: true,
    infinite: false,
    variableWidth: true,
    arrows: false,
    appendDots: (dots) => <div>{dots}</div>,
    dotsClass: 'assets-slider-dots',
    focusOnSelect: true,
  }

  return <Slider {...settings}>{children}</Slider>
}

export default AssetsSliderWrap
