import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import uniqid from 'uniqid'

interface Notification {
  text: string
  type: string
  id: string
  link: string | null
  explorerUrl?: string
  isPending: boolean
  button?: boolean
}

export interface NotificationState {
  notifications: Notification[]
}

const initialState: NotificationState = {
  notifications: [],
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<any>) => {
      state.notifications = [
        ...state.notifications,
        {
          ...action.payload,
          id: action.payload.id || uniqid(),
          isPending: action.payload.isPending || false,
        },
      ]
    },
    deleteNotifications: (state, action: PayloadAction<any>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload
      )
    },
  },
})

export const { setNotifications, deleteNotifications } =
  notificationsSlice.actions

export default notificationsSlice.reducer
