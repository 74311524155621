import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import NavMenu from 'src/components/NavMenu/NavMenu'
import Header from 'src/components/Header/Header'
import NeedHelpButton from 'src/components/NeedHelpButton/NeedHelpButton'
import WrongNetworkModal from 'src/components/WrongNetworkModal/WrongNetworkModal'
// @ts-ignore
import bg from '../img/NFT/bgNFT.png'
import Footer from '../components/Footer/Footer'
import { paramsMoonbeam, paramsMovr } from '../utils/networkConst'
import NFT from '../components/NFT/NFT'
import { setLoading } from '../redux/loading'
import { useNavigate } from 'react-router-dom'
import ClaimModal from '../components/ClaimModal/ClaimModal'
import { moonBaseAlfaNFT } from '../utils/chainConfig'

const styles = createUseStyles({
  bgColor: {
    height: '100%',
    backgroundColor: '#1B1824',
  },
  allContent: {
    display: 'none',
  },
  allContentWisible: {
    display: 'block',
  },
  bg: {
    position: 'absolute',
    width: '100%',
    height: '2528px',
    zIndex: 0,
  },
  wrapperHeader: {
    width: '100%',
    position: 'fixed',
    backgroundColor: '#171932',
    backdropFilter: 'blur(60px)',
    zIndex: 5,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  wrapperSmallHeader: {
    extend: 'wrapperHeader',
  },
  wrapper: {
    // extend: 'wrapperHeader',
    margin: '0 auto',
    maxWidth: 1140,
    position: 'relative',
    paddingBottom: 75,
    paddingTop: 105,
  },
  hr: {
    color: '#42424E',
    backgroundColor: '#42424E',
    height: 1,
    border: 'none',
    margin: 0,
    width: '100%',
    top: 105,
    zIndex: 1,
    position: 'fixed',
  },
  smallHr: {
    extend: 'hr',
    top: 80,
  },
  contentWrapper: {
    display: 'flex',
    marginBottom: '20px',
  },
  contentBlockWrapper: {
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    padding: 20,
    marginTop: 35,
  },
  topLeftSideContent: {
    width: '360px',
    paddingRight: 30,
    display: 'flex',
  },
  leftMiddleContent: {
    width: '369px',
    display: 'flex',
  },
  topRightSideContent: {
    width: '70%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
  },
  rightMiddleContent: {
    width: '70%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
  },
  bottomLeftSideContent: {
    width: '70%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
  },
  bottomRightSideContent: {
    width: 384,
    paddingLeft: 30,
    display: 'flex',
  },
  composition: {
    display: 'flex',
    gap: 20,
    paddingBottom: 30,
  },
  notConnectedWrapper: {
    paddingTop: 30,
  },
  topContentWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  middleContentWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  modalBg: {
    width: '100%',
    background: 'rgb(0 0 0 / 70%)',
    height: '100vh',
    position: 'fixed',
    top: 0,
    zIndex: 1,
  },
  assetsMobile: {
    display: 'none',
  },
  '@media (max-width: 1250px)': {
    wrapperHeader: {
      width: '100%',
      position: 'fixed',
      zIndex: 5,
      left: '50%',
      transform: 'translateX(-50%)',
      userSelect: 'none',
    },
    bg: {
      height: '2342px',
    },
    wrapper: {
      maxWidth: 700,
      userSelect: 'none',
    },
    contentWrapper: {
      display: 'block',
      marginBottom: '21px',
    },
    topLeftSideContent: {
      paddingRight: 0,
      width: '48.6%',
      display: 'flex',
    },
    topRightSideContent: {
      width: '50%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      display: 'flex',
    },
    topContentWrapper: {
      gap: 20,
      flexDirection: 'row-reverse',
      paddingBottom: 0,
    },
    middleContentWrapper: {
      paddingBottom: 0,
    },
    leftMiddleContent: {
      width: '48.5%',
      display: 'flex',
    },
    rightMiddleContent: {
      width: '51.5%',
    },
    composition: {
      display: 'none',
    },
    bottomLeftSideContent: {
      width: '100%',
    },
    bottomRightSideContent: {
      width: '100%',
      marginTop: 30,
      paddingLeft: 0,
    },
    assetsMobile: {
      display: 'none',
    },
  },
  '@media (max-width: 730px)': {
    bgColor: {
      height: '100%',
    },
    wrapper: {
      maxWidth: 340,
      paddingBottom: 78,
    },
    topContentWrapper: {
      display: 'block',
    },
    topLeftSideContent: {
      paddingRight: 0,
      width: '100%',
      display: 'block',
      marginBottom: 20,
    },
    topRightSideContent: {
      width: '100%',
    },
    leftMiddleContent: {
      display: 'none',
    },
    rightMiddleContent: {
      width: '100%',
    },
    bottomLeftSideContent: {
      display: 'block',
    },
    contentWrapper: {
      marginBottom: '10px',
    },
    bottomRightSideContent: {
      marginTop: 20,
    },
    assetsMobile: {
      display: 'block',
    },
  },
})
const WalletType = {
  metamask: 'metamask',
  walletconnect: 'walletconnect',
}
interface Props {
  removeWalletData: () => void
  handleOpen: () => void
  connectWallet: (a: string, b: boolean) => void
  handleConnect: () => void
  openSwitchNetworkModal: (a: boolean) => void
  removeWalletType: () => void
  addToken: (a: any) => void
  setWalletType: () => void
  getWalletType: () => void
  switchNetwork: (params: any) => void
  getWalletData: () => void
  createProviderWalletConnect: () => void
  createProviderMetamask: () => void
  open: boolean
  connected: boolean
  showSwitchNetworkModal: boolean
  setShowSwitchNetworkModal: (a: boolean) => void
  addTokenFaucet: (a: string, b: string) => void
}

const NFTPage: React.FC<Props> = ({
  setShowSwitchNetworkModal,
  showSwitchNetworkModal,
  open,
  connectWallet,
  openSwitchNetworkModal,
  handleOpen,
  removeWalletData,
  switchNetwork,
  addToken,
  addTokenFaucet,
}) => {
  const [smallHeader, setSmallHeader] = useState(false)
  const nftAllowed = process.env.REACT_APP_X_NFT_ALLOWED
  const classes = styles()
  const { user } = useSelector((state: any) => state.userReducer)
  const openClaimModal = useSelector(
    (state: any) => state.claimModalReducer.open
  )
  const { openWalletModal } = useSelector((state: any) => state.loadingReducer)
  const dispatch = useDispatch()
  let navigate = useNavigate()

  useEffect(() => {
    if (nftAllowed === 'false') {
      window.open('https://app.orbiter.one/whiskers', '_blank')
      navigate('/')
    }

    window.scrollTo(0, 0)

    const connectedWalletType = getWalletType()
    if (
      connectedWalletType === WalletType.walletconnect &&
      !localStorage.getItem('walletconnect')
    ) {
      removeWalletType()
      return
    }
    dispatch(setLoading(false))
    open && handleOpen()

    connectedWalletType && connectWallet(connectedWalletType, false)
  }, [])

  useEffect(() => {
    if (!user.chainId) return

    if (
      moonBaseAlfaNFT
        ? user.chainId !== paramsMovr.chainId
        : user.chainId !== paramsMoonbeam.chainId
    ) {
      openSwitchNetworkModal(true)
    } else {
      const body = document.body
      body.style.height = ''
      body.style.overflowY = ''
      setShowSwitchNetworkModal(false)
    }
  }, [user.chainId])

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
  })
  const listenScrollEvent = () => {
    if (window.scrollY > 50) {
      setSmallHeader(true)
    } else {
      setSmallHeader(false)
    }
  }

  const getWalletType = () => {
    return localStorage.getItem('wallet-type')
  }

  const removeWalletType = () => {
    localStorage.removeItem('wallet-type')
  }

  return (
    <>
      {/* {loading.loading && <Loader />} */}
      <div className={classes.allContentWisible}>
        <div className={classes.bgColor}>
          <img src={bg} className={classes.bg} />
          <div
            className={
              smallHeader ? classes.wrapperSmallHeader : classes.wrapperHeader
            }
            style={
              openWalletModal
                ? {
                    zIndex: 20,
                  }
                : {}
            }
          >
            <div>
              <Header
                switchNetwork={switchNetwork}
                smallHeader={smallHeader}
                removeWalletData={removeWalletData}
                handleOpen={handleOpen}
                open={open}
                showSwitchNetworkModal={showSwitchNetworkModal}
                connectWallet={connectWallet}
                isNftPage={true}
              />
            </div>
          </div>
          <hr className={smallHeader ? classes.smallHr : classes.hr} />
          {open ? (
            <NavMenu handleOpen={handleOpen} smallHeader={smallHeader} />
          ) : (
            <></>
          )}
          <div className={classes.wrapper}>
            <div className={classes.contentWrapper}>
              <NFT />
            </div>
            <NeedHelpButton />
          </div>
        </div>
        <Footer />
        {showSwitchNetworkModal && user.connected && (
          <WrongNetworkModal switchNetwork={switchNetwork} isNftPage={true} />
        )}
        {openClaimModal ? <ClaimModal addToken={addToken} /> : null}
      </div>
    </>
  )
}
export default NFTPage
