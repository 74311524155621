export const faucetNotification = {
  textSuccessMint:
    'You have succussfully minted your test funds and your balances have been updated.',
  textErrorMint:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textInfoMint: 'Please confirm your mint transaction in your wallet.',
  textSubmittedMint:
    'You submitted your mint transaction. This process might take a few minutes to complete.',
}

export const supplyNotification = {
  textInfoApproval:
    'You need to grant access to your funds to Orbiter One Protocol before completing your deposit. Confirm the fund approval in your wallet.',
  textErrorApproval:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textSubmittedApproval:
    'You submitted your approval to grant Orbiter One Protocol access to your funds. This process might take a few minutes to complete.',
  textSuccessApproval: 'Complete your transaction in your wallet.',

  textSuccessDeposit:
    'Your deposit is complete and your balance has been updated.',
  textErrorDeposit:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textInfoDeposit: 'Please confirm your deposit in your wallet.',
  textSubmittedDeposit:
    'Your deposit has been submitted. Your deposit might take a couple of minutes to complete.',
}

export const borrowNotification = {
  textSuccessBorrow:
    'Your borrow is complete. You have received your borrowed amount in your wallet.',
  textErrorBorrow:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textInfoBorrow: 'Please confirm your loan in your wallet.',
  textSubmittedBorrow:
    'Your loan has been submitted. Your loan might take a couple of minutes to complete.',
}
export const withdrawNotification = {
  textSuccessWithdraw:
    'Your withdrawal has been completed. You have received your withdrawn funds in your wallet.',
  textErrorWithdraw:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textInfoWithdraw: 'Please confirm your withdrawal in your wallet.',
  textSubmittedWithdraw:
    'Your withdrawal has been submitted. Your withdrawal might take a couple of minutes to complete.',
}

export const collateralNotification = {
  textInfoCollateral: 'Please confirm your operation in your wallet.',
  textErrorCollateral:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textOnCollateral:
    'Your collateral enabling is in progress. This process might take a couple of minutes.',
  textOffCollateral:
    'Your collateral disabling is in progress. This process might take a couple of minutes.',
  textWhenCollateralOn: 'Your collateral is enabled.',
  textWhenCollateralOff: 'Your collateral is disabled.',
  textWhenThereIsAnOutstandingDebt: "You can't stop using ",
  textAsCollateral: ' as Collateral',
}

export const claimNotification = {
  textInfoClaim: 'Please confirm your claim of rewards in your wallet.',
  textErrorClaim:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textSuccessClaim:
    'Your claim rewards are completed and your balance has been updated.',
  textSubmittedClaim:
    'Your claim rewards have been submitted. It might take a couple of minutes to complete.',
}

export const nftNotification = {
  textInfoApproval:
    'You need to grant access to your funds to Orbiter One Protocol before completing your deposit. Confirm the fund approval in your wallet.',
  textErrorApproval:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textSubmittedApproval:
    'You submitted your approval to grant Orbiter One Protocol access to your funds. This process might take a few minutes to complete.',
  textSuccessApproval: 'Complete your transaction in your wallet.',

  textInfoMint: 'Please confirm your mint transaction in your wallet.',
  textSuccessMint:
    'You have successfully minted and your NFTs will be updated in a few minutes.',

  textErrorDeposit:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textErrorMint:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',

  textSubmittedMint:
    'You submitted your mint transaction. This process might take a few minutes to complete.',

  textInfoUnstake: 'Please confirm your unstake transaction in your wallet.',
  textSuccessUnstake:
    'You have successfully unstake and your NFTs will be updated in a few minutes.',

  textErrorUnstaketake:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textSubmittedUnstake:
    'You submitted your unstake transaction. This process might take a few minutes to complete.',

  textInfoStake: 'Please confirm your stake transaction in your wallet.',
  textSuccessStake:
    'You have successfully stake and your NFTs will be updated in a few minutes.',
  textNFTLoad: "Don't see your new NFT in the Gallery?",

  textErrorStake:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textSubmittedStake:
    'You submitted your stake transaction. This process might take a few minutes to complete.',
}

export const poolNotification = {
  textInfoApproval:
    'You need to grant access to your funds to Orbiter Farming Pools before completing your deposit. Confirm the fund approval in your wallet.',
  textErrorApproval:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textSubmittedApproval:
    'You submitted your approval to grant Orbiter Farming Pools access to your funds. This process might take a few minutes to complete.',
  textSuccessApproval:
    'You have given Orbiter Farming Pools access to your funds.',

  textInfoUnstake: 'Please confirm your unstake transaction in your wallet.',
  textSuccessUnstake: 'You have successfully unstaked LP tokens.',
  textErrorUnstaketake:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textSubmittedUnstake:
    'You submitted your unstake transaction. This process might take a few minutes to complete.',

  textSuccessUnstakeReq:
    'You have successfully unstaked LP tokens. You can claim these LP tokens after this pool cycle ends.',

  textSuccessUnstakeXORB: 'You have successfully unstake хORB tokens.',

  textInfoStake: 'Please confirm your stake transaction in your wallet.',
  textSuccessStake: 'You have successfully staked LP tokens.',
  textSuccessStakeXORB: 'You have successfully stake хORB tokens.',

  textErrorStake:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textSubmittedStake:
    'You submitted your stake transaction. This process might take a few minutes to complete.',

  textInfoClaim: 'Please confirm your claim in your wallet.',
  textSubmittedClaim:
    'Your claim has been submitted. Your claim might take a couple of minutes to complete.',
  textSuccessClaim: 'Your claim is complete and your balance has been updated.',
  textErrorClaim:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',

  textInfoClaimLp: 'Please confirm your withdrawal in your wallet.',
  textSubmittedClaimLp:
    'Your withdrawal has been submitted. Your claim might take a couple of minutes to complete.',
  textSuccessClaimLp:
    'Your withdrawal is complete and your balance has been updated.',
  textErrorClaimLp:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
}

export const convertNotification = {
  textInfoConvert: 'Please confirm your convert transaction in your wallet. ',
  textSuccessConvert: 'You have successfully converted ORB to xORB.',
  textErrorConvert:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textSubmittedConvert: 'You submitted your convert transaction.',
}

export const redeemNotification = {
  textInfoRedeem: 'Please confirm your redeem transaction in your wallet.',
  textSuccessRedeem: 'You have successfully redeemed xORB to ORB.',
  textErrorRedeem:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textSubmittedRedeem: 'You submitted your redeem transaction.',

  textInfoApproval:
    'You need to grant access to your funds to Orbiter before completing your conversion. Confirm the fund approval in your wallet.',
  textErrorApproval:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textSubmittedApproval:
    'You submitted your approval to grant Orbiter access to your funds. This process might take a few minutes to complete.',
  textSuccessApproval: 'You have given Orbiter access to your funds.',
}

export const cancelRedemptionNotification = {
  textInfoRedeemCancel:
    'Please confirm your cancel redeem transaction in your wallet.',
  textSuccessRedeemCancel:
    'You have successfully canceled redeem transaction.' + '\n',
  textErrorRedeemCancel:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  textSubmittedRedeemCancel:
    'You submitted your cancel redeem transaction.\n    ',
}

export const claimRedemptionNotification = {
  textInfoRedeemClaim: 'Please confirm the claim transaction in your wallet.\n',
  textSuccessRedeemClaim:
    'Your claim is complete, and your balance has been updated.\n',
  textErrorRedeemClaim:
    'You denied the transaction request in your wallet. Please resubmit your transaction. ',
  textSubmittedRedeemClaim:
    'Your claim has been submitted. Your claim might take a couple of minutes to complete.\n',
}
