import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import NFTMainBlock from '../NFTMainBlock/NFTMainBlock'
import NFTGallery from '../NFTGallery/NFTGallery'
import NFTInfo from '../NFTInfo/NFTInfo'
import NFTProperties from '../NFTProperties/NFTProperties'
import NFTModal from '../NFTModal/NFTModal'
import NFTAbout from '../NFTAbout/NFTAbout'
import { request } from '../../factory/axios'
import { moonBaseAlfaNFT } from '../../utils/chainConfig'

const styles = createUseStyles({
  pageWrapper: {},
  '@media (max-width: 1250px)': {
    pageWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
})

const NFT = () => {
  const [open, setOpen] = useState(false)
  const [orbPrice, setOrbPrice] = useState(0)

  const classes = styles()
  useEffect(() => {
    request({
      method: 'get',
      path: 'markets/orb-rate',
    }).then((res) => setOrbPrice(res.data.data))
  }, [])

  return (
    <div className={classes.pageWrapper}>
      <NFTMainBlock setOpen={setOpen} open={open} />
      <NFTInfo />
      <NFTGallery setOpen={setOpen} open={open} />
      <NFTAbout />
      <NFTProperties />
      {open && <NFTModal setOpen={setOpen} open={open} orbPrice={orbPrice} />}
    </div>
  )
}

export default NFT
