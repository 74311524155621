import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { request } from '../../factory/axios'
import { useDispatch, useSelector } from 'react-redux'
import { setCompositions } from 'src/redux/compositions'
import CustomScrollWrap from '../CustomScrollWrap'
import { Tooltip } from 'antd'
import onCompositionImg from '../../img/emoji-sad.svg'
import Skeleton from 'react-loading-skeleton'
import { setUserBalances } from '../../redux/userBalances'

const styles = createUseStyles({
  composition: {
    display: 'flex',
    flexDirection: 'column',
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    padding: [15, 20, 23],
    width: 360,
    boxShadow: 'inset 1px 1px 1px #42426a',
    minHeight: 215,
    cursor: 'default',
  },
  compositionTitle: {
    fontWeight: 600,
    color: '#FFFFFF',
    fontSize: '18px',
    paddingBottom: 20,
    lineHeight: '27px',
  },
  mainText: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
  },
  wrapper: {
    display: 'flex',
    paddingBottom: 7,
    paddingTop: 7,
    cursor: 'default',
  },
  blockWrapper: {
    maxHeight: '176px',
    '&:after': {
      content: "''",
      position: 'absolute',
      zIndex: 1,
      bottom: -10,
      left: 0,
      pointerEvents: 'none',
      backgroundImage:
        'linear-gradient(180deg, rgba(49, 51, 78, 0) 0%, #31334E 100%)',
      height: 62,
      width: '98%',
    },
  },
  blockWrapperWithoutBlur: {
    maxHeight: '176px',
  },
  blockBorrowedWrapper: {
    maxHeight: '176px',
    '&:after': {
      content: "''",
      position: 'absolute',
      zIndex: 1,
      bottom: -10,
      left: 0,
      pointerEvents: 'none',
      backgroundImage:
        'linear-gradient(180deg, rgba(49, 51, 78, 0) 0%, #31334E 100%)',
      height: 62,
      width: '98%',
    },
  },
  blockBorrowedWrapperWithoutBlur: {
    maxHeight: '176px',
  },
  coinWrapper: {
    alignItems: 'center',
    display: 'flex',
    gap: 5,
    width: '43%',
  },
  coinIcon: {
    width: 18,
  },
  percentBox: {
    width: '100%',
  },
  percentItem: {
    padding: '3px 0',
    backgroundColor: '#5493F7',
    width: '220px',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
  },
  percentItemText: {
    marginLeft: 15,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
  },
  noCompositionsWrapper: {
    display: 'flex',
    height: 140,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 'auto',
  },
  noCompositionsImg: {
    width: 36,
    height: 36,
    paddingBottom: 15,
  },
  noCompositions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noCompositionsMainText: {
    color: '#ffffff',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  noCompositionsSubText: {
    extend: 'noCompositionsMainText',
    fontWeight: 400,
    color: '#A8A8B2',
  },
  second: {
    padding: '3px 0',
    backgroundColor: '#FBC928',
    width: '198px',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
  },
  third: {
    padding: '3px 0',
    backgroundColor: '#53CBC8',
    width: '6px',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
  },
  fourth: {
    padding: '3px 0',
    backgroundColor: '#E2006E',
    width: '4px',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
  },
  tooltipWithRewards: {
    minWidth: 165,
    width: 'fit-content',
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipWithRewardsLong: {
    extend: 'tooltipWithRewards',
    minWidth: '165px',
    width: '100%',
  },
  tooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '4px',
  },
  tooltipItemText: {
    color: '#A8A8B2',
    fontWeight: 400,
    fontSize: '14px',
  },
  tooltipItemTextLong: {
    extend: 'tooltipItemText',
    marginRight: '10px',
  },
  tooltipItemNumber: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: '14px',
  },
  tooltipItemTextSign: {
    color: '#5493F7',
    fontWeight: 500,
    marginRight: '4px',
  },
  tooltipLine: {
    width: '100%',
    height: '1px',
    borderBottom: 'none',
    border: 'none',
    backgroundColor: '#4B567D',
    marginTop: '8px',
    marginBottom: '8px',
  },
  tooltipItemTotal: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewards: {
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipItemWithoutRewards: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewardsLong: {
    extend: 'tooltipWithoutRewards',
    // minWidth: '165px',
    // width: '100%',
  },

  lastTooltipItem: {
    extend: 'tooltipItem',
    marginBottom: '0px',
  },
  lastTooltipItemLong: {
    extend: 'tooltipItemLong',
    marginBottom: '0px',
  },
  noTexttooltipItemWithoutRewards: {
    extend: 'tooltipItemWithoutRewards',
    justifyContent: 'center',
  },
  tokenImg: {
    width: 20,
  },
  '@media (max-width: 1250px)': {
    composition: {
      width: 300,
    },
  },
})

interface Props {
  title: string
  supply: boolean
}

const Composition: React.FC<Props> = ({ title, supply }) => {
  const [blur, setBlur] = useState(false)
  const [fetching, setFetching] = useState(true)

  const classes = styles()
  const { data } = useSelector((state: any) => state.compositionsReducer)
  const { user } = useSelector((state: any) => state.userReducer)
  const dispatch = useDispatch()

  const skeletonsArray = Array(4).fill(1)

  useEffect(() => {
    request({
      method: 'get',
      path: `assets/${user.address}/composition`,
    })
      .then((res) => dispatch(setCompositions(res.data.data)))
      .finally(() => {
        setFetching(false)
      })
  }, [])

  useEffect(() => {
    setFetching(true)
    request({
      method: 'get',
      path: `assets/${user.address}/composition`,
    })
      .then((res) => dispatch(setCompositions(res.data.data)))
      .finally(() => {
        setFetching(false)
      })
  }, [user.address])

  const hideBlur = (e: any) => {
    setBlur(
      e.target.scrollHeight - e.target.offsetHeight - 40 <= e.target.scrollTop
    )
  }
  const arrToMap = supply ? data.supplied : data.borrowed

  return (
    <div className={classes.composition}>
      <div className={classes.compositionTitle}>{title}</div>
      <CustomScrollWrap mathod={hideBlur}>
        {arrToMap.length === 0 ? (
          <div className={classes.noCompositionsWrapper}>
            <img
              className={classes.noCompositionsImg}
              src={onCompositionImg}
              alt=""
            />
            <div className={classes.noCompositions}>
              <div className={classes.noCompositionsMainText}>
                There's nothing here yet
              </div>
              <div className={classes.noCompositionsSubText}>
                You haven't {supply ? 'supplied' : 'borrowed'} anything yet
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              arrToMap.length <= 4 || blur
                ? classes.blockWrapperWithoutBlur
                : classes.blockWrapper
            }
          >
            {fetching
              ? skeletonsArray.map((el, index) => (
                  <Skeleton
                    key={index}
                    baseColor="#4B567D"
                    highlightColor="#5B6796"
                    width={300 - 50 * index}
                    height="20px"
                    count={1}
                    borderRadius="32px"
                    style={{ marginBottom: 23 }}
                  />
                ))
              : arrToMap.length !== 0 &&
                arrToMap.map((item: any, index: number) => (
                  <div key={index} className={classes.wrapper}>
                    <div className={classes.coinWrapper}>
                      <img
                        className={classes.tokenImg}
                        src={item.token.image}
                        alt=""
                      />
                      <div className={classes.mainText}>
                        {item.token.symbol}
                      </div>
                    </div>
                    <div className={classes.percentBox}>
                      <div
                        className={classes.percentItem}
                        style={{
                          backgroundColor: item.token.color,
                          width:
                            item.percent >= 50
                              ? '100%'
                              : `${(item.percent * 100) / 50}%`,
                        }}
                      >
                        <div className={classes.percentItemText}>
                          <Tooltip
                            overlayInnerStyle={{ boxShadow: 'none' }}
                            color="none"
                            placement="top"
                            title={
                              <div className={classes.tooltipWithoutRewards}>
                                <div
                                  className={
                                    classes.noTexttooltipItemWithoutRewards
                                  }
                                >
                                  {/* <span className={classes.tooltipItemText}>Balance</span> */}
                                  <span>
                                    {Math.round(item.percent * 1000000) /
                                      1000000}
                                    %
                                  </span>
                                </div>
                              </div>
                            }
                          >
                            {Math.round(item.percent * 100) / 100}%
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        )}
      </CustomScrollWrap>
    </div>
  )
}

export default Composition
