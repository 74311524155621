import { Asset } from 'src/components/Assets/Assets'

export const findMovrIndex = (assets: Asset[]) => {
  const firstAsset = 'MOVR'
  return assets.findIndex((el) => el.symbol === firstAsset)
}

export const findAssetIndex = (assets: Asset[], asset: Asset) => {
  const currentAsset = asset.symbol
  return assets.findIndex((el) => el.symbol === currentAsset)
}

export const findMovrIndexFauset = (assets: any) => {
  const firstAsset = 'MOVR'
  return assets.findIndex((el) => el.token.symbol === firstAsset)
}

export const positionHealthColor = (positionHealth) => {
  if (positionHealth >= 80) {
    return '#E2006E'
  } else if (positionHealth < 50) {
    return '#53CBC8'
  } else {
    return '#FBC928'
  }
}

export function commify(value: string) {
  const splitedValue = value.split('.')
  let parts2 = ''
  const parts = splitedValue[0].replace(/[\s,]/g, '')

  const thousands = /\B(?=(\d{3})+(?!\d))/g
  if (splitedValue.length > 1) {
    parts2 = '.' + splitedValue[1]
  }

  return parts.replace(thousands, ',') + parts2
}

export function numberToStringInput(num: number) {
  let numStr = String(num)

  if (Math.abs(num) < 1.0) {
    let e = parseInt(num.toString().split('e-')[1])
    if (e) {
      let negative = num < 0
      if (negative) num *= -1
      num *= Math.pow(10, e - 1)
      numStr = '0.' + new Array(e).join('0') + num.toString().substring(2)
      if (negative) numStr = '-' + numStr
    }
  } else {
    let e = parseInt(num.toString().split('+')[1])
    if (e > 20) {
      e -= 20
      num /= Math.pow(10, e)
      numStr = num.toString() + new Array(e + 1).join('0')
    }
  }

  return numStr
}

export const totalIncentives = (incentives, type) => {
  let supply = 0
  let borrow = 0
  incentives.forEach((el) => {
    supply += +el.supplyApy
    borrow += +el.borrowApy
  })
  return type === 'supply'
    ? parseFloat(supply.toString())
    : parseFloat(borrow.toString())
}

export const sortRewordsAssets = (assets: any, field: string = 'symbol') => {
  const order = {
    ORB: 1,
    xORB: 2,
    'USDC.wh': 3,
    WGLMR: 4,
    d2o: 5,
  }

  const arrayToSort = [...assets]

  let sortedAssets = arrayToSort.sort((item1, item2) => {
    if (order[item1[field]] > order[item2[field]]) {
      return 1
    } else {
      return -1
    }
  })

  return sortedAssets
}
