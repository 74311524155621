import React from 'react'
import * as ScrollArea from '@radix-ui/react-scroll-area'
import './style.css'

const AssetsScrollWrap = ({ children }) => {
  return (
    <ScrollArea.Root className="ScrollAreaRoot" type="always">
      <ScrollArea.Viewport className="ScrollAreaViewport">
        {children}
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar
        className="ScrollAreaScrollbar"
        orientation="horizontal"
      >
        <ScrollArea.Thumb className="ScrollAreaThumb" />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  )
}

export default AssetsScrollWrap
