import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface modalState {
  isOpen: boolean
}

const initialState: modalState = {
  isOpen: false,
}

export const swingSwapModalSlice = createSlice({
  name: 'swingSwapModal',
  initialState,
  reducers: {
    setIsOpen: (state, action: PayloadAction<any>) => {
      state.isOpen = action.payload
    },
  },
})

export const { setIsOpen } = swingSwapModalSlice.actions

export default swingSwapModalSlice.reducer
