import React from 'react'
import { createUseStyles } from 'react-jss'
import moon from '../../img/moonbeam_2.svg'
import orb from '../../img/ORB_logo.svg'
import buyOrbIcon from '../../img/buy_orb.svg'
import useWindowSize from '../../utils/resizeHook'
import { useSelector } from 'react-redux'
import { transform } from '../../factory/bigNumber'
import { Tooltip } from 'antd'

const actualApiFor = process.env.REACT_APP_X_ORBITER_ACTIVE_API_FOR

const styles = createUseStyles({
  networkDropdown: {
    boxSizing: 'border-box',
    padding: [0, 12],
    height: 42,
    borderRadius: 12,
    paddingTop: 9,
    paddingBottom: 9,
    display: 'flex',
    gap: '10px',
    marginLeft: '23px',
    textDecoration: 'none',
    alignItems: 'center',
    border: '1px solid  #4B567D',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      border: '1px solid #5493F7',
    },
  },

  text: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: '#FFFFFF',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    gap: 10,
  },
  ratesBlock: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  ratesItem: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },

  icon: {
    width: 20,
    height: 20,
  },
  line: {
    width: '1px',
    height: ' 16px',
    background: '#4B567D',
  },
  tooltip: {
    padding: '12px 12px',
    // background: '#1F1F2D',
    background: 'linear-gradient(90deg, #2E344A 0%, #2E314C 100%)',
    borderRadius: '12px',
    height: '100%',
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: 20,
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    boxSizing: 'border-box',
    position: 'relative',
    color: '#FFFFFF',
    '&:hover': {
      color: '#FFFFFF',
    },

    '&::before': {
      position: 'absolute',
      content: '""',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: [0, 8, 8, 8],
      borderColor: ['transparent', 'transparent', '#2E344A', 'transparent'],
      top: -7,
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  tooltipImgContainer: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltipImg: {
    width: '100%',
    height: '100%',
    flexShrink: 0,
  },
  tooltipText: {
    fontSize: 18,
    textDecoration: 'underline',
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: 'normal',
    margin: 0,
    fontFamily: 'Poppins',
  },
})

interface Props {}

const RatesDropdown: React.FC<Props> = ({}) => {
  const classes = styles()

  const { currentWidth } = useWindowSize()
  const { data: rates } = useSelector((state: any) => state.ratesReducer)

  return (
    <div id="rates_block" style={{ position: 'relative' }}>
      <Tooltip
        overlayInnerStyle={{ boxShadow: 'none' }}
        color="none"
        mouseLeaveDelay={0.5}
        showArrow={true}
        arrowPointAtCenter={true}
        getTooltipContainer={() =>
          document.querySelector('#rates_block') as HTMLElement
        }
        title={
          <a
            href="https://app.beamswap.io/exchange/braindex"
            target="_blank"
            rel="noreferrer"
            className={classes.tooltip}
          >
            <div className={classes.tooltipImgContainer}>
              <img
                className={classes.tooltipImg}
                src={buyOrbIcon}
                alt="Buy Orb"
              />
            </div>
            <p className={classes.tooltipText}>BUY ORB</p>
          </a>
        }
      >
        <div className={classes.networkDropdown}>
          <div className={classes.ratesBlock}>
            <div className={classes.ratesItem}>
              <img className={classes.icon} src={orb} alt="" />
              <div className={classes.text}>${transform(rates.ORB, 4)}</div>
            </div>
            {currentWidth > 730 && (
              <>
                <div className={classes.line} />
                <div className={classes.ratesItem}>
                  <img className={classes.icon} src={moon} alt="" />
                  <div className={classes.text}>
                    $
                    {transform(
                      actualApiFor === 'dev' ? rates.MOVR : rates.GLMR,
                      4
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Tooltip>
    </div>
  )
}

export default RatesDropdown
