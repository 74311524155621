import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface assetState {
  asset: [
    {
      _id: string
      name: string
      symbol: string
      fullName: string
      oTokenDecimal: number
      tokenDecimal: number
      oTokenAddress: string
      tokenAddress: string
      typeNetwork: string
      collateralFactor: number
      reserveFactor: number
      exchangeRate: string
      supplyRate: string
      borrowRate: string
      totalSupply: string
      totalBorrow: string
      totalReserves: string
      lastPrice: string
      liquidity: string
      image: string
      color: string
      countSuppliers: number
      countBorrowers: number
      utilization: number
      supplyPaused: true
      borrowPaused: true
    }
  ]
}

const initialState: assetState = {
  asset: [
    {
      _id: '',
      name: '',
      fullName: '',
      symbol: '',
      oTokenDecimal: 0,
      tokenDecimal: 0,
      oTokenAddress: '',
      tokenAddress: '',
      typeNetwork: '',
      collateralFactor: 0,
      reserveFactor: 0,
      exchangeRate: '',
      supplyRate: '',
      borrowRate: '',
      totalSupply: '',
      totalBorrow: '',
      totalReserves: '',
      lastPrice: '',
      liquidity: '',
      image: '',
      color: '',
      countSuppliers: 0,
      countBorrowers: 0,
      utilization: 0,
      supplyPaused: true,
      borrowPaused: true,
    },
  ],
}

export const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    setAsset: (state, action: PayloadAction<any>) => {
      state.asset = action.payload
    },
  },
})

export const { setAsset } = assetSlice.actions

export default assetSlice.reducer
