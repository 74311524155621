import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import close from '../../img/NFT/close-circle.svg'
import { useDispatch, useSelector } from 'react-redux'
import { requestNFT } from '../../factory/axios'
import { Web3Context } from '../../context'
import {
  deleteNotifications,
  setNotifications,
} from '../../redux/notifications'
import { cancelRedemptionNotification } from '../../utils/notificationTexts'
import uniqid from 'uniqid'
import { fromBN } from '../../factory/bigNumber'
import BigNumber from 'bignumber.js'

const styles = createUseStyles({
  modalBg: {
    width: '100%',
    background: 'rgb(0 0 0 / 70%)',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 15,
  },
  modalNftWrapper: {
    width: 360,
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    boxSizing: 'border-box',
    padding: 20,
    paddingBottom: 10,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
  },
  modalTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  modalSubTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#A8A8B2',
  },
  counter: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '36px',
    color: '#FFFFFF',
    width: 31,
    textAlign: 'center',
  },
  operationLobbyWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 43,
    justifyContent: 'center',
    paddingBottom: 32,
    paddingTop: 43,
  },
  operationLobbyButton: {
    width: 28,
    height: 28,
    cursor: 'pointer',
  },
  operationLobbyButtonDisabled: {
    width: 28,
    height: 28,
    cursor: 'not-allowed',
    pointerEvents: 'none',
    opacity: 0.7,
  },
  operationButtonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    paddingBottom: 20,
  },
  operationButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 51,
    height: 21,
    background: '#1F1F2D',
    borderRadius: 42,
    cursor: 'pointer',

    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
  },
  price: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 30,
  },
  mainButton: {
    background: '#5493F7',
    borderRadius: 12,
    width: 150,
    height: 60,
    border: 'none',
    textAlign: 'center',

    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
  },
  subButton: {
    background: 'inherit',
    borderRadius: 12,
    width: 148,
    height: 58,
    textAlign: 'center',
    border: '1px solid #5493F7',

    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
  },
  disabledMainButton: {
    background: '#44445B',
    extend: 'mainButton',
    cursor: 'not-allowed',
  },
  closeButton: {
    cursor: 'pointer',
  },
  priceTitle: {
    extend: 'modalSubTitle',
    fontSize: 20,
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 20,
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 40,
  },
  '@media (max-width: 730px)': {
    modalTitle: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: 'normal',
      color: '#FFFFFF',
    },
    mainButton: {
      width: 140,
    },
    subButton: {
      width: 138,
    },
    modalNftWrapper: {
      width: 340,
    },
  },
})

interface Props {
  open: {
    open: boolean
    value: string
    index?: number
  }
  convertContract: any
  setOpen: (a: { open: boolean; value: string; index: number }) => void
  setData: any
  getBalance: any
  setRedemption: any
}
const ORBITER_NFT_API_URL = process.env.REACT_APP_X_ORBITER_NFT_API_URL
const XORBModal: React.FC<Props> = ({
  open,
  setOpen,
  convertContract,
  setData,
  getBalance,
  setRedemption,
}) => {
  const classes = styles()

  const { web3 } = useContext(Web3Context)

  const { user } = useSelector((state: any) => state.userReducer)

  const dispatch = useDispatch()

  const cancelRedemption = async () => {
    let result
    const transactionsId = uniqid()
    dispatch(
      setNotifications({
        type: 'info',
        text: cancelRedemptionNotification.textInfoRedeemCancel,
        link: null,
      })
    )

    setOpen({
      open: false,
      value: '',
      index: 0,
    })

    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await convertContract.methods
        .cancelRedemption(open.index)
        .estimateGas({
          from: user.address,
        })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      result = await convertContract.methods
        .cancelRedemption(open.index)
        .send({
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
          from: user.address,
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: cancelRedemptionNotification.textSubmittedRedeemCancel,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          text: cancelRedemptionNotification.textSuccessRedeemCancel,
          link: result.transactionHash,
        })
      )
      setTimeout(() => {
        requestNFT({
          method: 'get',
          url: `${ORBITER_NFT_API_URL}/x-orb/${user.address}`,
        }).then((req) => {
          setData(req.data.redemptions)
          setRedemption(new BigNumber(req.data.totalRedemption).toFixed(0))
        })
      }, 5000)
      getBalance()
      console.log('convertOrb', result)
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: cancelRedemptionNotification.textErrorRedeemCancel,
          link: null,
        })
      )
      console.log(error)
    }
  }

  return (
    <div
      className={classes.modalBg}
      onClick={(e) => {
        e.target === e.currentTarget &&
          setOpen({
            open: false,
            value: '',
            index: 0,
          })
      }}
    >
      <div className={classes.modalNftWrapper}>
        <div className={classes.titleWrapper}>
          <div className={classes.modalTitle}>Cancel redeem</div>
          <img
            className={classes.closeButton}
            onClick={() =>
              setOpen({
                open: false,
                value: '',
                index: 0,
              })
            }
            src={close}
            alt=""
          />
        </div>
        <div className={classes.modalSubTitle}>
          By canceling active redemptions you will reset the process: you will
          only get back {fromBN(open.value, 18)} xORB, while canceling the rest
          of the redemption process.
        </div>
        <div className={classes.buttonWrapper}>
          <div
            className={classes.subButton}
            onClick={() =>
              setOpen({
                open: false,
                value: '',
                index: 0,
              })
            }
          >
            Cancel
          </div>
          <div
            className={classes.mainButton}
            onClick={() => cancelRedemption()}
          >
            Confirm
          </div>
        </div>
      </div>
    </div>
  )
}

export default XORBModal
