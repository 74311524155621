import React from 'react'
import { createUseStyles } from 'react-jss'
import * as ScrollArea from '@radix-ui/react-scroll-area'

const styles = createUseStyles({
  ScrollAreaRoot: {
    overflow: 'hidden',
    height: '100%',
    marginRight: '-15px',
    paddingRight: '15px',
  },
  ScrollAreaViewport: {
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
  },
  ScrollAreaScrollbar: {
    display: 'flex',
    userSelect: 'none',
    touchAction: 'none',
    padding: '2px',
    background: '#4B567D',
    borderRadius: '10px',
    transition: 'background 160ms ease-out',
    '&[data-orientation="vertical"]': {
      width: '4px',
    },
  },
  ScrollAreaThumb: {
    flex: 1,
    background: '#5493F7',
    borderRadius: '10px',
    position: 'relative',
    '&::before': {
      content: '',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '100%',
      minWidth: '44px',
      minHeight: '44px',
    },
  },
})

const CustomScrollWrap = ({ children, mathod, height = null }) => {
  const classes = styles()

  return (
    <ScrollArea.Root
      className={classes.ScrollAreaRoot}
      style={height && { height: height }}
      type="scroll"
    >
      <ScrollArea.Viewport
        className={classes.ScrollAreaViewport}
        onScroll={mathod}
      >
        {children}
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar
        className={classes.ScrollAreaScrollbar}
        orientation="vertical"
      >
        <ScrollArea.Thumb className={classes.ScrollAreaThumb} />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  )
}

export default CustomScrollWrap
