import React from 'react'
import { createUseStyles } from 'react-jss'
import logo from '../../img/logo.svg'
import telegram from '../../img/telegram.svg'
import github from '../../img/github.svg'
import medeum from '../../img/medium.svg'
import twitter from '../../img/twitter.svg'
import disk from '../../img/disk.svg'
import SolidProof from '../../img/SolidProof.svg'

const actualApiFor = process.env.REACT_APP_X_ORBITER_ACTIVE_API_FOR

const styles = createUseStyles({
  footer: {
    position: 'relative',
    zIndex: 1000,
    width: '100%',
    // maxWidth: 1140,
    margin: '0 auto',
    padding: [32, 0, 32, 0],
    backgroundColor: '#0F0E14',
    maxWidth: 1140,
    boxSizing: 'border-box',
  },
  footerWrapper: {
    position: 'relative',
    width: '100%',
    backgroundColor: '#0F0E14',
    marginTop: 'auto',
    zIndex: 1,
    boxSizing: 'border-box',
  },
  topContentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 32,
  },
  icon: {
    cursor: 'pointer',
    '&:hover': {
      filter: 'invert(0) sepia(0) saturate(1) hue-rotate(0deg) brightness(3)',
    },
  },
  iconImg: {
    width: 24,
    height: 24,
  },
  logo: {},
  socialLinks: {
    display: 'flex',
    gap: 15,
    alignItems: 'center',
  },
  bottomContentWrapper: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#A8A8B2',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
  },
  text: {
    fontFamily: 'Poppins',
    display: 'flex',
    justifyContent: 'center',
  },
  textBottom: {},
  rightWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 117,
  },
  docsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  subTitleText: {
    fontFamily: 'Poppins',
    color: '#A8A8B2',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '17px',
  },
  mainTitleText: {
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  hr: {
    height: 1,
    backgroundColor: '#4B567D',
    width: '1140',
    marginBottom: 31,
  },
  solidProofWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  '@media (max-width: 1250px)': {
    footer: {
      maxWidth: 700,
    },
    rightWrapper: {
      gap: 53,
    },
  },
  '@media (max-width: 730px)': {
    footer: {
      paddingTop: 40,
      maxWidth: 340,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    topContentWrapper: {
      display: 'block',
      paddingBottom: 0,
    },
    bottomContentWrapper: {
      display: 'block',
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: 35,
    },
    text: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingBottom: 8,
    },
    textBottom: {
      display: 'flex',
      justifyContent: 'center',
    },
    hr: {
      height: 1,
      backgroundColor: '#4B567D',
      width: 340,
      marginBottom: 31,
    },
    solidProofWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 15,
      margin: [30, 0],
    },
  },
})

const Footer: React.FC = () => {
  const classes = styles()
  const isMobile = window.innerWidth <= 730

  return (
    <footer className={classes.footerWrapper}>
      <div className={classes.footer}>
        <div className={classes.topContentWrapper}>
          <div className={classes.logo}>
            <img src={logo} alt="" />
          </div>
          <div className={classes.rightWrapper}>
            {!isMobile && (
              <div className={classes.docsWrapper}>
                <div className={classes.subTitleText}>Docs</div>
                <a
                  href="https://docs.orbiter.one"
                  target="_blank"
                  className={classes.mainTitleText}
                >
                  Orbiter One Documentation
                </a>
              </div>
            )}
            <div className={classes.docsWrapper}>
              {!isMobile && (
                <div className={classes.subTitleText}>
                  Join our communities to stay up to date
                </div>
              )}
              <div className={classes.socialLinks}>
                <a
                  href="https://discord.gg/DZeF8UTxgS"
                  target="_blank"
                  className={classes.icon}
                >
                  <img className={classes.iconImg} src={disk} alt="" />
                </a>
                <a
                  href="https://twitter.com/OrbiterOne"
                  target="_blank"
                  className={classes.icon}
                >
                  <img className={classes.iconImg} src={twitter} alt="" />
                </a>
                <a
                  href="https://t.me/Orbiter_One"
                  target="_blank"
                  className={classes.icon}
                >
                  {' '}
                  <img className={classes.iconImg} src={telegram} alt="" />
                </a>
                <a
                  href="https://github.com/orbiterone"
                  target="_blank"
                  className={classes.icon}
                >
                  {' '}
                  <img className={classes.iconImg} src={github} alt="" />
                </a>
                <a
                  href="https://medium.com/@orbiter_one"
                  target="_blank"
                  className={classes.icon}
                >
                  {' '}
                  <img className={classes.iconImg} src={medeum} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {isMobile && (
          <div className={classes.solidProofWrapper}>
            <div className={classes.subTitleText}>Audited by</div>
            <div>
              <img src={SolidProof} alt="" />
            </div>
          </div>
        )}
        <div className={classes.hr}></div>
        <div className={classes.bottomContentWrapper}>
          <div className={classes.text}>
            <div className={classes.textBottom}>
              Copyright © 2023 Orbiter Labs Inc.&nbsp;
            </div>
            <div className={classes.textBottom}>All Rights Reserved.</div>
          </div>
          {!isMobile ? (
            actualApiFor === 'dev' ? (
              <div className={classes.textBottom}>
                This project is in beta. Use at your own risk
              </div>
            ) : (
              <div className={classes.solidProofWrapper}>
                <div>Audited by</div>
                <div>
                  <img src={SolidProof} alt="" />
                </div>
              </div>
            )
          ) : null}
        </div>
      </div>
    </footer>
  )
}

export default Footer
