import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface rates {
  data: {}
}

const initialState: rates = {
  data: {},
}

export const ratesSlice = createSlice({
  name: 'rates',
  initialState,
  reducers: {
    setRates: (state, action: PayloadAction<any>) => {
      state.data = action.payload
    },
  },
})

export const { setRates } = ratesSlice.actions

export default ratesSlice.reducer
