import React from 'react'
import { createUseStyles } from 'react-jss'
import catsDesktop from '../../img/NFT/aboutDesktop.png'
import catsTablet from '../../img/NFT/aboutMobile.png'
import catsMobile from '../../img/NFT/nftMobileAbout.svg'

const styles = createUseStyles({
  mainNftWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 140,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  mainNftImg: {
    width: 495,
    height: 499,
    borderRadius: 12,
  },
  mainNftTitleWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 555,
  },
  mainTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '40px',
    lineHeight: '60px',
    color: '#FFFFFF',
    paddingBottom: 40,
  },
  mainSubText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '32px',
    color: '#A8A8B2',
  },
  mainButton: {
    background: '#5493F7',
    borderRadius: 12,
    width: 225,
    height: 60,
    border: 'none',
    cursor: 'pointer',

    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#FFFFFF',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  '@media (max-width: 1250px)': {
    mainNftWrapper: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 90,
      gap: 35,
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'row',
      gap: 30,
      width: 700,
    },
    mainNftImg: {
      width: 720,
      height: 145,
    },
    mainNftTitleWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      paddingBottom: 0,
      width: 650,
    },
    mainTitle: {
      fontSize: '32px',
      lineHeight: '48px',
      paddingBottom: 10,
      paddingTop: 30,
    },
    mainSubText: {
      fontSize: '20px',
      lineHeight: '32px',
      textAlign: 'start',
      width: 515,
    },
  },
  '@media (max-width: 730px)': {
    mainNftWrapper: {
      gap: 30,
    },
    mainNftImg: {
      width: 370,
      height: 75,
    },
    mainTitle: {
      fontSize: '26px',
      paddingBottom: 0,
      lineHeight: '39px',
    },
    mainNftTitleWrapper: {
      paddingBottom: 0,
      width: 340,
    },
    mainSubText: {
      width: 340,
      fontSize: '16px',
      lineHeight: '26px',
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 30,
      width: 340,
    },
    '@media (max-width: 390px)': {
      mainNftImg: {
        width: 350,
        height: 75,
      },
    },
  },
})
const NFTAbout = () => {
  const classes = styles()
  const isMobile = window.innerWidth <= 700
  const isTablet = window.innerWidth <= 1250

  const setImg = () => {
    if (isTablet) {
      return catsTablet
    } else if (isMobile) {
      return catsMobile
    } else {
      return catsDesktop
    }
  }

  return (
    <div className={classes.mainNftWrapper}>
      {isTablet ? (
        <div className={classes.mainTitle}>About NFT Collection</div>
      ) : null}
      <img className={classes.mainNftImg} src={setImg()} />
      <div className={classes.mainNftTitleWrapper}>
        {isTablet ? null : (
          <div className={classes.mainTitle}>About NFT Collection</div>
        )}
        <div className={classes.textWrapper}>
          <div className={classes.mainSubText}>
            Intergalactic Whiskers Brigade is a one of a kind NFT collection
            that fuses feline allure and intergalactic adventure. Each Whisker
            NFT portrays a one-of-a-kind cat suited up for space exploration,
            ready to discover the mysteries of the cosmos. But these NFTs are
            more than just eye candy.
          </div>
          <div className={classes.mainSubText}>
            They provide their owners with exclusive governance rights to the
            Orbiter One lending protocol, putting them in control of the
            platform's trajectory. By joining the Intergalactic Whiskers
            Brigade, you become a trailblazer in decentralized finance,
            spearheading the evolution of financial systems
          </div>
        </div>
      </div>
    </div>
  )
}

export default NFTAbout
