import React, { useContext, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import refreshIcon from '../../img/NFT/refresh.svg'
import stakeIcon from '../../img/NFT/green-stake.svg'
import minusIcon from '../../img/NFT/minus-square.svg'
import { requestNFT } from '../../factory/axios'
import { INft, setUserNfts } from '../../redux/userNfts'
import { useDispatch, useSelector } from 'react-redux'
import Lottie from 'lottie-react'
import animationData from '../../lotties/notification'
import nftStakingContract from '../../contracts/nft-staking.abi'
import { Web3Context } from '../../context'
import uniqid from 'uniqid'
import {
  deleteNotifications,
  setNotifications,
} from '../../redux/notifications'
import { nftNotification } from '../../utils/notificationTexts'

const styles = createUseStyles({
  mainWrapper: {
    position: 'relative',
    background: '#4B567D',
    display: 'flex',
    width: 253,
    height: 477,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
  },
  mainRareWrapper: {
    position: 'relative',
    background: 'linear-gradient(45.31deg, #7D63F8 0%, #5493F7 100%)',
    display: 'flex',
    width: 253,
    height: 477,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
  },
  titleText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
  },
  subTitleText: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#A8A8B2',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subTitleNFTText: {
    paddingTop: 20,
  },
  mainBlock: {
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    boxSizing: 'border-box',
    padding: '20px',
    paddingBottom: 30,
    display: 'block',
    width: '100%',
    fontFamily: 'Poppins',
  },
  documentsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    cursor: 'pointer',
  },
  nftCardWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 31,
    paddingTop: 30,
    flexDirection: 'row',
    width: 1140,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  nftCard: {
    width: 243,
    height: 467,
    backgroundColor: '#1E1F28',
    borderRadius: 12,
  },
  nftCardRare: {
    extend: 'nftCard',
    position: 'relative',
    zIndex: 10,
  },
  nftImg: {
    width: 243,
    height: 252,
    borderRadius: [6, 6, 0, 0],
  },
  nftDiscription: {
    height: 215,
    padding: 11,
    paddingTop: 9,
  },
  nftCardNumber: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#AAAAAA',
    marginBottom: 8,
  },
  nftCardName: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#FFFFFF',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rarBg: {
    position: 'absolute',
    background: 'linear-gradient(45deg, #7D63F8 0%, #5493F7 100%)',
    filter: 'blur(60px)',
    width: 200,
    height: 400,
    borderRadius: 12,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  hr: {
    margin: [20, 0],
    height: 2,
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    width: 222,
  },
  stakingDateWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  stakingDateWhiteText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
  },
  stakingDateGreyText: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
  },
  stakeButton: {
    backgroundColor: '#5493F7',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    borderRadius: 13,
    width: 222,
    height: 54,
    border: 'none',
    cursor: 'pointer',
  },
  unStakeButton: {
    extend: 'stakeButton',
    border: '1px solid #5493F7',
    backgroundColor: 'inherit',
  },
  galleryImg: {
    width: 262,
    height: 262,
  },
  loadingNftCardWrapper: {
    extend: 'nftCardWrapper',
    justifyContent: 'center',
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 30,
  },
  greenText: {
    color: '#53CBC8',
    fontSize: '14px',
    extend: 'subTitleText',
  },
  unstakeText: {
    color: '#A8A8B2',
    fontSize: '14px',
    extend: 'subTitleText',
  },
  allButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  },
  '@media (max-width: 1250px)': {
    mainWrapper: {
      position: 'relative',
      background: '#4B567D',
      display: 'flex',
      width: 206,
      height: 423,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 12,
    },
    mainRareWrapper: {
      position: 'relative',
      background: 'linear-gradient(45.31deg, #7D63F8 0%, #5493F7 100%)',
      display: 'flex',
      width: 206,
      height: 423,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 12,
    },
    subTitleNFTText: {
      display: 'block',
      paddingTop: 10,
    },
    rarBg: {
      position: 'absolute',
      background: 'linear-gradient(45deg, #7D63F8 0%, #5493F7 100%)',
      filter: 'blur(60px)',
      width: 130,
      height: 350,
      borderRadius: 12,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    mainBlock: {
      paddingBottom: 20,
    },
    nftCard: {
      width: 196,
      height: 413,
      backgroundColor: '#1E1F28',
      borderRadius: 12,
    },
    nftCardRare: {
      width: 196,
      height: 413,
      position: 'relative',
      zIndex: 10,
      backgroundColor: '#1E1F28',
      borderRadius: 12,
    },
    titleText: {
      fontSize: '18px',
      lineHeight: '27px',
    },
    nftImg: {
      width: 196,
      height: 196,
    },
    stakeButton: {
      width: 176,
    },
    nftCardWrapper: {
      gap: 21,
      flexWrap: 'wrap',
      width: 660,
    },
    hr: {
      width: 176,
    },
    unStakeButton: {
      width: 176,
    },
    loadingNftCardWrapper: {
      gap: 21,
      flexWrap: 'wrap',
      width: 660,
    },
    subTitleText: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '21px',
      color: '#A8A8B2',
    },
  },
  '@media (max-width: 730px)': {
    subTitleNFTText: {},
    mainWrapper: {
      position: 'relative',
      background: '#4B567D',
      display: 'flex',
      width: 253,
      height: 477,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 12,
    },
    rarBg: {
      position: 'absolute',
      background: 'linear-gradient(45deg, #7D63F8 0%, #5493F7 100%)',
      filter: 'blur(60px)',
      width: 200,
      height: 400,
      borderRadius: 12,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    mainRareWrapper: {
      position: 'relative',
      background: 'linear-gradient(45.31deg, #7D63F8 0%, #5493F7 100%)',
      display: 'flex',
      width: 253,
      height: 477,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 12,
    },
    nftCard: {
      width: 243,
      height: 467,
      backgroundColor: '#1E1F28',
      borderRadius: 12,
    },
    nftCardRare: {
      width: 243,
      height: 467,
      backgroundColor: '#1E1F28',
      borderRadius: 12,
    },
    nftImg: {
      width: 243,
      height: 243,
    },
    stakeButton: {
      width: 222,
    },
    nftCardWrapper: {
      paddingTop: 43,
      gap: 21,
      flexWrap: 'wrap',
      width: 300,
      alignItems: 'center',
      justifyContent: 'center',
    },
    hr: {
      width: 222,
    },
    unStakeButton: {
      width: 222,
    },
    loadingNftCardWrapper: {
      gap: 21,
      flexWrap: 'wrap',
      width: 300,
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonsWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 20,
    },
    allButtonsWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 15,
    },
  },
})

const ORBITER_NFT_API_URL = process.env.REACT_APP_X_ORBITER_NFT_API_URL
const ORBITER_NFT_STAKING_CONTRACT_ADDRESS =
  process.env.REACT_APP_X_NFT_STAKING_CONTRACT

interface CardProps {
  title: string
  date: number
  id: number
  isLocked: boolean
  img: string
  stakeNft: (id: number) => void
  unStakeNft: (id: number) => void
  intercom: null | number
  disableStake: boolean
}

const NftCard: React.FC<CardProps> = ({
  img,
  title,
  id,
  date,
  isLocked,
  stakeNft,
  unStakeNft,
  intercom,
  disableStake,
}) => {
  const classes = styles()
  const options = { day: 'numeric', month: 'short', year: 'numeric' }
  const formatDate = new Date(date * 1000).toLocaleDateString('en-GB', options)
  const intercomRarity = +intercom.rarity > 8

  return (
    <div
      className={intercomRarity ? classes.mainWrapper : classes.mainRareWrapper}
    >
      <div
        key={id}
        className={intercomRarity ? classes.nftCard : classes.nftCardRare}
      >
        <img className={classes.nftImg} src={img} alt="" />
        <div className={classes.nftDiscription}>
          <div className={classes.nftCardNumber}>#{id}</div>
          <div className={classes.nftCardName}>{title}</div>
          <div className={classes.hr}></div>
          <div className={classes.stakingDateWrapper}>
            <div className={classes.stakingDateGreyText}>Staked date</div>
            <div className={classes.stakingDateWhiteText}>
              {isLocked || (date && isLocked) ? formatDate : '-'}
            </div>
          </div>
          {isLocked ? (
            <button
              style={disableStake ? { cursor: 'not-allowed' } : {}}
              disabled={disableStake}
              onClick={() => {
                unStakeNft(id)
              }}
              className={classes.unStakeButton}
            >
              unstake
            </button>
          ) : (
            <button
              style={disableStake ? { cursor: 'not-allowed' } : {}}
              disabled={disableStake}
              onClick={() => {
                stakeNft(id)
              }}
              className={classes.stakeButton}
            >
              stake
            </button>
          )}
        </div>
      </div>
      {!intercomRarity && <div className={classes.rarBg}></div>}
    </div>
  )
}

interface Props {
  loading: boolean
  setStakingInfo: (a: any) => void
  setLoading: (a: boolean) => void
}

const MyNFTS: React.FC<Props> = ({ loading, setStakingInfo, setLoading }) => {
  const [contract, setContract] = useState(null)
  const [disableStake, setDisableStake] = useState(false)
  const classes = styles()
  const { user } = useSelector((state: any) => state.userReducer)
  const { nft } = useSelector((state: any) => state.userNftsReducer)
  const { web3 } = useContext(Web3Context)

  const isMobile = window.innerWidth < 730

  const dispatch = useDispatch()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const isStakedNft = nft.find((item) => item.isLocked === true)
  const isUnStakedNft = nft.find((item) => item.isLocked === false)

  const getNftIds = (staked: boolean) => {
    const ids = []
    nft.forEach((item) => {
      if (item.isLocked === staked) {
        ids.push(item.tokenId)
      }
    })
    return ids
  }

  useEffect(() => {
    defineContracts()
  }, [user.address])

  const refetchInfo = () => {
    setLoading(true)
    requestNFT({
      method: 'get',
      url: `${ORBITER_NFT_API_URL}/user-nft/list/${user.address}?limit=99999`,
    })
      .then((req) => dispatch(setUserNfts(req.data.result)))
      .finally(() => setTimeout(() => setLoading(false), 1000))
    requestNFT({
      method: 'get',
      url: `${ORBITER_NFT_API_URL}/user-nft/staking`,
    }).then((req) => setStakingInfo(req.data))
  }

  const defineContracts = async () => {
    const contract = new web3.eth.Contract(
      nftStakingContract,
      ORBITER_NFT_STAKING_CONTRACT_ADDRESS
    )
    setContract(contract)
  }
  const stake = async (id: number) => {
    const transactionsId = uniqid()
    setDisableStake(true)
    dispatch(
      setNotifications({
        type: 'info',
        text: nftNotification.textInfoStake,
        link: null,
      })
    )
    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await contract.methods.stakeNft(id).estimateGas({
        from: user.address,
      })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      const result = await contract.methods
        .stakeNft(id)
        .send({
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
          from: user.address,
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: nftNotification.textSubmittedStake,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      setDisableStake(false)
      dispatch(
        setNotifications({
          type: 'success',
          text: nftNotification.textSuccessStake,
          link: result.transactionHash,
        })
      )
      setTimeout(() => refetchInfo(), 15000)
    } catch (error) {
      setDisableStake(false)
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: nftNotification.textErrorStake,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const stakeAll = async () => {
    setDisableStake(true)
    const transactionsId = uniqid()
    dispatch(
      setNotifications({
        type: 'info',
        text: nftNotification.textInfoStake,
        link: null,
      })
    )
    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      const gasLimit = await contract.methods
        .stakeBatch(getNftIds(false))
        .estimateGas({
          from: user.address,
        })

      const result = await contract.methods
        .stakeBatch(getNftIds(false))
        .send({
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
          from: user.address,
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: nftNotification.textSubmittedStake,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      setDisableStake(false)
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          text: nftNotification.textSuccessStake,
          link: result.transactionHash,
        })
      )
      setTimeout(() => refetchInfo(), 15000)
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      setDisableStake(false)
      dispatch(
        setNotifications({
          type: 'error',
          text: nftNotification.textErrorStake,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const unStake = async (id: number) => {
    const transactionsId = uniqid()
    setDisableStake(true)
    dispatch(
      setNotifications({
        type: 'info',
        text: nftNotification.textInfoUnstake,
        link: null,
      })
    )
    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      const parameter: any = {
        from: user.address,
        to: ORBITER_NFT_STAKING_CONTRACT_ADDRESS,
        data: contract.methods.unstakeNft(id).encodeABI(),
      }
      let gasLimit = await web3.eth.estimateGas(parameter)

      gasLimit = +gasLimit
      gasLimit += gasLimit

      const result = await contract.methods
        .unstakeNft(id)
        .send({
          from: user.address,
          gas: web3.utils.toHex(gasLimit),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: nftNotification.textSubmittedUnstake,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      setDisableStake(false)
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          text: nftNotification.textSuccessUnstake,
          link: result.transactionHash,
        })
      )
      setTimeout(() => refetchInfo(), 15000)
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      setDisableStake(false)
      dispatch(
        setNotifications({
          type: 'error',
          text: nftNotification.textErrorUnstaketake,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const unStakeAll = async () => {
    const transactionsId = uniqid()
    setDisableStake(true)
    dispatch(
      setNotifications({
        type: 'info',
        text: nftNotification.textInfoUnstake,
        link: null,
      })
    )
    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      const parameter: any = {
        from: user.address,
        to: ORBITER_NFT_STAKING_CONTRACT_ADDRESS,
        data: contract.methods.unstakeBatch(getNftIds(true)).encodeABI(),
      }
      const gasLimit = await web3.eth.estimateGas(parameter)

      const result = await contract.methods
        .unstakeBatch(getNftIds(true))
        .send({
          from: user.address,
          gas: web3.utils.toHex(gasLimit),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: nftNotification.textSubmittedUnstake,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      setDisableStake(false)
      dispatch(
        setNotifications({
          type: 'success',
          text: nftNotification.textSuccessUnstake,
          link: result.transactionHash,
        })
      )
      setTimeout(() => refetchInfo(), 15000)
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      setDisableStake(false)
      dispatch(
        setNotifications({
          type: 'error',
          text: nftNotification.textErrorUnstaketake,
          link: null,
        })
      )
      console.log(error)
    }
  }

  return (
    <div className={classes.mainBlock}>
      <div className={classes.titleWrapper}>
        <div className={classes.titleText}>My NFTs</div>
        <div className={classes.buttonsWrapper}>
          <div
            onClick={() => refetchInfo()}
            className={classes.documentsWrapper}
          >
            <img
              className={['refreshIcon', loading && 'refreshIconActive'].join(
                ' '
              )}
              src={refreshIcon}
              alt=""
            />
            <div className={classes.subTitleText}>Just now</div>
          </div>
          <div className={classes.allButtonsWrapper}>
            {isUnStakedNft ? (
              <div
                onClick={() => !disableStake && stakeAll()}
                style={disableStake ? { cursor: 'not-allowed' } : {}}
                className={classes.documentsWrapper}
              >
                <img className={'allIcon'} src={stakeIcon} alt="" />
                {!isMobile && (
                  <div className={classes.greenText}>STAKE ALL</div>
                )}
              </div>
            ) : null}
            {isStakedNft ? (
              <div
                style={disableStake ? { cursor: 'not-allowed' } : {}}
                onClick={() => !disableStake && unStakeAll()}
                className={classes.documentsWrapper}
              >
                <img className={'allIcon'} src={minusIcon} alt="" />
                {!isMobile && (
                  <div className={classes.unstakeText}>UNSTAKE ALL</div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {!isStakedNft ? (
        <div
          className={[classes.subTitleText, classes.subTitleNFTText].join(' ')}
        >
          Select your NFT for staking
        </div>
      ) : null}
      <div
        className={
          !loading ? classes.nftCardWrapper : classes.loadingNftCardWrapper
        }
      >
        {loading ? (
          <div className={classes.galleryImg}>
            <Lottie {...defaultOptions} />
          </div>
        ) : nft.length ? (
          nft.map((item: INft, index: number) => (
            <NftCard
              img={item.imageLink}
              title={item.name}
              id={item.tokenId}
              date={item.stakingTime}
              isLocked={item.isLocked}
              stakeNft={stake}
              disableStake={disableStake}
              unStakeNft={unStake}
              intercom={item.intercom}
            />
          ))
        ) : null}
      </div>
    </div>
  )
}
export default MyNFTS
