const actualApiFor = process.env.REACT_APP_X_ORBITER_ACTIVE_API_FOR

export const paramsMovr = {
  chainName: actualApiFor === 'dev' ? 'Moonbase Alpha' : 'Moonbeam',
  chainId: actualApiFor === 'dev' ? 1287 : 1284,
  nativeCurrency: {
    name: actualApiFor === 'dev' ? 'DEV' : 'GLMR',
    decimals: 18,
    symbol: actualApiFor === 'dev' ? 'DEV' : 'GLMR',
  },
  rpcUrls:
    actualApiFor === 'dev'
      ? ['https://rpc.api.moonbase.moonbeam.network']
      : ['https://rpc.api.moonbeam.network'],
  blockExplorerUrls:
    actualApiFor === 'dev'
      ? ['https://moonbase.moonscan.io/']
      : ['https://moonbeam.moonscan.io/'],
}

export const paramsMoonbeam = {
  chainName: 'Moonbeam',
  chainId: 1284,
  nativeCurrency: {
    name: 'GLMR',
    decimals: 18,
    symbol: 'GLMR',
  },
  rpcUrls: ['https://rpc.api.moonbeam.network'],
  blockExplorerUrls: ['https://moonbeam.moonscan.io/'],
}

export const paramsZkSyncTest = {
  chainName: 'zkSync Era Testnet',
  chainId: 280,
  nativeCurrency: {
    name: 'ETH',
    decimals: 18,
    symbol: 'ETH',
  },
  rpcUrls: ['https://testnet.era.zksync.dev'],
  blockExplorerUrls: ['https://goerli.explorer.zksync.io'],
}

export const paramsArbitrumTest = {
  chainName: 'Arbitrum Goerli',
  chainId: 421613,
  nativeCurrency: {
    name: 'AGOR',
    decimals: 18,
    symbol: 'AGOR',
  },
  rpcUrls: ['https://endpoints.omniatech.io/v1/arbitrum/goerli/public'],
  blockExplorerUrls: ['https://goerli-rollup-explorer.arbitrum.io'],
}
