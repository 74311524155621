import React, { useContext, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import plus from '../../img/NFT/add-circle.svg'
import minus from '../../img/NFT/minus-cirlce.svg'
import close from '../../img/NFT/close-circle.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenWalletModal } from '../../redux/loading'
import { request, requestNFT } from '../../factory/axios'
import NFTContract from '../../contracts/nft-contract.abi'
import { Web3Context } from '../../context'
import erc20AbiAbi from '../../contracts/erc20Abi.abi'
import {
  deleteNotifications,
  setNotifications,
} from '../../redux/notifications'
import {
  nftNotification,
  supplyNotification,
} from '../../utils/notificationTexts'
import { fromBn, toBn } from 'evm-bn'
import uniqid from 'uniqid'
import { setUserNfts } from '../../redux/userNfts'
import { transform } from '../../factory/bigNumber'
import { Simulate } from 'react-dom/test-utils'
import error = Simulate.error

const styles = createUseStyles({
  modalBg: {
    width: '100%',
    background: 'rgb(0 0 0 / 70%)',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 15,
  },
  modalNftWrapper: {
    width: 340,
    height: 392,
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    boxSizing: 'border-box',
    padding: [30, 45],
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  modalTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
    textAlign: 'center',
    paddingBottom: 10,
  },
  modalSubTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#A8A8B2',
    textAlign: 'center',
  },
  counter: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '36px',
    color: '#FFFFFF',
    width: 31,
    textAlign: 'center',
  },
  operationLobbyWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 43,
    justifyContent: 'center',
    paddingBottom: 32,
    paddingTop: 43,
  },
  operationLobbyButton: {
    width: 28,
    height: 28,
    cursor: 'pointer',
  },
  operationLobbyButtonDisabled: {
    width: 28,
    height: 28,
    cursor: 'not-allowed',
    pointerEvents: 'none',
    opacity: 0.7,
  },
  operationButtonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    paddingBottom: 20,
  },
  operationButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 51,
    height: 21,
    background: '#1F1F2D',
    borderRadius: 42,
    cursor: 'pointer',

    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
  },
  price: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 30,
  },
  mainButton: {
    background: '#5493F7',
    borderRadius: 12,
    width: 225,
    height: 60,
    border: 'none',
    textAlign: 'center',
    margin: '0 auto',

    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#FFFFFF',
    cursor: 'pointer',
  },
  disabledMainButton: {
    background: '#44445B',
    extend: 'mainButton',
    cursor: 'not-allowed',
  },
  closeButton: {
    position: 'absolute',
    cursor: 'pointer',
    top: 33,
    right: 34,
  },
  priceTitle: {
    extend: 'modalSubTitle',
    fontSize: 20,
  },
})

interface Props {
  open: boolean
  setOpen: (a: boolean) => void
  orbPrice: number
}
const NFT_CONTRACT = process.env.REACT_APP_X_NFT_CONTRACT
const ORB_CONTRACT = process.env.REACT_APP_X_ORB_CONTRACT
const ORBITER_NFT_API_URL = process.env.REACT_APP_X_ORBITER_NFT_API_URL
const NFTModal: React.FC<Props> = ({ open, setOpen, orbPrice }) => {
  const { web3 } = useContext(Web3Context)
  const [nftContract, setNftContract] = useState(null)
  const [orbContract, setOrbContract] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [disableApprove, setDisableApprove] = useState(false)
  const [tokenAllowance, setTokenAllowance] = useState('')
  const [value, setValue] = useState(1)
  const classes = styles()
  const { user } = useSelector((state: any) => state.userReducer)
  const dispatch = useDispatch()
  const price = 1000
  const priceUsd = orbPrice * price

  const infinity =
    '115792089237316195423570985008687907853269984665640564039457.584007913129639935'

  useEffect(() => {
    if (user.address) {
      defineContracts()
    }
  }, [user.address])

  useEffect(() => {
    checkAllowance()
  }, [orbContract])

  useEffect(() => {
    if (user.address) {
      defineContracts()
      checkAllowance()
    }
  }, [])

  const defineContracts = () => {
    const contract = new web3.eth.Contract(NFTContract, NFT_CONTRACT)
    setNftContract(contract)
    const orbContract = new web3.eth.Contract(erc20AbiAbi, ORB_CONTRACT)
    setOrbContract(orbContract)
  }

  const checkAllowance = async () => {
    try {
      const result = await orbContract.methods
        .allowance(user.address, NFT_CONTRACT)
        .call()

      setTokenAllowance(fromBn(result, 18).toString())
    } catch (error) {}
  }

  const requestNFTTest = () => {
    return requestNFT({
      method: 'post',
      url: `${ORBITER_NFT_API_URL}/nft-generator/random-nft`,
      data: {
        ownerAddress: user.address,
        count: value,
      },
    }).then(async (responce) => {
      await mint(responce.data.personalSign, responce.data.ipfsHashes)
    })
  }

  const heandleMint = async () => {
    setDisabled(true)
    dispatch(
      setNotifications({
        type: 'info',
        text: nftNotification.textInfoMint,
        link: null,
        id: 1,
        isPending: true,
      })
    )

    requestNFTTest().catch((e) => {
      if (e.response.status === 502) {
        requestNFTTest().catch(() => {
          setDisabled(false)
          dispatch(
            setNotifications({
              type: 'error',
              text: 'Sorry, we cannot connect to the Moonbeam network.',
              link: null,
              button: true,
            })
          )
        })
      }
    })
  }

  const approve = async () => {
    const transactionsId = uniqid()
    dispatch(
      setNotifications({
        type: 'info',
        text: nftNotification.textInfoApproval,
        link: null,
      })
    )
    setDisableApprove(true)
    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await orbContract.methods
        .approve(NFT_CONTRACT, toBn(`${infinity}`, 18).toString())
        .estimateGas({
          from: user.address,
        })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      setTimeout(() => dispatch(deleteNotifications(1)), 5000)
      const result = await orbContract.methods
        .approve(NFT_CONTRACT, toBn(`${infinity}`, 18).toString())
        .send({
          from: user.address,
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: nftNotification.textSubmittedApproval,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          text: nftNotification.textSuccessApproval,
          link: result.transactionHash,
        })
      )
      checkAllowance()
      setDisableApprove(false)
      console.log('approve:', result)
    } catch (error) {
      setDisableApprove(false)
      setDisabled(false)
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: nftNotification.textErrorApproval,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const mint = async (personalSign: string, ipfsHashes: string[]) => {
    const transactionsId = uniqid()
    setTimeout(() => dispatch(deleteNotifications(1)), 10000)
    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await nftContract.methods
        .safeMint(personalSign, ipfsHashes)
        .estimateGas({
          from: user.address,
        })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      const result = await nftContract.methods
        .safeMint(personalSign, ipfsHashes)
        .send({
          from: user.address,
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: nftNotification.textSubmittedMint,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          text: nftNotification.textSuccessMint,
          link: result.transactionHash,
        })
      )
      setOpen(false)
      setDisabled(false)

      setTimeout(() => {
        requestNFT({
          method: 'get',
          url: `${ORBITER_NFT_API_URL}/user-nft/list/${user.address}?limit=99999`,
        })
          .then((req) => {
            dispatch(setUserNfts(req.data.result))
          })
          .finally(() => {
            dispatch(
              setNotifications({
                type: 'info',
                text: nftNotification.textNFTLoad,
                link: null,
                button: true,
              })
            )
          })
      }, 15000)

      console.log('mint:', result)
    } catch (error) {
      setDisabled(false)
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: nftNotification.textErrorMint,
          link: null,
        })
      )
      console.log(error)
    }
  }

  return (
    <div className={classes.modalBg}>
      <div className={classes.modalNftWrapper}>
        <img
          className={classes.closeButton}
          onClick={() => setOpen(!open)}
          src={close}
          alt=""
        />
        <div className={classes.modalTitle}>Mint NFT</div>
        <div className={classes.modalSubTitle}>Choose the quantity of cats</div>
        <div className={classes.operationLobbyWrapper}>
          {value !== 1 ? (
            <img
              className={classes.operationLobbyButton}
              onClick={() => setValue(value - 1)}
              src={minus}
              alt=""
            />
          ) : (
            <div className={classes.operationLobbyButtonDisabled}></div>
          )}
          <div className={classes.counter}>{value}</div>
          {value < 10 ? (
            <img
              className={classes.operationLobbyButton}
              onClick={() => setValue(value + 1)}
              src={plus}
              alt=""
            />
          ) : (
            <div className={classes.operationLobbyButtonDisabled}></div>
          )}
        </div>
        <div className={classes.operationButtonsWrapper}>
          <div onClick={() => setValue(3)} className={classes.operationButtons}>
            3
          </div>
          <div onClick={() => setValue(5)} className={classes.operationButtons}>
            5
          </div>
          <div
            onClick={() => setValue(10)}
            className={classes.operationButtons}
          >
            10
          </div>
        </div>
        <div className={classes.price}>
          <span className={classes.priceTitle}>
            {value * price} ORB ≈ ${transform(value * priceUsd, 2)}
          </span>
        </div>
        {user.connected ? (
          +tokenAllowance >= value * price ? (
            <button
              disabled={disabled}
              onClick={heandleMint}
              className={
                disabled ? classes.disabledMainButton : classes.mainButton
              }
            >
              MINT
            </button>
          ) : (
            <button
              disabled={disableApprove}
              onClick={approve}
              className={
                disableApprove ? classes.disabledMainButton : classes.mainButton
              }
            >
              APPROVE
            </button>
          )
        ) : (
          <button
            onClick={() => dispatch(setOpenWalletModal(true))}
            className={classes.mainButton}
          >
            CONNECT WALLET
          </button>
        )}
      </div>
    </div>
  )
}

export default NFTModal
