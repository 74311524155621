import React, { useContext, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import 'react-loading-skeleton/dist/skeleton.css'

import arrowdown from '../../img/arrow-down.svg'
import arrowBlue from '../../img/arrow-right-blue.svg'
import arrowGreen from '../../img/arrow-right-green.svg'
import addIcon from '../../img/add-square-green.svg'
import arrow from '../../img/arrowEarn.svg'
import info from '../../img/NFT/info-circle.svg'
import { useDispatch, useSelector } from 'react-redux'
import { commify, sortRewordsAssets } from 'src/utils'
import { Web3Context } from '../../context'
import poolAbi from '../../contracts/erc20Abi.abi'
import farmingPoolAbi12h from '../../contracts/farmingPool12h.abi'
import farmingPoolAbi from '../../contracts/farmingPool.abi'
import { fromBn, toBn } from 'evm-bn'
import BigNumber from 'bignumber.js'
import bigDecimal from 'js-big-decimal'
import { activeTabsEarn } from '../../redux/tadsController'
import uniqid from 'uniqid'
import {
  deleteNotifications,
  setNotifications,
} from '../../redux/notifications'
import {
  poolNotification,
  supplyNotification,
} from '../../utils/notificationTexts'
import {
  paramsArbitrumTest,
  paramsMovr,
  paramsZkSyncTest,
} from '../../utils/networkConst'
import { transform } from '../../factory/bigNumber'
import { Tooltip } from 'antd'
import CountDownTimer from '../CountDownTimer/CountDownTimer'
import { setOpenWalletModal } from '../../redux/loading'
import { setEarnCardState } from '../../redux/earnCardState'

const styles = createUseStyles({
  earnCard: {
    width: 660,
    border: '1px solid #4B567D',
    borderRadius: 12,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  earnCardOpen: {
    extend: 'earnCard',
    height: 86,
  },
  tokenImg: {
    width: 24,
    height: 24,
  },
  imgBlockWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    paddingBottom: 2,
  },
  paddingBottomFive: {
    paddingBottom: 5,
  },
  orbText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
  },
  infoIconWrapper: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  cardWrapperOpen: {
    display: 'flex',
    paddingRight: 20,
    paddingLeft: 20,
  },
  imgWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  mainText: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
  },
  mainTopText: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
  },
  mainSmallText: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
  },
  mainSmallTextBlue: {
    fontWeight: 600,
    fontSize: '16px',
    width: 75,
    lineHeight: '24px',
    color: '#5493F7',
    fontFamily: 'Poppins',
  },
  subText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
    fontFamily: 'Poppins',
  },
  subTextBig: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#A8A8B2',
    fontFamily: 'Poppins',
    paddingRight: 5,
  },
  subCardText: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#A8A8B2',
    fontFamily: 'Poppins',
  },
  getOrbWrapper: {
    display: 'flex',
    gap: 30,
    paddingBottom: 31,
  },
  greenText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#53CBC8',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
  },
  blueText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#5493F7',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
  },
  hr: {
    height: 0,
    width: 660,
    borderTop: '1px solid #4B567D',
    margin: '0 auto',
  },
  harvestButton: {
    width: 130,
    height: 60,
    backgroundColor: '#44445B',

    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    border: 0,
    borderRadius: 12,
    textTransform: 'uppercase',
  },
  lockButton: {
    extend: 'greenText',
    backgroundColor: 'inherit',
    border: '1px solid #53CBC8',
    borderRadius: 8,
    width: 163,
    height: 30,
    marginTop: 5,
  },
  enableButton: {
    extend: 'harvestButton',
    width: 300,
    height: 60,
    backgroundColor: '#5493F7',
  },
  disableButton: {
    extend: 'harvestButton',
    width: 300,
    height: 60,
    backgroundColor: '#44445B',
    cursor: 'not-allowed',
  },
  earnCardWrapperOpen: {
    display: 'block',
  },
  earnCardWrapper: {
    display: 'none',
  },
  buttonWrapper: {
    display: 'flex',
    gap: 30,
  },
  userBalanceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    paddingBottom: 10,
    marginTop: 'auto',
  },
  balanceMainText: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
  },
  balanceSubText: {
    color: '#A8A8B2',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 'normal',
  },
  stakeTabsWrapper: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 20,
    paddingBottom: 30,
  },
  blueHr: {
    width: 20,
    height: 4,
    backgroundColor: '#5493F7',
    borderRadius: 37,
    margin: ' 5px auto 0',
  },
  rewardsTokensWrapper: {
    paddingBottom: 125,
    display: 'flex',
    gap: 100,
    alignItems: 'center',
  },
  inputWrapper: {
    backgroundColor: '#1F1F2D',
    borderRadius: 12,
    width: '300px',
    height: 68,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 15,
    boxSizing: 'border-box',
    padding: [10, 15],
  },
  inputDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'end',
  },
  inputDescriptionText: {
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
  },
  cursorDisable: {
    cursor: 'not-allowed',
  },
  inputPlaceholderText: {
    whiteSpace: 'nowrap',
    width: '270px',
    backgroundColor: '#1F1F2D',
    border: 'none',
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 400,
    color: '#7D7E83',
  },
  inputText: {
    extend: 'inputPlaceholderText',
    cursor: 'text',
    color: '#FFFFFF',
  },
  inputAvaliableValue: {
    extend: 'inputPlaceholderText',
    width: 'inherit',
    display: 'flex',
    flexDirection: 'column-reverse',
    color: '#5493F7',
    fontWeight: 600,
    alignItems: 'flex-end',
  },
  inputAvaliableValueUSD: {
    extend: 'inputPlaceholderText',
    color: '#A8A8B2',
    display: 'flex',
    width: 106,
    justifyContent: 'end',
    fontWeight: 400,
  },
  depositSelectWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    paddingBottom: 30,
  },
  depositSelectButton: {
    fontFamily: 'Poppins',
    cursor: 'pointer',
    backgroundColor: '#1F1F2D',
    border: 'none',
    width: 52,
    height: 22,
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 42,
  },
  depositSelectButtonDisabled: {
    backgroundColor: '#1F1F2D',
    border: 'none',
    width: 52,
    height: 22,
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 42,
    cursor: 'not-allowed',
  },
  stakeWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  rewardsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 20,
  },
  rewardsTitle: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    paddingBottom: 25,
  },
  rewardsEmountWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    paddingBottom: 38,
  },
  orbEmoutCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  orbEmountImgWrapper: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
  },
  tokenIcon: {
    width: 16,
    height: 16,
  },
  werticalHr: {
    width: 1,
    backgroundColor: '#4B567D',
    marginRight: 19,
    marginLeft: 20,
  },
  claimButton: {
    extend: 'harvestButton',
    width: 280,
    height: 60,
    backgroundColor: '#5493F7',
    marginTop: 20,
  },
  claimButtonDisable: {
    extend: 'harvestButton',
    width: 280,
    height: 60,
    backgroundColor: '#44445B',
    cursor: 'not-allowed',
    marginTop: 20,
  },
  imgTokenWrapper: {
    position: 'relative',
    width: 50,
    height: 50,
    marginRight: 15,
  },
  tokenImgTop: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  tokenImgBottom: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  networkApyWrapper: {
    display: 'flex',
  },
  imgApyWrapper: {
    extend: 'imgWrapper',
    paddingRight: 20,
  },
  switchMenu: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  ApySmallText: {
    extend: 'mainText',
    fontSize: '14px',
    lineHeight: '21px',
  },
  tabText: {
    extend: 'mainText',
    color: '#A8A8B2',
  },
  switchIcon: {
    width: 20,
    height: 20,
  },
  switchTextBlue: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#5493F7',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  switchTextGreen: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#53CBC8',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  hrSmall: {
    extend: 'hr',
    width: 640,
  },
  tokenRewordsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  claimButtonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    marginTop: 'auto',
  },
  readyToClaimWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  stakeBottomWrapper: {
    marginTop: 'auto',
  },
  smallTimer: {
    position: 'absolute',
    left: '50%',
    top: '-15px',
    transform: 'translate(-50%, 0)',
    borderRadius: 74,
    border: '1px solid #5493F7',
    background:
      'linear-gradient(180deg, rgba(49, 57, 84) 0%, rgba(53, 53, 81, 0.80) 100%)',
    display: 'flex',
    gap: 5,
    padding: [4, 10],
  },
  stakeTab: {
    cursor: 'pointer',
  },
  stakeTabActive: {
    cursor: 'pointer',
    paddingBottom: 9,
  },
  toolTipWrapper: {
    background: '#1F1F2D',
    borderRadius: 12,
    padding: [12, 16, 7],
    width: 162,
  },
  tooltipLine: {
    width: '100%',
    height: '1px',
    borderBottom: 'none',
    border: 'none',
    backgroundColor: '#4B567D',
    marginTop: '8px',
    marginBottom: '8px',
  },
  tooltipItemTextSign: {
    color: '#5493F7',
    fontWeight: 500,
    marginRight: '4px',
  },
  tooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 5,
  },
  tooltipMainText: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
  },
  tooltipSubText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
  },
  smallTimerStyle: {
    width: 80,
  },
  pointer: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  timerTextWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  timerWrapper: {
    marginBottom: 0,
    margin: 'auto',
  },
  poolsLableWrapper: {
    position: 'absolute',
    top: 0,
    left: -10,
    background: 'linear-gradient(44deg, #00E0FC 0%, #5493F7 100%)',
    borderRadius: 74,
    padding: [4, 10],
  },
  poolsLableText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
    textTransform: 'uppercase',
  },
  poolDescriptionWrapper: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    border: '1px solid #4B567D',
    borderRadius: 12,
    padding: 15,
    marginBottom: 30,
    alignItems: 'start',
  },
  poolDescriptionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: 'normal',
    color: '#FFFFFF',
  },
  arrowClosed: {
    rotate: '180deg',
  },
  poolDescriptionInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  poolDescriptionInfo24h: {
    display: 'flex',
    gap: 40,
    justifyContent: 'space-between',
  },
  poolDescriptionMainText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 'normal',
    color: '#A8A8B2',
  },
  poolDescriptionBlueText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 'normal',
    color: '#5493F7',
  },
  poolDescriptionGreenText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 'normal',
    color: '#53CBC8',
  },
  poolDescriptionTipWrapper: {
    paddingBottom: 0,
  },
  poolDescriptionMainTextWidth: {
    width: 356,
  },
  poolDescriptionInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
})
interface Props {
  data: any
  pool12Hours: any
  nextTime: any
  switchNetwork: any
  title: any
}

enum CardNetwork {
  MOONBEAM = 'MOONBEAM',
  ARBITRUM = 'ARBITRUM',
  ZKSYNC = 'ZKSYNC',
}
const EarnCardTabletNotConnected: React.FC<Props> = ({
  data,
  pool12Hours,
  nextTime,
  switchNetwork,
  refetch,
  title,
}) => {
  const [openCard, setOpenCard] = useState(false)
  const [openDescription, setOpenDescription] = useState(false)
  const [activeTab, setActiveTab] = useState<activeTabsEarn>(
    activeTabsEarn.stake
  )
  const [poolContract, setPoolContract] = useState(null)
  const [farmingPoolContract, setFarmingPoolContract] = useState(null)
  const [claimRewardsDisableButton, setClaimRewardsDisableButton] =
    useState(false)
  const [transactionInProgres, setTransactionInProgres] = useState(false)
  const [claimLpDisableButton, setClaimLpDisableButton] = useState(false)
  const [userBalance, setUserBalance] = useState<any>(null)
  const [userStaked, setUserStaked] = useState<any>(null)
  const [unstakeLocked, setUnstakeLocked] = useState(null)
  const [userTokenAllowance, setTokenAllowance] = useState<any>(null)
  const [inputValue, setInputValue] = useState('')
  const [currentNetwork, setCurrentNetwork] = useState(CardNetwork.MOONBEAM)
  const [firstNetwork, setFirstNetwork] = useState(CardNetwork.ARBITRUM)
  const [secondNetwork, setSecondNetwork] = useState(CardNetwork.ZKSYNC)

  const classes = styles()

  const { web3 } = useContext(Web3Context)
  const { open } = useSelector((state) => state.earnCardStateReducer)
  const { user } = useSelector((state) => state.userReducer)

  const dispatch = useDispatch()

  const disabledButton = (balance) => {
    const disabled = !balance || balance === '0'
    return (
      !transactionInProgres &&
      !disabled &&
      !!inputValue &&
      +inputValue.replace(/[\s,]/g, '') !== 0 &&
      !(+inputValue.replace(/[\s,]/g, '') > balance)
    )
  }

  const getClaimRewardsDisableButton = async () => {
    if (+data[currentNetwork].networkId !== user.chainId) {
      setClaimRewardsDisableButton(true)
      return
    }
    const getRewardsAmount = (): number => {
      return data[currentNetwork].rewards.reduce(
        (accumulator, item) => +accumulator + +item.availableToClaim,
        0
      )
    }
    if (getRewardsAmount() === 0) {
      setClaimRewardsDisableButton(true)
      return
    }
    try {
      const lastClaim = await farmingPoolContract.methods
        .lastClaim(user.address)
        .call()

      const getClaimCycleTime = await farmingPoolContract.methods
        .getClaimCycleTime()
        .call()

      setClaimRewardsDisableButton(lastClaim > getClaimCycleTime)
    } catch (error) {}
  }

  const getClaimLpDisableButton = async (
    unstakeLocked: null | undefined | string
  ) => {
    if (+data[currentNetwork].networkId !== user.chainId) {
      setClaimLpDisableButton(true)
      return
    }

    if (unstakeLocked === '0') {
      setClaimLpDisableButton(true)
      return
    }
    try {
      const lastUnstake = await farmingPoolContract.methods
        .lastUnstake(user.address)
        .call()

      const getClaimCycleTime = await farmingPoolContract.methods
        .getClaimCycleTime()
        .call()

      setClaimLpDisableButton(lastUnstake > getClaimCycleTime)
    } catch (error) {}
  }

  const isApproveNeed = userTokenAllowance >= inputValue

  // useEffect(() => {
  //   if (data[currentNetwork]?.farmingPool) {
  //     defineContracts(
  //       data[currentNetwork]?.liquidityPool,
  //       data[currentNetwork]?.farmingPool
  //     )
  //   }
  // }, [data, currentNetwork, user])

  // useEffect(() => {
  //   if (data[currentNetwork]?.farmingPool) {
  //     defineContracts(
  //       data[currentNetwork]?.liquidityPool,
  //       data[currentNetwork]?.farmingPool
  //     )
  //   }
  //   setOpenCard(open[title])
  // }, [])

  useEffect(() => {
    setOpenCard(open[title])
  }, [open])

  useEffect(() => {
    if (poolContract) {
      getBalance()
      checkAllowance()
    }
  }, [poolContract, user])

  useEffect(() => {
    if (farmingPoolContract) {
      getUserStaked()
      !pool12Hours && getUnstakeLocked()
      getClaimRewardsDisableButton()
    }
  }, [farmingPoolContract, user])

  useEffect(() => {
    if (farmingPoolContract) {
      !pool12Hours &&
        getUnstakeLocked().then((result) => getClaimLpDisableButton(result))
    }
  }, [farmingPoolContract, user, data])

  useEffect(() => {
    setInputValue('')
  }, [activeTab, user.chainId])

  // const defineContracts = async (poolAddress, farmingPoolAddress) => {
  //   const poolContract = new web3.eth.Contract(poolAbi, poolAddress)
  //   setPoolContract(poolContract)

  //   const farmingPoolContract = new web3.eth.Contract(
  //     pool12Hours ? farmingPoolAbi12h : farmingPoolAbi,
  //     farmingPoolAddress
  //   )
  //   setFarmingPoolContract(farmingPoolContract)
  // }

  const getBalance = async () => {
    try {
      const result = await poolContract.methods.balanceOf(user.address).call()

      setUserBalance(fromBn(result, 18).toString())
    } catch (error) {
      console.log(error)
    }
  }

  const checkAllowance = async () => {
    try {
      const result = await poolContract.methods
        .allowance(user.address, data[currentNetwork].farmingPool)
        .call()

      setTokenAllowance(fromBn(result, 18).toString())
    } catch (error) {
      console.log(error)
    }
  }

  const enterPersentInInput = (percent: number, balance) => {
    const availableToSupplyAssetBN = new BigNumber(balance)

    setInputValue(
      commify(bigDecimal.multiply(availableToSupplyAssetBN, percent))
    )
  }

  const checkTabs = (tab: activeTabsEarn) => {
    return activeTab === tab
  }

  const confirmUnstake = async () => {
    const transactionsId = uniqid()
    setTransactionInProgres(true)
    setClaimLpDisableButton(true)

    setInputValue('')
    dispatch(
      setNotifications({
        type: 'info',
        text: poolNotification.textInfoClaimLp,
        link: null,
      })
    )
    try {
      // let gasPrice = await web3.eth.getGasPrice()
      //
      // gasPrice = +gasPrice
      // gasPrice += gasPrice
      //
      // let gasLimit = await poolContract.methods.approve().estimateGas({
      //   from: user.address,
      // })
      //
      // gasLimit = +gasLimit
      // gasLimit += gasLimit

      const result = await farmingPoolContract.methods
        .confirmUnstake()
        .send({
          from: user.address,
          // gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          // gasPrice: web3.utils.toHex(Math.round(gasPrice)),
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                explorerUrl: data[currentNetwork].explorer,
                text: poolNotification.textSubmittedClaimLp,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      setTransactionInProgres(false)
      getUnstakeLocked().then((result) => getClaimLpDisableButton(result))
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          explorerUrl: data[currentNetwork].explorer,
          text: poolNotification.textSuccessClaimLp,
          link: result.transactionHash,
        })
      )

      console.log('claimLp:', result)
    } catch (error) {
      setTransactionInProgres(false)
      getUnstakeLocked().then((result) => getClaimLpDisableButton(result))
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: poolNotification.textErrorClaimLp,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const approve = async () => {
    const transactionsId = uniqid()
    setTransactionInProgres(true)

    setInputValue('')
    dispatch(
      setNotifications({
        type: 'info',
        text: poolNotification.textInfoApproval,
        link: null,
      })
    )
    try {
      // let gasPrice = await web3.eth.getGasPrice()
      //
      // gasPrice = +gasPrice
      // gasPrice += gasPrice
      //
      // let gasLimit = await poolContract.methods.approve().estimateGas({
      //   from: user.address,
      // })
      //
      // gasLimit = +gasLimit
      // gasLimit += gasLimit

      const result = await poolContract.methods
        .approve(
          data[currentNetwork]?.farmingPool,
          toBn(`${999999}`, 18).toString()
        )
        .send({
          from: user.address,
          // gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          // gasPrice: web3.utils.toHex(Math.round(gasPrice)),
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: poolNotification.textSubmittedApproval,
                explorerUrl: data[currentNetwork].explorer,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          text: poolNotification.textSuccessApproval,
          link: result.transactionHash,
          explorerUrl: data[currentNetwork].explorer,
        })
      )
      setTransactionInProgres(false)
      console.log('approve:', result)
      checkAllowance()
      await stakeTokens(inputValue.replace(/[\s,]/g, ''))
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      setTransactionInProgres(false)
      dispatch(
        setNotifications({
          type: 'error',
          text: poolNotification.textErrorApproval,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const stakeTokens = async (amount: string) => {
    setTransactionInProgres(true)
    let result
    const transactionsId = uniqid()
    dispatch(
      setNotifications({
        type: 'info',
        text: poolNotification.textInfoStake,
        link: null,
      })
    )

    setInputValue('')

    try {
      // let gasPrice = await web3.eth.getGasPrice()
      //
      // gasPrice = +gasPrice
      // gasPrice += gasPrice
      //
      // let gasLimit = await farmingPoolContract.methods
      //     .mint(toBn(`${amount}`, asset.tokenDecimal).toString())
      //     .estimateGas({
      //       from: user.address,
      //     })
      //
      // gasLimit = +gasLimit
      // gasLimit += gasLimit

      result = await farmingPoolContract.methods
        .stakeTokens(toBn(`${amount}`, 18).toString())
        .send({
          // gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          // gasPrice: web3.utils.toHex(Math.round(gasPrice)),
          from: user.address,
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: poolNotification.textSubmittedStake,
                explorerUrl: data[currentNetwork].explorer,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          explorerUrl: data[currentNetwork].explorer,
          text: poolNotification.textSuccessStake,
          link: result.transactionHash,
        })
      )
      setTransactionInProgres(false)
      refetch()
      getUserStaked()
      getBalance()
      console.log('Stake', result)
      setInputValue('')
    } catch (error) {
      setTransactionInProgres(false)
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: poolNotification.textErrorStake,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const unstakeTokens = async (amount: string) => {
    setTransactionInProgres(true)
    let result
    const transactionsId = uniqid()
    dispatch(
      setNotifications({
        type: 'info',
        text: poolNotification.textInfoUnstake,
        link: null,
      })
    )

    setInputValue('')

    try {
      // let gasPrice = await web3.eth.getGasPrice()
      //
      // gasPrice = +gasPrice
      // gasPrice += gasPrice
      //
      // let gasLimit = await farmingPoolContract.methods
      //     .mint(toBn(`${amount}`, asset.tokenDecimal).toString())
      //     .estimateGas({
      //       from: user.address,
      //     })
      //
      // gasLimit = +gasLimit
      // gasLimit += gasLimit
      if (pool12Hours) {
        result = await farmingPoolContract.methods
          .unstakeTokens(toBn(`${amount}`, 18).toString())
          .send({
            // gasLimit: web3.utils.toHex(Math.round(gasLimit)),
            // gasPrice: web3.utils.toHex(Math.round(gasPrice)),
            from: user.address,
          })
          .on(
            'transactionHash',
            (hash: string) =>
              hash &&
              dispatch(
                setNotifications({
                  type: 'info',
                  explorerUrl: data[currentNetwork].explorer,
                  text: poolNotification.textSubmittedUnstake,
                  link: hash,
                  id: transactionsId,
                  isPending: true,
                })
              )
          )
      } else {
        result = await farmingPoolContract.methods
          .unstakeRequest(toBn(`${amount}`, 18).toString())
          .send({
            // gasLimit: web3.utils.toHex(Math.round(gasLimit)),
            // gasPrice: web3.utils.toHex(Math.round(gasPrice)),
            from: user.address,
          })
          .on(
            'transactionHash',
            (hash: string) =>
              hash &&
              dispatch(
                setNotifications({
                  type: 'info',
                  explorerUrl: data[currentNetwork].explorer,
                  text: poolNotification.textSubmittedUnstake,
                  link: hash,
                  id: transactionsId,
                  isPending: true,
                })
              )
          )
      }
      dispatch(deleteNotifications(transactionsId))
      setTransactionInProgres(false)
      dispatch(
        setNotifications({
          type: 'success',
          explorerUrl: data[currentNetwork].explorer,
          text: pool12Hours
            ? poolNotification.textSuccessUnstake
            : poolNotification.textSuccessUnstakeReq,
          link: result.transactionHash,
        })
      )
      refetch()
      getUserStaked()
      getBalance()
      console.log('unstakeTokens', result)
      setInputValue('')
    } catch (error) {
      setTransactionInProgres(false)
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: supplyNotification.textErrorDeposit,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const claim = async () => {
    const transactionsId = uniqid()
    setClaimRewardsDisableButton(true)
    setTransactionInProgres(true)

    dispatch(
      setNotifications({
        type: 'info',
        text: poolNotification.textInfoClaim,
        link: null,
      })
    )
    try {
      // let gasPrice = await web3.eth.getGasPrice()
      //
      // gasPrice = +gasPrice
      // gasPrice += gasPrice
      //
      // let gasLimit = await farmingPoolContract.methods.claim().estimateGas({
      //   from: user.address,
      // })
      //
      // gasLimit = +gasLimit
      // gasLimit += gasLimit

      const result = await farmingPoolContract.methods
        .claim()
        .send({
          // gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          // gasPrice: web3.utils.toHex(Math.round(gasPrice)),
          from: user.address,
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                explorerUrl: data[currentNetwork].explorer,
                text: poolNotification.textSubmittedClaim,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      refetch()
      setTransactionInProgres(false)
      getClaimRewardsDisableButton()
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          explorerUrl: data[currentNetwork].explorer,
          text: poolNotification.textSuccessClaim,
          link: result.transactionHash,
        })
      )
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      setTransactionInProgres(false)
      getClaimRewardsDisableButton()
      dispatch(
        setNotifications({
          type: 'error',
          text: poolNotification.textErrorClaim,
          link: null,
        })
      )
      console.log('error', error)
    }
  }

  const getUnstakeLocked = async () => {
    if (!pool12Hours) {
      try {
        const result = await farmingPoolContract.methods
          .unstakeLocked(user.address)
          .call()

        setUnstakeLocked(fromBn(result, 18).toString())
        return fromBn(result, 18).toString()
      } catch (error) {
        console.log(error)
      }
    }
  }

  const getUserStaked = async () => {
    try {
      const result = await farmingPoolContract.methods
        .userTotalStaked(user.address)
        .call()

      setUserStaked(fromBn(result, 18).toString())
    } catch (error) {
      console.log(error)
    }
  }

  const switchNetworkLocal = async (networkToChange, setNetwork) => {
    if (networkToChange === CardNetwork.MOONBEAM) {
      switchNetwork(paramsMovr, () =>
        setCurrentNetwork((prevState) => {
          setNetwork(prevState)
          return networkToChange
        })
      )
    } else if (networkToChange === CardNetwork.ARBITRUM) {
      switchNetwork(paramsArbitrumTest, () =>
        setCurrentNetwork((prevState) => {
          setNetwork(prevState)
          return networkToChange
        })
      )
    } else if (networkToChange === CardNetwork.ZKSYNC) {
      switchNetwork(paramsZkSyncTest, () =>
        setCurrentNetwork((prevState) => {
          setNetwork(prevState)
          return networkToChange
        })
      )
    }
  }

  const changeNetwork = async (networkToChange, setNetwork, e) => {
    setFarmingPoolContract(null)
    setPoolContract(null)
    switchNetworkLocal(networkToChange, setNetwork)
    e.stopPropagation()
  }

  const approveAndStake = async () => {
    if (!isApproveNeed) {
      await approve()
    }

    if (inputValue.replace(/[\s,]/g, '') <= userTokenAllowance) {
      await stakeTokens(inputValue.replace(/[\s,]/g, ''))
    }
  }

  const renderText = () => {
    if (title === '12h') {
      return openDescription ? (
        <div
          className={classes.poolDescriptionWrapper}
          onClick={() => setOpenDescription(false)}
        >
          <div className={classes.poolDescriptionTitle}>
            <div>Simple Farm 🌱</div>
            <img style={{}} src={arrowdown} alt="" />
          </div>
          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>
              Claim Frequency:
            </span>{' '}
            You can claim your rewards every 12 hours
          </div>
          {/* <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>
              Timer Reset:
            </span>{' '}
            If you make additional deposits, the 12-hour timer will reset
          </div> */}
          <div
            className={[
              classes.poolDescriptionMainText,
              classes.poolDescriptionTipWrapper,
            ].join(' ')}
          >
            <span className={classes.poolDescriptionGreenText}>
              👉 Quick Tip:
            </span>{' '}
            This is perfect if you like to check in frequently and claim your
            rewards!
          </div>
        </div>
      ) : (
        <div
          className={classes.poolDescriptionWrapper}
          onClick={() => setOpenDescription(true)}
        >
          <div className={classes.poolDescriptionTitle}>
            <div>Level 1 Farm</div>
            <img className={classes.arrowClosed} src={arrowdown} alt="" />
          </div>
        </div>
      )
    } else if (title === '24h') {
      return openDescription ? (
        <div
          className={classes.poolDescriptionWrapper}
          onClick={() => setOpenDescription(false)}
        >
          <div className={classes.poolDescriptionTitle}>
            <div>Advanced Farm 🌿</div>
            <img style={{}} src={arrowdown} alt="" />
          </div>
          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>
              Earning Start:
            </span>{' '}
            Your earnings kick off at the start of the next cycle
          </div>
          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>Token Lock:</span>{' '}
            When you decide to withdraw your LP tokens, they'll stay locked but
            continue to earn until the end of the current cycle
          </div>
          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>Withdrawal:</span>{' '}
            Your tokens will be available for withdrawal at the start of the
            next cycle
          </div>
          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>
              Claim Frequency:
            </span>{' '}
            You can claim your rewards at the start of each new cycle
          </div>
          <div
            className={[
              classes.poolDescriptionMainText,
              classes.poolDescriptionTipWrapper,
            ].join(' ')}
          >
            <span className={classes.poolDescriptionGreenText}>
              👉 Quick Tip:{' '}
            </span>
            This is great if you're looking for a balance between frequent and
            long-term farming!
          </div>
        </div>
      ) : (
        <div
          className={classes.poolDescriptionWrapper}
          onClick={() => setOpenDescription(true)}
        >
          <div className={classes.poolDescriptionTitle}>
            <div>Advanced Farm 🌿</div>
            <img className={classes.arrowClosed} src={arrowdown} alt="" />
          </div>
        </div>
      )
    } else if (title === '7d') {
      return openDescription ? (
        <div
          className={classes.poolDescriptionWrapper}
          onClick={() => setOpenDescription(false)}
        >
          <div className={classes.poolDescriptionTitle}>
            <div>SElite Farm 🌳</div>
            <img style={{}} src={arrowdown} alt="" />
          </div>

          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>
              Earning Start:
            </span>{' '}
            Your earnings begin at the start of the next cycle.
          </div>
          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>Token Lock:</span>{' '}
            If you withdraw your LP tokens, they'll be locked until the end of
            the current cycle but will keep earning.
          </div>

          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>Withdrawal:</span>{' '}
            Your tokens will be ready for withdrawal at the start of the next
            cycle.
          </div>
          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>
              Claim Frequency:
            </span>{' '}
            You can claim your rewards at the start of every 8th cycle.
          </div>
          <div
            className={[
              classes.poolDescriptionMainText,
              classes.poolDescriptionTipWrapper,
            ].join(' ')}
          >
            <span className={classes.poolDescriptionGreenText}>
              👉 Quick Tip:{' '}
            </span>
            Choose this if you're in it for the long haul and want to maximize
            your earnings over time!
          </div>
        </div>
      ) : (
        <div
          className={classes.poolDescriptionWrapper}
          onClick={() => setOpenDescription(true)}
        >
          <div className={classes.poolDescriptionTitle}>
            <div>Elite Farm 🌳</div>
            <img className={classes.arrowClosed} src={arrowdown} alt="" />
          </div>
        </div>
      )
    }
  }

  const renderButton = () => {
    return (
      <button
        onClick={() => dispatch(setOpenWalletModal(true))}
        className={classes.enableButton}
      >
        Connect Wallet
      </button>
    )
  }

  return (
    <div className={classes.earnCard}>
      <div className={classes.poolsLableWrapper}>
        <span className={classes.poolsLableText}>{title}</span>
      </div>
      <div
        className={classes.earnCardWrapperOpen}
        onClick={() =>
          !openCard && dispatch(setEarnCardState({ ...open, [title]: true }))
        }
      >
        <div
          className={classes.cardWrapper}
          onClick={() =>
            openCard && dispatch(setEarnCardState({ ...open, [title]: false }))
          }
        >
          <div className={classes.titleWrapper}>
            <div className={classes.imgTokenWrapper}>
              <img src={data[currentNetwork].liquidityPoolImage} alt="" />
            </div>
            <div>
              <div
                className={[
                  classes.mainTopText,
                  classes.paddingBottomFive,
                ].join(' ')}
              >
                {data[currentNetwork].name}
              </div>
              <span className={[classes.subTextBig].join(' ')}>TVL </span>
              <span className={classes.mainSmallText}>
                ${data[currentNetwork]?.farmingTvl}
              </span>
            </div>
          </div>
          <div>
            <div
              className={[classes.subText, classes.paddingBottomFive].join(' ')}
            >
              You staked
            </div>
            <div className={classes.mainTopText}>-</div>
          </div>
          <div>
            <div className={[classes.subText, classes.imgWrapper].join(' ')}>
              <img src={data[currentNetwork].networkImage} alt="" />
              {data[currentNetwork].network} APR
            </div>
            <Tooltip
              overlayInnerStyle={{ boxShadow: 'none' }}
              color="none"
              title={
                <div className={classes.toolTipWrapper}>
                  {data[currentNetwork].rewards.map((el: any, index: any) => {
                    return (
                      <div key={index} className={classes.tooltipItem}>
                        <div className={classes.tooltipSubText}>
                          <span className={classes.tooltipItemTextSign}>+</span>
                          {el.symbol} APR
                        </div>
                        <div className={classes.tooltipMainText}>
                          {transform(el.APY, 2)}%
                        </div>
                      </div>
                    )
                  })}
                  <hr className={classes.tooltipLine} />
                  <div className={classes.tooltipItem}>
                    <div className={classes.tooltipSubText}>Total APR</div>
                    <div className={classes.tooltipMainText}>
                      {transform(data[currentNetwork].totalAPY, 2)}%
                    </div>
                  </div>
                </div>
              }
            >
              <div className={classes.imgWrapper}>
                <span className={classes.mainTopText}>
                  {transform(data[currentNetwork].totalAPY, 2)}%
                </span>
                <img src={info} alt="" />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      {/* <div className={classes.hrSmall}></div> */}
      <div className={classes.switchMenu}>
        {/* <div className={classes.networkApyWrapper}>
          <div className={classes.imgApyWrapper}>
            <img src={data[firstNetwork].networkImage} alt="" />
            <span className={classes.subText}>
              {data[firstNetwork].network} APY{' '}
            </span>
          </div>
          <div className={classes.switchWrapper}>
            <div className={classes.ApySmallText}>
              {data[firstNetwork].totalAPY}%
            </div>
            <div
              className={classes.imgWrapper}
              onClick={(e) => changeNetwork(firstNetwork, setFirstNetwork, e)}
            >
              <span className={classes.switchTextGreen}>SWITCH</span>
              <img src={arrowGreen} className={classes.switchIcon} />
            </div>
          </div>
        </div>
        <div className={classes.networkApyWrapper}>
          <div className={classes.imgApyWrapper}>
            <img src={data[secondNetwork].networkImage} alt="" />
            <span className={classes.subText}>
              {' '}
              {data[secondNetwork].network} APY{' '}
            </span>
          </div>
          <div className={classes.switchWrapper}>
            <div className={classes.ApySmallText}>
              {data[secondNetwork].totalAPY}%
            </div>
            <div
              className={classes.imgWrapper}
              onClick={(e) => changeNetwork(secondNetwork, setSecondNetwork, e)}
            >
              <span className={classes.switchTextBlue}>SWITCH</span>
              <img src={arrowBlue} className={classes.switchIcon} />
            </div>
          </div>
        </div> */}
        {!openCard && pool12Hours ? (
          <div className={classes.smallTimer}>
            <span className={classes.subText}>Time to Claim</span>{' '}
            <span
              className={[classes.orbText, classes.smallTimerStyle].join(' ')}
            >
              <CountDownTimer
                updateRound={refetch}
                expiryTimestamp={nextTime}
                withoutDate={false}
              />
            </span>
          </div>
        ) : null}
      </div>
      <div className={openCard ? classes.hr : classes.earnCardWrapper}></div>
      <div
        className={
          openCard ? classes.earnCardWrapperOpen : classes.earnCardWrapper
        }
      >
        <div className={classes.cardWrapperOpen}>
          <div className={classes.stakeWrapper}>
            <div className={classes.stakeTabsWrapper}>
              <div
                className={
                  checkTabs(activeTabsEarn.stake)
                    ? classes.stakeTab
                    : classes.stakeTabActive
                }
                onClick={() => setActiveTab(activeTabsEarn.stake)}
              >
                <div
                  className={
                    checkTabs(activeTabsEarn.stake)
                      ? classes.mainText
                      : classes.tabText
                  }
                >
                  Stake
                </div>
                {checkTabs(activeTabsEarn.stake) && (
                  <div className={classes.blueHr}></div>
                )}
              </div>
              <div
                className={
                  checkTabs(activeTabsEarn.unstake)
                    ? classes.stakeTab
                    : classes.stakeTabActive
                }
                onClick={() => setActiveTab(activeTabsEarn.unstake)}
              >
                <div
                  className={
                    checkTabs(activeTabsEarn.unstake)
                      ? classes.mainText
                      : classes.tabText
                  }
                >
                  Unstake
                </div>
                {checkTabs(activeTabsEarn.unstake) && (
                  <div className={classes.blueHr}></div>
                )}
              </div>
            </div>
            <div className={classes.inputWrapper}>
              {/*<div className={classes.inputDescription}></div>*/}
              <div className={classes.inputDescription}>
                {/*{disabled ? (*/}
                {/*    <span*/}
                {/*        className={[*/}
                {/*          classes.inputPlaceholderText,*/}
                {/*          classes.cursorDisable,*/}
                {/*        ].join(' ')}*/}
                {/*    >*/}
                {/*        You have no <a>123</a>*/}
                {/*      </span>*/}
                {/*) : (*/}
                <input
                  type="text"
                  className={classes.inputText}
                  placeholder={'Enter the amount'}
                  style={{ outline: 'none' }}
                  value={inputValue}
                  onChange={(e) =>
                    setInputValue(
                      `${commify(
                        `${e.target.value
                          .replace(/[^0-9.]/g, '')
                          .replace(/(\..*)\./g, '$1')}`
                      )}`
                    )
                  }
                />
                {/*)}*/}
              </div>
            </div>
            <div className={classes.depositSelectWrapper}>
              <button
                disabled={
                  checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked
                }
                className={
                  (checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked)
                    ? classes.depositSelectButtonDisabled
                    : classes.depositSelectButton
                }
                onClick={() =>
                  enterPersentInInput(
                    0.25,
                    checkTabs(activeTabsEarn.stake) ? userBalance : userStaked
                  )
                }
              >
                25%
              </button>
              <button
                disabled={
                  checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked
                }
                className={
                  (checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked)
                    ? classes.depositSelectButtonDisabled
                    : classes.depositSelectButton
                }
                onClick={() =>
                  enterPersentInInput(
                    0.5,
                    checkTabs(activeTabsEarn.stake) ? userBalance : userStaked
                  )
                }
              >
                50%
              </button>
              <button
                disabled={
                  checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked
                }
                className={
                  (checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked)
                    ? classes.depositSelectButtonDisabled
                    : classes.depositSelectButton
                }
                onClick={() =>
                  enterPersentInInput(
                    0.75,
                    checkTabs(activeTabsEarn.stake) ? userBalance : userStaked
                  )
                }
              >
                75%
              </button>
              <button
                disabled={
                  checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked
                }
                className={
                  (checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked)
                    ? classes.depositSelectButtonDisabled
                    : classes.depositSelectButton
                }
                onClick={() =>
                  enterPersentInInput(
                    1,
                    checkTabs(activeTabsEarn.stake) ? userBalance : userStaked
                  )
                }
              >
                MAX
              </button>
            </div>
            <div className={classes.stakeBottomWrapper}>
              {checkTabs(activeTabsEarn.stake) ? (
                <div className={classes.userBalanceWrapper}>
                  <div className={classes.balanceSubText}>Balance</div>
                  <div className={classes.imgWrapper}>
                    <span className={classes.balanceMainText}>
                      - {data[currentNetwork].name}{' '}
                    </span>
                    <span className={classes.subTextBig}>
                      {userBalance &&
                        `($${transform(
                          (
                            userBalance *
                            +data[currentNetwork].liquidityTokenRate
                          ).toString(),
                          2
                        )})`}
                    </span>
                  </div>
                </div>
              ) : (
                <div className={classes.userBalanceWrapper}>
                  <div className={classes.balanceSubText}>You staked</div>
                  <div className={classes.imgWrapper}>
                    <span className={classes.balanceMainText}>
                      - {data[currentNetwork].name}{' '}
                    </span>
                    <span className={classes.subTextBig}>
                      {' '}
                      {userStaked &&
                        `($${transform(
                          (
                            userStaked *
                            +data[currentNetwork].liquidityTokenRate
                          ).toString(),
                          2
                        )})`}
                    </span>
                  </div>
                </div>
              )}
              {renderButton()}
            </div>
          </div>
          <div className={classes.werticalHr}></div>
          <div className={classes.rewardsWrapper}>
            <div className={classes.rewardsTitle}>Rewards</div>
            <div className={classes.getOrbWrapper}>
              <a
                className={[classes.imgWrapper, classes.pointer].join(' ')}
                href={data[currentNetwork]?.buyOrbiterLink}
                target="_blank"
              >
                <img src={arrow} alt="" />
                <span className={classes.blueText}>Buy ORB</span>
              </a>
              <a
                href={data[currentNetwork]?.liquidityPoolDex}
                target="_blank"
                className={[classes.imgWrapper, classes.pointer].join(' ')}
              >
                <img src={addIcon} alt="" />
                <span className={classes.greenText}>Add Liquidity</span>
              </a>
            </div>
            <div className={classes.rewardsEmountWrapper}>
              {data[currentNetwork]?.rewards.map((el, index) => {
                return (
                  <div key={index} className={classes.tokenRewordsWrapper}>
                    <div className={classes.subCardText}>
                      {el.symbol} Rewards
                    </div>
                    <div className={classes.orbEmountImgWrapper}>
                      <img
                        className={classes.tokenIcon}
                        src={el.image}
                        alt=""
                      />
                      <div className={classes.balanceMainText}>-</div>
                    </div>
                  </div>
                )
              })}
            </div>
            {renderText()}
            {!pool12Hours ? (
              <>
                <div className={classes.readyToClaimWrapper}>
                  <div className={classes.subTextBig}>Ready to withdraw</div>
                  <div className={classes.imgWrapper}>
                    <span className={classes.mainSmallText}>-</span>
                    <span className={classes.subTextBig}>
                      {unstakeLocked &&
                        `($${transform(
                          (
                            unstakeLocked *
                            +data[currentNetwork].liquidityTokenRate
                          ).toString(),
                          2
                        )})`}
                    </span>
                  </div>
                </div>
                <div className={classes.claimButtonsWrapper}>
                  <button
                    disabled={true}
                    onClick={() => confirmUnstake()}
                    className={classes.claimButtonDisable}
                  >
                    WITHDRAW LP
                  </button>
                </div>
              </>
            ) : (
              <div className={classes.timerWrapper}>
                <div className={classes.timerTextWrapper}>
                  <div className={classes.mainSmallText}>Time to claim</div>
                  <div className={classes.mainSmallTextBlue}>
                    <CountDownTimer
                      updateRound={refetch}
                      expiryTimestamp={nextTime}
                      withoutDate={false}
                    />
                  </div>
                </div>
                <div className={classes.subText}>
                  This reward can be claimed once every 12 hours
                </div>
              </div>
            )}
            <button
              disabled={true}
              onClick={() => claim()}
              className={classes.claimButtonDisable}
            >
              CLAIM Rewards
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default EarnCardTabletNotConnected
