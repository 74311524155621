import React, { useContext, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import closeIcon from '../../img/close-circle.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setOpen } from '../../redux/claimModal'
import { request } from '../../factory/axios'
import { transform } from '../../factory/bigNumber'
import uniqid from 'uniqid'
import {
  deleteNotifications,
  setNotifications,
} from '../../redux/notifications'
import { claimNotification } from '../../utils/notificationTexts'
import { Web3Context } from '../../context'
import incentiveAbi from '../../contracts/incentive.abi'
import Lottie from 'lottie-react'
import animationData from '../../lotties/notification'
import { Tooltip } from 'antd'
import { commify, sortRewordsAssets } from '../../utils'
import wallet from '../../img/wallet-green.svg'
import refetch from '../../utils/Refetch'
import { toBn } from 'evm-bn'
import CustomScrollWrap from '../CustomScrollWrap'

const CHAIN_ID = Number(process.env.REACT_APP_X_ORBITER_CHAIN_ID)
const CUSTOM_INCENTIVES_ASSETS = {
  1284: {
    '0x931715FEE2d06333043d11F658C8CE934aC61D0c': {
      image: 'https://assets.orbiter.one/images/tokens/USDC.svg',
      tokenSymbol: 'USDC.wh',
    },
  },
}

const styles = createUseStyles({
  modalBg: {
    width: '100%',
    background: 'rgb(0 0 0 / 70%)',
    height: '100vh',
    position: 'fixed',
    top: 0,
    zIndex: 15,
  },
  claimModal: {
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    backdropFilter: 'blur(25px)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    padding: [30, 20],
    minWidth: 540,
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    borderRadius: '12px',
    minHeight: 180,
    overflow: 'hidden',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '70vh',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 20,
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
    margin: 0,
  },
  closeIcon: {
    width: 24,
    height: 24,
    cursor: 'pointer',
  },
  cardWrapper: {
    border: '1px solid #3B4363',
    borderRadius: '12px',
    width: 516,
    height: 98,
    padding: [15, 20],
  },
  subText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
  },
  mainText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
  },
  claimButton: {
    border: '1px solid #5493F7',
    borderRadius: '12px',
    minWidth: 100,
    height: 60,
    cursor: 'pointer',
    backgroundColor: 'inherit',

    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
  },
  claimButtonDisable: {
    extend: 'claimButton',
    border: '1px solid #4B567D',
    color: '#A8A8B2',
    cursor: 'not-allowed',
  },
  cardTopText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 15,
  },
  cardUnclaimed: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 172,
    paddingBottom: 5,
  },
  tokenWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    width: 226,
  },
  cardORB: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 25,
  },
  orbIcon: {
    width: 18,
    height: 18,
  },
  cardBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'inherit',
    gap: 20,
    paddingRight: 10,
  },
  scrollWrapper: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: '8px',
    },

    '&::-webkit-scrollbar-track': {
      background: '#4B567D',
      borderRadius: 10,
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#5493F7',
      borderRadius: 10,
    },
  },
  loadingWrapper: {
    width: 100,
    height: 100,
  },
  connectWalletWrapper: {
    height: 259,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tooltipWrapper: {
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
  },
  addToWalletWrapper: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    cursor: 'pointer',

    '&:$addToWalletImg': {},
    '&:$addToWalletImgWhite': {},
    '&:$addToWalletText': {},
  },
  addToWalletText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#53CBC8',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
  },
  addToWalletImg: {
    display: 'block',
  },
  addToWalletImgWhite: {
    display: 'none',
  },
  protocolRewardsText: {
    width: 236,
  },
  protocolRewardsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  '@media (max-width: 730px)': {
    claimModal: {
      background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
      backdropFilter: 'blur(25px)',
      boxShadow: 'inset 1px 1px 1px #42426A',
      padding: [30, 20],
      minWidth: 300,
    },
    titleWrapper: {
      paddingBottom: 10,
    },
    cardsWrapper: {
      justifyContent: 'flex-start',
    },
    cardWrapper: {
      border: '2px solid #3B4363',
      borderRadius: '12px',
      width: 256,
      height: 238,
      padding: [15, 20],
    },
    cardBottom: {
      display: 'block',
    },
    cardUnclaimed: {
      gap: 0,
      justifyContent: 'space-between',
    },
    claimButton: {
      border: '1px solid #5493F7',
      borderRadius: '12px',
      width: '100%',
      height: 60,
      cursor: 'pointer',
      backgroundColor: 'inherit',

      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '30px',
      color: '#FFFFFF',
    },
    claimButtonDisable: {
      width: '100%',
    },
    tokenWrapper: {
      paddingBottom: 15,
    },
    cardORB: {
      paddingBottom: 20,
      paddingTop: 5,
    },
    addToWalletImg: {
      width: 20,
    },
    protocolRewardsText: {
      width: 'auto',
    },
  },
})

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

interface Props {
  addToken: (a: any) => void
}

const ClaimModal: React.FC<Props> = ({ addToken }) => {
  const classes = styles()
  const dispatch = useDispatch()
  const isMobile = window.innerWidth < 730
  const [rewards, setRewards] = useState(null)
  const [loading, setLoading] = useState(true)

  const { web3 } = useContext(Web3Context)
  const { user } = useSelector((state: any) => state.userReducer)
  const { asset } = useSelector((state: any) => state.assetReducer)

  const addOrbToken = (token: any) => {
    addToken({
      address: token.token,
      symbol: token.tokenSymbol,
      decimals: token.decimal,
      image: token.image,
    })
  }

  const customizeIncentivesAssets = (data: any[]) => {
    const assets = []
    for (const item of data) {
      let customItem = {}
      if (
        CUSTOM_INCENTIVES_ASSETS[CHAIN_ID] &&
        typeof CUSTOM_INCENTIVES_ASSETS[CHAIN_ID][item.token] !== 'undefined'
      ) {
        customItem = {
          ...customItem,
          image: CUSTOM_INCENTIVES_ASSETS[CHAIN_ID][item.token].image,
          tokenSymbol:
            CUSTOM_INCENTIVES_ASSETS[CHAIN_ID][item.token].tokenSymbol,
        }
      }

      assets.push({
        ...item,
        ...customItem,
      })
    }

    return sortRewordsAssets(assets, 'tokenSymbol')
  }

  useEffect(() => {
    request({
      method: 'get',
      path: `assets/${user.address}/incentives`,
    })
      .then((res) => setRewards(customizeIncentivesAssets(res.data.data)))
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const claimContract = new web3.eth.Contract(
    incentiveAbi,
    process.env.REACT_APP_X_INCENTIVE_ADDRESS
  )

  const claim = async (address: string) => {
    const transactionsId = uniqid()

    dispatch(
      setNotifications({
        type: 'info',
        text: claimNotification.textInfoClaim,
        link: null,
      })
    )
    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await claimContract.methods
        .claimIncentive(address, user.address)
        .estimateGas({
          from: user.address,
        })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      const result = await claimContract.methods
        .claimIncentive(address, user.address)
        .send({
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
          from: user.address,
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: claimNotification.textSubmittedClaim,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          text: claimNotification.textSuccessClaim,
          link: result.transactionHash,
        })
      )
      setTimeout(() => {
        request({
          method: 'get',
          path: `assets/${user.address}/incentives`,
        })
          .then((res) => setRewards(customizeIncentivesAssets(res.data.data)))
          .finally(() => {
            setLoading(false)
          })
        setTimeout(() => {
          refetch(dispatch, user.address, asset)
        }, 5000)
      }, 5000)
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: claimNotification.textErrorClaim,
          link: null,
        })
      )
      console.log('error', error)
    }
  }

  const render = () => {
    if (loading) {
      return (
        <div className={classes.connectWalletWrapper}>
          <div className={classes.loadingWrapper}>
            <Lottie {...defaultOptions} height={42} width={42} />
          </div>
        </div>
      )
    } else {
      return rewards?.map((el) => {
        return isMobile ? (
          <div key={el.tokenSymbol} className={classes.cardWrapper}>
            <div className={classes.cardTopText}>
              <div
                className={[classes.subText, classes.protocolRewardsText].join(
                  ' '
                )}
              >
                Protocol rewards ({el.tokenSymbol})
              </div>
              <div className={classes.addToWalletWrapper}>
                <img
                  onClick={() => addOrbToken(el)}
                  className={classes.addToWalletImg}
                  src={wallet}
                  alt=""
                />
              </div>
            </div>
            <div className={classes.cardBottom}>
              <div>
                <div className={classes.cardUnclaimed}>
                  <div className={classes.subText}>Unclaimed</div>
                  <div className={classes.subText}>
                    1 {el.tokenSymbol} ≈ ${transform(el.lastPrice, 2)}
                  </div>
                </div>
                <div className={classes.tokenWrapper}>
                  <img className={classes.orbIcon} src={el.image} alt="" />
                  <Tooltip
                    overlayInnerStyle={{ boxShadow: 'none' }}
                    color="none"
                    title={
                      <div className={classes.tooltipWrapper}>
                        {commify(el.reward)}
                      </div>
                    }
                    className={classes.mainText}
                  >
                    {commify(transform(el.reward, 4))} {el.tokenSymbol}
                  </Tooltip>
                </div>
                <div className={classes.subText}>Unclaimed (USD)</div>
                <div className={classes.cardORB}>
                  <div className={classes.mainText}>
                    ${transform((el.lastPrice * el.reward).toString(), 2)}
                  </div>
                </div>
              </div>
              <button
                disabled={el.reward === '0'}
                className={
                  el.reward === '0'
                    ? classes.claimButtonDisable
                    : classes.claimButton
                }
                onClick={() => claim(el.token)}
              >
                CLAIM
              </button>
            </div>
          </div>
        ) : (
          <div key={el.tokenSymbol} className={classes.cardWrapper}>
            <div className={classes.cardTopText}>
              <div className={classes.protocolRewardsWrapper}>
                <div
                  className={[
                    classes.subText,
                    classes.protocolRewardsText,
                  ].join(' ')}
                >
                  Protocol rewards ({el.tokenSymbol})
                </div>
                <div className={classes.addToWalletWrapper}>
                  <img className={classes.addToWalletImg} src={wallet} alt="" />
                  <div
                    onClick={() => addOrbToken(el)}
                    className={classes.addToWalletText}
                  >
                    ADD TO WALLET
                  </div>
                </div>
              </div>
              <div className={classes.subText}>
                1 {el.tokenSymbol} ≈ ${transform(el.lastPrice, 2)}
              </div>
            </div>
            <div className={classes.cardBottom}>
              <div>
                <div className={classes.cardUnclaimed}>
                  <div className={classes.subText}>Unclaimed</div>
                  <div className={classes.subText}>Unclaimed (USD)</div>
                </div>
                <div className={classes.cardORB}>
                  <div className={classes.tokenWrapper}>
                    <img className={classes.orbIcon} src={el.image} alt="" />
                    <Tooltip
                      overlayInnerStyle={{ boxShadow: 'none' }}
                      color="none"
                      title={
                        <div className={classes.tooltipWrapper}>
                          {commify(el.reward)}
                        </div>
                      }
                      className={classes.mainText}
                    >
                      {commify(transform(el.reward, 4))} {el.tokenSymbol}
                    </Tooltip>
                  </div>
                  <div className={classes.mainText}>
                    ${transform((el.lastPrice * el.reward).toString(), 2)}
                  </div>
                </div>
              </div>
              <button
                disabled={el.reward === '0'}
                className={
                  el.reward === '0'
                    ? classes.claimButtonDisable
                    : classes.claimButton
                }
                onClick={() => claim(el.token)}
              >
                CLAIM
              </button>
            </div>
          </div>
        )
      })
    }
  }

  return (
    <div className={classes.modalBg}>
      <div className={classes.claimModal}>
        <div className={classes.titleWrapper}>
          <p className={classes.title}>Claim your rewards</p>
          <img
            className={classes.closeIcon}
            onClick={() => {
              dispatch(setOpen(false))
            }}
            src={closeIcon}
            alt=""
          />
        </div>
        <div className={classes.scrollWrapper}>
          <div className={classes.cardsWrapper}>{render()}</div>
        </div>
      </div>
    </div>
  )
}

export default ClaimModal
