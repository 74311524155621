import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { setAsset } from 'src/redux/asset'
import { setAssets } from 'src/redux/assets'
import { request } from '../../factory/axios'
import { commify, sortRewordsAssets, totalIncentives } from '../../utils'
import { transform } from '../../factory/bigNumber'
import AssetsScrollWrap from '../AssetsScrollWrap'
import AssetsSliderWrap from '../AssetsSliderWrap'
import useWindowSize from 'src/utils/resizeHook'
import { Tooltip } from 'antd'
import Skeleton from 'react-loading-skeleton'

const styles = createUseStyles({
  assetsMobile: {
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    position: 'relative',
    paddingTop: 17,
    paddingBottom: 25,
    boxShadow: 'inset 1px 1px 1px #42426a',
    paddingLeft: 20,
    height: 139,
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    width: 28,
    height: 28,
  },
  wrapper: {
    display: 'flex',
    gap: 10,
    flex: '1 1 auto',
    paddingRight: 20,
    paddingBottom: 10,
  },
  cardSliderItem: {
    marginRight: 0,
  },
  cardSelected: {
    backgroundColor: '#3B4363',
    borderRadius: 12,
    flex: '0 0 auto',
    padding: '10px 15px 23px',
    width: 170,
    height: 82,
    border: '1px solid #3B4363',
    cursor: 'pointer',
  },
  card: {
    width: 170,
    height: 82,
    border: '1px solid #3B4363',
    background: 'inherit',
    flex: '0 0 auto',
    borderRadius: 12,
    padding: '10px 15px 23px',
    cursor: 'pointer',
  },
  cardHeader: {
    display: 'flex',
    gap: 10,
    justifyContent: 'start',
    alignItems: 'center',
    paddingBottom: 6,
  },
  cardHeaderText: {
    color: '#FFFFFF',
    fontSize: 16,
    lineHeight: '16px',
    fontWeight: 600,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContentMainText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
  },
  cardContentSubText: {
    extend: 'cardContentMainText',
    color: '#A8A8B2',
    fontWeight: 400,
    marginRight: 2,
    textDecorationLine: 'none',
    cursor: 'pointer',
  },
  balanceWrapper: {
    display: 'flex',
    gap: 3,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  hr: {
    backgroundColor: '#A8A8B2',
    width: 1,
    height: 16,
  },
  infoIcon: {
    width: 15,
  },
  tooltipWithRewards: {
    minWidth: 165,
    width: 'fit-content',
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '4px',
    gap: 10,
  },
  tooltipItemText: {
    color: '#A8A8B2',
    fontWeight: 400,
    fontSize: '14px',
  },
  tooltipItemNumber: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: '14px',
  },
  tooltipItemTextSign: {
    color: '#5493F7',
    fontWeight: 500,
    marginRight: '4px',
  },
  tooltipLine: {
    width: '100%',
    height: '1px',
    borderBottom: 'none',
    border: 'none',
    backgroundColor: '#4B567D',
    marginTop: '8px',
    marginBottom: '8px',
  },
  tooltipItemTotal: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewards: {
    width: '165px',
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipItemWithoutRewards: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  '@media (max-width: 1250px)': {
    assetsTitle: {
      fontSize: '18px',
    },
  },
  '@media (max-width: 730px)': {
    assetsMobile: {
      borderRadius: [12, 12, 0, 0],
      display: 'block',
    },
    cardSliderItem: {
      marginRight: 10,
    },
  },
})

export interface Asset {
  _id: string
  name: string
  symbol: string
  fullName: string
  oTokenDecimal: number
  tokenDecimal: number
  oTokenAddress: string
  tokenAddress: string
  typeNetwork: string
  collateralFactor: number
  reserveFactor: number
  exchangeRate: string
  supplyRate: string
  borrowRate: string
  totalSupply: string
  totalBorrow: string
  totalReserves: string
  lastPrice: string
  liquidity: string
  image: string
  color: string
  countSuppliers: number
  countBorrowers: number
  utilization: number
  supplyPaused: true
  borrowPaused: true
}

const Assets = () => {
  const [fetching, setFetching] = useState(true)

  const classes = styles()
  const { currentWidth } = useWindowSize()

  const { data } = useSelector((state: any) => state.assetsReducer)
  const { fetching: refetching } = useSelector(
    (state: any) => state.loadingReducer
  )
  const { asset } = useSelector((state: any) => state.assetReducer)
  const { user } = useSelector((state: any) => state.userReducer)
  const { data: userAssets } = useSelector(
    (state: any) => state.userAssetsReducer
  )

  const dispatch = useDispatch()
  const skeletonsArray = Array(16).fill(1)

  useEffect(() => {
    setFetching(true)
    if (!!user.address) {
      request({
        method: 'get',
        path: `assets/${user.address}/balance`,
      }).then((res) => {
        const test = res.data.data.map((el: any) => {
          return { ...el.token, walletBalance: el.walletBalance }
        })
        dispatch(setAssets(test))
        dispatch(setAsset(test[0]))
        setFetching(false)
      })
    } else {
      request({
        method: 'get',
        path: 'assets',
      }).then((res) => {
        dispatch(setAssets(res.data.data))
        dispatch(setAsset(res.data.data[0]))
        setFetching(false)
      })
    }
  }, [user.address])

  const handleSelectAsset = (el: Asset) => {
    dispatch(setAsset(el))
  }

  //new array for assets where added new value(supply)->supplyValue
  const newDataArray = []
  const a = data.map((it: any) => {
    const b = userAssets.supplied.find((el: any) => it._id === el.token._id)
    if (b) {
      newDataArray.push({ ...it, supplyValue: b.value })
    } else {
      newDataArray.push({ ...it, supplyValue: '0' })
    }
  })
  const arrayToMap = fetching ? skeletonsArray : newDataArray

  const mappedAssets = arrayToMap?.map((el: any, index: number) => {
    return (
      <div key={el._id ? el._id : index} className={classes.cardSliderItem}>
        <div
          onClick={() => handleSelectAsset(el)}
          className={asset._id === el._id ? classes.cardSelected : classes.card}
        >
          {fetching && refetching ? (
            <Skeleton
              baseColor="#4B567D"
              highlightColor="#5B6796"
              width="150px"
              height="20px"
              count={1}
              borderRadius="32px"
              style={{ marginBottom: 15 }}
            />
          ) : (
            <div className={classes.cardHeader}>
              <img className={classes.icon} src={el.image} alt="" />
              <span className={classes.cardHeaderText}>{el.symbol}</span>
            </div>
          )}
          <div className={classes.cardContent}>
            {fetching && refetching ? (
              <Skeleton
                baseColor="#4B567D"
                highlightColor="#5B6796"
                width="150px"
                height="15px"
                count={1}
                borderRadius="32px"
              />
            ) : (
              <div className={classes.balanceWrapper}>
                <span className={classes.cardContentSubText}>Supplied</span>
                <div className={classes.balanceWrapper}>
                  <Tooltip
                    overlayInnerStyle={{ boxShadow: 'none' }}
                    color="none"
                    className={classes.cardContentMainText}
                    title={
                      <div className={classes.tooltipWithoutRewards}>
                        <div className={classes.tooltipItemWithoutRewards}>
                          <span className={classes.tooltipItemText}>
                            Supplied
                          </span>
                          <span>
                            {user.address
                              ? transform(el.supplyValue, 2)
                              : 'N/A'}
                          </span>
                        </div>
                      </div>
                    }
                  >
                    {user.address ? transform(el.supplyValue, 4) : 'N/A'}
                  </Tooltip>
                </div>
              </div>
            )}
            {fetching && refetching ? (
              <Skeleton
                baseColor="#4B567D"
                highlightColor="#5B6{id: 1}796"
                width="150px"
                height="15px"
                count={1}
                borderRadius="32px"
              />
            ) : (
              <div className={classes.balanceWrapper}>
                <span className={classes.cardContentSubText}>Balance</span>
                <Tooltip
                  overlayInnerStyle={{ boxShadow: 'none' }}
                  color="none"
                  title={
                    <div className={classes.tooltipWithoutRewards}>
                      <div className={classes.tooltipItemWithoutRewards}>
                        <span className={classes.tooltipItemText}>Balance</span>
                        <span>
                          {user.address
                            ? commify(transform(el.walletBalance, 2))
                            : 'N/A'}
                        </span>
                      </div>
                    </div>
                  }
                  className={classes.cardContentMainText}
                >
                  {user.address ? commify(transform(el.walletBalance)) : 'N/A'}
                </Tooltip>
              </div>
            )}
            {fetching && refetching ? (
              <Skeleton
                baseColor="#4B567D"
                highlightColor="#5B6796"
                width="150px"
                height="15px"
                count={1}
                borderRadius="32px"
              />
            ) : (
              <div className={classes.balanceWrapper}>
                <span className={classes.cardContentSubText}>APY</span>
                <div className={classes.balanceWrapper}>
                  <Tooltip
                    color="none"
                    overlayInnerStyle={{ boxShadow: 'none' }}
                    className={classes.cardContentMainText}
                    title={
                      el.incentives ? (
                        el.incentives?.length === 0 ||
                        (transform(
                          el.incentives && el.incentives[0]?.supplyApy,
                          2
                        ) === '0' &&
                          transform(
                            el.incentives && el.incentives[1]?.supplyApy,
                            2
                          ) === '0') ? (
                          <div className={classes.tooltipWithoutRewards}>
                            <div className={classes.tooltipItemWithoutRewards}>
                              <span className={classes.tooltipItemText}>
                                Supply APY
                              </span>
                              <span>+{transform(el.supplyRate, 2)}%</span>
                            </div>
                          </div>
                        ) : (
                          <div className={classes.tooltipWithRewards}>
                            <div className={classes.tooltipItem}>
                              <span className={classes.tooltipItemText}>
                                Supply<span>&nbsp;</span>APY
                              </span>
                              <span>{transform(el.supplyRate, 2)}%</span>
                            </div>
                            {sortRewordsAssets(el.incentives, 'symbol').map(
                              (it: any, index: number) => (
                                <div key={el._id}>
                                  {transform(it.supplyApy, 2) === '0' ? (
                                    <></>
                                  ) : (
                                    <div className={classes.tooltipItem}>
                                      <span className={classes.tooltipItemText}>
                                        <span
                                          className={
                                            classes.tooltipItemTextSign
                                          }
                                        >
                                          +
                                        </span>
                                        {it.symbol}
                                        <span>&nbsp;</span>Rewards
                                      </span>
                                      <span
                                        className={classes.tooltipItemNumber}
                                      >
                                        {transform(it.supplyApy, 2)}%
                                      </span>
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                            {/* <div className={classes.tooltipItem}>
                            <span className={classes.tooltipItemText}>
                              <span className={classes.tooltipItemTextSign}>
                                +
                              </span>
                              {el.incentives[1].symbol} Rewards
                            </span>
                            <span className={classes.tooltipItemNumber}>
                              {transform(el.incentives[1].supplyApy, 2)}%
                            </span>
                          </div> */}
                            <hr className={classes.tooltipLine} />
                            <div className={classes.tooltipItemTotal}>
                              <span className={classes.tooltipItemText}>
                                Total APY
                              </span>
                              <span className={classes.tooltipItemNumber}>
                                <span>+</span>
                                {transform(
                                  (
                                    parseFloat(el.supplyRate) +
                                    totalIncentives(el.incentives, 'supply')
                                  ).toString(),
                                  2
                                )}
                                %
                              </span>
                            </div>
                          </div>
                        )
                      ) : (
                        <div className={classes.tooltipWithoutRewards}>
                          <div className={classes.tooltipItemWithoutRewards}>
                            <span className={classes.tooltipItemText}>
                              Supply APY
                            </span>
                            <span>+{transform(el.supplyRate, 2)}%</span>
                          </div>
                        </div>
                      )
                    }
                  >
                    +
                    {el?.incentives && (el?.incentives[0] || el?.incentives[1])
                      ? parseFloat(el.supplyRate) +
                          totalIncentives(el.incentives, 'supply') <
                        1000
                        ? +transform(
                            (
                              parseFloat(el.supplyRate) +
                              totalIncentives(el.incentives, 'supply')
                            ).toString(),
                            2
                          )
                        : '<1000'
                      : +transform(el.supplyRate, 2)}
                    %
                  </Tooltip>
                  <div className={classes.hr}></div>
                  <Tooltip
                    overlayInnerStyle={{ boxShadow: 'none' }}
                    color="none"
                    className={classes.cardContentMainText}
                    title={
                      !el.incentives ||
                      el.incentives?.length === 0 ||
                      (transform(
                        el.incentives && el.incentives[0]?.borrowApy,
                        2
                      ) === '0' &&
                        transform(
                          el.incentives && el.incentives[1]?.borrowApy,
                          2
                        ) === '0') ? (
                        <div className={classes.tooltipWithoutRewards}>
                          <div className={classes.tooltipItemWithoutRewards}>
                            <span className={classes.tooltipItemText}>
                              Borrow APY
                            </span>
                            <span>-{transform(el.borrowRate, 2)}%</span>
                          </div>
                        </div>
                      ) : (
                        <div className={classes.tooltipWithRewards}>
                          <div className={classes.tooltipItem}>
                            <span className={classes.tooltipItemText}>
                              Borrow APY
                            </span>
                            <span className={classes.tooltipItemNumber}>
                              -{transform(el.borrowRate, 2)}%
                            </span>
                          </div>
                          {sortRewordsAssets(el.incentives, 'symbol').map(
                            (it: any, index: any) => (
                              <div key={el._id ? el._id : index}>
                                {transform(it.borrowApy, 2) === '0' ? (
                                  <></>
                                ) : (
                                  <div className={classes.tooltipItem}>
                                    <span className={classes.tooltipItemText}>
                                      <span
                                        className={classes.tooltipItemTextSign}
                                      >
                                        +
                                      </span>
                                      {it.symbol}
                                      <span>&nbsp;</span>Rewards
                                    </span>
                                    <span className={classes.tooltipItemNumber}>
                                      {transform(it.borrowApy, 2)}%
                                    </span>
                                  </div>
                                )}
                              </div>
                            )
                          )}
                          <hr className={classes.tooltipLine} />
                          <div className={classes.tooltipItemTotal}>
                            <span className={classes.tooltipItemText}>
                              Total APY
                            </span>
                            <span className={classes.tooltipItemNumber}>
                              <span>
                                {el.incentives &&
                                (el.incentives[0] || el.incentives[1])
                                  ? totalIncentives(el.incentives, 'borrow') -
                                      parseFloat(el.borrowRate) >
                                    0
                                    ? '+'
                                    : null
                                  : '-'}
                              </span>
                              {transform(
                                (
                                  totalIncentives(el.incentives, 'borrow') -
                                  parseFloat(el.borrowRate)
                                ).toString(),
                                2
                              )}
                              %
                            </span>
                          </div>
                        </div>
                      )
                    }
                  >
                    <span>
                      {el.incentives && (el.incentives[0] || el.incentives[1])
                        ? totalIncentives(el.incentives, 'borrow') -
                            parseFloat(el.borrowRate) >
                          0
                          ? '+'
                          : null
                        : '-'}
                    </span>
                    {el.incentives && (el.incentives[0] || el.incentives[1])
                      ? totalIncentives(el.incentives, 'borrow') -
                          parseFloat(el.borrowRate) <
                        1000
                        ? transform(
                            (
                              totalIncentives(el.incentives, 'borrow') -
                              parseFloat(el.borrowRate)
                            ).toString(),
                            2
                          )
                        : '<1000'
                      : transform(el.borrowRate, 2)}
                    %
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  })

  return (
    <>
      <div className={classes.assetsMobile}>
        {currentWidth > 730 ? (
          <AssetsScrollWrap>
            <div className={classes.wrapper}>{mappedAssets}</div>
          </AssetsScrollWrap>
        ) : (
          <AssetsSliderWrap>{mappedAssets}</AssetsSliderWrap>
        )}
      </div>
    </>
  )
}

export default Assets
