import React, { useEffect, useContext, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { request } from '../../factory/axios'
import { useDispatch, useSelector } from 'react-redux'
import { setAssetBalance } from 'src/redux/assetsBalance'
import { setAssets } from 'src/redux/assets'
import faucet from '../../img/faucet.svg'
import useWindowSize from 'src/utils/resizeHook'
import { transform } from '../../factory/bigNumber'
import faucetAbi from 'src/contracts/faucet.abi'
import { Web3Context } from '../../context'
import { deleteNotifications, setNotifications } from 'src/redux/notifications'
import wallet from '../../img/wallet-add.svg'
import whiteWallet from '../../img/wallet-add-white.svg'
import { faucetNotification } from '../../utils/notificationTexts'
import { Asset } from '../Assets/Assets'
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton'
import uniqid from 'uniqid'
import { Tooltip } from 'antd'
import { commify, numberToStringInput } from '../../utils'

//  move to env
const FAUCET_CONTRACT = process.env.REACT_APP_X_FAUCET_CONTRACT
const styles = createUseStyles({
  faucetBlock: {
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    padding: '30px 0px',
    paddingBottom: 30,
    display: 'block',
    width: '100%',
    fontFamily: 'Poppins',
  },
  titeBlock: {
    display: 'flex',
    padding: '0px 20px 30px 20px',
  },
  titleText: {
    width: '60%',
    fontWeight: 600,
    fontSize: '20px',
    color: '#FFFFFF',
    cursor: 'default',
  },
  titleBoxLink: {
    width: '40%',
    fontWeight: 600,
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    color: '#53CBC8',
  },
  titleLinkText: {
    marginLeft: '8px',
    textTransform: 'uppercase',
    textDecorationLine: 'none',
    cursor: 'pointer',
    color: '#53CBC8',
    '&:active': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecorationLine: 'underline',
    },
  },
  assetsListBlock: {},
  assetsList: {
    display: 'flex',
    padding: '10px 20px',
    '&:hover': {
      background: '#3B4363',
    },
  },
  assetsinfo: {
    width: '90%',
    display: 'flex',
    fontWeight: 600,
    fontSize: '20px',
    color: '#FFFFFF',
  },
  assetsToken: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '60px',
    width: '45%',
  },
  assetsBalance: {
    width: '20%',
    display: 'flex',
    alignItems: 'center',
  },
  assetsLink: {
    display: 'flex',
    width: '10%',
    justifyContent: 'end',
  },
  assetsLinkButton: {
    color: '#53CBC8',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    border: '1px solid #53CBC8',
    borderRadius: '8px',
    padding: '5px 12px',
    cursor: 'pointer',
  },
  assetsListTitle: {
    width: '53.6%',
    display: 'flex',
    justifyContent: 'space-between',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    color: '#A8A8B2',
    padding: '0px 20px 5px 20px',
    cursor: 'default',
  },
  tokenImg: {
    width: '18px',
    height: '18px',
    marginRight: '8px',
    cursor: 'default',
  },
  balanceMobile: {
    display: 'none',
  },
  walletBalance: {
    display: 'none',
  },
  addToWallet: {
    display: 'flex',
    alignItems: 'center',
    color: '#A8A8B2',
    fontSize: '20px',
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      color: 'white',
    },
    '&:$walletImgWhite': {},
    '&:$walletImg': {},
    '&:hover $walletImgWhite': {
      display: 'block',
    },
    '&:hover $walletImg': {
      display: 'none',
    },
  },
  disabledWallet: {
    display: 'none',
    cursor: 'not-allowed',
  },
  walletImg: {
    marginRight: '5px',
  },
  walletImgWhite: {
    marginRight: '5px',
    display: 'none',
  },
  addToWalletMobile: {
    display: 'none',
  },
  walletImgMobile: {},
  disconnectAddToWalletMobile: {
    display: 'none',
  },
  walletImgMobileWhite: {},
  notConnectToWallet: {
    cursor: 'not-allowed',
    '&:hover': {
      color: '#A8A8B2',
    },
    '&:$walletImgWhite': {},
    '&:$walletImg': {},
    '&:hover $walletImg': {
      display: 'block',
    },
  },
  notConnectedToWalletLinkButton: {
    cursor: 'not-allowed',
    color: '#A8A8B2',
    border: '1px solid #A8A8B2',
  },
  tokenName: {
    cursor: 'default',
  },
  countBalance: {
    cursor: 'default',
  },
  imgSkeleton: {
    width: '18px',
    marginRight: '10px',
  },
  titleMintInfo: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#A8A8B2',
    marginLeft: '10px',
  },
  tooltipWithRewards: {
    minWidth: 165,
    width: 'fit-content',
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipWithRewardsLong: {
    extend: 'tooltipWithRewards',
    minWidth: '165px',
    width: '100%',
  },
  tooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '4px',
  },
  tooltipItemText: {
    color: '#A8A8B2',
    fontWeight: 400,
    fontSize: '14px',
  },
  tooltipItemTextLong: {
    extend: 'tooltipItemText',
    marginRight: '10px',
  },
  tooltipItemNumber: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: '14px',
  },
  tooltipItemTextSign: {
    color: '#5493F7',
    fontWeight: 500,
    marginRight: '4px',
  },
  tooltipLine: {
    width: '100%',
    height: '1px',
    borderBottom: 'none',
    border: 'none',
    backgroundColor: '#4B567D',
    marginTop: '8px',
    marginBottom: '8px',
  },
  tooltipItemTotal: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewards: {
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipItemWithoutRewards: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewardsLong: {
    extend: 'tooltipWithoutRewards',
    // minWidth: '165px',
    // width: '100%',
  },

  lastTooltipItem: {
    extend: 'tooltipItem',
    marginBottom: '0px',
  },
  lastTooltipItemLong: {
    extend: 'tooltipItemLong',
    marginBottom: '0px',
  },
  noTexttooltipItemWithoutRewards: {
    extend: 'tooltipItemWithoutRewards',
    justifyContent: 'center',
  },
  '@media (max-width: 1250px)': {
    titleLinkText: {
      textDecorationLine: 'none',
      '&:active': {
        textDecorationLine: 'underline',
      },
    },
    assetsinfo: {
      width: '90%',
      fontSize: '18px',
    },
    assetsToken: {
      width: '32%',
    },
    assetsBalance: {
      width: '24%',
    },
    assetsListTitle: {
      width: '51%',
    },
    assetsLink: {
      width: '10%',
    },
    titleText: {
      display: 'flex',
      flexDirection: 'column',
      width: '55%',
      fontSize: '18px',
    },
    titleBoxLink: {
      alignItems: 'start',
      width: '51%',
    },
    titleMintInfo: {
      marginLeft: '0px',
    },
    addToWallet: {
      fontSize: '18px',
    },
  },
  '@media (max-width: 730px)': {
    titeBlock: {
      display: 'block',
      paddingBottom: '20px',
    },
    titleBoxLink: {
      width: '100%',
      marginTop: '15px',
    },
    assetsLinkButton: {
      height: '30px',
      padding: '0px 12px',
      display: 'flex',
      alignItems: 'center',
    },
    assetsinfo: {
      width: '70%',
      display: 'block',
    },
    balanceMobile: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      marginTop: '4px',
    },
    walletBalance: {
      display: 'flex',
      fontWeight: 400,
      color: '#A8A8B2',
      marginRight: '5px',
    },
    assetsLink: {
      width: '30%',
    },
    assetsToken: {
      fontSize: '16px',
    },
    titleLinkText: {
      textDecorationLine: 'none',
      '&:active': {
        textDecorationLine: 'underline',
      },
    },
    addToWalletMobile: {
      display: 'block',
      width: '15%',
      cursor: 'pointer',
      '&:$walletImgMobile': {},
      '&:$walletImgMobileWhite': {},
      '&:active $walletImgMobile': {
        display: 'none',
      },
      '&:active $walletImgMobileWhite': {
        display: 'block',
      },
    },
    walletImgMobile: {
      display: 'block',
    },
    walletImgMobileWhite: {
      display: 'none',
    },
    disabledWallet: {
      display: 'none',
    },
    disconnectAddToWalletMobile: {
      cursor: 'not-allowed',
      '&:$walletImgMobile': {},
      '&:active $walletImgMobile': {
        display: 'block',
      },
    },
    titleText: {
      width: '100%',
    },
    titleMintInfo: {
      marginTop: '5px',
      fontSize: '14px',
    },
  },
})
interface Props {
  addTokenFaucet: (a: string, b: string) => void
}

const Faucet: React.FC<Props> = ({ addTokenFaucet }) => {
  const [faucetContract, setFaucetContract] = useState(null)
  const [fetching, setFetching] = useState(true)
  const skeletonsArray = Array(16).fill(1)

  const { user } = useSelector((state: any) => state.userReducer)
  const { data: balances } = useSelector(
    (state: any) => state.assetsBalanceReducer
  )
  const { data: assets } = useSelector((state: any) => state.assetsReducer)

  const { web3 } = useContext(Web3Context)

  const dispatch = useDispatch()
  const classes = styles()
  const { currentWidth } = useWindowSize()

  useEffect(() => {
    getBalances(user.address)
  }, [user.address])

  useEffect(() => {
    user.connected && defineContracts()
  }, [user.connected])

  const defineContracts = () => {
    const contract = new web3.eth.Contract(faucetAbi, FAUCET_CONTRACT)
    setFaucetContract(contract)
  }
  const getBalances = async (address: string) => {
    // debugger
    if (!address) {
      request({
        method: 'get',
        path: 'assets',
      }).then((res) => {
        dispatch(setAssets(res.data.data))
        // debugger
        setFetching(false)
      })
    } else {
      request({
        method: 'get',
        path: `assets/${address}/balance`,
      }).then((res) => {
        dispatch(setAssetBalance(res.data.data))
        setFetching(false)
      })
    }
  }

  const makeMint = async (symbol: any) => {
    const transactionsId = uniqid()

    dispatch(
      setNotifications({
        type: 'info',
        text: faucetNotification.textInfoMint,
        link: null,
      })
    )

    const tokenAddress = assets.find(
      (asset: any) => asset.symbol === symbol
    ).tokenAddress
    try {
      const result = await faucetContract.methods
        .mint(tokenAddress)
        .send({ from: user.address })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: faucetNotification.textSubmittedMint,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      getBalances(user.address)
      dispatch(
        setNotifications({
          type: 'success',
          text: faucetNotification.textSuccessMint,
          link: result.transactionHash,
        })
      )
      console.log('mint: ', result)
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: faucetNotification.textErrorMint,
          link: null,
        })
      )

      console.log(error)
    }
  }

  return (
    <div className={classes.faucetBlock}>
      <div className={classes.titeBlock}>
        <span className={classes.titleText}>
          Moonbase Test assets{' '}
          <span className={classes.titleMintInfo}>
            Mint is only available once every 24 hours
          </span>
        </span>
        <div className={classes.titleBoxLink}>
          {fetching ||
          (user.address ? !balances[0].token.name : !assets[0].name) ? (
            <>
              <Skeleton
                baseColor="#4B567D"
                highlightColor="#5B6796"
                width="296px"
                count={1}
                borderRadius="9px"
              />
            </>
          ) : (
            <>
              <img
                src={faucet}
                style={{ width: '20px', height: '20px' }}
                alt="logo faucet"
              />
              <a
                href="https://apps.moonbeam.network/moonbase-alpha/faucet/"
                target="_blank"
                className={classes.titleLinkText}
              >
                Faucet for the Network token DEV
              </a>
            </>
          )}
        </div>
      </div>
      <div className={classes.assetsListBlock}>
        <div className={classes.assetsListTitle}>
          <span>Asset</span>
          {currentWidth > 730 ? <span>Wallet balance</span> : null}
        </div>
        {(fetching ||
          (user.address ? !balances[0].token.name : !assets[0].name)) &&
          skeletonsArray.map((item: any, index: number) => (
            <div key={index} className={classes.assetsList}>
              <div className={classes.assetsinfo}>
                <div className={classes.assetsToken}>
                  <span className={classes.imgSkeleton}>
                    <Skeleton
                      baseColor="#4B567D"
                      highlightColor="#5B6796"
                      width="18px"
                      height="18px"
                      count={1}
                      borderRadius="32px"
                    />
                  </span>
                  <Skeleton
                    baseColor="#4B567D"
                    highlightColor="#5B6796"
                    width="123px"
                    count={1}
                    borderRadius="9px"
                  />
                </div>
                <div className={classes.assetsBalance}>
                  <Skeleton
                    baseColor="#4B567D"
                    highlightColor="#5B6796"
                    width="196px"
                    count={1}
                    borderRadius="9px"
                  />
                </div>
              </div>
            </div>
          ))}

        {!fetching &&
          balances[0].token.name &&
          user.address &&
          balances.map((item: any, index: number) => (
            <>
              {item.token.symbol === 'd2O' ? (
                <></>
              ) : (
                <div key={index} className={classes.assetsList}>
                  <div className={classes.assetsinfo}>
                    <div className={classes.assetsToken}>
                      <img
                        className={classes.tokenImg}
                        src={item.token.image}
                        alt=""
                      />
                      <span className={classes.tokenName}>
                        {item.token.symbol === 'MOVR'
                          ? 'MOVR(DEV)'
                          : item.token.symbol}
                      </span>
                    </div>
                    <div className={classes.balanceMobile}>
                      <span className={classes.walletBalance}>
                        Wallet balancef
                      </span>
                      <Tooltip
                        overlayInnerStyle={{ boxShadow: 'none' }}
                        color="none"
                        title={
                          <div className={classes.tooltipWithoutRewards}>
                            <div
                              className={
                                classes.noTexttooltipItemWithoutRewards
                              }
                            >
                              {/* <span className={classes.tooltipItemText}>Balance</span> */}
                              <span>
                                {commify(
                                  transform(
                                    numberToStringInput(item.walletBalance),
                                    6
                                  )
                                )}
                              </span>
                            </div>
                          </div>
                        }
                      >
                        {transform(item.walletBalance, 2)}
                        {item.walletBalance.includes('.') ? null : '.00'}{' '}
                      </Tooltip>
                    </div>

                    {currentWidth > 730 ? (
                      <div className={classes.assetsBalance}>
                        <Tooltip
                          overlayInnerStyle={{ boxShadow: 'none' }}
                          color="none"
                          title={
                            <div className={classes.tooltipWithoutRewards}>
                              <div
                                className={
                                  classes.noTexttooltipItemWithoutRewards
                                }
                              >
                                {/* <span className={classes.tooltipItemText}>Balance</span> */}
                                <span>
                                  {commify(
                                    transform(
                                      numberToStringInput(item.walletBalance),
                                      6
                                    )
                                  )}
                                </span>
                              </div>
                            </div>
                          }
                          className={classes.countBalance}
                        >
                          {transform(item.walletBalance, 2)}
                          {item.walletBalance.includes('.') ? null : '.00'}
                        </Tooltip>
                      </div>
                    ) : null}
                    {currentWidth > 730 && (
                      <div
                        onClick={() => {
                          if (item.token.symbol === 'MOVR') return
                          addTokenFaucet(item, item.token.symbol)
                        }}
                        className={[
                          classes.addToWallet,
                          item.token.symbol === 'MOVR' &&
                            classes.disabledWallet,
                        ].join(' ')}
                      >
                        <img
                          className={classes.walletImg}
                          src={wallet}
                          alt="wallet add"
                        />
                        <img
                          className={classes.walletImgWhite}
                          src={whiteWallet}
                          alt="wallet add"
                        />

                        <span>Add to wallet</span>
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => addTokenFaucet(item, item.token.symbol)}
                    className={[
                      classes.addToWalletMobile,
                      item.token.symbol === 'MOVR' && classes.disabledWallet,
                    ].join(' ')}
                  >
                    <img
                      className={classes.walletImgMobile}
                      src={wallet}
                      alt="wallet add"
                    />
                    <img
                      className={classes.walletImgMobileWhite}
                      src={whiteWallet}
                      alt="wallet add"
                    />
                  </div>
                  <div className={classes.assetsLink}>
                    <span
                      onClick={() => {
                        if (item.token.symbol === 'MOVR') {
                          window.open(
                            'https://apps.moonbeam.network/moonbase-alpha/faucet/',
                            '_blank',
                            'noopener,noreferrer'
                          )
                          return
                        }

                        makeMint(item.token.symbol)
                      }}
                      className={classes.assetsLinkButton}
                    >
                      MINT
                    </span>
                  </div>
                </div>
              )}
            </>
          ))}
        {!fetching &&
          assets[0].name &&
          !user.address &&
          assets.map((item: Asset, index: number) => (
            <>
              {item.symbol === 'd2O' ? (
                <></>
              ) : (
                <div key={index} className={classes.assetsList}>
                  <div className={classes.assetsinfo}>
                    <div className={classes.assetsToken}>
                      <img
                        className={classes.tokenImg}
                        src={item.image}
                        alt=""
                      />
                      <span className={classes.tokenName}>
                        {item.symbol === 'MOVR' ? 'MOVR(DEV)' : item.symbol}
                      </span>
                    </div>
                    <div className={classes.balanceMobile}>
                      <span className={classes.walletBalance}>
                        Wallet balance
                      </span>
                      <span> N/A</span>
                    </div>
                    {currentWidth > 730 ? (
                      <div className={classes.assetsBalance}>
                        <span className={classes.countBalance}>N/A</span>
                      </div>
                    ) : null}
                    {currentWidth > 730 && (
                      <div
                        className={[
                          classes.addToWallet,
                          classes.notConnectToWallet,
                          item.symbol === 'MOVR' && classes.disabledWallet,
                        ].join(' ')}
                      >
                        <img
                          className={classes.walletImg}
                          src={wallet}
                          alt="wallet add"
                        />

                        <span>Add to wallet</span>
                      </div>
                    )}
                  </div>
                  <div
                    className={[
                      classes.addToWalletMobile,
                      classes.disconnectAddToWalletMobile,
                      item.symbol === 'MOVR' && classes.disabledWallet,
                    ].join(' ')}
                  >
                    <img
                      className={classes.walletImgMobile}
                      src={wallet}
                      alt="wallet add"
                    />
                  </div>
                  <div className={classes.assetsLink}>
                    <span
                      className={[
                        classes.assetsLinkButton,
                        classes.notConnectedToWalletLinkButton,
                      ].join(' ')}
                    >
                      MINT
                    </span>
                  </div>
                </div>
              )}
            </>
          ))}
      </div>
    </div>
  )
}
export default Faucet
