import React from 'react'
import { createUseStyles } from 'react-jss'
import buttonIcon from '../../img/arrow-swap-horizontal.svg'
import { paramsMovr } from '../../utils/networkConst'
import { useSelector } from 'react-redux'

const actualApiFor = process.env.REACT_APP_X_ORBITER_ACTIVE_API_FOR

const styles = createUseStyles({
  modalBg: {
    width: '100%',
    background: 'rgb(0 0 0 / 70%)',
    height: '100vh',
    position: 'fixed',
    top: 0,
    zIndex: 99999,
  },
  wrongNetworkModal: {
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    backdropFilter: 'blur(25px)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    padding: [30, 20],
    width: 300,
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    borderRadius: '12px',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '30px',
    color: '#FFFFFF',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  infoText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#A8A8B2',
    paddingBottom: 40,
  },
  linkText: {
    extend: 'infoText',
    color: '#FBC928',
    textDecoration: 'underline',
    cursor: 'pointer',
    paddingBottom: 0,
  },
  depositButton: {
    backgroundColor: '#4B567D',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0',
    width: '100%',
    border: 'none',
    borderRadius: 12,
    cursor: 'pointer',
  },
  depositButtonText: {
    fontFamily: 'Poppins',
    paddingLeft: 10,
    lineHeight: '27px',
    fontSize: '18px',
    fontWeight: 600,
    color: '#FFFFFF',
  },
})

interface Props {
  switchNetwork: (params: any) => void
  isNftPage?: boolean
}

const WrongNetworkModal: React.FC<Props> = ({ switchNetwork, isNftPage }) => {
  const { isOpen: isOpenSwingSwapModal } = useSelector(
    (state: any) => state.swingSwapReducer
  )
  const classes = styles()

  const handleSwitchNetwork = (paramsMovr: any) => {
    const body = document.body
    body.style.height = ''
    body.style.overflowY = ''
    switchNetwork(paramsMovr)
  }

  return !isOpenSwingSwapModal ? (
    <div className={classes.modalBg}>
      <div className={classes.wrongNetworkModal}>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>Wrong Network</div>
        </div>
        <div className={classes.infoText}>
          Hello friend 👋, you appear to be one the wrong network. To use
          Orbiter One , you’ll need to switch to the{' '}
          <div
            className={classes.linkText}
            onClick={() => handleSwitchNetwork(paramsMovr)}
          >
            {actualApiFor === 'dev'
              ? 'Moonbase Alpha Network'
              : 'Moonbeam Network'}
          </div>
        </div>
        <button
          onClick={() => handleSwitchNetwork(paramsMovr)}
          className={classes.depositButton}
        >
          <img src={buttonIcon} alt="" />
          <span className={classes.depositButtonText}>Switch Network</span>
        </button>
      </div>
    </div>
  ) : null
}

export default WrongNetworkModal
