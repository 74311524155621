import React, { useEffect, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import NavMenu from 'src/components/NavMenu/NavMenu'
import Header from 'src/components/Header/Header'
import NeedHelpButton from 'src/components/NeedHelpButton/NeedHelpButton'
import WrongNetworkModal from 'src/components/WrongNetworkModal/WrongNetworkModal'
// @ts-ignore
import bg from '../img/bg.png'
import Faucet from 'src/components/Faucet/faucet'
import TestNet from '../components/TestNet/TestNet'
import {
  paramsArbitrumTest,
  paramsMovr,
  paramsZkSyncTest,
} from '../utils/networkConst'
import Footer from '../components/Footer/Footer'
import Earn from 'src/components/Earn/Earn'
import loading, { setLoading } from '../redux/loading'
import EarnCycle from '../components/EarnCycle/EarnCycle'
import { requestNFT } from '../factory/axios'
import WrongNetBaner from '../components/WrongNetBanner/WrongNetBanner'
import WrongNetBanner from '../components/WrongNetBanner/WrongNetBanner'

import AttentionNotification from 'src/components/AttentionNotification/AttentionNotification'
import ClaimModal from 'src/components/ClaimModal/ClaimModal'

const actualApiFor = process.env.REACT_APP_X_ORBITER_ACTIVE_API_FOR

const styles = createUseStyles({
  bgColor: {
    height: '100%',
    backgroundColor: '#1B1824',
  },
  allContent: {
    display: 'none',
  },
  allContentWisible: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '100%',
  },
  bg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
  wrapperHeader: {
    width: '100%',
    position: 'fixed',
    zIndex: 5,
    backgroundColor: '#171932',
    backdropFilter: 'blur(60px)',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  wrapperSmallHeader: {
    extend: 'wrapperHeader',
  },
  wrapper: {
    // extend: 'wrapperHeader',
    margin: '0 auto',
    maxWidth: 1140,
    position: 'relative',
    paddingBottom: 200,
    paddingTop: 120,
  },
  hr: {
    color: '#42424E',
    backgroundColor: '#42424E',
    height: 1,
    border: 'none',
    margin: 0,
    width: '100%',
    top: 105,
    zIndex: 1,
    position: 'fixed',
  },
  smallHr: {
    extend: 'hr',
    top: 80,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    paddingTop: 57,
    marginBottom: '30px',
  },
  contentBlockWrapper: {
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    padding: 20,
    marginTop: 35,
  },
  topLeftSideContent: {
    width: '360px',
    paddingRight: 30,
    display: 'flex',
  },
  leftMiddleContent: {
    width: '369px',
    display: 'flex',
  },
  topRightSideContent: {
    width: '70%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
  },
  rightMiddleContent: {
    width: '70%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
  },
  bottomLeftSideContent: {
    width: '70%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
  },
  bottomRightSideContent: {
    width: 384,
    paddingLeft: 30,
    display: 'flex',
  },
  composition: {
    display: 'flex',
    gap: 20,
    paddingBottom: 30,
  },
  notConnectedWrapper: {
    paddingTop: 30,
  },
  topContentWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  middleContentWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  modalBg: {
    width: '100%',
    background: 'rgb(0 0 0 / 70%)',
    height: '100vh',
    position: 'fixed',
    top: 0,
    zIndex: 1,
  },
  assetsMobile: {
    display: 'none',
  },
  '@media (max-width: 1250px)': {
    wrapperHeader: {
      width: '100%',
      position: 'fixed',
      zIndex: 5,
      left: '50%',
      transform: 'translateX(-50%)',
      userSelect: 'none',
    },
    wrapper: {
      maxWidth: 700,
      userSelect: 'none',
      paddingBottom: 75,
    },
    contentWrapper: {
      display: 'block',
      paddingTop: 58,
      marginBottom: '30px',
    },
    topLeftSideContent: {
      paddingRight: 0,
      width: '48.6%',
      display: 'flex',
    },
    topRightSideContent: {
      width: '50%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      display: 'flex',
    },
    topContentWrapper: {
      gap: 20,
      flexDirection: 'row-reverse',
      paddingBottom: 0,
    },
    middleContentWrapper: {
      paddingBottom: 0,
    },
    leftMiddleContent: {
      width: '48.5%',
      display: 'flex',
    },
    rightMiddleContent: {
      width: '51.5%',
    },
    composition: {
      display: 'none',
    },
    bottomLeftSideContent: {
      width: '100%',
    },
    bottomRightSideContent: {
      width: '100%',
      marginTop: 30,
      paddingLeft: 0,
    },
    assetsMobile: {
      display: 'none',
    },
  },
  '@media (max-width: 730px)': {
    bgColor: {
      height: '100%',
    },
    wrapper: {
      maxWidth: 340,
      paddingBottom: 95,
    },
    topContentWrapper: {
      display: 'block',
    },
    topLeftSideContent: {
      paddingRight: 0,
      width: '100%',
      display: 'block',
      marginBottom: 20,
    },
    topRightSideContent: {
      width: '100%',
    },
    leftMiddleContent: {
      display: 'none',
    },
    rightMiddleContent: {
      width: '100%',
    },
    bottomLeftSideContent: {
      display: 'block',
    },
    contentWrapper: {
      paddingTop: 63,
      marginBottom: '10px',
    },
    bottomRightSideContent: {
      marginTop: 20,
    },
    assetsMobile: {
      display: 'block',
    },
  },
})
const CHAIN_ID = Number(process.env.REACT_APP_X_ORBITER_CHAIN_ID)

const WalletType = {
  metamask: 'metamask',
  walletconnect: 'walletconnect',
}
interface Props {
  removeWalletData: () => void
  handleOpen: () => void
  connectWallet: (a: string, b: boolean) => void
  handleConnect: () => void
  openSwitchNetworkModal: (a: boolean, b: boolean) => void
  removeWalletType: () => void
  addToken: () => void
  setWalletType: () => void
  getWalletType: () => void
  switchNetwork: (params: any) => void
  getWalletData: () => void
  createProviderWalletConnect: () => void
  createProviderMetamask: () => void
  open: boolean
  connected: boolean
  showSwitchNetworkModal: boolean
  setShowSwitchNetworkModal: (a: boolean) => void
  addTokenFaucet: (a: string, b: string) => void
}

const ORBITER_NFT_API_URL = process.env.REACT_APP_X_ORBITER_NFT_API_URL

const EarnPage: React.FC<Props> = ({
  handleConnect,
  setShowSwitchNetworkModal,
  showSwitchNetworkModal,
  connected,
  open,
  connectWallet,
  openSwitchNetworkModal,
  handleOpen,
  removeWalletData,
  addToken,
  switchNetwork,
  getWalletData,
  createProviderWalletConnect,
  createProviderMetamask,
  addTokenFaucet,
}) => {
  const [smallHeader, setSmallHeader] = useState(false)
  const [data, setData] = useState(null)
  const [dataXORB, setDataXORB] = useState(null)
  const dispatch = useDispatch()
  const classes = styles()
  const abortControllerRef = useRef<AbortController>(new AbortController())
  const [isShowBanner, setIsShowBanner] = useState(true)

  const { user } = useSelector((state: any) => state.userReducer)
  const isOpenClaimModal = useSelector(
    (state: any) => state.claimModalReducer.open
  )

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(setLoading(false))
    const connectedWalletType = getWalletType()
    if (
      connectedWalletType === WalletType.walletconnect &&
      !localStorage.getItem('walletconnect')
    ) {
      removeWalletType()
      return
    }

    connectedWalletType && connectWallet(connectedWalletType, false)
  }, [])

  useEffect(() => {
    if (user.address) {
      abortControllerRef.current.abort()
      requestNFT({
        method: 'get',
        url: `${ORBITER_NFT_API_URL}/user-nft/farming-pools/${user.address}`,
      }).then((req) => {
        setData(req.data)
      })

      requestNFT({
        method: 'get',
        url: `${ORBITER_NFT_API_URL}/user-nft/farming-pools/x-orb/${user.address}`,
      }).then((req) => {
        setDataXORB(req.data)
      })
      // .finally(() => setLoadingORB(false))
    }
    if (!user.connected) {
      requestNFT({
        method: 'get',
        // signal: abortControllerRef.current.signal,
        url: `${ORBITER_NFT_API_URL}/user-nft/farming-pools/0x0000000000000000000000000000000000000000`,
      }).then((req) => {
        setData(req.data)
      })
      requestNFT({
        method: 'get',
        // signal: abortControllerRef.current.signal,
        url: `${ORBITER_NFT_API_URL}/user-nft/farming-pools/x-orb/0x0000000000000000000000000000000000000000`,
      }).then((req) => {
        setDataXORB(req.data)
      })
    }
  }, [user.address])

  useEffect(() => {
    if (!user.chainId) return

    if (
      // user.chainId !== paramsZkSyncTest.chainId &&
      // user.chainId !== paramsArbitrumTest.chainId &&
      user.chainId !== paramsMovr.chainId
    ) {
      openSwitchNetworkModal(true, false)
    } else {
      const body = document.body
      body.style.height = ''
      body.style.overflowY = ''
      setShowSwitchNetworkModal(false)
    }
  }, [user.chainId])

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
  })

  const refetch = () => {
    if (user.address) {
      abortControllerRef.current.abort()
      requestNFT({
        method: 'get',
        url: `${ORBITER_NFT_API_URL}/user-nft/farming-pools/${user.address}`,
      }).then((req) => {
        setData(req.data)
      })
      requestNFT({
        method: 'get',
        url: `${ORBITER_NFT_API_URL}/user-nft/farming-pools/x-orb/${user.address}`,
      }).then((req) => {
        setDataXORB(req.data)
      })
    }
    if (!user.connected) {
      requestNFT({
        method: 'get',
        url: `${ORBITER_NFT_API_URL}/user-nft/farming-pools/0x0000000000000000000000000000000000000000`,
      }).then((req) => {
        setData(req.data)
      })
      requestNFT({
        method: 'get',
        url: `${ORBITER_NFT_API_URL}/user-nft/farming-pools/x-orb/0x0000000000000000000000000000000000000000`,
      }).then((req) => {
        setDataXORB(req.data)
      })
    }
  }

  const listenScrollEvent = () => {
    if (window.scrollY > 50) {
      setSmallHeader(true)
    } else {
      setSmallHeader(false)
    }
  }

  const getWalletType = () => {
    return localStorage.getItem('wallet-type')
  }

  const removeWalletType = () => {
    localStorage.removeItem('wallet-type')
  }

  const closeBanner = () => {
    setIsShowBanner(false)
  }

  return (
    <div className={classes.allContentWisible}>
      <div className={classes.bgColor}>
        <img src={bg} className={classes.bg} />
        <div
          className={
            smallHeader ? classes.wrapperSmallHeader : classes.wrapperHeader
          }
        >
          <div>
            <Header
              switchNetwork={switchNetwork}
              smallHeader={smallHeader}
              removeWalletData={removeWalletData}
              handleOpen={handleOpen}
              open={open}
              showSwitchNetworkModal={showSwitchNetworkModal}
              connectWallet={connectWallet}
            />
          </div>
        </div>
        <hr className={smallHeader ? classes.smallHr : classes.hr} />
        {/* {isShowBanner ? (
          <AttentionNotification closeModal={closeBanner} />
        ) : null} */}
        {/*{actualApiFor === 'dev' ? <TestNet /> : <WrongNetBanner switchNetwork={switchNetwork} /> }*/}
        {showSwitchNetworkModal && user.connected && (
          <WrongNetworkModal switchNetwork={switchNetwork} />
          // <WrongNetBanner switchNetwork={switchNetwork} />
        )}
        {open ? (
          <NavMenu handleOpen={handleOpen} smallHeader={smallHeader} />
        ) : (
          <></>
        )}
        <div className={classes.wrapper}>
          <div className={classes.contentWrapper}>
            <EarnCycle
              cycleCount={data?.v1Cycle}
              v1NextTime={data?.v1NextTime}
              refetch={refetch}
            />
            <Earn
              switchNetwork={switchNetwork}
              data={data}
              refetch={refetch}
              dataXORB={dataXORB}
            />
          </div>
          <NeedHelpButton />
        </div>
      </div>
      <Footer />
      {isOpenClaimModal ? <ClaimModal addToken={addToken} /> : null}

      {/*{showSwitchNetworkModal && user.connected && (*/}
      {/*  <WrongNetworkModal switchNetwork={switchNetwork} />*/}
      {/*)}*/}
    </div>
  )
}
export default EarnPage
