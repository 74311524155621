import React, { useContext, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import coin from '../../img/coin.svg'
import { Tooltip } from 'antd'
import { fromBn, toBn } from 'evm-bn'
import { Web3Context } from '../../context'
import { useDispatch, useSelector } from 'react-redux'
import erc20Abi from '../../contracts/erc20Abi.abi'
import cEthAbi from '../../contracts/cEthAbi.abi'
import cErcAbi from '../../contracts/cErcAbi.abi'
import { transform } from '../../factory/bigNumber'
import { Asset } from '../Assets/Assets'
import Borrow from '../Borrow/Borrow'
import {
  deleteNotifications,
  setNotifications,
} from '../../redux/notifications'
import { supplyNotification } from '../../utils/notificationTexts'
import refetch from '../../utils/Refetch'
import { commify, sortRewordsAssets, totalIncentives } from 'src/utils'
import usePositionHealth from 'src/hooks/usePositionHealth'
import { positionHealthColor } from 'src/utils'
import uniqid from 'uniqid'
import BigNumber from 'bignumber.js'
import { activeTabs, setTabs } from '../../redux/tadsController'
import Skeleton from 'react-loading-skeleton'

const styles = createUseStyles({
  supply: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    width: '360px',
    borderRadius: 12,
    paddingTop: 15,
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  supplyInfoBlock: {
    display: 'flex',
    padding: [20, 16, 2, 16],
    justifyContent: 'space-between',
  },
  supplySubText: {
    color: '#A8A8B2',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
  },
  cardContentMainText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
  },
  supplyMainText: {
    extend: 'supplySubText',
    color: '#FFFFFF',
    fontWeight: 600,
  },
  red: {
    width: 4,
    height: 12,
    backgroundColor: '#E2006E',
    borderRadius: '52px',
    marginRight: 8,
  },
  healthWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  tabWrapper: {
    display: 'flex',
    justifyContent: 'start',
    gap: 20,
    '-webkit-tap-highlight-color': 'transparent',
  },
  activeTabText: {
    fontWeight: '600',
    color: '#FFFFFF',
    fontSize: '18px',
    paddingBottom: 20,
    lineHeight: '27px',
    cursor: 'pointer',
  },
  blueHr: {
    width: 20,
    height: 4,
    backgroundColor: '#5493F7',
    borderRadius: 37,
    margin: ' 5px auto 0',
  },
  disabledBlueHr: {
    display: 'none',
  },
  disabledTab: {
    extend: 'activeTabText',
    cursor: 'not-allowed',
    opacity: '0.4',
    color: '#A8A8B2',
  },
  tabText: {
    extend: 'activeTabText',
    color: '#A8A8B2',
  },
  inputWrapper: {
    backgroundColor: '#1F1F2D',
    borderRadius: 12,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    marginBottom: 15,
    boxSizing: 'border-box',
    padding: [10, 15],
  },
  inputDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'end',
  },
  inputDescriptionText: {
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
  },
  cursorDisable: {
    cursor: 'not-allowed',
  },
  inputPlaceholderText: {
    whiteSpace: 'nowrap',
    width: '130px',
    backgroundColor: '#1F1F2D',
    border: 'none',
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 400,
    color: '#7D7E83',
  },
  inputText: {
    extend: 'inputPlaceholderText',
    cursor: 'text',
    color: '#FFFFFF',
  },
  inputAvaliableValue: {
    extend: 'inputPlaceholderText',
    width: 'inherit',
    display: 'flex',
    flexDirection: 'column-reverse',
    color: '#5493F7',
    fontWeight: 600,
    alignItems: 'flex-end',
  },
  inputAvaliableValueUSD: {
    extend: 'inputPlaceholderText',
    color: '#A8A8B2',
    display: 'flex',
    width: 106,
    justifyContent: 'end',
    fontWeight: 400,
  },
  tokenValueWrapper: {
    display: 'flex',
    justifyContent: 'end',
  },
  hr: {
    borderBottom: 'none',
    margin: '8px 16px',
  },
  incomeInfoWrapper: {},
  incomeInfoText: {
    fontWeight: 500,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    marginLeft: 16,
  },
  incomeInfoPercentages: {
    fontWeight: 600,
    color: '#FFFFFF',
    fontSize: '14px',
    lineHeight: '21px',
    marginRight: 16,
  },
  depositSelectWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  depositSelectButton: {
    fontFamily: 'Poppins',
    cursor: 'pointer',
    backgroundColor: '#1F1F2D',
    width: 52,
    height: 22,
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 42,
  },
  depositButton: {
    cursor: 'pointer',
    backgroundColor: '#5493F7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0',
    width: '100%',
    border: 'none',
    borderRadius: 12,
  },
  depositButtonDisable: {
    extend: 'depositButton',
    backgroundColor: '#44445B',
    cursor: 'not-allowed',
  },
  approveDepositButton: {
    extend: 'depositButton',
    backgroundColor: '#4B567D',
  },
  depositButtonText: {
    fontFamily: 'Poppins',
    paddingLeft: 10,
    lineHeight: '27px',
    fontSize: '18px',
    fontWeight: 600,
    color: '#FFFFFF',
  },
  inputDescriptionWrapper: {
    width: '100%',
    padding: [20, 0],
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    cursor: 'default',
  },
  inputDescriptionAPY: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  borrowInformationBlock: {
    paddingTop: 30,
    margin: [0, 16],
  },
  borrowInformationText: {
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
  },
  tooltipWithRewards: {
    minWidth: 165,
    width: 'fit-content',
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipWithRewardsLong: {
    extend: 'tooltipWithRewards',
    minWidth: '165px',
    width: '100%',
  },
  tooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '4px',
    gap: 10,
  },
  tooltipItemText: {
    color: '#A8A8B2',
    fontWeight: 400,
    fontSize: '14px',
  },
  tooltipItemTextLong: {
    extend: 'tooltipItemText',
    marginRight: '10px',
  },
  tooltipItemNumber: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: '14px',
  },
  tooltipItemTextSign: {
    color: '#5493F7',
    fontWeight: 500,
    marginRight: '4px',
  },
  tooltipLine: {
    width: '100%',
    height: '1px',
    borderBottom: 'none',
    border: 'none',
    backgroundColor: '#4B567D',
    marginTop: '8px',
    marginBottom: '8px',
  },
  tooltipItemTotal: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewards: {
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipItemWithoutRewards: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewardsLong: {
    extend: 'tooltipWithoutRewards',
    // minWidth: '165px',
    // width: '100%',
  },

  lastTooltipItem: {
    extend: 'tooltipItem',
    marginBottom: '0px',
  },
  lastTooltipItemLong: {
    extend: 'tooltipItemLong',
    marginBottom: '0px',
  },
  noTexttooltipItemWithoutRewards: {
    extend: 'tooltipItemWithoutRewards',
    justifyContent: 'center',
  },
  '@media (max-width: 1250px)': {
    supply: {
      width: '300px',
    },
    activeTabText: {
      fontSize: '18px',
      lineHeight: '27px',
    },
    tabText: {
      fontSize: '18px',
      lineHeight: '27px',
    },
    inputPlaceholderText: {
      width: 115,
    },
  },
  '@media (max-width: 730px)': {
    supply: {
      width: 300,
    },
    inputPlaceholderText: {
      width: 115,
    },
  },
})

const Supply = () => {
  const [inputValue, setInputValue] = useState('')
  const [tokenBalance, setTokenBalance] = useState<any>('')
  const [tokenAllowance, setTokenAllowance] = useState('')
  const [tokenContract, setTokenContract] = useState(null)
  const [marketContract, setMarketContract] = useState(null)
  const [isMainToken, setIsMainToken] = useState(false)
  const [fetching, setFetching] = useState(true)

  const dispatch = useDispatch()

  const { web3 } = useContext(Web3Context)
  const { data } = useSelector((state: any) => state.userAssetsReducer)
  const { user } = useSelector((state: any) => state.userReducer)
  const { asset } = useSelector((state: any) => state.assetReducer)
  const { tabs } = useSelector((state: any) => state.tabsControllerReducer)
  const { userBalances } = useSelector(
    (state: any) => state.userBalancesReducer
  )

  const { percentage, coefficient } = usePositionHealth(
    'supply',
    inputValue,
    userBalances.totalBorrowed,
    userBalances.totalCollateral,
    asset.lastPrice,
    asset.collateralFactor
  )

  const zeroInInput = !(inputValue.replace(/[\s,]/g, '') == 0)

  const infinity =
    '115792089237316195423570985008687907853269984665640564039457.584007913129639935'

  const classes = styles()

  useEffect(() => {
    if (!asset._id) {
      setFetching(true)
      return
    }
    setFetching(false)

    setIsMainToken(!asset.tokenAddress)

    defineContracts(asset, !asset.tokenAddress)
    setInputValue('')
  }, [asset])

  useEffect(() => {
    if (!tokenContract || !user.address) return
    setInputValue('')

    getTokenBalance()
    !isMainToken && checkAllowance()
  }, [tokenContract, user.address])

  const defineContracts = async (asset: Asset, isMainToken: boolean) => {
    const tokenContract = new web3.eth.Contract(erc20Abi, asset.tokenAddress)
    setTokenContract(tokenContract)

    const marketContract = new web3.eth.Contract(
      isMainToken ? cEthAbi : cErcAbi,
      asset.oTokenAddress
    )
    setMarketContract(marketContract)
  }

  const disabled = !tokenBalance || tokenBalance === '0'

  const disabledButton =
    !disabled &&
    !!inputValue &&
    +inputValue.replace(/[\s,]/g, '') !== 0 &&
    !(+inputValue.replace(/[\s,]/g, '') > tokenBalance)

  const approveAndDeposit =
    (!isMainToken &&
      +inputValue.replace(/[\s,]/g, '') > +tokenAllowance &&
      !isMainToken) ||
    (tokenAllowance === '0' && !isMainToken)

  const filteredAssetIncentives = asset?.incentives?.filter(
    (el: any) => el.supplyApy > 0
  )

  const getTokenBalance = async () => {
    try {
      let result

      if (isMainToken) {
        result = await web3.eth.getBalance(user.address)
      } else {
        result = await tokenContract.methods.balanceOf(user.address).call()
      }
      setTokenBalance(fromBn(result, asset.tokenDecimal).toString())
    } catch (error) {
      console.log(error)
    }
  }

  const checkAllowance = async () => {
    try {
      const result = await tokenContract.methods
        .allowance(user.address, asset.oTokenAddress)
        .call()

      setTokenAllowance(fromBn(result, asset.tokenDecimal).toString())
    } catch (error) {
      console.log(error)
    }
  }

  const approve = async (amount: number) => {
    const transactionsId = uniqid()

    setInputValue('')
    dispatch(
      setNotifications({
        type: 'info',
        text: supplyNotification.textInfoApproval,
        link: null,
      })
    )
    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await tokenContract.methods
        .approve(
          asset.oTokenAddress,
          toBn(
            `${inputValue.replace(/[\s,]/g, '')}`,
            asset.tokenDecimal
          ).toString()
        )
        .estimateGas({
          from: user.address,
        })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      const result = await tokenContract.methods
        .approve(
          asset.oTokenAddress,
          toBn(`${infinity}`, asset.tokenDecimal).toString()
        )
        .send({
          from: user.address,
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: supplyNotification.textSubmittedApproval,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          text: supplyNotification.textSuccessApproval,
          link: result.transactionHash,
        })
      )

      console.log('approve:', result)
      getTokenBalance()
      checkAllowance()
      await supplyTokens(inputValue.replace(/[\s,]/g, ''))
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: supplyNotification.textErrorApproval,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const supplyTokens = async (amount: string) => {
    let result
    const transactionsId = uniqid()
    dispatch(
      setNotifications({
        type: 'info',
        text: supplyNotification.textInfoDeposit,
        link: null,
      })
    )

    setInputValue('')

    try {
      if (isMainToken) {
        let gasPrice = await web3.eth.getGasPrice()

        gasPrice = +gasPrice
        gasPrice += gasPrice

        let gasLimit = await marketContract.methods.mint().estimateGas({
          from: user.address,
        })

        gasLimit = +gasLimit
        gasLimit += gasLimit

        result = await marketContract.methods
          .mint()
          .send({
            from: user.address,
            gasLimit: web3.utils.toHex(Math.round(gasLimit)),
            gasPrice: web3.utils.toHex(Math.round(gasPrice)),
            value: toBn(`${amount}`, asset.tokenDecimal).toString(),
          })
          .on(
            'transactionHash',
            (hash: string) =>
              hash &&
              dispatch(
                setNotifications({
                  type: 'info',
                  text: supplyNotification.textSubmittedDeposit,
                  link: hash,
                  id: transactionsId,
                  isPending: true,
                })
              )
          )
      } else {
        let gasPrice = await web3.eth.getGasPrice()

        gasPrice = +gasPrice
        gasPrice += gasPrice

        let gasLimit = await marketContract.methods
          .mint(toBn(`${amount}`, asset.tokenDecimal).toString())
          .estimateGas({
            from: user.address,
          })

        gasLimit = +gasLimit
        gasLimit += gasLimit

        result = await marketContract.methods
          .mint(toBn(`${amount}`, asset.tokenDecimal).toString())
          .send({
            gasLimit: web3.utils.toHex(Math.round(gasLimit)),
            gasPrice: web3.utils.toHex(Math.round(gasPrice)),
            from: user.address,
          })
          .on(
            'transactionHash',
            (hash: string) =>
              hash &&
              dispatch(
                setNotifications({
                  type: 'info',
                  text: supplyNotification.textSubmittedDeposit,
                  link: hash,
                  id: transactionsId,
                  isPending: true,
                })
              )
          )
      }
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          text: supplyNotification.textSuccessDeposit,
          link: result.transactionHash,
        })
      )
      console.log('mint', result)
      setTimeout(() => {
        refetch(dispatch, user.address, asset)
      }, 5000)
      getTokenBalance()
      !isMainToken && checkAllowance()
      setInputValue('')
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: supplyNotification.textErrorDeposit,
          link: null,
        })
      )
      console.log(error)
    }
  }
  const disableTab = data.supplied.some((el: any) => el.collateral === true)

  const handleActiveTab = (activeTab: activeTabs) => () => {
    if (activeTab === activeTabs.borrow && !disableTab) {
      return
    }
    dispatch(setTabs({ supplyActiveTab: activeTab }))
  }

  const checkTab = (tab: string) =>
    tabs.supplyActiveTab === tab ? classes.activeTabText : classes.tabText

  const checkHr = (tab: string) =>
    tabs.supplyActiveTab === tab ? classes.blueHr : classes.disabledBlueHr

  const enterPersentInInput = (persent: number) => {
    const availableToSupplyAssetBN = new BigNumber(tokenBalance)

    setInputValue(
      commify(availableToSupplyAssetBN.multipliedBy(persent).toString())
    )
  }

  const deposit = async (inputValue: string) => {
    if (+inputValue.replace(/[\s,]/g, '') > +tokenAllowance && !isMainToken) {
      await approve(tokenBalance)
    } else {
      await supplyTokens(inputValue.replace(/[\s,]/g, ''))
    }
  }

  return (
    <>
      <div className={classes.supply}>
        <div className={classes.tabWrapper}>
          <div
            className={checkTab(activeTabs.supply)}
            onClick={handleActiveTab(activeTabs.supply)}
          >
            <span>Supply</span>
            <div className={checkHr(activeTabs.supply)}></div>
          </div>
          <div
            className={
              disableTab ? checkTab(activeTabs.borrow) : classes.disabledTab
            }
          >
            <span onClick={handleActiveTab(activeTabs.borrow)}>Borrow</span>
            <div className={checkHr(activeTabs.borrow)}></div>
          </div>
        </div>
        {tabs.supplyActiveTab === activeTabs.supply ? (
          <>
            <div>
              {fetching ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Skeleton
                    baseColor="#4B567D"
                    highlightColor="#5B6796"
                    width="300px"
                    height="70px"
                    count={1}
                    borderRadius="22px"
                  />{' '}
                </div>
              ) : (
                <div className={classes.inputWrapper}>
                  <div className={classes.inputDescription}>
                    <div className={classes.inputDescriptionText}>
                      Available
                    </div>
                    <div className={classes.inputAvaliableValue}>
                      <span className={classes.tokenValueWrapper}>
                        {transform(tokenBalance)} {asset.symbol}
                      </span>
                      <span className={classes.inputAvaliableValueUSD}>
                        (${transform(tokenBalance * asset.lastPrice, 1)})
                      </span>
                    </div>
                  </div>
                  <div className={classes.inputDescription}>
                    {disabled ? (
                      <span
                        className={[
                          classes.inputPlaceholderText,
                          classes.cursorDisable,
                        ].join(' ')}
                      >
                        You have no <a>{asset.symbol}</a>
                      </span>
                    ) : (
                      <input
                        type="text"
                        className={classes.inputText}
                        placeholder={'Enter the amount'}
                        style={{ outline: 'none' }}
                        value={inputValue}
                        onChange={(e) =>
                          setInputValue(
                            `${commify(
                              `${e.target.value
                                .replace(/[^0-9.]/g, '')
                                .replace(/(\..*)\./g, '$1')}`
                            )}`
                          )
                        }
                      />
                    )}
                  </div>
                </div>
              )}
              {!disabled && !fetching ? (
                <div className={classes.depositSelectWrapper}>
                  <div
                    className={classes.depositSelectButton}
                    onClick={() => enterPersentInInput(0.25)}
                  >
                    25%
                  </div>
                  <div
                    className={classes.depositSelectButton}
                    onClick={() => enterPersentInInput(0.5)}
                  >
                    50%
                  </div>
                  <div
                    className={classes.depositSelectButton}
                    onClick={() => enterPersentInInput(0.75)}
                  >
                    75%
                  </div>
                  <div
                    className={classes.depositSelectButton}
                    onClick={() => enterPersentInInput(1)}
                  >
                    MAX
                  </div>
                </div>
              ) : null}
            </div>
            {fetching ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Skeleton
                  baseColor="#4B567D"
                  highlightColor="#5B6796"
                  width="300px"
                  height="20px"
                  count={1}
                  borderRadius="32px"
                />{' '}
              </div>
            ) : (
              <div className={classes.supplyInfoBlock}>
                <div className={classes.healthWrapper}>
                  <div
                    className={classes.red}
                    style={{
                      backgroundColor: positionHealthColor(percentage),
                    }}
                  ></div>
                  <span className={classes.supplySubText}>
                    Health after Supplying:
                  </span>
                </div>
                {inputValue && zeroInInput ? (
                  <span className={classes.supplyMainText}>
                    {`${coefficient} | ${percentage}%`}
                  </span>
                ) : (
                  <span className={classes.supplyMainText}>N/A</span>
                )}
              </div>
            )}
            {asset.symbol === 'GLMR' &&
            inputValue &&
            +inputValue.replace(/[\s,]/g, '') >= tokenBalance ? (
              <div className={classes.borrowInformationBlock}>
                <span className={classes.borrowInformationText}>
                  You are going to supply 100% of your GLMR to the market,
                  leaving you with nothing to pay transaction fees on further
                  transactions. Are you sure you want to continue
                </span>
              </div>
            ) : null}
            <div>
              <div className={classes.incomeInfoWrapper}>
                <hr className={classes.hr} />
                <div className={classes.inputDescriptionWrapper}>
                  <div className={classes.inputDescriptionAPY}>
                    <div className={classes.incomeInfoText}>Supply APY</div>
                    {fetching ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Skeleton
                          baseColor="#4B567D"
                          highlightColor="#5B6796"
                          width="65px"
                          height="20px"
                          count={1}
                          borderRadius="22px"
                        />{' '}
                      </div>
                    ) : (
                      <div className={classes.incomeInfoPercentages}>
                        <Tooltip
                          overlayInnerStyle={{ boxShadow: 'none' }}
                          color="none"
                          className={classes.cardContentMainText}
                          title={
                            !asset.incentives?.length ||
                            filteredAssetIncentives.length === 0 ? (
                              <div className={classes.tooltipWithoutRewards}>
                                <div
                                  className={
                                    classes.noTexttooltipItemWithoutRewards
                                  }
                                >
                                  {/* <span className={classes.tooltipItemText}>Balance</span> */}
                                  <span>{transform(asset.supplyRate, 2)}%</span>
                                </div>
                              </div>
                            ) : (
                              <div className={classes.tooltipWithRewards}>
                                <div>
                                  <div className={classes.tooltipItem}>
                                    <span className={classes.tooltipItemText}>
                                      Supply APY
                                    </span>
                                    <span className={classes.tooltipItemNumber}>
                                      {transform(asset?.supplyRate, 2)}%
                                    </span>
                                  </div>
                                  {sortRewordsAssets(
                                    asset.incentives,
                                    'symbol'
                                  ).map((it: any, index: any) => (
                                    <div key={asset._id ? asset._id : index}>
                                      {transform(it.supplyApy, 2) === '0' ? (
                                        <></>
                                      ) : (
                                        <div className={classes.tooltipItem}>
                                          <span
                                            className={classes.tooltipItemText}
                                          >
                                            <span
                                              className={
                                                classes.tooltipItemTextSign
                                              }
                                            >
                                              +
                                            </span>
                                            {it.symbol}
                                            <span>&nbsp;</span>Rewards
                                          </span>
                                          <span
                                            className={
                                              classes.tooltipItemNumber
                                            }
                                          >
                                            {transform(it.supplyApy, 2)}%
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                                <hr className={classes.tooltipLine} />
                                <div className={classes.tooltipItemTotal}>
                                  <span className={classes.tooltipItemText}>
                                    Total APY
                                  </span>
                                  <span className={classes.tooltipItemNumber}>
                                    {'+' +
                                      transform(
                                        (
                                          totalIncentives(
                                            asset.incentives,
                                            'supply'
                                          ) + parseFloat(asset?.supplyRate)
                                        ).toString(),
                                        2
                                      )}
                                    %
                                  </span>
                                </div>
                              </div>
                            )
                          }
                        >
                          {asset.incentives?.length
                            ? '+' +
                              (totalIncentives(asset.incentives, 'supply') +
                                parseFloat(asset.supplyRate) >
                              1000
                                ? '<1000'
                                : transform(
                                    (
                                      totalIncentives(
                                        asset.incentives,
                                        'supply'
                                      ) + parseFloat(asset.supplyRate)
                                    ).toString(),
                                    2
                                  ))
                            : '+' + transform(asset.supplyRate, 2)}
                          %
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  {/*<div className={classes.inputDescriptionAPY}>*/}
                  {/*  <div className={classes.incomeInfoText}>Distribution APY</div>*/}
                  {/*  <div className={classes.incomeInfoPercentages}>5.15%</div>*/}
                  {/*</div>*/}
                </div>
              </div>
              {approveAndDeposit ? (
                <button
                  className={
                    disabledButton
                      ? classes.depositButton
                      : classes.depositButtonDisable
                  }
                  onClick={() => deposit(inputValue)}
                  disabled={!disabledButton}
                >
                  <img src={coin} />
                  <span className={classes.depositButtonText}>
                    {' '}
                    APPROVE & DEPOSIT
                  </span>
                </button>
              ) : (
                <button
                  className={
                    disabledButton
                      ? classes.depositButton
                      : classes.depositButtonDisable
                  }
                  onClick={() => deposit(inputValue)}
                  disabled={!disabledButton}
                >
                  <img src={coin} />
                  <span className={classes.depositButtonText}>DEPOSIT</span>
                </button>
              )}
            </div>
          </>
        ) : (
          <Borrow />
        )}
      </div>
    </>
  )
}

export default Supply
