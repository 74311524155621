import React from 'react'
import { createUseStyles } from 'react-jss'
import mainVideo from '../../img/NFT/main.gif'
import catPictureDesktop from '../../img/NFT/catsDesktop.png'
import catPictureTablet from '../../img/NFT/catsTablet.png'
import catPictureMobile from '../../img/NFT/cats.png'

const styles = createUseStyles({
  mainNftWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 140,
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  mainNftImg: {
    width: 1140,
    height: 526,
  },
  mainNftVideoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row-reverse',
    gap: 20,
  },
  mainNftTitleWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 555,
  },
  mainTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '40px',
    lineHeight: '60px',
    color: '#FFFFFF',
    paddingBottom: 20,
  },
  mainNftVideo: {
    width: 555,
    height: 555,
    borderRadius: 12,
  },
  mainSubText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '32px',
    color: '#A8A8B2',
    paddingBottom: 40,
  },
  mainButton: {
    background: '#5493F7',
    borderRadius: 12,
    width: 225,
    height: 60,
    border: 'none',
    cursor: 'pointer',

    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#FFFFFF',
  },
  colectionNameWrapper: {
    position: 'relative',
    paddingTop: 40,
  },
  colectionName: {
    background: 'linear-gradient(43.98deg, #00E0FC -8.44%, #5493F7 93.55%)',
    borderRadius: '100px',
    color: '#FFFFFF',
    width: 561,
    height: 63,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    top: '80%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '51px',
  },
  nftSubTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    paddingBottom: 30,
    paddingTop: 20,

    color: '#A8A8B2',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '38px',
  },
  hr: {
    height: 1,
    width: 600,
    backgroundColor: '#4B567D',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30,
  },
  '@media (max-width: 1250px)': {
    mainNftVideo: {
      width: 335,
      height: 335,
    },
    mainNftVideoWrapper: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    mainNftWrapper: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 90,
    },
    mainNftImg: {
      width: 1140,
      height: 526,
    },
    mainNftTitleWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      paddingBottom: 0,
      width: 650,
    },
    mainTitle: {
      fontSize: '32px',
      lineHeight: '48px',
      paddingBottom: 10,
      paddingTop: 30,
      textAlign: 'center',
    },
    mainSubText: {
      fontSize: '18px',
      lineHeight: '29px',
      paddingBottom: 40,
      textAlign: 'center',
      width: 515,
    },
  },
  '@media (max-width: 1140px)': {
    mainNftImg: {
      width: '98vw',
      minWidth: '98vw',
      height: 526,
    },
  },
  '@media (max-width: 730px)': {
    mainNftImg: {
      height: 360,
      width: '96vw',
      minWidth: '96vw',
    },
    mainNftVideo: {
      width: 220,
      height: 220,
    },
    mainTitle: {
      fontSize: '26px',
      lineHeight: '39px',
    },
    mainNftTitleWrapper: {
      paddingBottom: 0,
      width: 340,
    },
    mainSubText: {
      width: 340,
    },
    colectionName: {
      width: 326,
      height: 41,

      top: '80%',

      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '29px',
    },
    colectionNameWrapper: {
      position: 'relative',
      paddingTop: 20,
    },
    nftSubTitle: {
      paddingTop: 10,
      fontSize: '16px',
      lineHeight: '26px',
    },
    hr: {
      height: 1,
      width: 340,
      backgroundColor: '#4B567D',
    },
    '@media (max-width: 425px)': {
      mainNftImg: {
        height: 263,
      },
      colectionName: {
        width: 326,
        height: 41,

        top: '75%',

        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '29px',
      },
    },
  },
})

interface Props {
  open: boolean
  setOpen: (a: boolean) => void
}
const NFTMainBlock: React.FC<Props> = ({ open, setOpen }) => {
  const classes = styles()

  const setPicture = () => {
    if (window.innerWidth <= 930 && window.innerWidth > 425) {
      return catPictureTablet
    } else if (window.innerWidth <= 425) {
      return catPictureMobile
    } else {
      return catPictureDesktop
    }
  }

  return (
    <div className={classes.mainNftWrapper}>
      <div className={classes.colectionNameWrapper}>
        <img className={classes.mainNftImg} src={setPicture()} />
        <div className={classes.colectionName}>
          Intergalactic Whiskers Brigade
        </div>
        <div className={classes.nftSubTitle}>NFT Collection</div>
      </div>
      <div className={classes.hr}></div>
      <div className={classes.mainNftVideoWrapper}>
        <img className={classes.mainNftVideo} src={mainVideo} />
        <div className={classes.mainNftTitleWrapper}>
          <div className={classes.mainTitle}>
            Randomly generated Whiskers NFTs, Minted on Moonbeam
          </div>
          <div className={classes.mainSubText}>
            Choose the number of NFTs and get your collectible cats in just a
            few clicks
          </div>
          <button onClick={() => setOpen(!open)} className={classes.mainButton}>
            Mint NFT
          </button>
        </div>
      </div>
    </div>
  )
}

export default NFTMainBlock
