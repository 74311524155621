import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { request } from '../../factory/axios'
import { setUserBalances } from '../../redux/userBalances'
import { transform } from '../../factory/bigNumber'
import { commify, positionHealthColor } from 'src/utils'
import { Tooltip } from 'antd'
import useWindowSize from '../../utils/resizeHook'
import Skeleton from 'react-loading-skeleton'

const styles = createUseStyles({
  balances: {
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    padding: [15, 109, 20, 20],
    paddingBottom: 30,
    height: 169,
    width: '100%',
    cursor: 'default',
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    color: '#FFFFFF',
    fontSize: 18,
    paddingBottom: 20,
    lineHeight: '27px',
  },
  block: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
    marginBottom: 5,
  },
  number: {
    display: 'flex',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
    textDecorationLine: 'underline',
    cursor: 'default',
  },
  positionHealth: {
    display: 'flex',
    justifyContent: 'start',
    gap: 10,
    alignItems: 'center',
  },
  red: {
    width: 4,
    height: 20,
    backgroundColor: '#E2006E',
    borderRadius: '52px',
    marginRight: 8,
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  hr: {
    backgroundColor: '#A8A8B2',
    width: 1,
    height: 16,
  },
  wrapper: {
    paddingBottom: 20,
  },
  blockWrapper: {},
  widthMobileTooltip: {},
  tooltipWithRewards: {
    minWidth: 165,
    width: 'fit-content',
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipWithRewardsLong: {
    extend: 'tooltipWithRewards',
    minWidth: '165px',
    width: 'fit-content',
  },
  tooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '4px',
  },
  tooltipItemText: {
    color: '#A8A8B2',
    fontWeight: 400,
    fontSize: '14px',
  },
  tooltipItemTextLong: {
    extend: 'tooltipItemText',
    marginRight: '10px',
  },
  tooltipItemNumber: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: '14px',
  },
  tooltipItemTextSign: {
    color: '#5493F7',
    fontWeight: 500,
    marginRight: '4px',
  },
  tooltipLine: {
    width: '100%',
    height: '1px',
    borderBottom: 'none',
    border: 'none',
    backgroundColor: '#4B567D',
    marginTop: '8px',
    marginBottom: '8px',
  },
  tooltipItemTotal: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewards: {
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipItemWithoutRewards: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewardsLong: {
    extend: 'tooltipWithoutRewards',
    minWidth: '165px',
    // width: '100%',
  },

  lastTooltipItem: {
    extend: 'tooltipItem',
    marginBottom: '0px',
  },
  lastTooltipItemLong: {
    extend: 'tooltipItemLong',
    marginBottom: '0px',
  },
  noTexttooltipItemWithoutRewards: {
    extend: 'tooltipItemWithoutRewards',
    justifyContent: 'center',
  },

  '@media (max-width: 1250px)': {
    balances: {
      padding: [15, 20, 30],
      boxSizing: 'border-box',
      height: 218,
    },
    block: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    blockWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      fontSize: 18,
      paddingBottom: 20,
      lineHeight: '27px',
    },
    text: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: 0,
    },
    number: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      alignItems: 'center',
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 10,
    },
    positionHealth: {
      gap: 6,
    },
    red: {
      height: 14,
    },
  },
  '@media (max-width: 730px)': {
    widthMobileTooltip: {
      maxWidth: '200px !important',
    },
  },
})

const Balances = () => {
  const classes = styles()
  const [fetching, setFetching] = useState(true)

  const { userBalances } = useSelector(
    (state: any) => state.userBalancesReducer
  )
  const { user } = useSelector((state: any) => state.userReducer)
  const dispatch = useDispatch()
  const isMobile = window.innerWidth < 731

  const { isDesktop: desktop } = useWindowSize()

  useEffect(() => {
    request({
      method: 'get',
      path: `users/${user.address}`,
    })
      .then((res) => dispatch(setUserBalances(res.data.data)))
      .finally(() => {
        setFetching(false)
      })
  }, [])

  useEffect(() => {
    setFetching(true)
    request({
      method: 'get',
      path: `users/${user.address}`,
    })
      .then((res) => dispatch(setUserBalances(res.data.data)))
      .finally(() => {
        setFetching(false)
      })
  }, [user.address])

  return (
    <>
      <div className={classes.balances}>
        <div className={classes.title}>Your Balances</div>
        <div className={classes.block}>
          <div>
            <div className={classes.wrapper}>
              <div className={classes.text}>Supplied</div>
              {fetching ? (
                <Skeleton
                  baseColor="#4B567D"
                  highlightColor="#5B6796"
                  width={desktop ? 180 : 120}
                  count={1}
                  height={desktop ? 30 : 20}
                  borderRadius="9px"
                />
              ) : (
                <Tooltip
                  overlayInnerStyle={{ boxShadow: 'none' }}
                  color="none"
                  // placement={"leftBottom"}
                  title={
                    <div className={classes.tooltipWithRewardsLong}>
                      <div className={classes.tooltipItem}>
                        <span className={classes.tooltipItemTextLong}>
                          {isMobile ? null : 'Total'}&nbsp;Supplied
                        </span>
                        <span>
                          ${commify(transform(userBalances.totalSupplied, 6))}
                        </span>
                      </div>
                      <div className={classes.lastTooltipItem}>
                        <span className={classes.tooltipItemTextLong}>
                          {isMobile ? null : 'Total'}&nbsp;Collateral
                        </span>
                        <span className={classes.tooltipItemNumber}>
                          ${commify(transform(userBalances.totalCollateral, 6))}
                        </span>
                      </div>
                    </div>
                    //     `Total Supplied: $${commify(
                    //     transform(userBalances.totalSupplied, 6)
                    //   )}
                    // Total Collateral: $${commify(
                    //   transform(userBalances.totalCollateral, 6)
                    // )}
                    // `
                  }
                  className={classes.number}
                >
                  ${transform(userBalances.totalSupplied, 2)}
                </Tooltip>
              )}
            </div>
            <div className={classes.blockWrapper}>
              <div className={classes.text}>Borrowed</div>
              {fetching ? (
                <Skeleton
                  baseColor="#4B567D"
                  highlightColor="#5B6796"
                  width={desktop ? 180 : 120}
                  count={1}
                  height={desktop ? 30 : 20}
                  borderRadius="9px"
                />
              ) : (
                <Tooltip
                  overlayInnerStyle={{ boxShadow: 'none' }}
                  color="none"
                  title={
                    <div className={classes.tooltipWithoutRewards}>
                      <div className={classes.noTexttooltipItemWithoutRewards}>
                        {/* <span className={classes.tooltipItemText}>Balance</span> */}
                        <span>
                          $
                          {user.address
                            ? commify(transform(userBalances.totalBorrowed, 6))
                            : 'N/A'}
                        </span>
                      </div>
                    </div>
                    //   `$${commify(
                    //   transform(userBalances.totalBorrowed, 6)
                    // )}`
                  }
                  className={classes.number}
                >
                  ${transform(userBalances.totalBorrowed, 2)}
                </Tooltip>
              )}
            </div>
          </div>
          <div>
            <div className={classes.wrapper}>
              <div className={classes.text}>Position Health</div>
              {fetching ? (
                <Skeleton
                  baseColor="#4B567D"
                  highlightColor="#5B6796"
                  width={desktop ? 180 : 120}
                  count={1}
                  height={desktop ? 30 : 20}
                  borderRadius="9px"
                />
              ) : (
                <div
                  className={[classes.number, classes.positionHealth].join(' ')}
                >
                  <div className={classes.wrap}>
                    <div
                      className={classes.red}
                      style={{
                        backgroundColor: positionHealthColor(
                          +userBalances.positionHealth.percentage
                        ),
                      }}
                    ></div>
                    <Tooltip
                      overlayInnerStyle={{ boxShadow: 'none' }}
                      color="none"
                      title={
                        <div className={classes.tooltipWithoutRewardsLong}>
                          <div
                            className={classes.noTexttooltipItemWithoutRewards}
                          >
                            {/* <span className={classes.tooltipItemText}>Balance</span> */}
                            <span>Your current health factor</span>
                          </div>
                        </div>
                      }
                    >
                      {transform(userBalances.positionHealth.coefficient, 2)}
                    </Tooltip>
                  </div>
                  <div className={classes.hr}></div>
                  <Tooltip
                    overlayInnerStyle={{ boxShadow: 'none' }}
                    color="none"
                    title={
                      <div className={classes.tooltipWithoutRewardsLong}>
                        <div
                          className={classes.noTexttooltipItemWithoutRewards}
                        >
                          {/* <span className={classes.tooltipItemText}>Balance</span> */}
                          <span>
                            The percent of your collateral value that you've
                            borrowed agains
                          </span>
                        </div>
                      </div>
                      // "The percent of your collateral value that you've borrowed agains"
                    }
                  >
                    {userBalances.positionHealth.percentage > 100
                      ? 100
                      : transform(userBalances.positionHealth.percentage, 2)}
                    %
                  </Tooltip>
                </div>
              )}
            </div>
            <div className={classes.blockWrapper}>
              <div className={classes.text}>Available to Borrow</div>
              {fetching ? (
                <Skeleton
                  baseColor="#4B567D"
                  highlightColor="#5B6796"
                  width={desktop ? 180 : 120}
                  count={1}
                  height={desktop ? 30 : 20}
                  borderRadius="9px"
                />
              ) : (
                <Tooltip
                  overlayInnerStyle={{ boxShadow: 'none' }}
                  color="none"
                  placement={desktop ? 'topLeft' : 'top'}
                  title={
                    <div className={classes.tooltipWithoutRewards}>
                      <div className={classes.noTexttooltipItemWithoutRewards}>
                        {/* <span className={classes.tooltipItemText}>Balance</span> */}
                        <span>
                          $
                          {commify(
                            transform(userBalances.availableToBorrow, 6)
                          )}
                        </span>
                      </div>
                    </div>
                    //   `$${commify(
                    //   transform(userBalances.availableToBorrow, 6)
                    // )}`
                  }
                  className={classes.number}
                >
                  ${transform(userBalances.availableToBorrow, 2)}
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Balances
