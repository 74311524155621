import React from 'react'
// @ts-ignore
import gif from '../../img/02.gif'
import { createUseStyles } from 'react-jss'

const styles = createUseStyles({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: 'black',
    zIndex: 99999,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gif: {
    position: 'absolute',
    display: 'block',
    margin: 'auto',
    width: 500,
  },
})

const Loader = () => {
  const classes = styles()

  return (
    <div className={classes.wrapper}>
      <img className={classes.gif} src={gif} alt="" />
    </div>
  )
}
export default Loader
