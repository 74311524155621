import React from 'react'
import { createUseStyles } from 'react-jss'
import cat from '../../img/NFT/catBody.png'
import hero from '../../img/NFT/catHelmet.png'
import face from '../../img/NFT/catFace.png'
import bg from '../../img/NFT/catBackgrounds.png'
import intercom from '../../img/NFT/catIntercom.png'

const styles = createUseStyles({
  mainNftWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  galleryImg: {
    width: 262,
    height: 262,
  },
  propertiesWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: 30,
    paddingBottom: 30,
  },
  propertiesTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '40px',
    lineHeight: '60px',
    color: '#FFFFFF',
    paddingBottom: 40,
  },
  propertiesBodyWrapper: {
    width: 750,
    height: 390,
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    boxSizing: 'border-box',
    padding: [40, 30],
    position: 'relative',
  },
  propertiesHelmetWrapper: {
    extend: 'propertiesBodyWrapper',
    width: 360,
    padding: [30, 30],
  },
  propertiesFaceWrapper: {
    extend: 'propertiesBodyWrapper',
    width: 262,
    padding: [30, 30],
  },
  propertiesBgWrapper: {
    extend: 'propertiesBodyWrapper',
    width: 262,
    padding: [30, 30],
  },
  propertiesIntecomWrapper: {
    extend: 'propertiesBodyWrapper',
    width: 554,
    padding: [30, 30],
  },
  bodyTextWrapper: {
    width: 433,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
  },
  bodyText: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '29px',
    color: '#A8A8B2',
    paddingBottom: 100,
    position: 'relative',
    zIndex: 2,
  },
  cardTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '29px',
    color: '#5493F7',
    position: 'relative',
    zIndex: 2,
  },
  cardSubText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
    position: 'relative',
    zIndex: 2,
  },
  cardMainText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
    position: 'relative',
    zIndex: 2,
  },
  cardBodyTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '29px',
    color: '#5493F7',
    paddingBottom: 10,
    position: 'relative',
    zIndex: 2,
  },
  cardBodyPropsWrapper: {
    display: 'flex',
    gap: 40,
  },
  bodyImg: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: [0, 12, 12, 0],
  },
  cardHelmetPropsWrapper: {
    display: 'flex',
    gap: 20,
    paddingTop: 20,
    flexDirection: 'column',
  },
  helmetImg: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: 12,
  },
  cardFacePropsWrapper: {
    display: 'flex',
    paddingTop: 15,
    flexDirection: 'column',
  },
  faceCardPropsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  faceImg: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderRadius: [0, 0, 12, 12],
  },
  cardBgPropsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20,
    paddingTop: 10,
    justifyContent: 'space-between',
  },
  cardBgPropsPadding: {
    paddingBottom: 20,
  },
  bgImg: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  cardIntercomPropsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20,
    paddingTop: 10,
    justifyContent: 'space-between',
  },
  intercomImg: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderRadius: 12,
  },
  bodyPropsWrapper: {},
  helmetPropsWrapper: {},
  facePropsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardFacePropsPadding: {},
  bodyQuantityWrapper: {
    display: 'flex',
    gap: 40,
    alignItems: 'center',
  },

  '@media (max-width: 1250px)': {
    mainNftWrapper: {
      paddingBottom: 30,
    },
    propertiesTitle: {
      fontSize: '32px',
      lineHeight: '48px',
      color: '#FFFFFF',
      paddingBottom: 30,
    },
    bodyText: {
      width: 379,
      paddingBottom: 70,
    },
    galleryImg: {
      width: 152,
      height: 152,
    },
    galleryImgWrapper: {
      paddingBottom: 40,
    },
    propertiesBodyWrapper: {
      width: 457,
      position: 'relative',
    },
    propertiesHelmetWrapper: {
      extend: 'propertiesBodyWrapper',
      width: 213,
      padding: [30, 30],
    },
    propertiesFaceWrapper: {
      extend: 'propertiesBodyWrapper',
      width: 213,
      padding: [30, 30],
    },
    propertiesBgWrapper: {
      extend: 'propertiesBodyWrapper',
      width: 213,
      padding: [30, 30],
    },
    propertiesIntecomWrapper: {
      extend: 'propertiesBodyWrapper',
      width: 213,
      padding: [30, 30],
    },

    cardFacePropsWrapper: {
      display: 'flex',
      paddingTop: 20,
      flexDirection: 'column',
    },
    cardIntercomPropsWrapper: {
      paddingTop: 20,
      flexDirection: 'column',
      gap: 0,
    },
    cardBgPropsWrapper: {
      paddingTop: 20,
    },

    faceCardPropsWrapper: {
      display: 'block',
    },
    facePropsWrapper: {
      display: 'block',
      paddingBottom: 20,
    },

    bodyImg: {
      display: 'none',
    },
    helmetImg: {
      display: 'none',
    },
    intercomImg: {
      display: 'none',
    },
    faceImg: {
      display: 'none',
    },
    bgImg: {
      display: 'none',
    },
  },
  '@media (max-width: 730px)': {
    mainNftWrapper: {
      paddingBottom: 60,
    },
    propertiesTitle: {
      fontSize: '26px',
      lineHeight: '39px',
      color: '#FFFFFF',
      paddingBottom: 30,
    },
    bodyText: {
      width: 280,
      paddingBottom: 40,
      fontSize: '16px',
      lineHeight: '26px',
    },
    cardBodyTitle: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '29px',
      color: '#5493F7',
      paddingBottom: 10,
    },
    propertiesWrapper: {
      display: 'block',
      paddingBottom: 0,
    },
    galleryImg: {
      width: 152,
      height: 152,
    },
    propertiesBodyWrapper: {
      width: 340,
      height: 447,
      position: 'relative',
      marginBottom: 20,
    },
    propertiesHelmetWrapper: {
      extend: 'propertiesBodyWrapper',
      width: 340,
      height: 241,
      padding: [30, 57, 30, 30],
      marginBottom: 20,
    },
    propertiesFaceWrapper: {
      extend: 'propertiesBodyWrapper',
      width: 340,
      height: 241,
      padding: [30, 57, 30, 30],
      marginBottom: 20,
    },
    propertiesBgWrapper: {
      extend: 'propertiesBodyWrapper',
      width: 340,
      height: 241,
      padding: [30, 57, 30, 30],
      marginBottom: 20,
    },
    propertiesIntecomWrapper: {
      extend: 'propertiesBodyWrapper',
      width: 340,
      height: 241,
      padding: [30, 57, 30, 30],
    },

    cardFacePropsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 20,
      paddingTop: 10,
      justifyContent: 'space-between',
    },
    cardIntercomPropsWrapper: {
      paddingTop: 20,
      flexDirection: 'row',
    },
    cardBgPropsWrapper: {
      paddingTop: 20,
    },
    faceCardPropsWrapper: {
      display: 'block',
    },
    bodyPropsWrapper: {
      width: '100%',
      paddingBottom: 20,
    },
    helmetPropsWrapper: {
      width: '100%',
    },
    cardBodyPropsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 20,
      width: 253,
      padding: [0, 27, 0, 0],
      justifyContent: 'space-between',
    },
    cardHelmetPropsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 20,
      paddingTop: 10,
      justifyContent: 'space-between',
    },
    facePropsWrapper: {
      width: '100%',
      display: 'block',
    },
    bgPropsWrapper: {
      width: '100%',
    },
    bodyTextWrapper: {
      width: '100%',
    },
    cardFacePropsPadding: {
      paddingTop: 0,
    },
    bodyQuantityWrapper: {
      display: 'block',
    },
  },
})

const NFTProperties = () => {
  const classes = styles()

  return (
    <div className={classes.mainNftWrapper}>
      <div className={classes.propertiesTitle}>Properties</div>
      <div className={classes.propertiesWrapper}>
        <div className={classes.propertiesBodyWrapper}>
          <div className={classes.bodyTextWrapper}>
            <div className={classes.bodyText}>
              Intergalactic Whiskers Brigade is an NFT collection of 10,000
              astronaut cats on the Moonbeam blockchain. Each NFT is unique and
              generated with different options of faces, outfits, backgrounds
              etc.
            </div>
            <div>
              <div className={classes.cardBodyTitle}>BODY</div>
              <div className={classes.cardBodyPropsWrapper}>
                <div className={classes.bodyQuantityWrapper}>
                  <div
                    className={[
                      classes.bodyPropsWrapper,
                      classes.cardFacePropsPadding,
                    ].join(' ')}
                  >
                    <div className={classes.cardSubText}>Quantity</div>
                    <div className={classes.cardMainText}>11</div>
                  </div>
                  <div className={classes.bodyPropsWrapper}>
                    <div className={classes.cardSubText}>Original</div>
                    <div className={classes.cardMainText}>10</div>
                  </div>
                </div>
                <div className={classes.bodyQuantityWrapper}>
                  <div
                    className={[
                      classes.bodyPropsWrapper,
                      classes.cardFacePropsPadding,
                    ].join(' ')}
                  >
                    <div className={classes.cardSubText}>Rare</div>
                    <div className={classes.cardMainText}>1</div>
                  </div>
                  <div className={classes.bodyPropsWrapper}>
                    <div className={classes.cardSubText}>Rare Chance</div>
                    <div className={classes.cardMainText}>2.43%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <img className={classes.bodyImg} src={cat} alt="" />
          </div>
        </div>
        <div className={classes.propertiesHelmetWrapper}>
          <div>
            <div className={classes.cardTitle}>HELMET</div>
            <div className={classes.cardHelmetPropsWrapper}>
              <div>
                <div
                  className={[
                    classes.helmetPropsWrapper,
                    classes.cardBgPropsPadding,
                  ].join(' ')}
                >
                  <div className={classes.cardSubText}>Quantity</div>
                  <div className={classes.cardMainText}>11</div>
                </div>
                <div className={classes.helmetPropsWrapper}>
                  <div className={classes.cardSubText}>Original</div>
                  <div className={classes.cardMainText}>10</div>
                </div>
              </div>
              <div>
                <div
                  className={[
                    classes.helmetPropsWrapper,
                    classes.cardBgPropsPadding,
                  ].join(' ')}
                >
                  <div className={classes.cardSubText}>Rare</div>
                  <div className={classes.cardMainText}>1</div>
                </div>
                <div className={classes.helmetPropsWrapper}>
                  <div className={classes.cardSubText}>Rare Chance</div>
                  <div className={classes.cardMainText}>2.91%</div>
                </div>
              </div>
            </div>
            <div>
              <img className={classes.helmetImg} src={hero} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.propertiesWrapper}>
        <div className={classes.propertiesFaceWrapper}>
          <div className={classes.cardTitle}>FACE</div>
          <div>
            <div className={classes.cardFacePropsWrapper}>
              <div>
                <div
                  className={[
                    classes.facePropsWrapper,
                    classes.cardFacePropsPadding,
                  ].join(' ')}
                >
                  <div className={classes.cardSubText}>Quantity</div>
                  <div className={classes.cardMainText}>11</div>
                </div>
                <div className={classes.facePropsWrapper}>
                  <div className={classes.cardSubText}>Rare</div>
                  <div className={classes.cardMainText}>0</div>
                </div>
              </div>
              <div>
                <div
                  className={[
                    classes.facePropsWrapper,
                    classes.cardFacePropsPadding,
                  ].join(' ')}
                >
                  <div className={classes.cardSubText}>Original</div>
                  <div className={classes.cardMainText}>11</div>
                </div>
                <div className={classes.facePropsWrapper}>
                  <div className={classes.cardSubText}>Rare Chance</div>
                  <div className={classes.cardMainText}>0%</div>
                </div>
              </div>
            </div>
          </div>
          <img className={classes.faceImg} src={face} alt="" />
        </div>
        <div className={classes.propertiesBgWrapper}>
          <div className={classes.cardTitle}>BACKGROUNDS</div>
          <div>
            <div className={classes.cardBgPropsWrapper}>
              <div>
                <div
                  className={[
                    classes.bgPropsWrapper,
                    classes.cardBgPropsPadding,
                  ].join(' ')}
                >
                  <div className={classes.cardSubText}>Quantity</div>
                  <div className={classes.cardMainText}>11</div>
                </div>
                <div className={classes.bgPropsWrapper}>
                  <div className={classes.cardSubText}>Rare</div>
                  <div className={classes.cardMainText}>1</div>
                </div>
              </div>
              <div>
                <div
                  className={[
                    classes.bgPropsWrapper,
                    classes.cardBgPropsPadding,
                  ].join(' ')}
                >
                  <div className={classes.cardSubText}>Original</div>
                  <div className={classes.cardMainText}>10</div>
                </div>
                <div className={classes.bgPropsWrapper}>
                  <div className={classes.cardSubText}>Rare Chance</div>
                  <div className={classes.cardMainText}>2.04%</div>
                </div>
              </div>
            </div>
          </div>
          <img className={classes.bgImg} src={bg} alt="" />
        </div>
        <div className={classes.propertiesIntecomWrapper}>
          <div className={classes.cardTitle}>INTERCOM</div>
          <div>
            <div className={classes.cardIntercomPropsWrapper}>
              <div>
                <div
                  className={[
                    classes.intercomPropsWrapper,
                    classes.cardBgPropsPadding,
                  ].join(' ')}
                >
                  <div className={classes.cardSubText}>Quantity</div>
                  <div className={classes.cardMainText}>4</div>
                </div>
                <div
                  className={[
                    classes.intercomPropsWrapper,
                    classes.cardBgPropsPadding,
                  ].join(' ')}
                >
                  <div className={classes.cardSubText}>Original</div>
                  <div className={classes.cardMainText}>0</div>
                </div>
              </div>
              <div>
                <div
                  className={[
                    classes.intercomPropsWrapper,
                    classes.cardBgPropsPadding,
                  ].join(' ')}
                >
                  <div className={classes.cardSubText}>Rare</div>
                  <div className={classes.cardMainText}>4</div>
                </div>
                <div className={classes.intercomPropsWrapper}>
                  <div className={classes.cardSubText}>Rare Chance</div>
                  <div className={classes.cardMainText}>8%</div>
                </div>
              </div>
            </div>
          </div>
          <img className={classes.intercomImg} src={intercom} alt="" />
        </div>
      </div>
    </div>
  )
}

export default NFTProperties
