import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import moon from '../../img/moonbeam_2.svg'
import arbitrum from '../../img/arbitrum-logo.svg'
import zksync from '../../img/zksync era.svg'
// @ts-ignore
import neonLogo from '../../img/neon-logo.png'
import arrow from '../../img/HeaderArrowDown.svg'
import { useDispatch } from 'react-redux'
import { setChainId } from '../../redux/chainId'
import useComponentVisible from '../../hooks/useComponentVisible'
import { paramsMovr } from 'src/utils/networkConst'

const actualApiFor = process.env.REACT_APP_X_ORBITER_ACTIVE_API_FOR

const styles = createUseStyles({
  wrapper: {
    position: 'relative',
  },
  networkDropdown: {
    boxSizing: 'border-box',
    width: 'auto',
    gap: 8,
    padding: [0, 15],
    height: 42,
    borderRadius: 10,
    paddingTop: 9,
    margin: 0,
    paddingBottom: 9,
    display: 'flex',
    justifyContent: 'space-between',
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    alignItems: 'center',
    boxShadow: 'inset 1px 1px 1px #42426a',
    backdropFilter: 'blur(25px)',
    cursor: 'pointer',
    position: 'relative',
    userSelect: 'none',
  },
  networkDropdownMobile: {
    width: 48,
    height: 42,
    borderRadius: 15,
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    display: 'flex',
    boxShadow: 'inset 1px 1px 1px #42426A',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  text: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: '#FFFFFF',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    gap: 10,
  },
  dropdown: {
    width: 124,
    height: 'auto',
    background:
      'linear-gradient(180deg, rgba(49, 57, 84) 0%, rgba(53, 53, 81) 100%)',
    position: 'absolute',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    top: 'calc(100% + 10px)',
    padding: 20,
    left: 0,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  smallDropdown: {
    extend: 'dropdown',
  },
  dropdownItem: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'start',
    gap: 10,
    cursor: 'pointer',
    userSelect: 'none',
  },
  icon: {
    width: 20,
    height: 20,
  },
  dropdownItemDisable: {
    cursor: 'not-allowed',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'start',
    gap: 10,
    color: '#A8A8B2',
    userSelect: 'none',
  },
  textDisable: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: '#A8A8B2',
  },
  dropdownArrowRotate: {
    transform: 'rotate(-180deg)',
  },
  dropdownArrow: {},
  '@media (max-width: 730px)': {
    networkDropdown: {
      width: 34,
      height: 34,
      justifyContent: 'center',
    },
    dropdown: {
      left: 'unset',
      right: 0,
    },
    smallDropdown: {
      left: 'unset',
      right: 0,
    },
    dropdownArrow: {
      display: 'none',
    },
    dropdownArrowRotate: {
      display: 'none',
    },
  },
})

interface Props {
  smallHeader: boolean
  switchNetwork: (params: any) => void
  isNftPage?: boolean
}

const NetworkDropdown: React.FC<Props> = ({
  smallHeader,
  switchNetwork,
  isNftPage,
}) => {
  const classes = styles()
  const [data, setData] = useState({
    name: actualApiFor === 'dev' ? 'Moonbase' : 'Moonbeam',
    icon: moon,
  })

  const dispatch = useDispatch()

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  const handleOpen = () => {
    setIsComponentVisible((prev) => !prev)
  }

  const handleChangeNetwork = (img: any, params: any) => {
    setData(img)
    switchNetwork(params)
    dispatch(setChainId(params.chainId))
    handleOpen()
  }

  return (
    <div ref={ref} className={classes.wrapper}>
      <div className={classes.networkDropdown} onClick={() => handleOpen()}>
        <div className={classes.iconWrapper}>
          <img className={classes.icon} src={data.icon} alt="" />
        </div>
        <img
          className={
            isComponentVisible
              ? classes.dropdownArrowRotate
              : classes.dropdownArrow
          }
          src={arrow}
          alt=""
        />
      </div>

      {isComponentVisible ? (
        <div className={smallHeader ? classes.smallDropdown : classes.dropdown}>
          <div
            className={classes.dropdownItem}
            onClick={() => {
              handleChangeNetwork(
                {
                  name: actualApiFor === 'dev' ? 'Moonbase' : 'Moonbeam',
                  icon: moon,
                },
                paramsMovr
              )
            }}
          >
            <img className={classes.icon} src={moon} alt="" />
            <div className={classes.text}>
              {actualApiFor === 'dev' ? 'Moonbase' : 'Moonbeam'}
            </div>
          </div>

          <div className={classes.dropdownItemDisable}>
            <img className={classes.icon} src={neonLogo} alt="" />
            <div className={classes.textDisable}>Neon</div>
          </div>

          <div className={classes.dropdownItemDisable}>
            <img className={classes.icon} src={arbitrum} alt="" />
            <div className={classes.textDisable}>Arbitrum</div>
          </div>

          <div className={classes.dropdownItemDisable}>
            <img className={classes.icon} src={zksync} alt="" />
            <div className={classes.textDisable}>ZkSync</div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default NetworkDropdown
