import React, { useEffect, useState } from 'react'
import { Routes, Route, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Web3 from 'web3'
import { setUser } from './redux/user'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import FaucetPage from './page/faucetPage'
import Main from './page/main'
import { Web3Context } from './context'
import { setNotifications } from 'src/redux/notifications'
import Notifications from './components/Notifications/Notifications'
import TagManager from 'react-gtm-module'
import { activeTabs, setTabs } from './redux/tadsController'
import { setAsset } from './redux/asset'
import NFTPage from './page/NFTPage'
import StakingNFTPage from './page/stakingNFTPage'
import '@swing.xyz/ui/theme.css'
import './App.css'
import TermsModal from './components/TermsModal/TermsModal'
import Loader from './components/Loader/Loader'
import { request } from './factory/axios'
import { setRates } from './redux/rates'
import EarnPage from './page/EarnPage'
import XORBPage from './page/XORBPage'
import SwapModal from './components/SwapModal/SwapModal'
import { setIsOpen } from './redux/swingSwapModal'

const WalletType = {
  metamask: 'metamask',
  walletconnect: 'walletconnect',
}

let provider = {
  [WalletType.metamask]: undefined, // required, not null
  [WalletType.walletconnect]: undefined, // required, not null
}

let web3 = Web3
function App() {
  const [connected, setConnected] = useState(true)
  const [open, setOpen] = useState(false)
  const [showSwitchNetworkModal, setShowSwitchNetworkModal] = useState(false)
  const [openTerms, setOpenTerms] = useState(true)

  const [searchParams, setSearchParams] = useSearchParams()

  const handleConnect = () => {
    setConnected(!connected)
  }

  const { asset } = useSelector((state) => state.assetReducer)
  // don't  delete this line
  const { user } = useSelector((state) => state.userReducer)
  const { notifications } = useSelector((state) => state.notificationsReducer)
  const { chainId } = useSelector((state) => state.chainIdReducer)
  const loading = useSelector((state) => state.loadingReducer)
  const { isOpen: isOpenSwingSwapModal } = useSelector(
    (state) => state.swingSwapReducer
  )

  const dispatch = useDispatch()
  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_X_GTM_ID })
  }, [])

  useEffect(() => {
    request({
      method: 'get',
      path: `markets/rates`,
    })
      .then((res) => dispatch(setRates(res.data.data)))
      .finally(() => {
        // setFetching(false)
      })

    const isSwap = searchParams.get('swap')

    if (isSwap) {
      dispatch(setIsOpen(true))
    }
  }, [])

  useEffect(() => {
    if (!isOpenSwingSwapModal) {
      searchParams.delete('swap')

      setSearchParams(searchParams)
    }
  }, [isOpenSwingSwapModal])

  const createProviderMetamask = async (force = true) => {
    return new Promise(async (resolve, reject) => {
      try {
        switch (true) {
          case provider[WalletType.metamask] === undefined:
          case provider[WalletType.metamask] === null:
            provider[WalletType.metamask] =
              'ethereum' in window ? window['ethereum'] : Web3.givenProvider
            break
          default:
        }

        web3 = new Web3(provider[WalletType.metamask])

        if ('enable' in web3.currentProvider) {
          await web3.currentProvider.enable()
        }
      } catch (error) {
        if (!error.code) {
          window.open(
            'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
            '_blank'
          )
        }
        return reject(null)
      }

      try {
        await getWalletData(WalletType.metamask)

        provider[WalletType.metamask].removeAllListeners('accountsChanged')
        provider[WalletType.metamask].removeAllListeners('chainChanged')
        provider[WalletType.metamask].removeAllListeners('disconnect')

        provider[WalletType.metamask].on('accountsChanged', async (address) => {
          await getWalletData(WalletType.metamask)

          dispatch(
            setTabs({
              supplyActiveTab: activeTabs.supply,
              suppliedActiveTab: activeTabs.supply,
            })
          )
          dispatch(setAsset({}))
        })
        provider[WalletType.metamask].on(
          'chainChanged',
          async (chainIdLocal) => {
            await getWalletData(WalletType.metamask)
          }
        )

        provider[WalletType.metamask].on('disconnect', () => {
          removeWalletData()
          removeWalletType()
        })

        return resolve(null)
      } catch (error) {
        console.error('Internal JSON-RPC error')

        return reject(null)
      }
    })
  }

  const createProviderWalletConnect = async (force = true) => {
    return new Promise(async (resolve, reject) => {
      try {
        switch (true) {
          case force:
            localStorage.removeItem(WalletType.walletconnect)
          case !provider[WalletType.walletconnect]:
          case !provider[WalletType.walletconnect].connected:
            provider[WalletType.walletconnect] = await new Web3Modal({
              disableInjectedProvider: true,
              cacheProvider: false,
              providerOptions: {
                [WalletType.walletconnect]: {
                  package: WalletConnectProvider,
                  options: {
                    // network: 'Moonbase Alpha',
                    rpc: {
                      [chainId]: 'https://rpc.api.moonbase.moonbeam.network/', // WalletConnect requires MAINNET rpc by default
                      // [AppSettingsConfig.CHAIN_ID]: AppSettingsConfig.RPC_URL,
                    },
                    chainId: chainId,
                  },
                },
              },
            }).connectTo(WalletType.walletconnect)

            break
          default:
        }

        web3 = new Web3(provider[WalletType.walletconnect])
        let localChainId = null
        try {
          localChainId = await web3.eth.net.getId()
        } catch (error) {
          console.error(error)
        }

        if (localChainId !== chainId) {
          console.error("You're connected to the wrong network")
        }

        if ('enable' in web3.currentProvider) {
          await web3.currentProvider.enable()
        }

        await getWalletData(WalletType.walletconnect)

        provider[WalletType.walletconnect].removeAllListeners('accountsChanged')
        provider[WalletType.walletconnect].removeAllListeners('chainChanged')
        provider[WalletType.walletconnect].removeAllListeners('disconnect')

        provider[WalletType.walletconnect].on('accountsChanged', async () => {
          await getWalletData(WalletType.walletconnect)
        })

        provider[WalletType.walletconnect].on(
          'chainChanged',
          (chainIdLocal) => {
            dispatch(setUser({ chainId: web3.utils.hexToNumber(chainIdLocal) }))
          }
        )

        provider[WalletType.walletconnect].on('disconnect', () => {
          dispatch(setUser({ connected: false }))
          removeWalletData()
          removeWalletType()
        })

        return resolve(null)
      } catch (error) {
        return reject(null)
      }
    })
  }

  const getWalletData = async (walletType) => {
    const [wallet] = await web3.eth.getAccounts()

    if (!wallet) {
      return await removeWalletData()
    }

    let chainId = null
    try {
      chainId = await web3.eth.net.getId()
    } catch (error) {
      console.error(error)
    }

    // Set Wallet type
    setWalletType(walletType)

    // Set Wallet address
    dispatch(
      setUser({
        address: wallet,
        addressShort: `${wallet.substr(0, 5)}...${wallet.substr(
          wallet.length - 4
        )}`,
        chainId,
        connected: true,
      })
    )

    return true
  }

  const switchNetwork = async (params, callback = undefined) => {
    const connectedWalletType = getWalletType()
    try {
      await provider[WalletType[connectedWalletType]]
        .request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3.utils.toHex(params.chainId) }],
        })
        .then(() => callback && callback())
        .catch((e) => {
          throw { code: e?.code }
        })
    } catch (err) {
      if (err.code === 4902) {
        await provider[WalletType[connectedWalletType]].request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              ...params,
              chainId: web3.utils.toHex(params.chainId),
            },
          ],
        })
      }
    }
  }

  const removeWalletData = async () => {
    // Remove Wallet type
    removeWalletType()
    localStorage.removeItem('walletconnect')

    // Reset Wallet address
    dispatch(
      setUser({
        address: null,
        addressShort: null,
        chainId: null,
        connected: false,
      })
    )

    setShowSwitchNetworkModal(false)

    return true
  }

  const getWalletType = () => {
    return localStorage.getItem('wallet-type')
  }

  const setWalletType = (walletType) => {
    localStorage.setItem('wallet-type', walletType)
  }

  const removeWalletType = () => {
    localStorage.removeItem('wallet-type')
  }

  const connectWallet = async (walletType, force = true) => {
    if (walletType === 'metamask') {
      try {
        await createProviderMetamask(force)
        await dispatch(setUser({ connected: true }))
      } catch (error) {
        dispatch(setUser({ connected: false }))
        return null
      }

      return null
    }

    if (walletType === 'walletconnect') {
      try {
        await createProviderWalletConnect(force)
        await dispatch(setUser({ connected: true }))
      } catch (error) {
        dispatch(setUser({ connected: false }))
        return null
      }

      return null
    }
  }

  const addTokenFaucet = (item, tokenName) => {
    const successAddToWalletFaucet = `${tokenName} has been added to your wallet successfully`
    const errorAddToWalletFaucet = `You denied the add ${tokenName} in your wallet. Please resubmit your operation.`
    const token = {
      address: item.token.tokenAddress,
      symbol: item.token.symbol,
      decimals: item.token.tokenDecimal,
      image: item.token.image,
    }
    const connectedWalletType = getWalletType()

    provider[WalletType[connectedWalletType]]
      .request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: token,
        },
      })
      .then((success) => {
        if (success) {
          // handle success if needed
          dispatch(
            setNotifications({
              type: 'success',
              text: successAddToWalletFaucet,
            })
          )
          console.log('success', success)
        } else {
          console.error('Something went wrong.')
        }
      })
      .catch((error) => {
        dispatch(
          setNotifications({ type: 'error', text: errorAddToWalletFaucet })
        )

        console.log(error)
      })
  }

  const addToken = (token) => {
    const successAddToWallet = `${token.symbol} has been added to your wallet successfully`
    const errorAddToWallet = `You denied the add ${token.symbol} in your wallet. Please resubmit your operation.`
    const connectedWalletType = getWalletType()

    provider[WalletType[connectedWalletType]]
      .request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: token,
        },
      })
      .then((success) => {
        if (success) {
          // handle success if needed
          dispatch(
            setNotifications({ type: 'success', text: successAddToWallet })
          )
          console.log('success', success)
        } else {
          console.error('Something went wrong.')
        }
      })
      .catch((error) => {
        dispatch(setNotifications({ type: 'error', text: errorAddToWallet }))

        console.log(error)
      })
  }

  const openTermsloc = localStorage.getItem('agreedTc')

  const handleOpen = () => {
    setOpen(!open)
  }

  const openSwitchNetworkModal = (value, offScroll = true) => {
    const body = document.body
    if (offScroll) {
      const body = document.body
      body.style.height = '100vh'
      body.style.overflowY = 'hidden'
    }
    window.scrollTo(0, parseInt(body.style.top || '0') * -1)
    setShowSwitchNetworkModal(value)
  }

  return (
    <>
      {loading.loading && <Loader />}
      {isOpenSwingSwapModal && <SwapModal />}
      <Web3Context.Provider
        value={{
          provider,
          web3,
        }}
      >
        {notifications[0] ? <Notifications /> : null}
        {openTermsloc !== 'true' && loading.loading === false ? (
          <TermsModal setOpenTerms={setOpenTerms} />
        ) : null}
        <Routes>
          <Route
            exact
            path="/faucet/"
            element={
              <FaucetPage
                openSwitchNetworkModal={openSwitchNetworkModal}
                handleConnect={handleConnect}
                handleOpen={handleOpen}
                addToken={addToken}
                connectWallet={connectWallet}
                removeWalletType={removeWalletType}
                setWalletType={setWalletType}
                getWalletType={getWalletType}
                removeWalletData={removeWalletData}
                switchNetwork={switchNetwork}
                setShowSwitchNetworkModal={setShowSwitchNetworkModal}
                getWalletData={getWalletData}
                createProviderWalletConnect={createProviderWalletConnect}
                createProviderMetamask={createProviderMetamask}
                showSwitchNetworkModal={showSwitchNetworkModal}
                open={open}
                setOpen={setOpen}
                addTokenFaucet={addTokenFaucet}
              />
            }
          />
          <Route
            exact
            path="/whiskers/"
            element={
              <NFTPage
                openSwitchNetworkModal={openSwitchNetworkModal}
                handleConnect={handleConnect}
                handleOpen={handleOpen}
                addToken={addToken}
                connectWallet={connectWallet}
                removeWalletType={removeWalletType}
                setWalletType={setWalletType}
                getWalletType={getWalletType}
                removeWalletData={removeWalletData}
                switchNetwork={switchNetwork}
                setShowSwitchNetworkModal={setShowSwitchNetworkModal}
                getWalletData={getWalletData}
                createProviderWalletConnect={createProviderWalletConnect}
                createProviderMetamask={createProviderMetamask}
                showSwitchNetworkModal={showSwitchNetworkModal}
                open={open}
                setOpen={setOpen}
              />
            }
          />
          <Route
            exact
            path="/staking/"
            element={
              <StakingNFTPage
                openSwitchNetworkModal={openSwitchNetworkModal}
                handleConnect={handleConnect}
                handleOpen={handleOpen}
                addToken={addToken}
                connectWallet={connectWallet}
                removeWalletType={removeWalletType}
                setWalletType={setWalletType}
                getWalletType={getWalletType}
                removeWalletData={removeWalletData}
                switchNetwork={switchNetwork}
                setShowSwitchNetworkModal={setShowSwitchNetworkModal}
                getWalletData={getWalletData}
                createProviderWalletConnect={createProviderWalletConnect}
                createProviderMetamask={createProviderMetamask}
                showSwitchNetworkModal={showSwitchNetworkModal}
                open={open}
                setOpen={setOpen}
                addTokenFaucet={addTokenFaucet}
              />
            }
          />
          <Route
            exact
            path="/xORB/"
            element={
              <XORBPage
                openSwitchNetworkModal={openSwitchNetworkModal}
                handleConnect={handleConnect}
                handleOpen={handleOpen}
                addToken={addToken}
                connectWallet={connectWallet}
                removeWalletType={removeWalletType}
                setWalletType={setWalletType}
                getWalletType={getWalletType}
                removeWalletData={removeWalletData}
                switchNetwork={switchNetwork}
                setShowSwitchNetworkModal={setShowSwitchNetworkModal}
                getWalletData={getWalletData}
                createProviderWalletConnect={createProviderWalletConnect}
                createProviderMetamask={createProviderMetamask}
                showSwitchNetworkModal={showSwitchNetworkModal}
                open={open}
                setOpen={setOpen}
                addTokenFaucet={addTokenFaucet}
              />
            }
          />
          <Route
            exact
            path="/farms"
            element={
              <EarnPage
                openSwitchNetworkModal={openSwitchNetworkModal}
                handleConnect={handleConnect}
                handleOpen={handleOpen}
                addToken={addToken}
                connectWallet={connectWallet}
                removeWalletType={removeWalletType}
                setWalletType={setWalletType}
                getWalletType={getWalletType}
                removeWalletData={removeWalletData}
                switchNetwork={switchNetwork}
                setShowSwitchNetworkModal={setShowSwitchNetworkModal}
                getWalletData={getWalletData}
                createProviderWalletConnect={createProviderWalletConnect}
                createProviderMetamask={createProviderMetamask}
                showSwitchNetworkModal={showSwitchNetworkModal}
                open={open}
                setOpen={setOpen}
                addTokenFaucet={addTokenFaucet}
              />
            }
          />
          <Route
            exact
            path="/"
            element={
              <Main
                openSwitchNetworkModal={openSwitchNetworkModal}
                handleConnect={handleConnect}
                handleOpen={handleOpen}
                addToken={addToken}
                connectWallet={connectWallet}
                removeWalletType={removeWalletType}
                setWalletType={setWalletType}
                getWalletType={getWalletType}
                removeWalletData={removeWalletData}
                switchNetwork={switchNetwork}
                setShowSwitchNetworkModal={setShowSwitchNetworkModal}
                getWalletData={getWalletData}
                createProviderWalletConnect={createProviderWalletConnect}
                createProviderMetamask={createProviderMetamask}
                showSwitchNetworkModal={showSwitchNetworkModal}
                open={open}
                setOpen={setOpen}
              />
            }
          />
        </Routes>
      </Web3Context.Provider>
    </>
  )
}

export default App
