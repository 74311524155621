import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { createUseStyles } from 'react-jss'
import Checkbox from 'react-custom-checkbox'
import CustomScrollWrap from '../CustomScrollWrap'
import checkBoxIcon from '../../img/CheckBoxTrue.svg'

const styles = createUseStyles({
  modalBg: {
    width: '100%',
    background: 'rgb(0 0 0 / 70%)',
    height: '100vh',
    position: 'fixed',
    top: 0,
    zIndex: 15,
  },
  collateralModal: {
    display: 'flex',
    flexDirection: 'column',
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    backdropFilter: 'blur(25px)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    padding: [30, 20, 22],
    position: 'absolute',
    marginLeft: 'auto',
    width: 560,
    height: 658,
    marginRight: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    borderRadius: '12px',
    cursor: 'default',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '30px',
    color: '#FFFFFF',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  infoText: {
    position: 'relative',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#A8A8B2',
    overflow: 'hidden',
    paddingRight: '15px',
    marginRight: '-15px',

    '&::before, &::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      width: 'calc(100% - 15px)',
      height: '50%',
      zIndex: 1,
      pointerEvents: 'none',
    },

    '&::before': {
      top: 0,
      boxShadow: 'inset 0 160px 80px -100px #323854',
    },

    '&::after': {
      bottom: 0,
      boxShadow: 'inset 0 -160px 80px -100px #343651',
    },
  },
  infoTextBlurTop: {
    '&::before': {
      display: 'none',
    },
  },
  infoTextBlurBottom: {
    '&::after': {
      display: 'none',
    },
  },
  bottomText: {
    paddingTop: 20,
  },
  depositButton: {
    backgroundColor: '#5493F7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0',
    width: '225px',
    height: 60,
    border: 'none',
    borderRadius: 12,
    cursor: 'pointer',
    margin: 0,
  },
  disableButton: {
    extend: 'depositButton',
    backgroundColor: '#44445B',
  },
  declineButton: {
    extend: 'depositButton',
    backgroundColor: 'inherit',
    border: '1px solid #5493F7',
  },
  depositButtonText: {
    fontFamily: 'Poppins',
    lineHeight: '27px',
    fontSize: '18px',
    fontWeight: 600,
    color: '#FFFFFF',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
  },
  termsTitle: {
    color: 'white',
    fontWeight: '500',
    paddingTop: 10,
    paddingBottom: 10,
  },
  termsSubTitle: {
    color: 'white',
    fontWeight: '500',
  },
  termsLink: {
    color: '#5493F7',
    fontWeight: '500',
  },
  // termsBlur: {},
  borrowInformationCheckBoxWrapper: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 32,
    alignItems: 'center',
    gap: 10,
  },
  borrowInformationCheckBoxText: {
    color: '#5493F7',
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '150%',
  },
  borrowInformationCheckBoxDisableText: {
    color: '#A8A8B2',
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '150%',
  },
  borrowInformationCheckBox: {
    width: 21,
    height: 21,
  },
  lastUpdated: {
    fontFamily: 'Poppins',
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 400,
    color: '#7D7E83',
  },
  '@media (max-width: 730px)': {
    collateralModal: {
      width: 300,
      height: 590,
    },
    titleWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      paddingBottom: 20,
      justifyContent: 'space-between',
    },
    title: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '27px',
    },
    depositButton: {
      width: 140,
    },
    disableButton: {
      width: 140,
    },
    declineButton: {
      width: 140,
    },
    lastUpdated: {
      paddingTop: 5,
      fontFamily: 'Poppins',
      lineHeight: '20px',
      fontSize: '14px',
      fontWeight: 400,
      color: '#7D7E83',
    },
  },
})

interface Props {
  setOpenTerms: (a: boolean) => void
}

enum ScrollPos {
  'TOP' = 'infoTextBlurTop',
  'MIDDLE' = 'infoTextBlurBoth',
  'BOTTOM' = 'infoTextBlurBottom',
}

const TermsModal: React.FC<Props> = ({ setOpenTerms }) => {
  const [checkboxValue, setCheckboxValue] = useState(false)
  const [checkboxDisable, setCheckboxDisable] = useState(true)
  const [scrollPos, setScrollPos] = useState<ScrollPos>(ScrollPos.TOP)

  const classes = styles()

  const handleCloseModal = () => {
    localStorage.setItem('agreedTc', 'true')
    setOpenTerms(false)
  }

  const handleDecline = () => {
    window.location.replace('https://orbiter.one/')
  }

  const scrolled = (e) => {
    if (
      e.target.offsetHeight + e.target.scrollTop >=
      e.target.scrollHeight - 100
    ) {
      setCheckboxDisable(false)
      setScrollPos(ScrollPos.BOTTOM)
      return
    }

    if (e.target.scrollTop <= 0) {
      setScrollPos(ScrollPos.TOP)
      return
    }

    setScrollPos(ScrollPos.MIDDLE)
  }

  return (
    <div className={classes.modalBg}>
      <div className={classes.collateralModal}>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>Terms & Conditions</div>
          <div className={classes.lastUpdated}>Updated July 04 2023</div>
        </div>
        <div className={`${classes.infoText} ${classes[scrollPos]}`}>
          <CustomScrollWrap mathod={scrolled}>
            <div>
              These Terms and Conditions ("Terms") govern your use of the
              Orbiter One protocol (the "Protocol") provided by Orbiter Labs
              ("we," "us," or "our"). By using the Protocol, you agree to be
              bound by these Terms. If you do not agree to these Terms, you may
              not use the Protocol.
              <div className={classes.termsTitle}>1. Introduction </div>
              By accessing and using the Protocol, you acknowledge that you have
              read, understood, and agreed to these Terms. These Terms
              constitute a legally binding agreement between you and Orbiter
              Labs.
              <div className={classes.termsTitle}>2. Eligibility</div>
              You must be at least 18 years old and have the legal capacity to
              enter into a binding agreement to use the Protocol. If you are
              using the Protocol on behalf of an organization, you represent and
              warrant that you have the authority to bind that organization to
              these Terms.
              <div className={classes.termsTitle}>3. Restricted Persons</div>
              The Protocol and any related services are not offered to and may
              not be used by persons or entities who reside in, are citizens of,
              are located in, are incorporated in, or have a registered office
              in any Restricted Territory. Restricted Territory includes
              Algeria, Bangladesh, Bolivia, Belarus, Burundi, Burma (Myanmar),
              Cote D'Ivoire (Ivory Coast), Crimea and Sevastopol, Cuba,
              Democratic Republic of Congo, Ecuador, Iran, Iraq, Liberia, Libya,
              Mali, Morocco, Nepal, North Korea, Somalia, Sudan, Syria,
              Venezuela, Yemen, Zimbabwe, or any other country to which Canada,
              Panama, the United States, the United Kingdom, or the European
              Union embargoes goods or imposes similar sanctions. The use of a
              virtual private network ("VPN") or any other similar means
              intended to circumvent these restrictions is prohibited.
              <div className={classes.termsTitle}>4. Intellectual Property</div>
              The Protocol, including all intellectual property rights, is and
              will remain the exclusive property of Orbiter Labs. You
              acknowledge that the Protocol is protected by copyright,
              trademark, and other laws. Except as expressly stated in these
              Terms, you are not granted any rights to the Protocol or any
              related intellectual property.
              <div className={classes.termsTitle}>5. Use of the Protocol</div>
              You may use the Protocol in compliance with these Terms and any
              additional guidelines or policies provided by Orbiter Labs. You
              agree to comply with all applicable laws and regulations while
              using the Protocol and not to violate any third-party rights.
              <div className={classes.termsTitle}>6. Termination</div>
              Orbiter Labs may suspend or terminate your access to the Protocol
              at any time and for any reason without prior notice. Upon
              termination, all licenses and rights granted to you under these
              Terms will immediately cease.
              <div className={classes.termsTitle}>
                7. Disclaimer of Warranties
              </div>
              The Protocol is provided on an "as is" basis without warranty of
              any kind. We disclaim all warranties, whether express, implied, or
              statutory, including but not limited to warranties of
              merchantability, fitness for a particular purpose, and
              non-infringement.
              <div className={classes.termsTitle}>
                8. Limitation of Liability
              </div>
              To the maximum extent permitted by law, Orbiter Labs and its
              affiliates, directors, officers, employees, and agents shall not
              be liable for any indirect, incidental, special, consequential, or
              punitive damages, including but not limited to damages for loss of
              profits, goodwill, use, data, or other intangible losses arising
              out of or in connection with the use or inability to use the
              Protocol.
              <div className={classes.termsTitle}>
                9. Governing Law and Jurisdiction
              </div>
              These Terms are governed by and construed in accordance with the
              laws of Seychelles without regard to its conflict of laws
              principles. Any legal action or proceeding arising out of or
              relating to these Terms or the use of the Protocol shall be
              exclusively brought in the courts located in Victoria, Mahe,
              Seychelles.
              <div className={classes.termsTitle}>10. Entire Agreement</div>
              These Terms constitute the entire agreement between you and
              Orbiter Labs regarding the use of the Protocol and supersede any
              prior agreements, understandings, or representations.
              <div className={classes.termsTitle}>11. About the Interface</div>
              <span className={classes.termsSubTitle}>A) Interface: </span> The
              Site provides an interface by which Users may access the Protocol,
              which is a fully decentralized, non-custodial, community-governed
              lending protocol deployed on multiple blockchain networks and
              systems, and provides information about the Company’s ecosystem,
              governance, community, and various interfaces and integrations to
              the Interface. All information provided in connection with your
              access and use of the Interface is for informational purposes
              only. You should not take, or refrain from taking, any action
              based on any information contained on the Site or any other
              information that we make available at any time, including blog
              posts, data, articles, links to third-party content, Discord
              content, Discourse content, news feeds, tutorials, tweets, and
              videos. Before you make any financial, legal, technical, or other
              decisions involving the Interface, you should seek independent
              professional advice from a licensed and qualified individual in
              the area for which such advice would be appropriate. Because the
              Interface provides information about the Protocol, these Terms
              also provide some information about the use of the Protocol. This
              information is not intended to be comprehensive or address all
              aspects of the Protocol.
              <div>
                <span className={classes.termsSubTitle}>
                  B) Our Relationship:{' '}
                </span>{' '}
                You acknowledge and agree that Orbiter Labs is an online
                platform provider and not a broker-dealer or a Lender. Orbiter
                Labs does not direct or control the day-to-day activities of the
                users accessing the Site. Neither Orbiter Labs nor any
                affiliated entity is a party to any transaction on the
                blockchain networks underlying the Protocol. We do not have
                possession, custody, or control over any cryptoassets appearing
                on the Interface, and we do not have possession, custody, or
                control over any User’s funds or cryptoassets. You understand
                that when you interact with any Protocol smart contracts, you
                retain control over your cryptoassets at all times. The private
                key associated with the wallet address from which you transfer
                cryptoassets is the only private key that can control the
                cryptoassets you transfer into the smart contracts. You alone
                are responsible for securing your private keys. Due to the
                non-custodial and decentralized nature of the technology, we are
                not intermediaries, agents, advisors, or custodians, and we do
                not have a fiduciary relationship or obligation to you regarding
                any other decisions or activities that you affect when using our
                Services. You acknowledge that we do not have any information
                regarding any Users, Users’ identities, or services beyond what
                is available or obtainable publicly via the blockchain. We are
                not responsible for any activities you engage in when using the
                Interface, and you should understand the risks associated with
                cryptoassets, blockchain technology generally, and the
                Interface.
              </div>
              <div>
                <span className={classes.termsSubTitle}>C) Interface: </span>
                The Site provides an interface by which Users may access the
                Protocol. The Protocol is deployed on multiple blockchain-based
                networks, and we are not responsible for the operation of such
                networks. The software underlying blockchain networks on which
                the Protocol is deployed, including, for example, the Ethereum
                blockchain, is open source, which means that anyone can use,
                utilize, and build on top of it. By using the Interface, you
                acknowledge and agree (i) that we are not responsible for the
                operation of the blockchain-based software and networks
                underlying the Protocol, (ii) that there exists no guarantee of
                the functionality, security, or availability of that software
                and networks, and (iii) that the underlying blockchain-based
                networks are subject to sudden changes in operating rules, such
                as those commonly referred to as “forks”.
              </div>
              <div>
                <span className={classes.termsSubTitle}>
                  D) Blockchain Fees:{' '}
                </span>
                There may be associated blockchain fees. All transactions using
                blockchains require the payment of gas fees, which are
                essentially transaction fees paid on every transaction that
                occurs on the selected blockchain network. Please note that gas
                fees are non-refundable.
              </div>
              <div>
                <span className={classes.termsSubTitle}>E) ORB Rewards: </span>
                You may receive token rewards in the form of ORB, which only
                utility is to mint Intergalactic Whiskers Brigade NFTs
                (Whiskers.) Revenue generated through Borrowers’ interest is
                distributed directly to users who stake Intergalactic Whiskers
                Brigade NFTs. More information on ORB, Whiskers general
                functionality can be found{' '}
                <a
                  className={classes.termsLink}
                  target="_blank"
                  href="https://docs.orbiter.one"
                >
                  here
                </a>
              </div>
              <div>
                <span className={classes.termsSubTitle}>F) Liquidation: </span>
                Borrowers may lose some or all of their collateral in certain
                circumstances, including if their collateral value does not
                properly cover their loan/debt value (“Liquidation”). Upon
                Liquidation, Borrowers may also incur a penalty fee. Users may
                seek out loans that are eligible for Liquidation and pay back
                part of the debt owed in exchange for receiving discounted
                collateral. More information on Liquidation is available{' '}
                <a
                  className={classes.termsLink}
                  target="_blank"
                  href="https://docs.orbiter.one"
                >
                  here
                </a>
                .
              </div>
              <div>
                <span className={classes.termsSubTitle}>
                  G) Communication from the Interface:{' '}
                </span>
                You may elect to receive emails or other communications from
                Orbiter Labs in connection with the Interface. If you elect to
                receive communications in connection with your Loan, you agree
                and acknowledge that Orbiter Labs is under no obligation to send
                any emails or communicate with you regarding such Loan or the
                expiration of such Loan, and that Orbiter Labs will not be
                responsible or liable to you for any loss and takes no
                responsibility for and will not be liable to you in the case of
                failure of such email or communication.{' '}
              </div>
              <div>
                <span className={classes.termsSubTitle}>
                  H) Disputes Between Borrowers and Lenders:{' '}
                </span>
                Orbiter Labs is not a party to any relationship between any
                Borrowers and/or Lenders or in the delivery or completion of
                borrowing/lending transactions. In the event that you have a
                dispute with any user of the Site, including any Borrowers or
                Lenders, you agree to address such dispute directly with such
                user. If permitted in your jurisdiction, you release Orbiter
                Labs (and Orbiter Labs’ officers, directors, agents, investors,
                subsidiaries, and employees) (collectively “Releasees”) from,
                and covenant not to sue Releasees for any and all claims,
                demands, or damages (actual or consequential) of any kind and
                nature, known and unknown, suspected and unsuspected, disclosed
                and undisclosed, arising out of or in any way connected with
                such dispute. As a Lender, to the degree that any collateral
                placed by a Borrower is a fraud, scam, or a non-authenticated
                asset, you agree and acknowledge that Orbiter Labs and the
                Interface will not be responsible for any Loan made by you in
                reliance on collateral placed on the Interface.
              </div>
              <div>
                <span className={classes.termsSubTitle}>
                  I) Regulatory and Compliance Suspensions or Terminations:{' '}
                </span>
                We may suspend or terminate your access to the Interface at any
                time in connection with any transaction as required by
                applicable law, any governmental authority, or if we, in our
                sole and reasonable discretion, determine that you are violating
                these Terms or the terms of any third-party service provider.
                Such suspension or termination shall not be considered a breach
                of these Terms by Orbiter Labs. In accordance with its
                anti-money laundering, anti-terrorism, anti-fraud, and other
                compliance policies and practices, we may impose reasonable
                limitations and controls on the ability of you or any
                beneficiary to utilize the Interface. Such limitations may
                include, where good cause exists, rejecting transaction
                requests, freezing funds, or otherwise restricting you from
                using the Interface.
              </div>
              <div className={classes.termsTitle}>
                12. Governing Law and Jurisdiction
              </div>
              These Terms are governed by and construed in accordance with the
              laws of Seychelles without regard to its conflict of laws
              principles. Any legal action or proceeding arising out of or
              relating to these Terms or the use of the Protocol shall be
              exclusively brought in the courts located in Victoria, Mahe,
              Seychelles.
              <div className={classes.termsTitle}>13. Entire Agreement</div>
              These Terms, together with the Interface section, constitute the
              entire agreement between you and Orbiter Labs regarding the use of
              the Protocol and supersede any prior agreements, understandings,
              or representations.
              <div className={classes.termsTitle}>14. Contact Information</div>
              If you have any questions or concerns about these Terms or the
              Protocol, you may contact us at{' '}
              <a
                className={classes.termsLink}
                target="_blank"
                href="mailto:info@orbiter.one"
              >
                info@orbiter.one
              </a>
            </div>
          </CustomScrollWrap>
        </div>
        <div>
          <div
            style={{ cursor: !checkboxDisable ? 'pointer' : '' }}
            onClick={() => !checkboxDisable && setCheckboxValue(!checkboxValue)}
            className={classes.borrowInformationCheckBoxWrapper}
          >
            <Checkbox
              onChange={() => setCheckboxValue(!checkboxValue)}
              borderColor={checkboxDisable ? '#A8A8B2' : '#5493F7'}
              disabled={checkboxDisable}
              checked={checkboxValue}
              size={20}
              style={{ cursor: !checkboxDisable ? 'pointer' : '' }}
              icon={<img src={checkBoxIcon} alt="" />}
            />
            <div
              className={
                !checkboxDisable
                  ? classes.borrowInformationCheckBoxText
                  : classes.borrowInformationCheckBoxDisableText
              }
            >
              I agree with the Terms & Conditions
            </div>
          </div>
          <div className={classes.buttonWrapper}>
            <button
              onClick={() => handleDecline()}
              className={classes.declineButton}
            >
              <span className={classes.depositButtonText}>DECLINE</span>
            </button>
            <button
              disabled={!checkboxValue}
              onClick={() => handleCloseModal()}
              className={
                checkboxValue ? classes.depositButton : classes.disableButton
              }
            >
              <span className={classes.depositButtonText}>ACCEPT</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsModal
