import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface chainIdState {
  chainId: number | undefined
}

const initialState: chainIdState = {
  chainId: +process.env.REACT_APP_X_ORBITER_CHAIN_ID,
}

export const chainIdSlice = createSlice({
  name: 'chainId',
  initialState,
  reducers: {
    setChainId: (state, action: PayloadAction<any>) => {
      state.chainId = action.payload
    },
  },
})
export const { setChainId } = chainIdSlice.actions

export default chainIdSlice.reducer
