import React, { useContext, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { transform } from '../../factory/bigNumber'
import coin from '../../img/coin.svg'
// @ts-ignore
import Checkbox from 'react-custom-checkbox'
import { useDispatch, useSelector } from 'react-redux'
import cEthAbi from '../../contracts/cEthAbi.abi'
import cErcAbi from '../../contracts/cErcAbi.abi'
import { toBn } from 'evm-bn'
import { Web3Context } from '../../context'
import checkBoxIcon from '../../img/CheckBoxTrue.svg'
import {
  deleteNotifications,
  setNotifications,
} from '../../redux/notifications'
import { borrowNotification } from '../../utils/notificationTexts'
import refetch from '../../utils/Refetch'
import {
  commify,
  positionHealthColor,
  sortRewordsAssets,
  totalIncentives,
} from '../../utils'
import { Tooltip } from 'antd'
import usePositionHealth from 'src/hooks/usePositionHealth'
import uniqid from 'uniqid'
import bigDecimal from 'js-big-decimal'

const styles = createUseStyles({
  inputWrapper: {
    backgroundColor: '#1F1F2D',
    borderRadius: 12,
    width: '100%',
    marginBottom: 15,
  },
  inputDescription: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputDescriptionText: {
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    margin: '10px 16px 3px',
  },
  inputPlaceholderText: {
    whiteSpace: 'nowrap',
    width: '100%',
    backgroundColor: '#1F1F2D',
    border: 'none',
    lineHeight: '24px',
    fontSize: '16px',
    outline: 'none',
    fontWeight: 400,
    color: '#7D7E83',
    margin: '0 0 10px 16px',
  },
  inputText: {
    extend: 'inputPlaceholderText',
    cursor: 'text',
    color: '#FFFFFF',
  },
  borrowInfoWrapper: {
    paddingTop: 20,
    margin: [0, 16],
    cursor: 'default',
  },
  cardContentMainText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
  },
  borrowInfoBlock: {
    display: 'flex',
    paddingTop: 10,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    gap: 4,
  },
  borrowSubText: {
    color: '#A8A8B2',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
  },
  borrowMainText: {
    extend: 'borrowSubText',
    color: '#FFFFFF',
    fontWeight: 600,
  },
  borrowInformationBlock: {
    paddingTop: 30,
    margin: [0, 16],
  },
  borrowInformationText: {
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
  },
  borrowInformationTextYellow: {
    color: '#FBC928',
  },
  borrowInformationCheckBoxWrapper: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 20,
    alignItems: 'center',
    gap: 10,
  },
  borrowInformationCheckBoxText: {
    color: '#5493F7',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
  },
  borrowInformationCheckBox: {
    width: 21,
    height: 21,
  },
  red: {
    width: 4,
    height: 12,
    backgroundColor: '#E2006E',
    borderRadius: '52px',
    marginRight: 8,
  },
  healthWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  hr: {
    borderBottom: 'none',
    margin: '8px 16px',
  },
  incomeInfoWrapper: {},
  incomeInfoText: {
    fontWeight: 500,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    marginLeft: 16,
  },
  incomeInfoPercentages: {
    fontWeight: 600,
    color: '#FFFFFF',
    fontSize: '14px',
    lineHeight: '21px',
    marginRight: 16,
  },
  depositSelectWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  depositSelectButton: {
    fontFamily: 'Poppins',
    cursor: 'pointer',
    backgroundColor: '#1F1F2D',
    width: 52,
    height: 22,
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 42,
  },
  depositButton: {
    cursor: 'pointer',
    backgroundColor: '#5493F7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0',
    width: '100%',
    border: 'none',
    borderRadius: 12,
  },
  depositButtonDisable: {
    extend: 'depositButton',
    backgroundColor: '#44445B',
    cursor: 'not-allowed',
  },
  approveDepositButton: {
    extend: 'depositButton',
    backgroundColor: '#4B567D',
  },
  depositButtonText: {
    fontFamily: 'Poppins',
    paddingLeft: 10,
    lineHeight: '27px',
    fontSize: '18px',
    fontWeight: 600,
    color: '#FFFFFF',
  },
  inputDescriptionWrapper: {
    width: '100%',
    padding: [20, 0],
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    cursor: 'default',
  },
  tooltipWithRewards: {
    minWidth: 165,
    width: 'fit-content',
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipWithRewardsLong: {
    extend: 'tooltipWithRewards',
    minWidth: '165px',
    width: '100%',
  },
  tooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '4px',
    gap: 10,
  },
  tooltipItemText: {
    color: '#A8A8B2',
    fontWeight: 400,
    fontSize: '14px',
  },
  tooltipItemTextLong: {
    extend: 'tooltipItemText',
    marginRight: '10px',
  },
  tooltipItemNumber: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: '14px',
  },
  tooltipItemTextSign: {
    color: '#5493F7',
    fontWeight: 500,
    marginRight: '4px',
  },
  tooltipLine: {
    width: '100%',
    height: '1px',
    borderBottom: 'none',
    border: 'none',
    backgroundColor: '#4B567D',
    marginTop: '8px',
    marginBottom: '8px',
  },
  tooltipItemTotal: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewards: {
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipItemWithoutRewards: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewardsLong: {
    extend: 'tooltipWithoutRewards',
    // minWidth: '165px',
    // width: '100%',
  },

  lastTooltipItem: {
    extend: 'tooltipItem',
    marginBottom: '0px',
  },
  lastTooltipItemLong: {
    extend: 'tooltipItemLong',
    marginBottom: '0px',
  },
  noTexttooltipItemWithoutRewards: {
    extend: 'tooltipItemWithoutRewards',
    justifyContent: 'center',
  },
  '@media (max-width: 1250px)': {
    supply: {
      width: '300px',
      height: '100%',
    },
    activeTabText: {
      fontSize: '18px',
      lineHeight: '27px',
    },
    tabText: {
      fontSize: '18px',
      lineHeight: '27px',
    },
    inputPlaceholderText: {
      width: 115,
    },
  },
  '@media (max-width: 730px)': {
    supply: {
      width: '300px',
    },
    inputPlaceholderText: {
      width: 115,
    },
  },
})

const Borrow = () => {
  const [inputValue, setInputValue] = useState('')
  const [marketContract, setMarketContract] = useState(null)
  const [checkboxValue, setCheckboxValue] = useState(false)
  const { web3 } = useContext(Web3Context)

  const { userBalances } = useSelector(
    (state: any) => state.userBalancesReducer
  )
  const { data } = useSelector((state: any) => state.userAssetsReducer)
  const { user } = useSelector((state: any) => state.userReducer)
  const { asset } = useSelector((state: any) => state.assetReducer)
  const { percentage, coefficient, moreThen80Present } = usePositionHealth(
    'borrow',
    inputValue,
    userBalances.totalBorrowed,
    userBalances.totalCollateral,
    asset.lastPrice
  )

  const dispatch = useDispatch()
  const usdLiquidity = asset.liquidity * asset.lastPrice
  const linkLiquidity = asset.liquidity

  const calculateAvailableToBorrow =
    userBalances.availableToBorrow / asset.lastPrice
  const linkAvailableToBorrowAsset =
    linkLiquidity > calculateAvailableToBorrow
      ? calculateAvailableToBorrow
      : linkLiquidity

  const usdAvailableToBorrowAsset =
    usdLiquidity > userBalances.availableToBorrow
      ? userBalances.availableToBorrow
      : usdLiquidity

  useEffect(() => {
    if (!asset._id) return

    defineContracts(asset, !asset.tokenAddress)
  }, [asset])

  useEffect(() => {
    if (!data.supplied.length) return
    setCheckboxValue(false)
    setInputValue('')
  }, [asset, data.supplied])

  useEffect(() => {
    if (!data.supplied.length) return
    setCheckboxValue(false)
  }, [moreThen80Present, inputValue])

  const defineContracts = async (asset: any, isMainToken: boolean) => {
    const marketContract = new web3.eth.Contract(
      isMainToken ? cEthAbi : cErcAbi,
      asset.oTokenAddress
    )
    setMarketContract(marketContract)
  }

  const zeroInInput = !(inputValue.replace(/[\s,]/g, '') == 0)

  const disabled =
    !!inputValue &&
    (!moreThen80Present || checkboxValue) &&
    !(+inputValue.replace(/[\s,]/g, '') > +linkAvailableToBorrowAsset) &&
    +inputValue.replace(/[\s,]/g, '') !== 0 &&
    !(
      +inputValue.replace(/[\s,]/g, '') >=
      linkAvailableToBorrowAsset - linkAvailableToBorrowAsset * 0.05
    )

  const currentUserAsset = data.borrowed.find(
    (el: any) => el.token._id === asset._id
  )

  const borrow = async (amount: string) => {
    const transactionsId = uniqid()

    setInputValue('')
    dispatch(
      setNotifications({
        type: 'info',
        text: borrowNotification.textInfoBorrow,
        link: null,
      })
    )
    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await marketContract.methods
        .borrow(toBn(`${amount}`, asset.tokenDecimal).toString())
        .estimateGas({
          from: user.address,
        })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      const result = await marketContract.methods
        .borrow(toBn(amount, asset.tokenDecimal).toString())
        .send({
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
          from: user.address,
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: borrowNotification.textSubmittedBorrow,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      setTimeout(() => {
        refetch(dispatch, user.address, asset)
      }, 5000)
      dispatch(
        setNotifications({
          type: 'success',
          text: borrowNotification.textSuccessBorrow,
          link: result.transactionHash,
        })
      )
      console.log('borrow', result)
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: borrowNotification.textErrorBorrow,
          link: null,
        })
      )
      console.log('error', error)
    }
  }

  const filteredAssetIncentives = asset?.incentives?.filter(
    (el: any) => el.borrowApy > 0
  )

  const apy = () => {
    const totalBorrow =
      totalIncentives(filteredAssetIncentives, 'borrow') -
      parseFloat(asset.borrowRate)
    if (totalBorrow > 1000) {
      return '+ <1000'
    }
    if (totalBorrow > 0) {
      return '+' + transform(totalBorrow.toString(), 2)
    }
    return transform(totalBorrow.toString(), 2)
  }

  const classes = styles()

  const enterPersentInInput = (persent: number) => {
    setInputValue(
      commify(
        transform(
          bigDecimal.multiply(linkAvailableToBorrowAsset, persent),
          asset.tokenDecimal
        )
      )
    )
  }
  return (
    <>
      <div className={classes.inputWrapper}>
        <div className={classes.inputDescription}>
          <div className={classes.inputDescriptionText}>Borrow</div>
        </div>
        <div className={classes.inputDescription}>
          <input
            type="text"
            className={classes.inputText}
            placeholder={'Enter the amount'}
            style={{ outline: 'none' }}
            value={inputValue}
            onChange={(e) =>
              setInputValue(
                `${commify(
                  `${e.target.value
                    .replace(/[^0-9.]/g, '')
                    .replace(/(\..*)\./g, '$1')}`
                )}`
              )
            }
          />
        </div>
      </div>
      <div className={classes.depositSelectWrapper}>
        <div
          className={classes.depositSelectButton}
          onClick={() => enterPersentInInput(0.25)}
        >
          25%
        </div>
        <div
          className={classes.depositSelectButton}
          onClick={() => enterPersentInInput(0.5)}
        >
          50%
        </div>
        <div
          className={classes.depositSelectButton}
          onClick={() => enterPersentInInput(0.75)}
        >
          75%
        </div>
        {/* <div
          className={classes.depositSelectButton}
          onClick={() => enterPersentInInput(1)}
        >
          MAX
        </div> */}
      </div>
      <div className={classes.borrowInfoWrapper}>
        <div className={classes.borrowInfoBlock}>
          <span className={classes.borrowSubText}>
            Possible to Borrow ({asset.symbol}):
          </span>
          <span className={classes.borrowMainText}>
            <Tooltip
              overlayInnerStyle={{ boxShadow: 'none' }}
              color="none"
              placement="top"
              title={
                <div className={classes.tooltipWithoutRewards}>
                  <div className={classes.noTexttooltipItemWithoutRewards}>
                    {/* <span className={classes.tooltipItemText}>Balance</span> */}
                    <span>
                      {commify(transform(linkAvailableToBorrowAsset, 6))}
                    </span>
                  </div>
                </div>
              }
            >
              {transform(linkAvailableToBorrowAsset)}
            </Tooltip>
          </span>
        </div>
        <div className={classes.borrowInfoBlock}>
          <span className={classes.borrowSubText}>
            Possible to Borrow (USD):
          </span>
          <span className={classes.borrowMainText}>
            <Tooltip
              overlayInnerStyle={{ boxShadow: 'none' }}
              color="none"
              placement="top"
              title={
                <div className={classes.tooltipWithoutRewards}>
                  <div className={classes.noTexttooltipItemWithoutRewards}>
                    {/* <span className={classes.tooltipItemText}>Balance</span> */}
                    <span>
                      $
                      {commify(
                        transform(usdAvailableToBorrowAsset.toString(), 6)
                      )}
                    </span>
                  </div>
                </div>
              }
            >
              ${transform(usdAvailableToBorrowAsset, 2)}
            </Tooltip>
          </span>
        </div>
        <div className={classes.borrowInfoBlock}>
          <span className={classes.borrowSubText}>
            Currently Borrowed ({asset.symbol}):
          </span>
          <span className={classes.borrowMainText}>
            <Tooltip
              overlayInnerStyle={{ boxShadow: 'none' }}
              color="none"
              placement="top"
              title={
                <div className={classes.tooltipWithoutRewards}>
                  <div className={classes.noTexttooltipItemWithoutRewards}>
                    {/* <span className={classes.tooltipItemText}>Balance</span> */}
                    <span>
                      {currentUserAsset
                        ? commify(transform(currentUserAsset.value, 6))
                        : '0'}
                    </span>
                  </div>
                </div>
              }
            >
              {currentUserAsset ? transform(currentUserAsset.value) : '0'}
            </Tooltip>
          </span>
        </div>
        <div className={classes.borrowInfoBlock}>
          <div className={classes.healthWrapper}>
            <div
              className={classes.red}
              style={{
                backgroundColor: positionHealthColor(percentage),
              }}
            ></div>
            <span className={classes.borrowSubText}>
              Health after Borrowing:
            </span>
          </div>
          {inputValue && zeroInInput ? (
            <span className={classes.borrowMainText}>
              {`${coefficient} | ${percentage}%`}
            </span>
          ) : (
            <span className={classes.borrowMainText}>N/A</span>
          )}
        </div>
      </div>
      {moreThen80Present && inputValue && zeroInInput ? (
        <div className={classes.borrowInformationBlock}>
          <span className={classes.borrowInformationText}>
            Please be{' '}
            <span className={classes.borrowInformationTextYellow}>
              very careful
            </span>{' '}
            about borrowing more than 80% of your collateral value. By doing so
            you run the{' '}
            <span className={classes.borrowInformationTextYellow}>
              significant risk
            </span>{' '}
            of being liquidated.
          </span>
          {!(
            +inputValue.replace(/[\s,]/g, '') >
            linkAvailableToBorrowAsset - linkAvailableToBorrowAsset * 0.05
          ) && (
            <div className={classes.borrowInformationCheckBoxWrapper}>
              <Checkbox
                onChange={() => setCheckboxValue(!checkboxValue)}
                borderColor="#5493F7"
                checked={checkboxValue}
                icon={<img src={checkBoxIcon} alt="" />}
              />
              <div className={classes.borrowInformationCheckBoxText}>
                I know what I’m doing
              </div>
            </div>
          )}
        </div>
      ) : null}
      <div>
        <div className={classes.incomeInfoWrapper}>
          <hr className={classes.hr} />
          <div className={classes.inputDescriptionWrapper}>
            <div className={classes.inputDescription}>
              <div className={classes.incomeInfoText}>Borrow APY</div>
              <div className={classes.incomeInfoPercentages}>
                <Tooltip
                  overlayInnerStyle={{ boxShadow: 'none' }}
                  color="none"
                  className={classes.cardContentMainText}
                  title={
                    !asset.incentives ||
                    filteredAssetIncentives?.length === 0 ? (
                      <div className={classes.tooltipWithoutRewards}>
                        <div
                          className={classes.noTexttooltipItemWithoutRewards}
                        >
                          {/* <span className={classes.tooltipItemText}>Balance</span> */}
                          <span>-{transform(asset.borrowRate, 2)}%</span>
                        </div>
                      </div>
                    ) : (
                      <div className={classes.tooltipWithRewards}>
                        <div className={classes.tooltipItem}>
                          <span className={classes.tooltipItemText}>
                            Borrow APY
                          </span>
                          <span className={classes.tooltipItemNumber}>
                            -{transform(asset.borrowRate, 2)}%
                          </span>
                        </div>
                        {sortRewordsAssets(asset.incentives, 'symbol').map(
                          (it: any, index: any) => (
                            <div key={asset._id ? asset._id : index}>
                              {transform(it.borrowApy, 2) === '0' ? (
                                <></>
                              ) : (
                                <div className={classes.tooltipItem}>
                                  <span className={classes.tooltipItemText}>
                                    <span
                                      className={classes.tooltipItemTextSign}
                                    >
                                      +
                                    </span>
                                    {it.symbol}
                                    <span>&nbsp;</span>Rewards
                                  </span>
                                  <span className={classes.tooltipItemNumber}>
                                    {transform(it.borrowApy, 2)}%
                                  </span>
                                </div>
                              )}
                            </div>
                          )
                        )}
                        <hr className={classes.tooltipLine} />
                        <div className={classes.tooltipItemTotal}>
                          <span className={classes.tooltipItemText}>
                            Total APY
                          </span>
                          <span className={classes.tooltipItemNumber}>
                            {transform(
                              (
                                totalIncentives(asset.incentives, 'borrow') -
                                parseFloat(asset.borrowRate)
                              ).toString(),
                              2
                            ) > '0'
                              ? '+' +
                                transform(
                                  (
                                    totalIncentives(
                                      asset.incentives,
                                      'borrow'
                                    ) - parseFloat(asset.borrowRate)
                                  ).toString(),
                                  2
                                )
                              : transform(
                                  (
                                    totalIncentives(
                                      asset.incentives,
                                      'borrow'
                                    ) - parseFloat(asset.borrowRate)
                                  ).toString(),
                                  2
                                )}
                            %
                          </span>
                        </div>
                      </div>
                    )
                  }
                >
                  {asset?.incentives && filteredAssetIncentives.length > 0
                    ? apy()
                    : -transform(asset.borrowRate, 2)}
                  %
                </Tooltip>
              </div>
            </div>
            {/*<div className={classes.inputDescription}>*/}
            {/*  <div className={classes.incomeInfoText}>Distribution APY</div>*/}
            {/*  <div className={classes.incomeInfoPercentages}>5.15%</div>*/}
            {/*</div>*/}
          </div>
        </div>
        <button
          disabled={!disabled}
          className={
            disabled ? classes.depositButton : classes.depositButtonDisable
          }
          onClick={() => borrow(inputValue.replace(/[\s,]/g, ''))}
        >
          <img src={coin} />
          <span className={classes.depositButtonText}>BORROW</span>
        </button>
      </div>
    </>
  )
}

export default Borrow
