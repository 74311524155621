import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import check from '../../img/SwitchCheked.svg'
import uncheck from '../../img/Unchecked.svg'
import WithdrawButton from '../WithdrawButton/WithdrawButton'
import ReactSwitch from 'react-switch'
import { request } from '../../factory/axios'
import { useDispatch, useSelector } from 'react-redux'
import { setUserAssets } from '../../redux/userAssets'
import { transform } from 'src/factory/bigNumber'
import RepayButton from '../RepayButton/RepayButton'
import CustomScrollWrap from '../CustomScrollWrap'
import { Tooltip } from 'antd'
import onCompositionImg from '../../img/emoji-sad.svg'
import {
  commify,
  numberToStringInput,
  sortRewordsAssets,
  totalIncentives,
} from '../../utils'
import { activeTabs, setTabs } from 'src/redux/tadsController'
import useWindowSize from '../../utils/resizeHook'
import Skeleton from 'react-loading-skeleton'

const styles = createUseStyles({
  supplied: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    paddingTop: 15,
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 10,
    height: 315,
    boxSizing: 'border-box',
    cursor: 'default',
  },
  tabWrapper: {
    display: 'flex',
    justifyContent: 'start',
    gap: 20,
    '-webkit-tap-highlight-color': 'transparent',
  },
  blueHr: {
    width: 20,
    height: 4,
    backgroundColor: '#5493F7',
    borderRadius: 37,
    margin: ' 5px auto 0',
  },
  disabledBlueHr: {
    display: 'none',
  },
  cardContentMainText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
  },
  activeTabText: {
    fontWeight: '600',
    color: '#FFFFFF',
    fontSize: '18px',
    paddingBottom: 20,
    lineHeight: '27px',
    cursor: 'pointer',
  },
  tabText: {
    extend: 'activeTabText',
    color: '#A8A8B2',
  },
  disabledTab: {
    extend: 'activeTabText',
    cursor: 'not-allowed',
    opacity: '0.4',
    color: '#A8A8B2',
  },
  noCompositionsWrapper: {
    display: 'flex',
    height: 140,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 'auto',
  },
  noCompositionsImg: {
    width: 36,
    height: 36,
    paddingBottom: 15,
  },
  noCompositions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noCompositionsMainText: {
    color: '#ffffff',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  noCompositionsSubText: {
    extend: 'noCompositionsMainText',
    fontWeight: 400,
    color: '#A8A8B2',
  },
  mobileWrapper: {
    display: 'none',
  },
  infoText: {
    textAlign: 'left',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
    width: 130,
    paddingBottom: 4,
  },
  infoTextLast: {
    extend: 'infoText',
    width: 144,
  },
  borrowedInfoTextLast: {
    extend: 'infoText',
    width: 344,
  },
  mainText: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
    cursor: 'default',
  },
  emptyBlock: {
    extend: 'infoText',
    height: 21,
  },
  tableWrapper: {
    width: '90%',
    display: 'table',
  },
  borrowedTableWrapper: {
    width: '100%',
    display: 'table',
  },
  tbody: {
    height: 138,
    display: 'block',
  },
  th: {
    textAlign: 'left',
  },
  tdLast: {
    width: 190,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 8,
    paddingBottom: 20,
  },
  switchChecked: {
    width: '100%',
    height: '100%',
  },
  suppliedWrapper: {
    width: '100%',
    '&:after': {
      content: "''",
      position: 'absolute',
      zIndex: 1,
      bottom: 0,
      left: 0,
      pointerEvents: 'none',
      backgroundImage:
        'linear-gradient(180deg, rgba(49, 51, 78, 0) 0%, #31334E 100%)',
      height: 62,
      width: '98%',
      borderRadius: [0, 0, 0, 12],
    },
  },
  suppliedWrapperWithoutBlur: {
    width: '100%',
  },
  assetInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    width: 137,
    paddingBottom: 10,
  },
  assetInfoAPY: {
    display: 'flex',
    gap: 5,
    width: 137,
    paddingBottom: 10,
    marginLeft: -20,
  },
  assetInfoSuppliedValue: {
    display: 'flex',
    gap: 5,
    width: 137,
    paddingBottom: 10,
    marginRight: 40,
    marginLeft: -20,
  },
  infoTextAsset: {
    width: 156,
    extend: 'infoText',
  },
  borrowedInfoTextAsset: {
    width: 166,
    extend: 'infoText',
  },
  borrowedInfoTextAPY: {
    width: 165,
    extend: 'infoText',
  },
  infoTextValue: {
    width: 138,
    extend: 'infoText',
  },
  scrollWrapper: {
    display: 'flex',
    gap: 20,
    flexDirection: 'column',
    marginRight: 20,
  },
  infoWrapper: {},
  mobileControlPanel: {},
  mobileCardTitle: {},
  mobileCardWrapper: {},
  mainInfoText: {},
  mobileTextWrapper: {},
  mobileSuppliedWrapper: {
    display: 'none',
  },
  suppliedMobileWrapper: {},
  assetsImageInfoContainer: {
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  assetsImageInfo: {
    cursor: 'default',
    width: '100%',
    objectFit: 'contain',
  },
  switcherTooltip: {},
  disabledSwitcherTooltip: {
    display: 'none',
  },
  tooltipWithRewards: {
    minWidth: 165,
    width: 'fit-content',
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipWithRewardsLong: {
    extend: 'tooltipWithRewards',
    minWidth: '165px',
    width: '100%',
  },
  tooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '4px',
    gap: 10,
  },
  tooltipItemText: {
    color: '#A8A8B2',
    fontWeight: 400,
    fontSize: '14px',
  },
  tooltipItemTextLong: {
    extend: 'tooltipItemText',
    marginRight: '10px',
  },
  tooltipItemNumber: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: '14px',
  },
  tooltipItemTextSign: {
    color: '#5493F7',
    fontWeight: 500,
    marginRight: '4px',
  },
  tooltipLine: {
    width: '100%',
    height: '1px',
    borderBottom: 'none',
    border: 'none',
    backgroundColor: '#4B567D',
    marginTop: '8px',
    marginBottom: '8px',
  },
  tooltipItemTotal: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewards: {
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipItemWithoutRewards: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewardsLong: {
    extend: 'tooltipWithoutRewards',
    // minWidth: '165px',
    // width: '100%',
  },

  lastTooltipItem: {
    extend: 'tooltipItem',
    marginBottom: '0px',
  },
  lastTooltipItemLong: {
    extend: 'tooltipItemLong',
    marginBottom: '0px',
  },
  noTexttooltipItemWithoutRewards: {
    extend: 'tooltipItemWithoutRewards',
    justifyContent: 'center',
  },
  '@media (max-width: 1250px)': {
    supplied: {
      width: '100%',
      marginBottom: 20,
    },
    tabWrapper: {
      gap: 30,
    },
    activeTabText: {
      fontSize: '18px',
      lineHeight: '27px',
    },
    disabledTab: {
      fontSize: '18px',
      lineHeight: '27px',
    },
    tabText: {
      fontSize: '18px',
      lineHeight: '27px',
    },
    assetInfo: {
      width: 125,
    },
    mainText: {
      fontSize: '18px',
      lineHeight: '27px',
    },
    borrowedInfoTextAsset: {
      width: 144,
    },
    borrowedInfoTextAPY: {
      width: 154,
    },
    '@media (max-width: 730px)': {
      supplied: {
        width: '',
        marginBottom: 0,
        height: 'fit-content',
        paddingBottom: 15,
      },
      suppliedMobileWrapper: {
        paddingBottom: 15,
      },
      mobileSuppliedWrapper: {
        display: 'block',
      },
      suppliedWrapper: {
        display: 'none',
      },
      mobileWrapper: {
        display: 'block',
      },
      infoWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 110,
        justifyContent: 'space-between',
        paddingBottom: 5,
      },
      infoText: {
        paddingBottom: 0,
      },
      mobileCardTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
      },
      mobileControlPanel: {
        display: 'flex',
        alignItems: 'center',
        gap: 20,
      },
      mainText: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      mainInfoText: {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '21px',
        color: '#FFFFFF',
      },
      mobileTextWrapper: {
        display: 'flex',
        gap: 5,
      },
      mobileCardWrapper: {
        paddingBottom: 15,
      },
      assetsImageInfoContainer: {
        width: 18,
        height: 18,
      },
    },
  },
})

interface Props {
  setOpenCollateralModal: (a: {
    open: boolean
    token: string
    tokenId: ''
    isEnable: boolean
  }) => void
  setOpenWithdrawModal: (a: { open: boolean; token: string }) => void
  setOpenRepayModal: (a: { open: boolean; token: any }) => void
}

const Supplied: React.FC<Props> = ({
  setOpenCollateralModal,
  setOpenWithdrawModal,
  setOpenRepayModal,
}) => {
  const [blur, setBlur] = useState(false)
  const [fetching, setFetching] = useState(true)

  const { data } = useSelector((state: any) => state.userAssetsReducer)
  const { user } = useSelector((state: any) => state.userReducer)
  const { tabs } = useSelector((state: any) => state.tabsControllerReducer)

  const skeletonsArray = Array(4).fill(1)
  const dispatch = useDispatch()
  const { isTablet } = useWindowSize()
  const classes = styles()

  useEffect(() => {
    request({
      method: 'get',
      path: `assets/${user.address}`,
    })
      .then((res) => dispatch(setUserAssets(res.data.data)))
      .finally(() => {
        setFetching(false)
      })
  }, [])

  useEffect(() => {
    setFetching(true)
    request({
      method: 'get',
      path: `assets/${user.address}`,
    })
      .then((res) => dispatch(setUserAssets(res.data.data)))
      .finally(() => {
        setFetching(false)
      })
  }, [user.address])

  const disableTab = data.borrowed.length > 0

  const handleActiveTab = (activeTab: activeTabs) => () => {
    if (activeTab === activeTabs.borrow && !disableTab) {
      return
    }
    dispatch(setTabs({ suppliedActiveTab: activeTab }))
  }

  const arrToMap =
    tabs.suppliedActiveTab === activeTabs.supply ? data.supplied : data.borrowed

  const checkTab = (tab: string) =>
    tabs.suppliedActiveTab === tab ? classes.activeTabText : classes.tabText
  const checkHr = (tab: string) =>
    tabs.suppliedActiveTab === tab ? classes.blueHr : classes.disabledBlueHr
  const checkDisabled = (item: any) => {
    return Boolean(
      data.borrowed.find((elem: any) => elem.token._id === item.token._id)
    )
  }

  const hideBlur = (e: any) => {
    setBlur(
      e.target.scrollHeight - e.target.offsetHeight - 40 <= e.target.scrollTop
    )
  }

  const filteredIncentives = (asset) => {
    const filteredBorrowAssetIncentives = asset?.incentives?.filter(
      (el: any) => el.borrowApy > 0
    )

    const filteredSupplyAssetIncentives = asset?.incentives?.filter(
      (el: any) => el.supplyApy > 0
    )

    return tabs.suppliedActiveTab === activeTabs.supply
      ? filteredSupplyAssetIncentives
      : filteredBorrowAssetIncentives
  }

  const apy = (asset) => {
    const filteredAssetIncentives = asset.incentives?.filter(
      (el: any) => el.borrowApy > 0
    )

    const totalBorrow =
      totalIncentives(filteredAssetIncentives, 'borrow') - parseFloat(asset.apy)

    if (totalBorrow > 1000) {
      return '+<1000%'
    }
    if (totalBorrow > 0) {
      return '+' + transform(totalBorrow.toString(), 2) + '%'
    }
    return transform(totalBorrow.toString(), 2) + '%'
  }

  return (
    <div className={classes.supplied}>
      <div className={classes.tabWrapper}>
        <div
          className={checkTab(activeTabs.supply)}
          onClick={handleActiveTab(activeTabs.supply)}
        >
          <span>Supplied</span>
          <div className={checkHr(activeTabs.supply)}></div>
        </div>
        <div
          className={
            disableTab ? checkTab(activeTabs.borrow) : classes.disabledTab
          }
          onClick={handleActiveTab(activeTabs.borrow)}
        >
          <span>Borrowed</span>
          <div className={checkHr(activeTabs.borrow)}></div>
        </div>
      </div>
      {data.supplied.length === 0 ? (
        <div className={classes.noCompositionsWrapper}>
          <img
            className={classes.noCompositionsImg}
            src={onCompositionImg}
            alt=""
          />
          <div className={classes.noCompositions}>
            <div className={classes.noCompositionsMainText}>
              There's nothing here yet
            </div>
            <div className={classes.noCompositionsSubText}>
              You haven't supplied anything yet
            </div>
          </div>
        </div>
      ) : isTablet ? (
        <table
          className={
            tabs.suppliedActiveTab === 'borrow'
              ? data.borrowed.length <= 4 || blur
                ? classes.suppliedWrapperWithoutBlur
                : classes.suppliedWrapper
              : data.supplied.length <= 4 || blur
              ? classes.suppliedWrapperWithoutBlur
              : classes.suppliedWrapper
          }
        >
          <thead
            className={
              tabs.suppliedActiveTab === activeTabs.supply
                ? classes.tableWrapper
                : classes.borrowedTableWrapper
            }
          >
            <tr>
              <th
                align="left"
                className={
                  tabs.suppliedActiveTab === activeTabs.supply
                    ? classes.infoTextAsset
                    : classes.borrowedInfoTextAsset
                }
              >
                Asset
              </th>
              {tabs.suppliedActiveTab === activeTabs.supply && (
                <th align="left" className={classes.infoText}>
                  Collateral
                </th>
              )}
              <th
                align="left"
                className={
                  tabs.suppliedActiveTab === activeTabs.supply
                    ? classes.infoText
                    : classes.borrowedInfoTextAPY
                }
              >
                Net APY
              </th>
              {tabs.suppliedActiveTab === activeTabs.supply ? (
                <th align="left" className={classes.infoTextValue}>
                  Supplied Value
                </th>
              ) : (
                <th align="left" className={classes.infoText}>
                  Borrowed Value
                </th>
              )}
              <th
                align="left"
                className={
                  tabs.suppliedActiveTab === activeTabs.supply
                    ? classes.infoTextLast
                    : classes.borrowedInfoTextLast
                }
              ></th>
            </tr>
          </thead>
        </table>
      ) : null}
      {isTablet ? (
        <CustomScrollWrap mathod={hideBlur}>
          <table
            className={
              tabs.suppliedActiveTab === 'borrow'
                ? data.borrowed.length <= 4 || blur
                  ? classes.suppliedWrapperWithoutBlur
                  : classes.suppliedWrapper
                : data.supplied.length <= 4 || blur
                ? classes.suppliedWrapperWithoutBlur
                : classes.suppliedWrapper
            }
          >
            <tbody className={classes.tbody}>
              {fetching
                ? skeletonsArray.map((el, index) => (
                    <Skeleton
                      key={index}
                      baseColor="#4B567D"
                      highlightColor="#5B6796"
                      width="650px"
                      height="20px"
                      count={1}
                      borderRadius="32px"
                      style={{ marginBottom: 23 }}
                    />
                  ))
                : arrToMap.map((el: any) => (
                    <tr
                      key={el.token._id}
                      className={
                        tabs.suppliedActiveTab === activeTabs.supply
                          ? classes.tableWrapper
                          : classes.borrowedTableWrapper
                      }
                    >
                      <td className={classes.assetInfo}>
                        <div className={classes.assetsImageInfoContainer}>
                          <img
                            className={classes.assetsImageInfo}
                            src={el.token.image}
                          />
                        </div>

                        <span className={classes.mainText}>
                          {el.token.symbol}
                        </span>
                      </td>
                      {tabs.suppliedActiveTab === activeTabs.supply && (
                        <td>
                          <div className={classes.assetInfo}>
                            <Tooltip
                              placement="top"
                              title={
                                <div className={classes.tooltipWithoutRewards}>
                                  <div
                                    className={
                                      classes.noTexttooltipItemWithoutRewards
                                    }
                                  >
                                    {/* <span className={classes.tooltipItemText}>Balance</span> */}
                                    <span>
                                      You can't exit a market where you have an
                                      outstanding borrow
                                    </span>
                                  </div>
                                </div>
                              }
                              overlayInnerStyle={{ boxShadow: 'none' }}
                              color="none"
                              overlayClassName={
                                checkDisabled(el)
                                  ? classes.switcherTooltip
                                  : classes.disabledSwitcherTooltip
                              }
                            >
                              <div>
                                <ReactSwitch
                                  checked={el.collateral}
                                  height={27}
                                  checkedHandleIcon={
                                    <img
                                      className={classes.switchChecked}
                                      src={check}
                                      alt=""
                                    />
                                  }
                                  uncheckedHandleIcon={
                                    <img
                                      className={classes.switchChecked}
                                      src={uncheck}
                                      alt=""
                                    />
                                  }
                                  uncheckedIcon={false}
                                  offColor="#2A2A36"
                                  borderRadius={87}
                                  checkedIcon={false}
                                  onColor="#2A2A36"
                                  onChange={() =>
                                    setOpenCollateralModal({
                                      open: true,
                                      token: el.token.symbol,
                                      tokenId: el.token._id,
                                      isEnable: el.collateral,
                                    })
                                  }
                                  disabled={checkDisabled(el)}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </td>
                      )}
                      <td>
                        <div className={classes.assetInfoAPY}>
                          <span className={classes.mainText}>
                            <Tooltip
                              overlayInnerStyle={{ boxShadow: 'none' }}
                              color="none"
                              className={classes.cardContentMainText}
                              title={
                                !el.token.incentives ||
                                filteredIncentives(el.token).length === 0 ? (
                                  <div
                                    className={classes.tooltipWithoutRewards}
                                  >
                                    <div
                                      className={
                                        classes.noTexttooltipItemWithoutRewards
                                      }
                                    >
                                      {/* <span className={classes.tooltipItemText}>Balance</span> */}
                                      <span>
                                        {tabs.suppliedActiveTab ===
                                        activeTabs.supply
                                          ? '+'
                                          : '-'}
                                        {transform(el.token.apy, 2)}%
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className={classes.tooltipWithRewards}>
                                    {tabs.suppliedActiveTab ===
                                    activeTabs.supply ? (
                                      <div className={classes.tooltipItem}>
                                        <span
                                          className={classes.tooltipItemText}
                                        >
                                          <span
                                            className={
                                              classes.tooltipItemTextSign
                                            }
                                          ></span>
                                          Supply APY
                                        </span>
                                        <span
                                          className={classes.tooltipItemNumber}
                                        >
                                          {transform(el.token.apy, 2)}%
                                        </span>
                                      </div>
                                    ) : (
                                      <div className={classes.tooltipItem}>
                                        <span
                                          className={classes.tooltipItemText}
                                        >
                                          <span
                                            className={
                                              classes.tooltipItemTextSign
                                            }
                                          ></span>
                                          Borrow APY
                                        </span>
                                        <span
                                          className={classes.tooltipItemNumber}
                                        >
                                          -{transform(el.token.apy, 2)}%
                                        </span>
                                      </div>
                                    )}
                                    <div>
                                      {sortRewordsAssets(
                                        filteredIncentives(el.token),
                                        'symbol'
                                      ).map((it: any, index: any) => (
                                        <div
                                          key={
                                            el.token._id ? el.token._id : index
                                          }
                                        >
                                          {transform(it.supplyApy, 2) ===
                                          '0' ? (
                                            <></>
                                          ) : (
                                            <div
                                              className={classes.tooltipItem}
                                            >
                                              <span
                                                className={
                                                  classes.tooltipItemText
                                                }
                                              >
                                                <span
                                                  className={
                                                    classes.tooltipItemTextSign
                                                  }
                                                >
                                                  +
                                                </span>
                                                {it.symbol}
                                                <span>&nbsp;</span>Rewards
                                              </span>
                                              <span
                                                className={
                                                  classes.tooltipItemNumber
                                                }
                                              >
                                                {tabs.suppliedActiveTab ===
                                                activeTabs.supply
                                                  ? transform(it.supplyApy, 2)
                                                  : transform(it.borrowApy, 2)}
                                                %
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                    <hr className={classes.tooltipLine} />
                                    <div className={classes.tooltipItemTotal}>
                                      <span className={classes.tooltipItemText}>
                                        Total APY
                                      </span>
                                      <span
                                        className={classes.tooltipItemNumber}
                                      >
                                        {tabs.suppliedActiveTab ===
                                        activeTabs.supply ? (
                                          '+' +
                                          transform(
                                            (
                                              totalIncentives(
                                                el.token.incentives,
                                                'supply'
                                              ) + parseFloat(el.token.apy)
                                            ).toString(),
                                            2
                                          ) +
                                          '%'
                                        ) : (
                                          <span>
                                            {transform(
                                              (
                                                totalIncentives(
                                                  el.token.incentives,
                                                  'borrow'
                                                ) - parseFloat(el.token.apy)
                                              ).toString(),
                                              2
                                            ) > '0'
                                              ? '+' +
                                                transform(
                                                  (
                                                    totalIncentives(
                                                      el.token.incentives,
                                                      'borrow'
                                                    ) - parseFloat(el.token.apy)
                                                  ).toString(),
                                                  2
                                                ) +
                                                '%'
                                              : transform(
                                                  (
                                                    totalIncentives(
                                                      el.token.incentives,
                                                      'borrow'
                                                    ) - parseFloat(el.token.apy)
                                                  ).toString(),
                                                  2
                                                ) + '%'}
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                )
                              }
                            >
                              {' '}
                              <span className={classes.mainText}>
                                {el?.token?.incentives ? (
                                  tabs.suppliedActiveTab ===
                                  activeTabs.supply ? (
                                    <span>
                                      {el?.token.incentives?.length
                                        ? '+' +
                                          (totalIncentives(
                                            el.token.incentives,
                                            'supply'
                                          ) +
                                            parseFloat(el.token.apy) >
                                          1000
                                            ? '<1000'
                                            : transform(
                                                (
                                                  totalIncentives(
                                                    el.token.incentives,
                                                    'supply'
                                                  ) + parseFloat(el.token.apy)
                                                ).toString(),
                                                2
                                              ))
                                        : '+' + transform(el.token.apy, 2)}
                                      %
                                    </span>
                                  ) : (
                                    <span>{apy(el.token)}</span>
                                  )
                                ) : (
                                  <span>
                                    {tabs.suppliedActiveTab ===
                                    activeTabs.supply
                                      ? '+'
                                      : '-'}
                                    {transform(el.token.apy, 2) + '%'}
                                  </span>
                                )}
                              </span>
                            </Tooltip>
                            {/*<Tooltip*/}
                            {/*  overlayInnerStyle={{ boxShadow: 'none' }}*/}
                            {/*  color="none"*/}
                            {/*  placement="top"*/}
                            {/*  title={*/}
                            {/*    <div className={classes.tooltipWithoutRewards}>*/}
                            {/*      <div*/}
                            {/*        className={*/}
                            {/*          classes.noTexttooltipItemWithoutRewards*/}
                            {/*        }*/}
                            {/*      >*/}
                            {/*        /!* <span className={classes.tooltipItemText}>Balance</span> *!/*/}
                            {/*        <span>{transform(el.token.apy, 6)}%</span>*/}
                            {/*      </div>*/}
                            {/*    </div>*/}
                            {/*  }*/}
                            {/*>*/}
                            {/*  {transform(el.token.apy, 2)}%*/}
                            {/*</Tooltip>*/}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className={classes.assetInfoSuppliedValue}>
                          <span className={classes.mainText}>
                            <Tooltip
                              overlayInnerStyle={{ boxShadow: 'none' }}
                              color="none"
                              placement="top"
                              title={
                                <div className={classes.tooltipWithoutRewards}>
                                  <div
                                    className={
                                      classes.noTexttooltipItemWithoutRewards
                                    }
                                  >
                                    {/* <span className={classes.tooltipItemText}>Balance</span> */}
                                    {el.value}
                                  </div>
                                </div>
                              }
                            >
                              {transform(el.value)}
                            </Tooltip>
                          </span>
                        </div>
                      </td>
                      <td
                        className={
                          tabs.suppliedActiveTab === activeTabs.supply
                            ? ''
                            : classes.tdLast
                        }
                      >
                        <div className={classes.assetInfo}>
                          {tabs.suppliedActiveTab === activeTabs.supply ? (
                            <WithdrawButton
                              onClick={() =>
                                setOpenWithdrawModal({
                                  open: true,
                                  token: el,
                                })
                              }
                            />
                          ) : (
                            <>
                              <RepayButton
                                onClick={() =>
                                  setOpenRepayModal({
                                    open: true,
                                    token: el,
                                  })
                                }
                              />
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </CustomScrollWrap>
      ) : null}
      <div className={classes.mobileSuppliedWrapper}>
        {data.supplied.length === 0 ? null : (
          <div className={classes.infoWrapper}>
            <div className={classes.infoText}>Asset</div>
            {tabs.suppliedActiveTab === activeTabs.supply && (
              <div className={classes.infoText}>Collateral</div>
            )}
          </div>
        )}
        <div className={classes.mobileCardWrapper}>
          {fetching
            ? skeletonsArray.map((el, index) => (
                <Skeleton
                  key={index}
                  baseColor="#4B567D"
                  highlightColor="#5B6796"
                  width="300px"
                  height="40px"
                  count={1}
                  borderRadius="32px"
                  style={{ marginBottom: 23 }}
                />
              ))
            : arrToMap.map((el: any) => (
                <div
                  key={el.token._id}
                  className={classes.suppliedMobileWrapper}
                >
                  <div className={classes.infoWrapper}>
                    <div className={classes.mobileCardTitle}>
                      <div className={classes.assetsImageInfoContainer}>
                        <img
                          className={classes.assetsImageInfo}
                          src={el.token.image}
                          alt=""
                        />
                      </div>
                      <span className={classes.mainText}>
                        {el.token.symbol}
                      </span>
                    </div>
                    <div className={classes.mobileControlPanel}>
                      {tabs.suppliedActiveTab === activeTabs.supply ? (
                        <>
                          <Tooltip
                            overlayInnerStyle={{ boxShadow: 'none' }}
                            color="none"
                            placement="top"
                            title={
                              <div className={classes.tooltipWithoutRewards}>
                                <div
                                  className={
                                    classes.noTexttooltipItemWithoutRewards
                                  }
                                >
                                  {/* <span className={classes.tooltipItemText}>Balance</span> */}
                                  <span>
                                    You can't exit a market where you have an
                                    outstanding borrow
                                  </span>
                                </div>
                              </div>
                            }
                            overlayClassName={
                              checkDisabled(el)
                                ? classes.switcherTooltip
                                : classes.disabledSwitcherTooltip
                            }
                          >
                            <div>
                              <ReactSwitch
                                checked={el.collateral}
                                height={24}
                                checkedHandleIcon={
                                  <img
                                    className={classes.switchChecked}
                                    src={check}
                                    alt=""
                                  />
                                }
                                uncheckedHandleIcon={
                                  <img
                                    className={classes.switchChecked}
                                    src={uncheck}
                                    alt=""
                                  />
                                }
                                uncheckedIcon={false}
                                offColor="#2A2A36"
                                borderRadius={87}
                                checkedIcon={false}
                                onColor="#2A2A36"
                                onChange={() =>
                                  setOpenCollateralModal({
                                    open: true,
                                    token: el.token.symbol,
                                    tokenId: el.token._id,
                                    isEnable: el.collateral,
                                  })
                                }
                                disabled={checkDisabled(el)}
                              />
                            </div>
                          </Tooltip>
                          <WithdrawButton
                            onClick={() =>
                              setOpenWithdrawModal({
                                open: true,
                                token: el,
                              })
                            }
                          />
                        </>
                      ) : (
                        <>
                          <RepayButton
                            onClick={() =>
                              setOpenRepayModal({
                                open: true,
                                token: el,
                              })
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={classes.mobileTextWrapper}>
                      <span className={classes.infoText}>Net APY</span>
                      <span className={classes.mainInfoText}>
                        <Tooltip
                          overlayInnerStyle={{ boxShadow: 'none' }}
                          color="none"
                          className={classes.cardContentMainText}
                          title={
                            !el.token.incentives ||
                            filteredIncentives(el.token).length === 0 ? (
                              <div className={classes.tooltipWithoutRewards}>
                                <div
                                  className={
                                    classes.noTexttooltipItemWithoutRewards
                                  }
                                >
                                  {/* <span className={classes.tooltipItemText}>Balance</span> */}
                                  <span>
                                    {tabs.suppliedActiveTab ===
                                    activeTabs.supply
                                      ? '+ '
                                      : '-'}
                                    {transform(el.token.apy, 2)}%
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className={classes.tooltipWithRewards}>
                                {tabs.suppliedActiveTab ===
                                activeTabs.supply ? (
                                  <div className={classes.tooltipItem}>
                                    <span className={classes.tooltipItemText}>
                                      <span
                                        className={classes.tooltipItemTextSign}
                                      ></span>
                                      Supply APY
                                    </span>
                                    <span className={classes.tooltipItemNumber}>
                                      {transform(el.token.apy, 2)}%
                                    </span>
                                  </div>
                                ) : (
                                  <div className={classes.tooltipItem}>
                                    <span className={classes.tooltipItemText}>
                                      <span
                                        className={classes.tooltipItemTextSign}
                                      ></span>
                                      Borrow APY
                                    </span>
                                    <span className={classes.tooltipItemNumber}>
                                      -{transform(el.token.apy, 2)}%
                                    </span>
                                  </div>
                                )}
                                <div>
                                  {el.token.incentives.map(
                                    (it: any, index: any) => (
                                      <div
                                        key={
                                          el.token._id ? el.token._id : index
                                        }
                                      >
                                        {transform(it.supplyApy, 2) === '0' ? (
                                          <></>
                                        ) : (
                                          <div className={classes.tooltipItem}>
                                            <span
                                              className={
                                                classes.tooltipItemText
                                              }
                                            >
                                              <span
                                                className={
                                                  classes.tooltipItemTextSign
                                                }
                                              >
                                                +
                                              </span>
                                              {it.symbol}
                                              <span>&nbsp;</span>Rewards
                                            </span>
                                            <span
                                              className={
                                                classes.tooltipItemNumber
                                              }
                                            >
                                              {tabs.suppliedActiveTab ===
                                              activeTabs.supply
                                                ? transform(it.supplyApy, 2)
                                                : transform(it.borrowApy, 2)}
                                              %
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                                <hr className={classes.tooltipLine} />
                                <div className={classes.tooltipItemTotal}>
                                  <span className={classes.tooltipItemText}>
                                    Total APY
                                  </span>
                                  <span className={classes.tooltipItemNumber}>
                                    {tabs.suppliedActiveTab ===
                                    activeTabs.supply ? (
                                      '+' +
                                      transform(
                                        (
                                          totalIncentives(
                                            el.token.incentives,
                                            'supply'
                                          ) + parseFloat(el.token.apy)
                                        ).toString(),
                                        2
                                      ) +
                                      '%'
                                    ) : (
                                      <span>
                                        {transform(
                                          (
                                            totalIncentives(
                                              el.token.incentives,
                                              'borrow'
                                            ) - parseFloat(el.token.apy)
                                          ).toString(),
                                          2
                                        ) > '0'
                                          ? '+' +
                                            transform(
                                              (
                                                totalIncentives(
                                                  el.token.incentives,
                                                  'borrow'
                                                ) - parseFloat(el.token.apy)
                                              ).toString(),
                                              2
                                            ) +
                                            '%'
                                          : transform(
                                              (
                                                totalIncentives(
                                                  el.token.incentives,
                                                  'borrow'
                                                ) - parseFloat(el.token.apy)
                                              ).toString(),
                                              2
                                            ) + '%'}
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </div>
                            )
                          }
                        >
                          {' '}
                          <span className={classes.mainInfoText}>
                            {el?.token?.incentives ? (
                              tabs.suppliedActiveTab === activeTabs.supply ? (
                                <span>
                                  {el?.token.incentives?.length
                                    ? '+' +
                                      (totalIncentives(
                                        el.token.incentives,
                                        'supply'
                                      ) +
                                        parseFloat(el.token.apy) >
                                      1000
                                        ? '<1000'
                                        : transform(
                                            (
                                              totalIncentives(
                                                el.token.incentives,
                                                'supply'
                                              ) + parseFloat(el.token.apy)
                                            ).toString(),
                                            2
                                          ))
                                    : '+' + transform(el.token.apy, 2)}
                                  %
                                </span>
                              ) : (
                                <span>{apy(el.token)}</span>
                              )
                            ) : (
                              <span>
                                {tabs.suppliedActiveTab === activeTabs.supply
                                  ? '+'
                                  : '-'}
                                {transform(el.token.apy, 2) + '%'}
                              </span>
                            )}
                          </span>
                        </Tooltip>
                      </span>
                    </div>
                    <div className={classes.mobileTextWrapper}>
                      <span className={classes.infoText}>Supplied Value</span>
                      <span className={classes.mainInfoText}>
                        <Tooltip
                          overlayInnerStyle={{ boxShadow: 'none' }}
                          color="none"
                          placement="top"
                          title={
                            <div className={classes.tooltipWithoutRewards}>
                              <div
                                className={
                                  classes.noTexttooltipItemWithoutRewards
                                }
                              >
                                {/* <span className={classes.tooltipItemText}>Balance</span> */}
                                {el.value}
                              </div>
                            </div>
                          }
                        >
                          {transform(el.value)}
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  )
}

export default Supplied
