import React from 'react'
import { createUseStyles } from 'react-jss'
import coin from '../../img/NFT/coin.svg'
import staking from '../../img/NFT/staking.svg'
import Skeleton from 'react-loading-skeleton'
import { log } from 'util'

const styles = createUseStyles({
  mainBlock: {
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    boxSizing: 'border-box',
    padding: [20, 70, 20, 20],
    display: 'block',
    width: 360,
    height: 247,
    fontFamily: 'Poppins',
    marginBottom: 30,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 30,
  },
  titleText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
  },
  card: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 15,
  },
  iconWrapper: {
    border: '1px solid #3B4363',
    padding: 15,
    borderRadius: 12,
    width: 24,
    height: 24,
  },
  cardWrapper: {
    display: 'flex',
    gap: 30,
    flexDirection: 'column',
  },
  subText: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
  },
  textWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: 5,
  },
  img: {
    width: 24,
    height: 24,
  },
  mainText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
  },
  '@media (max-width: 1250px)': {
    mainBlock: {
      width: '100%',
      marginBottom: 20,
      height: 153,
    },
    cardWrapper: {
      display: 'flex',
      gap: 146,
      flexDirection: 'row',
    },
  },
  '@media (max-width: 730px)': {
    mainBlock: {
      width: '100%',
      marginBottom: 20,
      height: 155,
      paddingRight: 20,
    },
    iconWrapper: {
      display: 'none',
    },
    textWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
    },
    cardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    subText: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#A8A8B2',
    },
    mainText: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#FFFFFF',
    },
  },
})

interface Props {
  stakedInfo: any
}

const ClaimNftRewards: React.FC<Props> = ({ stakedInfo }) => {
  const classes = styles()

  const isMobile = window.innerWidth < 730
  return (
    <div className={classes.mainBlock}>
      <div className={classes.titleWrapper}>
        <div className={classes.titleText}>Staking Overview</div>
      </div>
      <div className={classes.cardWrapper}>
        <div className={classes.card}>
          <div className={classes.iconWrapper}>
            <img className={classes.img} src={staking} alt="" />
          </div>
          <div className={classes.textWrapper}>
            <div className={classes.subText}>Staked/Available</div>
            {!stakedInfo ? (
              <Skeleton
                baseColor="#4B567D"
                highlightColor="#5B6796"
                width="100px"
                height="20px"
                count={1}
                borderRadius="12px"
              />
            ) : (
              <div className={classes.mainText}>
                {stakedInfo?.totalStaked} / {stakedInfo?.totalMinted}
              </div>
            )}
          </div>
        </div>
        <div className={classes.card}>
          <div className={classes.iconWrapper}>
            <img className={classes.img} src={coin} alt="" />
          </div>
          <div className={classes.textWrapper}>
            <div className={classes.subText}>Percent staked</div>
            {!stakedInfo ? (
              <Skeleton
                baseColor="#4B567D"
                highlightColor="#5B6796"
                width="100px"
                height="20px"
                count={1}
                borderRadius="12px"
              />
            ) : (
              <div className={classes.mainText}>
                {stakedInfo?.percentStaked}%
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClaimNftRewards
