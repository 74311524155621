import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface userBalancesState {
  userBalances: {
    totalSupplied: string
    totalBorrowed: string
    availableToBorrow: string
    totalCollateral: string
    positionHealth: {
      coefficient: string
      percentage: string
    }
  }
}

const initialState: userBalancesState = {
  userBalances: {
    totalCollateral: '',
    totalSupplied: '',
    totalBorrowed: '',
    availableToBorrow: '',
    positionHealth: {
      coefficient: '',
      percentage: '',
    },
  },
}

export const userBalancesSlice = createSlice({
  name: 'userBalances',
  initialState,
  reducers: {
    setUserBalances: (state, action: PayloadAction<any>) => {
      state.userBalances = action.payload
    },
  },
})
export const { setUserBalances } = userBalancesSlice.actions

export default userBalancesSlice.reducer
