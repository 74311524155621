import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface userState {
  user: {
    address: null | string
    addressShort: null | string
    chainId: null | string
    connected: boolean
  }
}

const initialState: userState = {
  user: {
    address: null,
    addressShort: null,
    chainId: null,
    connected: false,
  },
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = {
        ...state.user,
        ...action.payload,
      }
    },
  },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
