import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import NotificationsItem from './NotificationsItem'

const styles = createUseStyles({
  modalblock: {
    animation: 'slowVisibility 0.3s ease-in',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '330px',
    position: 'fixed',
    left: 0,
    right: 0,
    top: '75px',
    zIndex: '1000',
    minHeight: '84px',
    maxHeight: '20000px',
    margin: 'auto',
  },
})

const Notifications = () => {
  const { notifications } = useSelector(
    (state: any) => state.notificationsReducer
  )
  const classes = styles()
  return (
    <div className={classes.modalblock}>
      {notifications.map((item: any) => (
        <>
          {notifications.text !== '' ? (
            <NotificationsItem
              key={item.id}
              text={item.text}
              link={item.link}
              explorerUrl={item.explorerUrl}
              type={item.type}
              isPending={item.isPending}
              id={item.id}
              button={item.button}
            />
          ) : null}
        </>
      ))}
    </div>
  )
}

export default Notifications
