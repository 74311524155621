import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { request } from '../../factory/axios'
import { useDispatch, useSelector } from 'react-redux'
import { setMarketOverview } from '../../redux/marketOverview'
import { transform } from '../../factory/bigNumber'
import Skeleton from 'react-loading-skeleton'
import useWindowSize from '../../utils/resizeHook'

const styles = createUseStyles({
  marketOverview: {
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    padding: [15, 109, 20, 20],
    cursor: 'default',
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    color: '#FFFFFF',
    fontSize: 18,
    paddingBottom: 20,
    lineHeight: '27px',
  },
  disconnectedTitle: {
    extend: 'title',
    paddingBottom: 20,
  },
  block: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  disconnectedBlock: {
    extend: 'block',
    paddingRight: 128,
  },
  text: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
    marginBottom: 5,
  },
  number: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    cursor: 'default',
  },
  img: {
    display: 'block',
    marginRight: 8,
    width: 18,
    height: 18,
  },
  wrapper: {
    paddingBottom: 20,
  },
  blockWrapper: {},

  '@media (max-width: 1250px)': {
    marketOverview: {
      padding: [15, 20, 30],
      height: 173,
    },
    block: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    blockWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    disconnectedBlock: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      padding: 0,
    },
    title: {
      fontSize: 18,
      paddingBottom: 20,
      lineHeight: '27px',
    },
    disconnectedTitle: {
      lineHeight: '27px',
      fontSize: 18,
    },
    text: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: 0,
    },
    number: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      alignItems: 'center',
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 10,
    },
    img: {
      width: 18,
      height: 18,
    },
  },
  '@media (max-width: 730px)': {
    marketOverview: {
      width: 300,
    },
  },
})

const MarketOverview = () => {
  const classes = styles()
  const { data } = useSelector((state: any) => state.marketOverviewReducer)
  const dispatch = useDispatch()
  const [fetching, setFetching] = useState(true)

  const { isDesktop: desktop } = useWindowSize()

  useEffect(() => {
    request({
      method: 'get',
      path: 'markets/overview',
    })
      .then((res) => dispatch(setMarketOverview(res.data.data)))
      .finally(() => {
        setFetching(false)
      })
  }, [])

  return (
    <>
      <div className={classes.marketOverview}>
        <div className={classes.title}>Market Overview</div>
        <div className={classes.block}>
          <div>
            <div className={classes.wrapper}>
              <div className={classes.text}>Total Supply</div>
              {fetching ? (
                <Skeleton
                  baseColor="#4B567D"
                  highlightColor="#5B6796"
                  width={desktop ? 180 : 120}
                  count={1}
                  height={desktop ? 30 : 20}
                  borderRadius="9px"
                />
              ) : (
                <div className={classes.number}>
                  ${transform(data.totalSupplyAmount, 2)}
                </div>
              )}
            </div>
            <div className={classes.blockWrapper}>
              <div className={classes.text}>Most Supplied Asset</div>
              {fetching ? (
                <Skeleton
                  baseColor="#4B567D"
                  highlightColor="#5B6796"
                  width={desktop ? 180 : 120}
                  count={1}
                  height={desktop ? 30 : 20}
                  borderRadius="9px"
                />
              ) : (
                <div className={classes.number}>
                  <img
                    className={classes.img}
                    src={data.mostSupply.image}
                    alt={data.mostSupply.symbol}
                  />
                  <span>{data.mostSupply.symbol}</span>
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.wrapper}>
              <div className={classes.text}>Total Borrow</div>
              {fetching ? (
                <Skeleton
                  baseColor="#4B567D"
                  highlightColor="#5B6796"
                  width={desktop ? 180 : 120}
                  count={1}
                  height={desktop ? 30 : 20}
                  borderRadius="9px"
                />
              ) : (
                <div className={classes.number}>
                  ${transform(data.totalBorrowAmount, 2)}
                </div>
              )}
            </div>
            <div className={classes.blockWrapper}>
              <div className={classes.text}>Most Borrowed Asset</div>
              {fetching ? (
                <Skeleton
                  baseColor="#4B567D"
                  highlightColor="#5B6796"
                  width={desktop ? 180 : 120}
                  count={1}
                  height={desktop ? 30 : 20}
                  borderRadius="9px"
                />
              ) : (
                <div className={classes.number}>
                  <img
                    className={classes.img}
                    src={data.mostBorrow.image}
                    alt={data.mostBorrow.symbol}
                  />
                  <span>{data.mostBorrow.symbol}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MarketOverview
