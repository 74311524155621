import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import ButtonIcon from '../../img/document-text.svg'
import closeIcon from '../../img/close-circle.svg'
import { Web3Context } from '../../context'
import comptrollerAbi from '../../contracts/comptrollerAbi.abi'
import { useDispatch, useSelector } from 'react-redux'
import { request } from '../../factory/axios'
import { setUserAssets } from '../../redux/userAssets'
import refetch from '../../utils/Refetch'
import {
  deleteNotifications,
  setNotifications,
} from '../../redux/notifications'
import { collateralNotification } from '../../utils/notificationTexts'
import uniqid from 'uniqid'

const styles = createUseStyles({
  modalBg: {
    width: '100%',
    background: 'rgb(0 0 0 / 70%)',
    height: '100vh',
    position: 'fixed',
    top: 0,
    zIndex: 15,
  },
  collateralModal: {
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    backdropFilter: 'blur(25px)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    padding: [30, 20],
    position: 'absolute',
    marginLeft: 'auto',
    width: 560,
    marginRight: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    borderRadius: '12px',
    cursor: 'default',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '30px',
    color: '#FFFFFF',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  infoText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#A8A8B2',
    paddingBottom: 40,
  },
  bottomText: {
    paddingTop: 20,
  },
  depositButton: {
    backgroundColor: '#5493F7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0',
    width: '320px',
    margin: '0 auto',
    border: 'none',
    borderRadius: 12,
    cursor: 'pointer',
  },
  depositButtonText: {
    fontFamily: 'Poppins',
    paddingLeft: 10,
    lineHeight: '27px',
    fontSize: '18px',
    fontWeight: 600,
    color: '#FFFFFF',
  },
  '@media (max-width: 730px)': {
    collateralModal: {
      width: 300,
    },
    title: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '27px',
    },
    depositButton: {
      width: 300,
    },
  },
})

interface Props {
  openCollateralModal: {
    open: boolean
    token: string
    tokenId: string
    isEnable: boolean
  }
  setOpenCollateralModal: (a: {
    open: boolean
    token: string
    tokenId: string
    isEnable: boolean
  }) => void
}

const CollateralModal: React.FC<Props> = ({
  openCollateralModal,
  setOpenCollateralModal,
}) => {
  const classes = styles()

  const { web3 } = useContext(Web3Context)
  const { user } = useSelector((state: any) => state.userReducer)
  const { data: assets } = useSelector((state: any) => state.assetsReducer)
  const { asset } = useSelector((state: any) => state.assetReducer)

  const dispatch = useDispatch()

  const comptrollerContract = new web3.eth.Contract(
    comptrollerAbi,
    process.env.REACT_APP_X_COMPTROLLER_ADDRESS
  )

  const handleCloseModal = () => {
    const body = document.body
    body.style.height = ''
    body.style.overflowY = ''
    setOpenCollateralModal({ ...openCollateralModal, open: false })
  }

  const getSuppliedAssets = async () => {
    request({
      method: 'get',
      path: `assets/${user.address}`,
    }).then((res) => dispatch(setUserAssets(res.data.data)))
  }

  const getAssetMarketAddress = (assetId: string) => {
    return assets.find((asset: any) => asset._id === assetId).oTokenAddress
  }

  const enableCollateral = async (assetId: string) => {
    const oTokenAddress = getAssetMarketAddress(assetId)
    const transactionsId = uniqid()

    dispatch(
      setNotifications({
        type: 'info',
        text: collateralNotification.textInfoCollateral,
        link: null,
      })
    )
    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await comptrollerContract.methods
        .enterMarkets([oTokenAddress])
        .estimateGas({
          from: user.address,
        })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      const result = await comptrollerContract.methods
        .enterMarkets([oTokenAddress])
        .send({
          from: user.address,
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
        })
        .on('transactionHash', (hash: string) => {
          if (hash) {
            dispatch(
              setNotifications({
                type: 'info',
                text: collateralNotification.textOnCollateral,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
          }
        })
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          text: collateralNotification.textWhenCollateralOn,
          link: result.transactionHash,
        })
      )
      setTimeout(() => {
        refetch(dispatch, user.address, asset)
      }, 5000)

      getSuppliedAssets()
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: collateralNotification.textErrorCollateral,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const disableCollateral = async (assetId: string, token: string) => {
    const oTokenAddress = getAssetMarketAddress(assetId)

    const transactionsId = uniqid()
    dispatch(
      setNotifications({
        type: 'info',
        text: collateralNotification.textInfoCollateral,
        link: null,
      })
    )

    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await comptrollerContract.methods
        .exitMarket(oTokenAddress)
        .estimateGas({
          from: user.address,
        })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      const result = await comptrollerContract.methods
        .exitMarket(oTokenAddress)
        .send({
          from: user.address,
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
        })
        .on('transactionHash', (hash: string) => {
          if (hash) {
            dispatch(
              setNotifications({
                type: 'info',
                text: collateralNotification.textOffCollateral,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
          }
        })
      dispatch(deleteNotifications(transactionsId))
      if (result.events.Failure) {
        dispatch(
          setNotifications({
            type: 'error',
            text: `${collateralNotification.textWhenThereIsAnOutstandingDebt}${token}${collateralNotification.textAsCollateral}`,
            link: null,
          })
        )
      } else {
        dispatch(
          setNotifications({
            type: 'success',
            text: collateralNotification.textWhenCollateralOff,
            link: result.transactionHash,
          })
        )
      }
      dispatch(deleteNotifications(transactionsId))

      setTimeout(() => {
        refetch(dispatch, user.address, asset)
      }, 5000)
      getSuppliedAssets()
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: collateralNotification.textErrorCollateral,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const handleEnableCollateral = (assetId: string) => {
    const body = document.body
    body.style.height = ''
    body.style.overflowY = ''
    enableCollateral(assetId)
    setOpenCollateralModal({ ...openCollateralModal, open: false })
  }

  const handleDisableCollateral = async (assetId: string, token: string) => {
    const body = document.body
    body.style.height = ''
    body.style.overflowY = ''
    disableCollateral(assetId, token)
    setOpenCollateralModal({ ...openCollateralModal, open: false })
  }

  return (
    <div
      className={classes.modalBg}
      onClick={(e) => {
        e.target === e.currentTarget && handleCloseModal()
      }}
    >
      <div className={classes.collateralModal}>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>
            Use your {openCollateralModal.token} as Collateral
          </div>
          <div>
            <img
              className={classes.closeIcon}
              onClick={handleCloseModal}
              src={closeIcon}
              alt=""
            />
          </div>
        </div>
        {!openCollateralModal.isEnable ? (
          <div>
            <div className={classes.infoText}>
              When you enable your {openCollateralModal.token} as collateral,
              you signal to the Orbiter One Protocol that you’d like to not only
              have your {openCollateralModal.token} assets avaliable for
              borrowing from other protocol participants, but that you’d also
              like to use your {openCollateralModal.token} as collateral to
              borrow other assets from the protocol.
              <div className={classes.bottomText}>
                Be cautios, and pay attention to your “Health” - if it goes
                below 1, your assets are subject to liquidation
              </div>
            </div>
            <button
              onClick={() =>
                handleEnableCollateral(openCollateralModal.tokenId)
              }
              className={classes.depositButton}
            >
              <img src={ButtonIcon} />
              <span className={classes.depositButtonText}>
                ENABLE AS COLLATERAL
              </span>
            </button>
          </div>
        ) : (
          <div>
            <div className={classes.infoText}>
              When you stop using your {openCollateralModal.token} as
              collateral, you signal to the Orbiter One Protocol that you'd no
              longer like to have your {openCollateralModal.token} used as
              collateral for borrowing other assets from the protocol.
              <div className={classes.bottomText}>
                Be cautious, and pay attention to your "Health" - if it goes
                below 1, your assets are subject to liquidation.
              </div>
            </div>
            <button
              onClick={() =>
                handleDisableCollateral(
                  openCollateralModal.tokenId,
                  openCollateralModal.token
                )
              }
              className={classes.depositButton}
            >
              <img src={ButtonIcon} />
              <span className={classes.depositButtonText}>
                DISABLE AS COLLATERAL
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default CollateralModal
