import React from 'react'
import { createUseStyles } from 'react-jss'
import needHelpIcon from '../../img/help-circle.svg'

const styles = createUseStyles({
  needHelpButton: {
    background: 'rgba(83, 203, 199, 0.2)',
    border: '1px solid #53CBC7',
    boxShadow: 'inset 1px 1px 1px #73FFFA',
    borderRadius: 87,
    width: 140,
    height: 42,
    bottom: 'auto',
    right: 0,
    position: 'absolute',
    cursor: 'pointer',
  },
  needHelpButtonText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#FFFFFF',
  },
  wrapper: {
    display: 'flex',
    gap: 4,
    padding: '13px 14px',
    textDecorationLine: 'none',
    '&:active': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
  '@media (max-width: 730px)': {
    needHelpButton: {
      marginTop: 20,
      right: 0,
    },
  },
})

const NeedHelpButton = () => {
  const classes = styles()

  return (
    <div className={classes.needHelpButton}>
      <a
        href="https://discord.gg/DZeF8UTxgS"
        target="__blank"
        className={classes.wrapper}
      >
        <div>
          <img src={needHelpIcon} alt="" />
        </div>
        <div className={classes.needHelpButtonText}>
          Report{'\u00A0'}a{'\u00A0'}bug
        </div>
      </a>
    </div>
  )
}

export default NeedHelpButton
