import React, { useContext, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import 'react-loading-skeleton/dist/skeleton.css'

import usdc from '../../img/USDC.svg'
import zkSynk from '../../img/zksync era.svg'
import arbitrum from '../../img/arbitrum-logo.svg'
import moonBeam from '../../img/moonbeam_2.svg'
import arrowBlue from '../../img/arrow-right-blue.svg'
import arrowGreen from '../../img/arrow-right-green.svg'
import addIcon from '../../img/add-square-green.svg'
import arrow from '../../img/arrowEarn.svg'
import { activeTabsEarn } from '../../redux/tadsController'
import { Web3Context } from '../../context'
import poolAbi from '../../contracts/erc20Abi.abi'
import farmingPoolAbi12h from '../../contracts/farmingPool12h.abi'
import { fromBn, toBn } from 'evm-bn'
import { useDispatch, useSelector } from 'react-redux'
import { commify, sortRewordsAssets } from 'src/utils'
import BigNumber from 'bignumber.js'
import farmingPoolAbi from '../../contracts/farmingPool.abi'
import uniqid from 'uniqid'
import {
  deleteNotifications,
  setNotifications,
} from '../../redux/notifications'
import {
  claimNotification,
  poolNotification,
  supplyNotification,
} from '../../utils/notificationTexts'
import info from '../../img/NFT/info-circle.svg'
import refetch from '../../utils/Refetch'
import { request } from '../../factory/axios'
import CountDownTimer from '../CountDownTimer/CountDownTimer'
import {
  paramsArbitrumTest,
  paramsMovr,
  paramsZkSyncTest,
} from '../../utils/networkConst'
import { transform } from '../../factory/bigNumber'
import { Tooltip } from 'antd'
import bigDecimal from 'js-big-decimal'
import { setOpenWalletModal } from '../../redux/loading'
import { setEarnCardState } from '../../redux/earnCardState'

const styles = createUseStyles({
  earnCard: {
    width: 1100,
    border: '1px solid #4B567D',
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
  },
  earnCardOpen: {
    extend: 'earnCard',
    height: 86,
  },
  tokenImg: {
    width: 24,
    height: 24,
  },
  cardWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 17,
    paddingBottom: 20,
  },
  imgWrapperTop: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    flexBasis: 327,
  },
  inputWrapper: {
    backgroundColor: '#1F1F2D',
    borderRadius: 12,
    width: '280px',
    height: 68,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    marginBottom: 15,
    boxSizing: 'border-box',
    padding: [10, 15],
  },
  inputDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  inputDescriptionText: {
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
  },
  cursorDisable: {
    cursor: 'not-allowed',
  },
  inputPlaceholderText: {
    whiteSpace: 'nowrap',
    backgroundColor: '#1F1F2D',
    border: 'none',
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 400,
    color: '#7D7E83',
  },
  inputText: {
    extend: 'inputPlaceholderText',
    cursor: 'text',
    color: '#FFFFFF',
  },
  inputAvaliableValue: {
    extend: 'inputPlaceholderText',
    width: 'inherit',
    display: 'flex',
    flexDirection: 'column-reverse',
    color: '#5493F7',
    fontWeight: 600,
    alignItems: 'flex-end',
  },
  inputAvaliableValueUSD: {
    extend: 'inputPlaceholderText',
    color: '#A8A8B2',
    display: 'flex',
    width: 106,
    justifyContent: 'end',
    fontWeight: 400,
  },
  imgWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  mainText: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
  },
  bigGrayText: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#A8A8B2',
    fontFamily: 'Poppins',
    paddingRight: 8,
  },
  orbText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
  },
  subText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
    fontFamily: 'Poppins',
  },
  depositSelectWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    paddingBottom: 40,
  },
  depositSelectWrapper24h: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    paddingBottom: 119,
  },
  depositSelectButton: {
    fontFamily: 'Poppins',
    cursor: 'pointer',
    backgroundColor: '#1F1F2D',
    border: 'none',
    width: 52,
    height: 22,
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 42,
  },
  depositSelectButtonDisabled: {
    backgroundColor: '#1F1F2D',
    border: 'none',
    width: 52,
    height: 22,
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 42,
    cursor: 'not-allowed',
  },
  rewardsTitleWrapper: {
    paddingBottom: 30,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '740px',
  },
  getOrbWrapper: {
    display: 'flex',
    gap: 20,
    flexBasis: 330,
  },
  userBalanceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: 10,
    gap: 6,
  },
  greenText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#53CBC8',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  blueText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#5493F7',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  hr: {
    height: 0,
    width: 1098,
    borderTop: '1px solid #4B567D',
    margin: '0 auto',
  },
  harvestButton: {
    width: 130,
    height: 60,
    backgroundColor: '#44445B',

    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    border: 0,
    borderRadius: 12,
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  balanceMainText: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
  },
  balanceSubText: {
    color: '#A8A8B2',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
  },
  balanceTokenSubText: {
    color: '#A8A8B2',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    paddingLeft: 5,
  },
  TLVSubText: {
    color: '#A8A8B2',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  lockButton: {
    extend: 'greenText',
    backgroundColor: 'inherit',
    border: '1px solid #53CBC8',
    borderRadius: 8,
    width: 163,
    height: 30,
    marginTop: 5,
    cursor: 'pointer',
  },
  enableButton: {
    extend: 'harvestButton',
    backgroundColor: '#5493F7',
    width: 280,
  },
  claimLPButton: {
    extend: 'harvestButton',
    backgroundColor: '#5493F7',
    width: 224,
  },
  claimLPButtonDisabled: {
    extend: 'harvestButton',
    backgroundColor: '#44445B',
    cursor: 'not-allowed',
    width: 224,
  },
  claimRewardsButton: {
    extend: 'harvestButton',
    backgroundColor: '#5493F7',
    width: 230,
  },
  claimRewardsButtonDisabled: {
    extend: 'harvestButton',
    backgroundColor: '#44445B',
    cursor: 'not-allowed',
    width: 230,
  },
  disableButton: {
    extend: 'harvestButton',
    backgroundColor: '#44445B',
    cursor: 'not-allowed',
    width: 280,
  },
  claimRewardsButtonWrapper: {
    justifyContent: 'end',
    display: 'flex',
    width: '47%',
  },
  earnCardWrapperOpenHover: {
    display: 'block',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#4B567D',
      borderRadius: 12,
    },
  },
  earnCardWrapperOpen: {
    display: 'block',
  },
  earnCardWrapper: {
    display: 'none',
  },
  infoIconWrapper: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
  },
  plusIng: {
    cursor: 'pointer',
    margin: 'auto 0 auto auto',
  },
  earnedWrapper: {
    flexBasis: 187,
  },
  apyWrapper: {
    flexBasis: 182,
  },
  liqudityWrapper: {
    flexBasis: 190,
  },
  multiplierWrapper: {
    flexBasis: 117,
  },
  stakeWrapper: {
    paddingRight: 40,
    // border: '1px solid #4B567D'
  },
  stakeTabsWrapper: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 20,
    paddingBottom: 20,
  },
  blueHr: {
    width: 20,
    height: 4,
    backgroundColor: '#5493F7',
    borderRadius: 37,
    margin: ' 5px auto 0',
  },
  werticalHr: {
    width: 0,
    height: 368,
    borderLeft: '1px solid #4B567D',
    left: '320px',
    position: 'absolute',
  },
  werticalHr24h: {
    width: 0,
    height: 444,
    borderLeft: '1px solid #4B567D',
    left: '320px',
    position: 'absolute',
  },
  rewardsTokensWrapper: {
    paddingBottom: 20,
    display: 'flex',
    gap: 100,
    alignItems: 'center',
  },
  tokenImgSmall: {
    width: 18,
    height: 18,
  },
  stakeTab: {
    cursor: 'pointer',
  },
  stakeTabActive: {
    cursor: 'pointer',
    paddingBottom: 9,
  },
  tabText: {
    extend: 'mainText',
    color: '#A8A8B2',
  },
  imgTokenWrapper: {
    position: 'relative',
    width: 50,
    height: 50,
    marginRight: 7,
  },
  tokenImgTop: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  tokenImgBottom: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  tlvText: {
    color: '#A8A8B2',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 'normal',
  },
  topVerticalHr: {
    width: 0,
    height: 54,
    borderLeft: '1px solid #4B567D',
    marginRight: 30,
  },
  networkWrapper: {
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 142,
    gap: 14,
    paddingRight: 25,
  },
  networkAPYWrapper: {
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 14,
  },
  switchWrapper: {
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 14,
    paddingLeft: 24,
  },
  switchIcon: {
    width: 20,
    height: 20,
  },
  switchTextBlue: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#5493F7',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  switchTextGreen: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#53CBC8',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  bottomCardWrapper: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 15,
  },
  smallTimer: {
    position: 'absolute',
    left: '50%',
    top: '80px',
    transform: 'translate(-50%, 0)',
    borderRadius: 74,
    border: '1px solid #5493F7',
    background:
      'linear-gradient(180deg, rgba(49, 57, 84) 0%, rgba(53, 53, 81, 0.80) 100%)',
    display: 'flex',
    gap: 5,
    padding: [4, 10],
  },
  paddingBottomFive: {
    paddingBottom: 5,
  },
  timerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  timerTextWrapper: {
    display: 'flex',
    gap: 1,
    flexDirection: 'column',
  },
  timerMainText: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
  },
  timerSubText: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
  },
  timerStyle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    width: 125,
    color: '#5493F7',
    fontSize: '28px',
    lineHeight: '21px',
  },
  smallTimerStyle: {
    width: 80,
  },
  toolTipWrapper: {
    background: '#1F1F2D',
    borderRadius: 12,
    padding: [12, 16, 7],
    width: 162,
  },
  tooltipLine: {
    width: '100%',
    height: '1px',
    borderBottom: 'none',
    border: 'none',
    backgroundColor: '#4B567D',
    marginTop: '8px',
    marginBottom: '8px',
  },
  tooltipItemTextSign: {
    color: '#5493F7',
    fontWeight: 500,
    marginRight: '4px',
  },
  tooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 5,
  },
  tooltipMainText: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
  },
  tooltipSubText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
  },
  pointer: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  poolsLableWrapper: {
    position: 'absolute',
    top: -10,
    left: -10,
    background: 'linear-gradient(44deg, #00E0FC 0%, #5493F7 100%)',
    borderRadius: 74,
    padding: [4, 10],
  },
  poolsLableText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
    textTransform: 'uppercase',
  },
  poolDescriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    alignItems: 'start',
  },
  poolDescriptionTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: 'normal',
    color: '#FFFFFF',
  },
  poolDescriptionInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  poolDescriptionInfo24h: {
    display: 'flex',
    gap: 40,
    justifyContent: 'space-between',
  },
  poolDescriptionMainText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 'normal',
    color: '#A8A8B2',
  },
  poolDescriptionBlueText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 'normal',
    color: '#5493F7',
  },
  poolDescriptionGreenText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 'normal',
    color: '#53CBC8',
  },
  poolDescriptionTipWrapper: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  poolDescriptionMainTextWidth: {
    width: 356,
  },
  poolDescriptionInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
})

interface Props {
  data: any
  pool12Hours: any
  nextTime: any
  switchNetwork: any
  title: any
}

enum CardNetwork {
  MOONBEAM = 'MOONBEAM',
  ARBITRUM = 'ARBITRUM',
  ZKSYNC = 'ZKSYNC',
}

// const networksData = {
//   MOONBEAM: {
//     name: 'Moonbeam',
//     img: usdc,
//     apy: 200,
//   },
//   ARBITRUM: {
//     name: 'Arbitrum',
//     img: arbitrum,
//     apy: 100,
//   },
//   ZKSINC: {
//     name: 'zkSinc',
//     img: zkSynk,
//     apy: 10,
//   },
// }

const EarnCardNotConnected: React.FC<Props> = ({
  data,
  pool12Hours,
  nextTime,
  switchNetwork,
  refetch,
  title,
}) => {
  const [openCard, setOpenCard] = useState(false)
  const [activeTab, setActiveTab] = useState<activeTabsEarn>(
    activeTabsEarn.stake
  )
  const [poolContract, setPoolContract] = useState(null)
  const [farmingPoolContract, setFarmingPoolContract] = useState(null)
  const [claimRewardsDisableButton, setClaimRewardsDisableButton] =
    useState(false)
  const [transactionInProgres, setTransactionInProgres] = useState(false)
  const [claimLpDisableButton, setClaimLpDisableButton] = useState(false)
  const [userBalance, setUserBalance] = useState<any>(null)
  const [userStaked, setUserStaked] = useState<any>(null)
  const [unstakeLocked, setUnstakeLocked] = useState(null)
  const [userTokenAllowance, setTokenAllowance] = useState<any>(null)
  const [inputValue, setInputValue] = useState('')
  const [currentNetwork, setCurrentNetwork] = useState(CardNetwork.MOONBEAM)
  const [firstNetwork, setFirstNetwork] = useState(CardNetwork.ARBITRUM)
  const [secondNetwork, setSecondNetwork] = useState(CardNetwork.ZKSYNC)

  const classes = styles()

  const { web3 } = useContext(Web3Context)
  const { open } = useSelector((state) => state.earnCardStateReducer)
  const { user } = useSelector((state) => state.userReducer)

  const dispatch = useDispatch()

  const disabledButton = (balance) => {
    const disabled = !balance || balance === '0'
    return (
      !transactionInProgres &&
      !disabled &&
      !!inputValue &&
      +inputValue.replace(/[\s,]/g, '') !== 0 &&
      !(+inputValue.replace(/[\s,]/g, '') > balance)
    )
  }

  const getClaimRewardsDisableButton = async () => {
    if (+data[currentNetwork].networkId !== user.chainId) {
      setClaimRewardsDisableButton(true)
      return
    }
    const getRewardsAmount = (): number => {
      return data[currentNetwork].rewards.reduce(
        (accumulator, item) => +accumulator + +item.availableToClaim,
        0
      )
    }
    if (getRewardsAmount() === 0) {
      setClaimRewardsDisableButton(true)
      return
    }
    try {
      const lastClaim = await farmingPoolContract.methods
        .lastClaim(user.address)
        .call()

      const getClaimCycleTime = await farmingPoolContract.methods
        .getClaimCycleTime()
        .call()

      setClaimRewardsDisableButton(lastClaim > getClaimCycleTime)
    } catch (error) {}
  }

  const getClaimLpDisableButton = async (
    unstakeLocked: null | undefined | string
  ) => {
    if (+data[currentNetwork].networkId !== user.chainId) {
      setClaimLpDisableButton(true)
      return
    }

    if (unstakeLocked === '0') {
      setClaimLpDisableButton(true)
      return
    }
    try {
      const lastUnstake = await farmingPoolContract.methods
        .lastUnstake(user.address)
        .call()

      const getClaimCycleTime = await farmingPoolContract.methods
        .getClaimCycleTime()
        .call()

      setClaimLpDisableButton(lastUnstake > getClaimCycleTime)
    } catch (error) {}
  }

  const isApproveNeed = userTokenAllowance >= inputValue

  // useEffect(() => {
  //   if (data[currentNetwork]?.farmingPool) {
  //     defineContracts(
  //       data[currentNetwork]?.liquidityPool,
  //       data[currentNetwork]?.farmingPool
  //     )
  //   }
  // }, [data, currentNetwork, user])

  // useEffect(() => {
  //   if (data[currentNetwork]?.farmingPool) {
  //     defineContracts(
  //       data[currentNetwork]?.liquidityPool,
  //       data[currentNetwork]?.farmingPool
  //     )
  //   }
  //   setOpenCard(open[title])
  // }, [])

  useEffect(() => {
    setOpenCard(open[title])
  }, [open])

  useEffect(() => {
    if (poolContract) {
      getBalance()
      checkAllowance()
    }
  }, [poolContract, user])

  useEffect(() => {
    if (farmingPoolContract) {
      getUserStaked()
      !pool12Hours && getUnstakeLocked()
      getClaimRewardsDisableButton()
    }
  }, [farmingPoolContract, user])

  useEffect(() => {
    if (farmingPoolContract) {
      !pool12Hours &&
        getUnstakeLocked().then((result) => getClaimLpDisableButton(result))
    }
  }, [farmingPoolContract, user, data])

  useEffect(() => {
    setInputValue('')
  }, [activeTab, user.chainId])

  // const defineContracts = async (poolAddress, farmingPoolAddress) => {
  //   const poolContract = new web3.eth.Contract(poolAbi, poolAddress)
  //   setPoolContract(poolContract)

  //   const farmingPoolContract = new web3.eth.Contract(
  //     pool12Hours ? farmingPoolAbi12h : farmingPoolAbi,
  //     farmingPoolAddress
  //   )
  //   setFarmingPoolContract(farmingPoolContract)
  // }

  const getBalance = async () => {
    try {
      const result = await poolContract.methods.balanceOf(user.address).call()

      setUserBalance(fromBn(result, 18).toString())
    } catch (error) {
      console.log(error)
    }
  }

  const checkAllowance = async () => {
    try {
      const result = await poolContract.methods
        .allowance(user.address, data[currentNetwork].farmingPool)
        .call()

      setTokenAllowance(fromBn(result, 18).toString())
    } catch (error) {
      console.log(error)
    }
  }

  const enterPersentInInput = (percent: number, balance) => {
    const availableToSupplyAssetBN = new BigNumber(balance)

    setInputValue(
      commify(bigDecimal.multiply(availableToSupplyAssetBN, percent))
    )
  }

  const checkTabs = (tab: activeTabsEarn) => {
    return activeTab === tab
  }

  const confirmUnstake = async () => {
    const transactionsId = uniqid()
    setTransactionInProgres(true)
    setClaimLpDisableButton(true)

    setInputValue('')
    dispatch(
      setNotifications({
        type: 'info',
        text: poolNotification.textInfoClaimLp,
        link: null,
      })
    )
    try {
      // let gasPrice = await web3.eth.getGasPrice()
      //
      // gasPrice = +gasPrice
      // gasPrice += gasPrice
      //
      // let gasLimit = await poolContract.methods.approve().estimateGas({
      //   from: user.address,
      // })
      //
      // gasLimit = +gasLimit
      // gasLimit += gasLimit

      const result = await farmingPoolContract.methods
        .confirmUnstake()
        .send({
          from: user.address,
          // gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          // gasPrice: web3.utils.toHex(Math.round(gasPrice)),
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                explorerUrl: data[currentNetwork].explorer,
                text: poolNotification.textSubmittedClaimLp,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      setTimeout(() => setTransactionInProgres(false), 1000)
      getUnstakeLocked().then((result) => getClaimLpDisableButton(result))
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          explorerUrl: data[currentNetwork].explorer,
          text: poolNotification.textSuccessClaimLp,
          link: result.transactionHash,
        })
      )

      console.log('claimLp:', result)
    } catch (error) {
      setTimeout(() => setTransactionInProgres(false), 1000)
      getUnstakeLocked().then((result) => getClaimLpDisableButton(result))
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: poolNotification.textErrorClaimLp,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const approve = async () => {
    const transactionsId = uniqid()
    setTransactionInProgres(true)

    setInputValue('')
    dispatch(
      setNotifications({
        type: 'info',
        text: poolNotification.textInfoApproval,
        link: null,
      })
    )
    try {
      // let gasPrice = await web3.eth.getGasPrice()
      //
      // gasPrice = +gasPrice
      // gasPrice += gasPrice
      //
      // let gasLimit = await poolContract.methods.approve().estimateGas({
      //   from: user.address,
      // })
      //
      // gasLimit = +gasLimit
      // gasLimit += gasLimit

      const result = await poolContract.methods
        .approve(
          data[currentNetwork]?.farmingPool,
          toBn(`${999999}`, 18).toString()
        )
        .send({
          from: user.address,
          // gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          // gasPrice: web3.utils.toHex(Math.round(gasPrice)),
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: poolNotification.textSubmittedApproval,
                explorerUrl: data[currentNetwork].explorer,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          text: poolNotification.textSuccessApproval,
          link: result.transactionHash,
          explorerUrl: data[currentNetwork].explorer,
        })
      )
      setTransactionInProgres(false)
      console.log('approve:', result)
      checkAllowance()
      await stakeTokens(inputValue.replace(/[\s,]/g, ''))
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      setTransactionInProgres(false)
      dispatch(
        setNotifications({
          type: 'error',
          text: poolNotification.textErrorApproval,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const stakeTokens = async (amount: string) => {
    setTransactionInProgres(true)
    let result
    const transactionsId = uniqid()
    dispatch(
      setNotifications({
        type: 'info',
        text: poolNotification.textInfoStake,
        link: null,
      })
    )

    setInputValue('')

    try {
      // let gasPrice = await web3.eth.getGasPrice()
      //
      // gasPrice = +gasPrice
      // gasPrice += gasPrice
      //
      // let gasLimit = await farmingPoolContract.methods
      //     .mint(toBn(`${amount}`, asset.tokenDecimal).toString())
      //     .estimateGas({
      //       from: user.address,
      //     })
      //
      // gasLimit = +gasLimit
      // gasLimit += gasLimit

      result = await farmingPoolContract.methods
        .stakeTokens(toBn(`${amount}`, 18).toString())
        .send({
          // gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          // gasPrice: web3.utils.toHex(Math.round(gasPrice)),
          from: user.address,
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: poolNotification.textSubmittedStake,
                explorerUrl: data[currentNetwork].explorer,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          explorerUrl: data[currentNetwork].explorer,
          text: poolNotification.textSuccessStake,
          link: result.transactionHash,
        })
      )
      setTransactionInProgres(false)
      refetch()
      getUserStaked()
      getBalance()
      console.log('Stake', result)
      setInputValue('')
    } catch (error) {
      setTransactionInProgres(false)
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: poolNotification.textErrorStake,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const unstakeTokens = async (amount: string) => {
    setTransactionInProgres(true)
    let result
    const transactionsId = uniqid()
    dispatch(
      setNotifications({
        type: 'info',
        text: poolNotification.textInfoUnstake,
        link: null,
      })
    )

    setInputValue('')

    try {
      // let gasPrice = await web3.eth.getGasPrice()
      //
      // gasPrice = +gasPrice
      // gasPrice += gasPrice
      //
      // let gasLimit = await farmingPoolContract.methods
      //     .mint(toBn(`${amount}`, asset.tokenDecimal).toString())
      //     .estimateGas({
      //       from: user.address,
      //     })
      //
      // gasLimit = +gasLimit
      // gasLimit += gasLimit
      if (pool12Hours) {
        result = await farmingPoolContract.methods
          .unstakeTokens(toBn(`${amount}`, 18).toString())
          .send({
            // gasLimit: web3.utils.toHex(Math.round(gasLimit)),
            // gasPrice: web3.utils.toHex(Math.round(gasPrice)),
            from: user.address,
          })
          .on(
            'transactionHash',
            (hash: string) =>
              hash &&
              dispatch(
                setNotifications({
                  type: 'info',
                  explorerUrl: data[currentNetwork].explorer,
                  text: poolNotification.textSubmittedUnstake,
                  link: hash,
                  id: transactionsId,
                  isPending: true,
                })
              )
          )
      } else {
        result = await farmingPoolContract.methods
          .unstakeRequest(toBn(`${amount}`, 18).toString())
          .send({
            // gasLimit: web3.utils.toHex(Math.round(gasLimit)),
            // gasPrice: web3.utils.toHex(Math.round(gasPrice)),
            from: user.address,
          })
          .on(
            'transactionHash',
            (hash: string) =>
              hash &&
              dispatch(
                setNotifications({
                  type: 'info',
                  explorerUrl: data[currentNetwork].explorer,
                  text: poolNotification.textSubmittedUnstake,
                  link: hash,
                  id: transactionsId,
                  isPending: true,
                })
              )
          )
      }
      dispatch(deleteNotifications(transactionsId))
      setTransactionInProgres(false)
      dispatch(
        setNotifications({
          type: 'success',
          explorerUrl: data[currentNetwork].explorer,
          text: pool12Hours
            ? poolNotification.textSuccessUnstake
            : poolNotification.textSuccessUnstakeReq,
          link: result.transactionHash,
        })
      )
      refetch()
      getUserStaked()
      getBalance()
      console.log('unstakeTokens', result)
      setInputValue('')
    } catch (error) {
      setTransactionInProgres(false)
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: supplyNotification.textErrorDeposit,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const claim = async () => {
    const transactionsId = uniqid()
    setClaimRewardsDisableButton(true)
    setTransactionInProgres(true)

    dispatch(
      setNotifications({
        type: 'info',
        text: poolNotification.textInfoClaim,
        link: null,
      })
    )
    try {
      // let gasPrice = await web3.eth.getGasPrice()
      //
      // gasPrice = +gasPrice
      // gasPrice += gasPrice
      //
      // let gasLimit = await farmingPoolContract.methods.claim().estimateGas({
      //   from: user.address,
      // })
      //
      // gasLimit = +gasLimit
      // gasLimit += gasLimit

      const result = await farmingPoolContract.methods
        .claim()
        .send({
          // gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          // gasPrice: web3.utils.toHex(Math.round(gasPrice)),
          from: user.address,
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                explorerUrl: data[currentNetwork].explorer,
                text: poolNotification.textSubmittedClaim,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      refetch()
      setTimeout(() => setTransactionInProgres(false), 1000)
      getClaimRewardsDisableButton()
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          explorerUrl: data[currentNetwork].explorer,
          text: poolNotification.textSuccessClaim,
          link: result.transactionHash,
        })
      )
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      setTimeout(() => setTransactionInProgres(false), 1000)
      getClaimRewardsDisableButton()
      dispatch(
        setNotifications({
          type: 'error',
          text: poolNotification.textErrorClaim,
          link: null,
        })
      )
      console.log('error', error)
    }
  }

  const getUnstakeLocked = async () => {
    if (!pool12Hours) {
      try {
        const result = await farmingPoolContract.methods
          .unstakeLocked(user.address)
          .call()

        setUnstakeLocked(fromBn(result, 18).toString())
        return fromBn(result, 18).toString()
      } catch (error) {
        console.log(error)
      }
    }
  }

  const getUserStaked = async () => {
    try {
      const result = await farmingPoolContract.methods
        .userTotalStaked(user.address)
        .call()

      setUserStaked(fromBn(result, 18).toString())
    } catch (error) {
      console.log(error)
    }
  }

  const switchNetworkLocal = async (networkToChange, setNetwork) => {
    if (networkToChange === CardNetwork.MOONBEAM) {
      switchNetwork(paramsMovr, () =>
        setCurrentNetwork((prevState) => {
          setNetwork(prevState)
          return networkToChange
        })
      )
    } else if (networkToChange === CardNetwork.ARBITRUM) {
      switchNetwork(paramsArbitrumTest, () =>
        setCurrentNetwork((prevState) => {
          setNetwork(prevState)
          return networkToChange
        })
      )
    } else if (networkToChange === CardNetwork.ZKSYNC) {
      switchNetwork(paramsZkSyncTest, () =>
        setCurrentNetwork((prevState) => {
          setNetwork(prevState)
          return networkToChange
        })
      )
    }
  }

  const changeNetwork = async (networkToChange, setNetwork, e) => {
    setFarmingPoolContract(null)
    setPoolContract(null)
    switchNetworkLocal(networkToChange, setNetwork)
    e.stopPropagation()
  }

  const approveAndStake = async () => {
    if (!isApproveNeed) {
      await approve()
    }

    if (inputValue.replace(/[\s,]/g, '') <= userTokenAllowance) {
      await stakeTokens(inputValue.replace(/[\s,]/g, ''))
    }
  }

  const renderText = () => {
    if (title === '12h') {
      return (
        <div className={classes.poolDescriptionWrapper}>
          {/* <div className={classes.poolDescriptionTitle}>Simple Farm 🌱</div> */}
          <div className={classes.poolDescriptionInfo}>
            <div className={[classes.poolDescriptionMainText].join(' ')}>
              <span className={classes.poolDescriptionBlueText}>
                Claim Frequency:
              </span>{' '}
              You can claim your rewards every 12 hours
            </div>
            {/* <div
              className={[
                classes.poolDescriptionMainText,
                classes.poolDescriptionMainTextWidth,
              ].join(' ')}
            >
              <span className={classes.poolDescriptionBlueText}>
                Timer Reset:
              </span>{' '}
              If you make additional deposits, the 12-hour timer will reset
            </div> */}
          </div>
          <div
            className={[
              classes.poolDescriptionMainText,
              classes.poolDescriptionTipWrapper,
            ].join(' ')}
          >
            <span className={classes.poolDescriptionGreenText}>
              👉 Quick Tip:
            </span>{' '}
            This is perfect if you like to check in frequently and claim your
            rewards!
          </div>
        </div>
      )
    } else if (title === '24h') {
      return (
        <div className={classes.poolDescriptionWrapper}>
          <div className={classes.poolDescriptionTitle}>Advanced Farm 🌿</div>
          <div className={classes.poolDescriptionInfo24h}>
            <div className={classes.poolDescriptionInfoWrapper}>
              <div
                className={[
                  classes.poolDescriptionMainText,
                  classes.poolDescriptionMainTextWidth,
                ].join(' ')}
              >
                <span className={classes.poolDescriptionBlueText}>
                  Earning Start:
                </span>{' '}
                Your earnings kick off at the start of the next cycle
              </div>
              <div
                className={[
                  classes.poolDescriptionMainText,
                  classes.poolDescriptionMainTextWidth,
                ].join(' ')}
              >
                <span className={classes.poolDescriptionBlueText}>
                  Token Lock:
                </span>{' '}
                When you decide to withdraw your LP tokens, they'll stay locked
                but continue to earn until the end of the current cycle
              </div>
            </div>
            <div className={classes.poolDescriptionInfoWrapper}>
              <div
                className={[
                  classes.poolDescriptionMainText,
                  classes.poolDescriptionMainTextWidth,
                ].join(' ')}
              >
                <span className={classes.poolDescriptionBlueText}>
                  Withdrawal:
                </span>{' '}
                Your tokens will be available for withdrawal at the start of the
                next cycle
              </div>
              <div
                className={[
                  classes.poolDescriptionMainText,
                  classes.poolDescriptionMainTextWidth,
                ].join(' ')}
              >
                <span className={classes.poolDescriptionBlueText}>
                  Claim Frequency:
                </span>{' '}
                You can claim your rewards at the start of each new cycle
              </div>
            </div>
          </div>
          <div
            className={[
              classes.poolDescriptionMainText,
              classes.poolDescriptionTipWrapper,
            ].join(' ')}
          >
            <span className={classes.poolDescriptionGreenText}>
              👉 Quick Tip:
            </span>{' '}
            This is great if you're looking for a balance between frequent and
            long-term farming!
          </div>
        </div>
      )
    } else if (title === '7d') {
      return (
        <div className={classes.poolDescriptionWrapper}>
          <div className={classes.poolDescriptionTitle}>Elite Farm 🌳</div>
          <div className={classes.poolDescriptionInfo24h}>
            <div className={classes.poolDescriptionInfoWrapper}>
              <div
                className={[
                  classes.poolDescriptionMainText,
                  classes.poolDescriptionMainTextWidth,
                ].join(' ')}
              >
                <span className={classes.poolDescriptionBlueText}>
                  Earning Start:
                </span>{' '}
                Your earnings begin at the start of the next cycle.
              </div>
              <div
                className={[
                  classes.poolDescriptionMainText,
                  classes.poolDescriptionMainTextWidth,
                ].join(' ')}
              >
                <span className={classes.poolDescriptionBlueText}>
                  Token Lock:
                </span>{' '}
                If you withdraw your LP tokens, they'll be locked until the end
                of the current cycle but will keep earning.
              </div>
            </div>
            <div className={classes.poolDescriptionInfoWrapper}>
              <div
                className={[
                  classes.poolDescriptionMainText,
                  classes.poolDescriptionMainTextWidth,
                ].join(' ')}
              >
                <span className={classes.poolDescriptionBlueText}>
                  Withdrawal:
                </span>{' '}
                Your tokens will be ready for withdrawal at the start of the
                next cycle.
              </div>
              <div
                className={[
                  classes.poolDescriptionMainText,
                  classes.poolDescriptionMainTextWidth,
                ].join(' ')}
              >
                <span className={classes.poolDescriptionBlueText}>
                  Claim Frequency:
                </span>{' '}
                You can claim your rewards at the start of every 8th cycle.
              </div>
            </div>
          </div>
          <div
            className={[
              classes.poolDescriptionMainText,
              classes.poolDescriptionTipWrapper,
            ].join(' ')}
          >
            <span className={classes.poolDescriptionGreenText}>
              👉 Quick Tip:
            </span>{' '}
            Choose this if you're in it for the long haul and want to maximize
            your earnings over time!
          </div>
        </div>
      )
    }
  }

  const renderButton = () => {
    return (
      <button
        onClick={() => dispatch(setOpenWalletModal(true))}
        className={classes.enableButton}
      >
        Connect Wallet
      </button>
    )
  }

  return (
    <div className={classes.earnCard}>
      <div
        onClick={() =>
          !openCard && dispatch(setEarnCardState({ ...open, [title]: true }))
        }
        className={
          openCard
            ? classes.earnCardWrapperOpen
            : classes.earnCardWrapperOpenHover
        }
      >
        <div
          className={[classes.cardWrapper, classes.pointer].join(' ')}
          onClick={() =>
            openCard && dispatch(setEarnCardState({ ...open, [title]: false }))
          }
        >
          <div className={classes.imgWrapperTop}>
            <div className={classes.imgTokenWrapper}>
              <img src={data[currentNetwork].liquidityPoolImage} alt="" />
            </div>
            <div>
              <div className={classes.mainText}>
                {data[currentNetwork].name}
              </div>
              <div className={classes.balanceMainText}>
                <span className={classes.TLVSubText}>TVL </span>$
                {data[currentNetwork]?.farmingTvl}
              </div>
            </div>
          </div>
          <div className={classes.earnedWrapper}>
            <div className={classes.subText}>You staked</div>
            <div className={classes.imgWrapperTop}>
              <span className={classes.mainText}>-</span>
            </div>
          </div>
          <div className={classes.apyWrapper}>
            <div className={classes.imgWrapper}>
              <img src={data[currentNetwork].networkImage} alt="" />
              <div className={classes.subText}>
                {data[currentNetwork].network} APR
              </div>
            </div>
            <Tooltip
              overlayInnerStyle={{ boxShadow: 'none' }}
              color="none"
              title={
                <div className={classes.toolTipWrapper}>
                  {data[currentNetwork].rewards.map((el, index) => {
                    return (
                      <div className={classes.tooltipItem}>
                        <div className={classes.tooltipSubText}>
                          <span className={classes.tooltipItemTextSign}>+</span>
                          {el.symbol} APR
                        </div>
                        <div className={classes.tooltipMainText}>
                          {transform(el.APY, 2)}%
                        </div>
                      </div>
                    )
                  })}
                  <hr className={classes.tooltipLine} />
                  <div className={classes.tooltipItem}>
                    <div className={classes.tooltipSubText}>Total APR</div>
                    <div className={classes.tooltipMainText}>
                      {transform(data[currentNetwork].totalAPY, 2)}%
                    </div>
                  </div>
                </div>
              }
            >
              <div className={classes.imgWrapper}>
                <span className={classes.mainText}>
                  {transform(data[currentNetwork].totalAPY, 2)}%
                </span>
                <img src={info} alt="" />
              </div>
            </Tooltip>
          </div>
          {/* <div className={classes.topVerticalHr}></div> */}
          {/* <div className={classes.networkWrapper}>
            <div className={classes.imgWrapper}>
              <img src={data[firstNetwork].networkImage} alt="" />
              <span className={classes.subText}>
                {data[firstNetwork].network} APY{' '}
              </span>
            </div>
            <div className={classes.imgWrapper}>
              <img src={data[secondNetwork].networkImage} alt="" />
              <span className={classes.subText}>
                {data[secondNetwork].network} APY{' '}
              </span>
            </div>
          </div>
          <div className={classes.networkAPYWrapper}>
            <div className={classes.imgWrapper}>
              <span className={classes.orbText}>
                {data[firstNetwork].totalAPY}%
              </span>
            </div>
            <div className={classes.imgWrapper}>
              <span className={classes.orbText}>
                {data[secondNetwork].totalAPY}%
              </span>
            </div>
          </div> */}
          {/* <div className={classes.switchWrapper}>
            <div
              onClick={(e) => changeNetwork(firstNetwork, setFirstNetwork, e)}
              className={[classes.imgWrapper, classes.pointer].join(' ')}
            >
              <span className={classes.switchTextBlue}>Switch</span>
              <img className={classes.switchIcon} src={arrowBlue} alt="" />
            </div>
            <div
              onClick={(e) => changeNetwork(secondNetwork, setSecondNetwork, e)}
              className={[classes.imgWrapper, classes.pointer].join(' ')}
            >
              <span className={classes.switchTextGreen}>Switch</span>
              <img className={classes.switchIcon} src={arrowGreen} alt="" />
            </div>
          </div> */}
          <div className={classes.poolsLableWrapper}>
            <span className={classes.poolsLableText}>{title}</span>
          </div>
          {!openCard && pool12Hours ? (
            <div className={classes.smallTimer}>
              <span className={classes.subText}>Time to Claim</span>{' '}
              <span
                className={[classes.orbText, classes.smallTimerStyle].join(' ')}
              >
                <CountDownTimer
                  updateRound={refetch}
                  expiryTimestamp={nextTime}
                  withoutDate={false}
                />
              </span>
            </div>
          ) : null}
        </div>
      </div>
      <div className={openCard ? classes.hr : classes.earnCardWrapper}></div>
      <div
        className={
          openCard ? classes.earnCardWrapperOpen : classes.earnCardWrapper
        }
      >
        <div className={classes.cardWrapper}>
          <div className={classes.stakeWrapper}>
            <div className={classes.stakeTabsWrapper}>
              <div
                className={
                  checkTabs(activeTabsEarn.stake)
                    ? classes.stakeTab
                    : classes.stakeTabActive
                }
                onClick={() => setActiveTab(activeTabsEarn.stake)}
              >
                <div
                  className={
                    checkTabs(activeTabsEarn.stake)
                      ? classes.mainText
                      : classes.tabText
                  }
                >
                  Stake
                </div>
                {checkTabs(activeTabsEarn.stake) && (
                  <div className={classes.blueHr}></div>
                )}
              </div>
              <div
                className={
                  checkTabs(activeTabsEarn.unstake)
                    ? classes.stakeTab
                    : classes.stakeTabActive
                }
                onClick={() => setActiveTab(activeTabsEarn.unstake)}
              >
                <div
                  className={
                    checkTabs(activeTabsEarn.unstake)
                      ? classes.mainText
                      : classes.tabText
                  }
                >
                  Unstake
                </div>
                {checkTabs(activeTabsEarn.unstake) && (
                  <div className={classes.blueHr}></div>
                )}
              </div>
            </div>
            <div className={classes.inputWrapper}>
              {/*<div className={classes.inputDescription}></div>*/}
              <div className={classes.inputDescription}>
                <input
                  type="text"
                  className={classes.inputText}
                  placeholder={'Enter the amount'}
                  style={{ outline: 'none' }}
                  value={inputValue}
                  onChange={(e) =>
                    setInputValue(
                      `${commify(
                        `${e.target.value
                          .replace(/[^0-9.]/g, '')
                          .replace(/(\..*)\./g, '$1')}`
                      )}`
                    )
                  }
                />
              </div>
            </div>
            <div
              className={
                title === '12h'
                  ? classes.depositSelectWrapper
                  : classes.depositSelectWrapper24h
              }
            >
              <button
                disabled={
                  checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked
                }
                className={
                  (checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked)
                    ? classes.depositSelectButtonDisabled
                    : classes.depositSelectButton
                }
                onClick={() =>
                  enterPersentInInput(
                    0.25,
                    checkTabs(activeTabsEarn.stake) ? userBalance : userStaked
                  )
                }
              >
                25%
              </button>
              <button
                disabled={
                  checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked
                }
                className={
                  (checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked)
                    ? classes.depositSelectButtonDisabled
                    : classes.depositSelectButton
                }
                onClick={() =>
                  enterPersentInInput(
                    0.5,
                    checkTabs(activeTabsEarn.stake) ? userBalance : userStaked
                  )
                }
              >
                50%
              </button>
              <button
                disabled={
                  checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked
                }
                className={
                  (checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked)
                    ? classes.depositSelectButtonDisabled
                    : classes.depositSelectButton
                }
                onClick={() =>
                  enterPersentInInput(
                    0.75,
                    checkTabs(activeTabsEarn.stake) ? userBalance : userStaked
                  )
                }
              >
                75%
              </button>
              <button
                disabled={
                  checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked
                }
                className={
                  (checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked)
                    ? classes.depositSelectButtonDisabled
                    : classes.depositSelectButton
                }
                onClick={() =>
                  enterPersentInInput(
                    1,
                    checkTabs(activeTabsEarn.stake) ? userBalance : userStaked
                  )
                }
              >
                MAX
              </button>
            </div>
            {checkTabs(activeTabsEarn.stake) ? (
              <div className={classes.userBalanceWrapper}>
                <div className={classes.balanceSubText}>Balance</div>
                <div className={classes.balanceMainText}>
                  - {data[currentNetwork].name}{' '}
                  <span className={classes.balanceTokenSubText}>
                    {userBalance &&
                      `($${transform(
                        (
                          userBalance * +data[currentNetwork].liquidityTokenRate
                        ).toString(),
                        2
                      )})`}
                  </span>
                </div>
              </div>
            ) : (
              <div className={classes.userBalanceWrapper}>
                <div className={classes.balanceSubText}>You staked</div>
                <div className={classes.balanceMainText}>
                  - {data[currentNetwork].name}{' '}
                  <span className={classes.balanceTokenSubText}>
                    {userStaked &&
                      `($${transform(
                        (
                          userStaked * +data[currentNetwork].liquidityTokenRate
                        ).toString(),
                        2
                      )})`}
                  </span>
                </div>
              </div>
            )}
            {renderButton()}
          </div>
          <div
            className={
              title === '12h' ? classes.werticalHr : classes.werticalHr24h
            }
          ></div>
          <div>
            <div className={classes.rewardsTitleWrapper}>
              <div className={classes.mainText}>Rewards</div>
              <div>
                <div className={classes.getOrbWrapper}>
                  <a
                    className={[classes.imgWrapper, classes.pointer].join(' ')}
                    href={data[currentNetwork]?.buyOrbiterLink}
                    target="_blank"
                  >
                    <img src={arrow} alt="" />
                    <span className={classes.blueText}>Buy ORB</span>
                  </a>
                  <a
                    href={data[currentNetwork]?.liquidityPoolDex}
                    target="_blank"
                    className={[classes.imgWrapper, classes.pointer].join(' ')}
                  >
                    <img src={addIcon} alt="" />
                    <span className={classes.greenText}>Add Liquidity</span>
                  </a>
                </div>
              </div>
            </div>
            <div className={classes.rewardsTokensWrapper}>
              {data[currentNetwork]?.rewards.map((el: any) => {
                return (
                  <div>
                    <div
                      className={[
                        classes.subText,
                        classes.paddingBottomFive,
                      ].join(' ')}
                    >
                      {el.symbol} Rewards
                    </div>
                    <div className={classes.imgWrapperTop}>
                      <img
                        className={classes.tokenImgSmall}
                        src={el.image}
                        alt=""
                      />
                      <span className={classes.mainText}>-</span>
                    </div>
                  </div>
                )
              })}
            </div>
            {renderText()}
            <div className={classes.bottomCardWrapper}>
              {!pool12Hours ? (
                <>
                  <button
                    disabled={true}
                    onClick={() => confirmUnstake()}
                    className={classes.claimLPButtonDisabled}
                  >
                    WITHDRAW LP
                  </button>
                  <div>
                    <div
                      className={[
                        classes.subText,
                        classes.paddingBottomFive,
                      ].join(' ')}
                    >
                      Ready to withdraw
                    </div>
                    <div className={classes.imgWrapperTop}>
                      <span className={classes.mainText}>-</span>{' '}
                      <span className={classes.bigGrayText}>
                        {unstakeLocked &&
                          `($${transform(
                            (
                              unstakeLocked *
                              +data[currentNetwork].liquidityTokenRate
                            ).toString(),
                            2
                          )})`}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <div className={classes.timerWrapper}>
                  <div className={classes.timerTextWrapper}>
                    <div className={classes.timerMainText}>Time to claim</div>
                    <div className={classes.timerSubText}>
                      This reward can be claimed once every 12 hours
                    </div>
                  </div>
                  <div className={classes.timerStyle}>
                    <CountDownTimer
                      updateRound={refetch}
                      expiryTimestamp={nextTime}
                      withoutDate={false}
                    />
                  </div>
                </div>
              )}
              <div className={classes.claimRewardsButtonWrapper}>
                {' '}
                <button
                  disabled={true}
                  onClick={() => claim()}
                  className={classes.claimRewardsButtonDisabled}
                >
                  CLAIM Rewards
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default EarnCardNotConnected
