import React from 'react'
import metamask from '../../img/MetaMask_Fox 1.svg'
import walletConnect from '../../img/WalletConnect.svg'
import mobileWallet from '../../img/walletMobile.svg'
import copy from '../../img/copy.svg'
import logout from '../../img/logout.svg'
import { createUseStyles } from 'react-jss'
import useComponentVisible from '../../hooks/useComponentVisible'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../../redux/user'
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton'
import { setNotifications } from '../../redux/notifications'
import { setOpenWalletModal } from '../../redux/loading'

const actualApiFor = process.env.REACT_APP_X_ORBITER_ACTIVE_API_FOR

const styles = createUseStyles({
  wrapper: {
    position: 'relative',
  },
  walletButton: {
    width: 165,
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    borderRadius: 10,
    paddingTop: 9,
    paddingBottom: 9,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    boxShadow: 'inset 1px 1px 1px #42426a',
    backdropFilter: 'blur(25px)',
    cursor: 'pointer',
    position: 'relative',
  },
  walletIcon: {
    paddingLeft: 14,
    paddingRight: 10,
  },
  walletText: {
    fontWeight: 500,
    // width: '54.5%',
    paddingRight: 15,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'white',
    userSelect: 'none',
  },
  walletNotConnectedText: {
    paddingLeft: 22,
    fontWeight: 400,
    paddingRight: 22,
    fontSize: '16px',
    lineHeight: '24px',
    extend: 'walletText',
    width: '',
    userSelect: 'none',
  },
  dashboard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
  },
  connectedModal: {
    width: 280,
    height: 177,
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    position: 'absolute',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    top: 'calc(100% + 10px)',
    padding: 20,
    right: 0,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  connectedModalButtons: {
    extend: 'connectedModal',
    right: 0,
    justifyContent: 'center',
    height: 46,
  },
  smallConnectedModalButtons: {
    extend: 'connectedModal',
    justifyContent: 'center',
    height: 46,
  },
  connectedModalRef: {
    display: 'contents',
  },
  connectedModalHeader: {
    display: 'flex',
    paddingBottom: 10,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  connectedModalContentWrapper: {
    width: '41%',
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  connectedModalMainText: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'white',
  },
  connectWalletButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    borderRadius: 10,
    padding: 10,
    cursor: 'pointer',
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    color: 'white',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    border: '1px solid #4B567D',
    '&:hover': {
      border: '1px solid #3B4363',
      background: '#3B4363',
    },
  },
  connectedModalSubText: {
    extend: 'connectedModalMainText',
    fontWeight: 400,
    color: '#A8A8B2',
  },
  connectedModalContent: {
    justifyContent: 'space-between',
    display: 'flex',
    paddingBottom: 10,
  },
  copyIcon: {
    cursor: 'pointer',
  },
  hr: {
    height: 1,
    backgroundColor: '#4B567D',
  },
  connectedModalButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    cursor: 'pointer',
  },
  connectedModalBlackdrop: {
    left: -571,
    top: '106px',
    width: '211%',
    height: '100vh',
    zIndex: '2',
    position: 'fixed',
    background: 'rgb(0 0 0 / 70%)',
  },
  smallConnectedModalBlackdrop: {
    top: '81px',
  },
  smallConnectedModal: {
    extend: 'connectedModal',
  },
  '@media (max-width: 1425px)': {
    connectedModal: {},
    smallConnectedModal: {},
    connectedModalButtons: {},
    smallConnectedModalButtons: {},
  },
  '@media (max-width: 1420px)': {
    connectedModal: {},
    smallConnectedModal: {},
    connectedModalButtons: {},
    smallConnectedModalButtons: {},
  },
  '@media (max-width: 1250px)': {
    connectedModalButtons: {},
    connectedModal: {},
    smallConnectedModalButtons: {},
    smallConnectedModal: {},
  },
  '@media (max-width: 730px)': {
    wrapper: {
      position: 'static',
    },
    connectedModal: {
      top: 225,
      right: '-50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    connectedModalButtons: {
      top: 115,
      right: '-50%',
      left: '50%',
      transform: 'translate(-50%, 0%)',
      height: 'auto',
    },
    smallConnectedModalButtons: {
      top: 124,
      right: '-50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: 'auto',
    },
    smallConnectedModal: {
      top: 200,
      right: '-50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    walletIcon: {
      padding: 0,
    },
    walletButton: {
      width: 34,
      height: 34,
      padding: 0,
      borderRadius: 10,
    },
    walletText: {
      display: 'none',
    },
    connectedModalBlackdrop: {
      left: -167,
    },
  },
})

interface Props {
  windowInnerWidth: number
  removeWalletData: () => void
  connectWallet: (a: string, b: boolean) => void
  showSwitchNetworkModal: boolean
  smallHeader: boolean
  isNftPage?: boolean
}

const WalletButton: React.FC<Props> = ({
  windowInnerWidth,
  removeWalletData,
  connectWallet,
  showSwitchNetworkModal,
  smallHeader,
  isNftPage,
}) => {
  const { user } = useSelector((state: any) => state.userReducer)

  const { openWalletModal } = useSelector((state: any) => state.loadingReducer)

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  const classes = styles()

  const isMobile = window.innerWidth < 730

  const actualApiFor = process.env.REACT_APP_X_ORBITER_ACTIVE_API_FOR

  const dispatch = useDispatch()

  const handleConnect = (walletType: string, force = true) => {
    connectWallet(walletType, force)
    setIsComponentVisible(false)
  }

  const handleOpen = () => {
    setIsComponentVisible((prev) => !prev)
  }

  const handleDisconnect = (walletType: string, force = true) => {
    setIsComponentVisible(false)
    removeWalletData()
    dispatch(setUser({ connected: false }))
  }

  return user.connected ? (
    <div
      ref={ref}
      className={classes.wrapper}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={classes.walletButton} onClick={() => handleOpen()}>
        <img
          className={classes.walletIcon}
          src={windowInnerWidth > 730 ? metamask : mobileWallet}
          alt="logo"
        />
        <span className={classes.walletText}>
          {user.addressShort || (
            <Skeleton
              baseColor="#4B567D"
              highlightColor="#5B6796"
              width="100%"
              count={1}
            />
          )}
        </span>
      </div>
      <div className={classes.connectedModalRef}>
        {isComponentVisible && user.connected && !showSwitchNetworkModal && (
          <>
            <div
              className={[
                classes.connectedModalBlackdrop,
                smallHeader && classes.smallConnectedModalBlackdrop,
              ].join(' ')}
              onClick={() => setIsComponentVisible(false)}
            />
            <div
              className={
                !smallHeader
                  ? classes.connectedModal
                  : classes.smallConnectedModal
              }
            >
              <div className={classes.connectedModalHeader}>
                <div>
                  <img src={metamask} alt="" />
                </div>
                <div className={classes.connectedModalMainText}>Metamask</div>
              </div>
              <div>
                <div className={classes.connectedModalContent}>
                  <div>
                    <span className={classes.connectedModalSubText}>
                      Wallet address
                    </span>
                  </div>
                  <div className={classes.connectedModalContentWrapper}>
                    <span className={classes.connectedModalMainText}>
                      {user.addressShort || (
                        <Skeleton
                          baseColor="#4B567D"
                          highlightColor="#5B6796"
                          width="100%"
                          count={1}
                        />
                      )}
                    </span>
                    <img
                      src={copy}
                      alt=""
                      className={classes.copyIcon}
                      onClick={() => {
                        navigator.clipboard.writeText(user.address)
                        dispatch(
                          setNotifications({
                            type: 'success',
                            text: 'Wallet address is copied',
                          })
                        )
                      }}
                    />
                  </div>
                </div>
                <div className={classes.connectedModalContent}>
                  <div>
                    <span className={classes.connectedModalSubText}>
                      Network type
                    </span>
                  </div>
                  <div>
                    <span className={classes.connectedModalMainText}>
                      {actualApiFor === 'dev' ? 'Moonbase Alpha' : 'Moonbeam'}
                    </span>
                  </div>
                </div>
              </div>
              <div className={classes.hr}></div>
              <div className={classes.connectedModalButton}>
                <img src={logout} alt="" />
                <div
                  onClick={() =>
                    handleDisconnect(
                      windowInnerWidth > 760 ? 'metamask' : 'walletconnect'
                    )
                  }
                  className={classes.connectedModalSubText}
                >
                  Disconnect
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    <div
      ref={ref}
      className={classes.wrapper}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={classes.walletButton} onClick={() => handleOpen()}>
        {windowInnerWidth > 730 ? (
          <span className={classes.walletNotConnectedText}>Connect wallet</span>
        ) : (
          <img className={classes.walletIcon} src={mobileWallet} alt="logo" />
        )}
      </div>
      <div className={classes.connectedModalRef}>
        {(isComponentVisible || openWalletModal) && (
          <>
            <div
              className={[
                classes.connectedModalBlackdrop,
                smallHeader && classes.smallConnectedModalBlackdrop,
              ].join(' ')}
              onClick={() => {
                setIsComponentVisible(false)
                dispatch(setOpenWalletModal(false))
              }}
            />
            <div
              className={
                smallHeader
                  ? classes.smallConnectedModalButtons
                  : classes.connectedModalButtons
              }
            >
              <button
                className={classes.connectWalletButton}
                onClick={() => {
                  if (isMobile && !window.ethereum) {
                    actualApiFor === 'dev'
                      ? window.location.replace(
                          'https://metamask.app.link/dapp/app.orbiterdev.one/'
                        )
                      : window.location.replace(
                          'https://metamask.app.link/dapp/app.orbiter.one/'
                        )
                  } else {
                    handleConnect('metamask')
                    dispatch(setOpenWalletModal(false))
                  }
                }}
              >
                <img src={metamask} alt="" />
                <span>Metamask</span>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default WalletButton
