import React from 'react'
import bg from '../../img/earnCycleBg.png'
import { createUseStyles } from 'react-jss'
import CountDownTimer from '../CountDownTimer/CountDownTimer'
import Skeleton from 'react-loading-skeleton'

const styles = createUseStyles({
  earnCycle: {
    width: '100%',
    height: 151,
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.80) 0%, rgba(53, 53, 81, 0.80) 100%)',
    borderRadius: 12,
    boxShadow: '1px 1px 1px 0px #42426A inset',
    backdropFilter: 'blur(25px)',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bg: {
    position: 'absolute',
    top: 0,
    borderRadius: 12,
    zIndex: 1,
  },
  textWrapper: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  blueText: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    color: '#5493F7',
    paddingBottom: 10,
  },
  mainText: {
    fontFamily: 'Poppins',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    color: '#FFFFFF',
  },
  subText: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    color: '#A8A8B2',
  },
  timerWidth: {
    width: 180,
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center'
  },
  '@media (max-width: 1250px)': {
    earnCycle: {
      width: '100%',
      height: 151,
      background:
        'linear-gradient(180deg, rgba(49, 57, 84, 0.80) 0%, rgba(53, 53, 81, 0.80) 100%)',
      borderRadius: 12,
      boxShadow: '1px 1px 1px 0px #42426A inset',
      backdropFilter: 'blur(25px)',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 20,
    },
    bg: {
      position: 'absolute',
      top: 0,
      borderRadius: 12,
      zIndex: 1,
      width: 700,
      height: 151,
    },
    textWrapper: {
      position: 'relative',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    blueText: {
      fontFamily: 'Poppins',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      textTransform: 'uppercase',
      color: '#5493F7',
      paddingBottom: 10,
    },
    mainText: {
      fontFamily: 'Poppins',
      fontSize: '40px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      textTransform: 'uppercase',
      color: '#FFFFFF',
    },
    subText: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      color: '#A8A8B2',
    },
  },
  '@media (max-width: 730px)': {
    earnCycle: {
      width: '100%',
      height: 136,
      background:
        'linear-gradient(180deg, rgba(49, 57, 84, 0.80) 0%, rgba(53, 53, 81, 0.80) 100%)',
      borderRadius: 12,
      boxShadow: '1px 1px 1px 0px #42426A inset',
      backdropFilter: 'blur(25px)',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 20,
    },
    bg: {
      width: 340,
      height: 136,
      position: 'absolute',
      top: 0,
      borderRadius: 12,
      zIndex: 1,
    },
    textWrapper: {
      position: 'relative',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    blueText: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      textTransform: 'uppercase',
      color: '#5493F7',
      paddingBottom: 10,
    },
    mainText: {
      fontFamily: 'Poppins',
      fontSize: '36px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      textTransform: 'uppercase',
      color: '#FFFFFF',
    },
    subText: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      color: '#A8A8B2',
    },
    timerWidth: {
      width: 170,
    },
  },
})

interface Props {
  v1NextTime: any
  refetch: any
  cycleCount: any
}

const EarnCycle: React.FC<Props> = ({ v1NextTime, refetch, cycleCount }) => {
  const clases = styles()

  return (
    <div className={clases.earnCycle}>
      <img className={clases.bg} src={bg} alt="" />
      <div className={clases.textWrapper}>
        {v1NextTime ? (
          <div className={clases.blueText}>Earn cycle #{cycleCount}</div>
        ) : (
          <Skeleton
            baseColor="#4B567D"
            highlightColor="#5B6796"
            width="160px"
            count={1}
          />
        )}
        <div className={[clases.mainText, clases.timerWidth].join(' ')}>
          {v1NextTime ? (
            <CountDownTimer
              updateRound={refetch}
              expiryTimestamp={v1NextTime}
              withoutDate={false}
            />
          ) : (
            <Skeleton
              baseColor="#4B567D"
              highlightColor="#5B6796"
              width="100%"
              count={1}
            />
          )}
        </div>
        <div className={clases.subText}>Time left to new cycle</div>
      </div>
    </div>
  )
}

export default EarnCycle
