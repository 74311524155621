import React, { useEffect, useState } from 'react'
import button from '../../img/export.svg'
import { createUseStyles } from 'react-jss'
import { request } from '../../factory/axios'
import { useDispatch, useSelector } from 'react-redux'
import { setTransactions } from '../../redux/transactions'
import CustomScrollWrap from '../CustomScrollWrap'
import moment from 'moment'
import onCompositionImg from '../../img/emoji-sad.svg'
import { transform } from '../../factory/bigNumber'
import { Tooltip } from 'antd'
import { commify, numberToStringInput } from '../../utils'
import Skeleton from 'react-loading-skeleton'
import mintNftLogo from '../../img/mintNft.svg'
import purchaseTicketLogo from '../../img/purchaseTicket.svg'
import unstakeNftLogo from '../../img/unstakeNft.svg'
import stakeNftLogo from '../../img/stakeNft.svg'

const styles = createUseStyles({
  transactions: {
    width: '100%',
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    boxShadow: 'inset 1px 1px 1px #42426a',
    padding: [15, 20, 0, 20],
    cursor: 'default',
  },
  transactionsTitle: {
    fontWeight: 600,
    fontSize: '18px',
    paddingBottom: 20,
    lineHeight: '27px',
    color: '#FFFFFF',
  },
  noTransactionsTitle: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
    paddingBottom: '15px',
  },
  noCompositionsWrapper: {
    display: 'flex',
    height: 140,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 'auto',
  },
  noCompositionsImg: {
    width: 36,
    height: 36,
    paddingBottom: 15,
  },
  noCompositions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noCompositionsMainText: {
    color: '#ffffff',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  noCompositionsSubText: {
    extend: 'noCompositionsMainText',
    fontWeight: 400,
    color: '#A8A8B2',
  },
  transactionsBlockWithBlur: {
    height: '500px',
    paddingBottom: '10px',
    '&:after': {
      content: "''",
      position: 'absolute',
      zIndex: 1,
      bottom: 0,
      left: 0,
      pointerEvents: 'none',
      backgroundImage:
        'linear-gradient(180deg, rgba(49, 51, 78, 0) 0%, #31334E 100%)',
      height: 90,
      width: '96%',
      borderRadius: [0, 0, 0, 12],
    },
  },
  transactionsBlockWithPadding: {
    height: '500px',
    paddingBottom: '10px',
  },
  noTransactionsBlock: {
    textAlign: 'center',
    paddingBottom: '20px',
  },
  transactionsText: {
    color: '#A8A8B2',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  transactionsInfoList: {
    display: 'flex',
  },
  transactionsInfoWrapper: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 8,
    cursor: 'default',
  },
  assetInfo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  assetInfoIcon: {
    width: 28,
    height: 28,
  },
  icon: {
    paddingLeft: 5,
  },
  assetInfoTitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#FFFFFF',
  },
  assetInfoTokenName: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
  },
  noAmount: {
    display: 'flex',
    alignItems: 'center',
  },
  assetInfoPercentages: {
    extend: 'assetInfoTitle',
    display: 'flex',
    marginLeft: 4,
  },
  assetInfoWrapper: {
    display: 'flex',
    justifyContent: 'end',
  },
  supplyText: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#5493F7',
  },
  borrowText: {
    extend: 'supplyText',
    color: '#FBC928',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#5493F7',
    cursor: 'pointer',
  },
  scrollAria: {
    height: 530,
  },
  scrollWrapper: {
    display: 'flex',
    gap: 20,
    flexDirection: 'column',
  },
  assetsAmount: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '95px',
    whiteSpace: 'nowrap',
    marginRight: '5px',
  },
  assetTimeInfoTokenName: {
    extend: 'assetInfoTokenName',
    display: 'flex',
    justifyContent: 'end',
  },
  tooltipContent: {
    color: '#fff',
    background: '#222',
    border: '1px solid transparent',
    borderRadius: '3px',
    padding: '8px 21px',
    fontSize: '14px',
  },
  arrowTooltip: {
    fill: '#222',
  },
  tooltipWithRewards: {
    width: '165px',
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipWithRewardsLong: {
    extend: 'tooltipWithRewards',
    minWidth: '165px',
    width: '100%',
  },
  tooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '4px',
  },
  tooltipItemText: {
    color: '#A8A8B2',
    fontWeight: 400,
    fontSize: '14px',
  },
  tooltipItemTextLong: {
    extend: 'tooltipItemText',
    marginRight: '10px',
  },
  tooltipItemNumber: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: '14px',
  },
  tooltipItemTextSign: {
    color: '#5493F7',
    fontWeight: 500,
    marginRight: '4px',
  },
  tooltipLine: {
    width: '100%',
    height: '1px',
    borderBottom: 'none',
    border: 'none',
    backgroundColor: '#4B567D',
    marginTop: '8px',
    marginBottom: '8px',
  },
  tooltipItemTotal: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewards: {
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipItemWithoutRewards: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewardsLong: {
    extend: 'tooltipWithoutRewards',
    // minWidth: '165px',
    // width: '100%',
  },

  lastTooltipItem: {
    extend: 'tooltipItem',
    marginBottom: '0px',
  },
  lastTooltipItemLong: {
    extend: 'tooltipItemLong',
    marginBottom: '0px',
  },
  noTexttooltipItemWithoutRewards: {
    extend: 'tooltipItemWithoutRewards',
    justifyContent: 'center',
  },
  '@media (max-width: 1250px)': {
    transactionsTitle: {
      fontSize: '18px',
      paddingBottom: 23,
    },
    scrollAria: {
      height: 420,
    },
    transactionsBlock: {
      height: 575,
    },
    assetInfoTokenName: {
      fontSize: '14px',
    },
    assetTimeInfoTokenName: {
      fontSize: '14px',
    },
  },
  '@media (max-width: 730px)': {
    assetInfoTokenName: {
      fontSize: '14px',
    },
    transactionsBlock: {
      height: 480,
      paddingBottom: 20,
    },
    transactions: {
      width: 'auto',
    },
    assetTimeInfoTokenName: {
      fontSize: '14px',
    },
  },
})

const Transactions = () => {
  const [blur, setBlur] = useState(false)
  const [fetching, setFetching] = useState(true)

  const classes = styles()
  const { data } = useSelector((state: any) => state.transactionsReducer)
  const { user } = useSelector((state: any) => state.userReducer)
  const dispatch = useDispatch()
  const skeletonsArray = Array(8).fill(1)
  const [transactionImages, setTransactionImages] = useState<{
    image: string
  } | null>(null)

  useEffect(() => {
    setFetching(true)
    request({
      method: 'get',
      path: `transactions/${user.address}`,
    })
      .then((res) => dispatch(setTransactions(res.data.data)))
      .finally(() => {
        setFetching(false)
      })
  }, [])

  useEffect(() => {
    setFetching(true)
    request({
      method: 'get',
      path: `transactions/${user.address}`,
    })
      .then((res) => dispatch(setTransactions(res.data.data)))
      .finally(() => {
        setFetching(false)
      })
  }, [user.address])

  useEffect(() => {
    setFetching(true)
    request({
      method: 'get',
      path: `assets/config`,
    })
      .then((res) => {
        setTransactionImages(res.data.data)
      })
      .finally(() => {
        setFetching(false)
      })
  }, [])

  const logoForTransactions = (item: any) => {
    if (!transactionImages) return

    return item.event === 'MintNft'
      ? mintNftLogo
      : item.event === 'TicketsPurchase'
      ? purchaseTicketLogo
      : item.event === 'NftStaked'
      ? stakeNftLogo
      : item.event === 'NftUnstaked'
      ? unstakeNftLogo
      : item.event === 'ClaimRewardIncentive'
      ? transactionImages[item.data.incentive.symbol]?.image
      : item.event === 'ClaimedRewards' && !item.data?.incentive?.symbol
      ? transactionImages['ORB']?.image
      : item.event === 'ClaimedRewards'
      ? transactionImages[item.data.incentive.symbol]?.image
      : item.event === 'LP_Staked'
      ? item?.data?.incentive?.image?.image
      : item.event === 'LP_Unstaked'
      ? item?.data?.incentive?.image?.image
      : item.event === 'LP_UnstakeRequested'
      ? item?.data?.incentive?.image?.image
      : item.event === 'LP_Claimed'
      ? transactionImages[item.data.incentive.symbol]?.image
      : item.event === 'LP_Approval'
      ? item?.data?.incentive?.image?.image
      : item.event === 'LP_ClaimedLpTokens'
      ? item?.data?.incentive?.image?.image
      : item.event === 'XORB_Staked'
      ? item?.data?.incentive?.image?.image
      : item.event === 'XORB_Unstaked'
      ? item?.data?.incentive?.image?.image
      : item.event === 'XORB_UnstakeRequested'
      ? item?.data?.incentive?.image?.image
      : item.event === 'XORB_Claimed'
      ? transactionImages[item.data.incentive.symbol]?.image
      : item.event === 'XORB_Approval'
      ? item?.data?.incentive?.image?.image
      : item.event === 'XORB_ClaimedLpTokens'
      ? item?.data?.incentive?.image?.image
      : item.event === 'ORB_CONVERT_CONVERT'
      ? transactionImages[item.data.incentive.symbol]?.image
      : item.event === 'ORB_CONVERT_REDEEM'
      ? transactionImages[item.data.incentive.symbol]?.image
      : item.event === 'ORB_CONVERT_CANCELREDEEM'
      ? transactionImages[item.data.incentive.symbol]?.image
      : item.event === 'ORB_CONVERT_CLAIM'
      ? transactionImages[item.data.incentive.symbol]?.image
      : null
  }

  const nameTransactions = (name: any) => {
    return name === 'Approval'
      ? 'Approve'
      : name === 'Borrow'
      ? 'Borrow'
      : name === 'Mint'
      ? 'Supply'
      : name === 'RepayBorrow'
      ? 'Repay'
      : name === 'Redeem'
      ? 'Withdraw'
      : name === 'MarketExited'
      ? 'Exit market'
      : name === 'MarketEntered'
      ? 'Enter market'
      : name === 'TicketsPurchase'
      ? 'Purchase Ticket'
      : name === 'ClaimRewardIncentive'
      ? 'Claim rewards'
      : name === 'MintNft'
      ? 'Mint NFT'
      : name === 'NftStaked'
      ? 'Stake NFT'
      : name === 'NftUnstaked'
      ? 'Unstake NFT'
      : name === 'ClaimedRewards'
      ? 'Claim rewards'
      : name === 'LP_Claimed'
      ? 'Claim rewards'
      : name === 'LP_UnstakeRequested'
      ? 'Unstake Request'
      : name === 'LP_Unstaked'
      ? 'Unstake LP'
      : name === 'LP_Staked'
      ? 'Stake LP'
      : name === 'LP_Approval'
      ? 'Approve LP'
      : name === 'LP_ClaimedLpTokens'
      ? 'Withdraw LP'
      : name === 'XORB_Claimed'
      ? 'Claim rewards'
      : name === 'XORB_UnstakeRequested'
      ? 'Unstake Request'
      : name === 'XORB_Unstaked'
      ? 'Unstake xORB'
      : name === 'XORB_Staked'
      ? 'Stake xORB'
      : name === 'XORB_Approval'
      ? 'Approve xORB'
      : name === 'XORB_ClaimedLpTokens'
      ? 'Withdraw xORB'
      : name === 'ORB_CONVERT_CONVERT'
      ? 'Convert'
      : name === 'ORB_CONVERT_REDEEM'
      ? 'Redeem'
      : name === 'ORB_CONVERT_CLAIM'
      ? 'Claim'
      : name === 'ORB_CONVERT_CANCELREDEEM'
      ? 'Cancel Redeem'
      : name
  }
  const colorTransactions = (name: any) => {
    return name === 'Approval'
      ? '#54BCF7'
      : name === 'Borrow'
      ? '#FBC928'
      : name === 'Mint'
      ? '#5493F7'
      : name === 'RepayBorrow'
      ? '#8854F7'
      : name === 'Redeem'
      ? '#53CBC8'
      : name === 'MarketExited'
      ? '#F754AC'
      : name === 'MarketEntered'
      ? '#F78F54'
      : 'FBC928'
  }

  const linkToscanForHash = (item: any) => {
    return item?.typeNetwork === 'moonbase'
      ? `https://moonbase.moonscan.io/tx/${item.txHash}`
      : item?.typeNetwork === 'moonbeam'
      ? `https://moonbeam.moonscan.io/tx/${item.txHash}`
      : item?.typeNetwork === 'arbitrum'
      ? `https://goerli.arbiscan.io/tx/${item.txHash}`
      : item?.typeNetwork === 'zksync'
      ? `https://goerli.explorer.zksync.io/tx/${item.txHash}`
      : `https://moonbase.moonscan.io/tx/${item.txHash}`
  }

  const hideBlur = (e: any) => {
    setBlur(
      e.target.scrollHeight - e.target.offsetHeight - 40 <= e.target.scrollTop
    )
  }

  return (
    <div className={classes.transactions}>
      <div
        className={
          data.entities.length === 0
            ? classes.noTransactionsTitle
            : classes.transactionsTitle
        }
      >
        Transactions
      </div>

      <div
        className={
          data.entities.length === 0
            ? classes.noTransactionsBlock
            : (data.entities.length !== 0 && data.entities.length < 9) || blur
            ? classes.transactionsBlockWithPadding
            : classes.transactionsBlockWithBlur
        }
      >
        {data.entities.length === 0 ? (
          <div className={classes.noCompositionsWrapper}>
            <img
              className={classes.noCompositionsImg}
              src={onCompositionImg}
              alt=""
            />
            <div className={classes.noCompositions}>
              <div className={classes.noCompositionsMainText}>
                There's nothing here yet
              </div>
              <div className={classes.noCompositionsSubText}>
                You haven't transactions anything yet
              </div>
            </div>
          </div>
        ) : (
          <CustomScrollWrap mathod={hideBlur}>
            <div className={classes.scrollWrapper}>
              {fetching
                ? skeletonsArray.map((el, index) => (
                    <div
                      key={index}
                      className={classes.transactionsInfoWrapper}
                    >
                      <div>
                        <Skeleton
                          baseColor="#4B567D"
                          highlightColor="#5B6796"
                          width="40px"
                          height="40px"
                          count={1}
                          borderRadius="32px"
                        />
                      </div>
                      <div className={classes.assetInfo}>
                        <div>
                          <div className={classes.assetInfoTitle}>
                            <Skeleton
                              baseColor="#4B567D"
                              highlightColor="#5B6796"
                              width={120}
                              count={1}
                              height={14}
                              borderRadius="9px"
                              style={{ marginBottom: 5 }}
                            />
                          </div>
                          <div className={classes.assetInfoTokenName}>
                            <Skeleton
                              baseColor="#4B567D"
                              highlightColor="#5B6796"
                              width={120}
                              count={1}
                              height={14}
                              borderRadius="9px"
                            />
                          </div>
                        </div>
                        <div>
                          <div>
                            <div className={classes.assetInfoTitle}>
                              <Skeleton
                                baseColor="#4B567D"
                                highlightColor="#5B6796"
                                width={120}
                                count={1}
                                height={14}
                                borderRadius="9px"
                                style={{ marginBottom: 5 }}
                              />
                            </div>
                            <div className={classes.assetInfoTokenName}>
                              <Skeleton
                                baseColor="#4B567D"
                                highlightColor="#5B6796"
                                width={120}
                                count={1}
                                height={14}
                                borderRadius="9px"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : data.entities.map((item: any, index: number) => (
                    <div
                      key={index}
                      className={classes.transactionsInfoWrapper}
                    >
                      <div>
                        <img
                          className={classes.assetInfoIcon}
                          src={
                            item.token.image
                              ? item.token.image
                              : logoForTransactions(item)
                          }
                        />
                      </div>
                      <div className={classes.assetInfo}>
                        <div>
                          <div className={classes.assetInfoTitle}>
                            <span
                              className={classes.borrowText}
                              // style={{ color: colorTransactions(item.event) }}
                              style={{ color: '#5493F7' }}
                            >
                              {nameTransactions(item.event)}
                            </span>
                            <a href={linkToscanForHash(item)} target="_blank">
                              <img
                                className={classes.icon}
                                src={button}
                                alt=""
                              />
                            </a>
                          </div>
                          <div className={classes.assetInfoTokenName}>
                            {item.status ? 'Success' : 'Denied'}
                          </div>
                        </div>
                        <div
                          className={
                            item.data.amount === null &&
                            item.event !== 'TicketsPurchase'
                              ? classes.noAmount
                              : ''
                          }
                        >
                          {item.data.amount !== null ||
                          item.event === 'TicketsPurchase' ? (
                            <div className={classes.assetInfoWrapper}>
                              <Tooltip
                                overlayInnerStyle={{ boxShadow: 'none' }}
                                color="none"
                                placement="top"
                                title={
                                  <div
                                    className={classes.tooltipWithoutRewards}
                                  >
                                    <div
                                      className={
                                        classes.noTexttooltipItemWithoutRewards
                                      }
                                    >
                                      {/* <span className={classes.tooltipItemText}>Balance</span> */}
                                      <span>
                                        {item.event === 'TicketsPurchase'
                                          ? commify(
                                              transform(
                                                numberToStringInput(
                                                  item.data.lottery.countTickets
                                                ),
                                                6
                                              )
                                            )
                                          : commify(
                                              transform(
                                                numberToStringInput(
                                                  +item.data.amount
                                                ),
                                                6
                                              )
                                            )}
                                      </span>
                                    </div>
                                  </div>
                                }
                              >
                                <span className={classes.assetInfoPercentages}>
                                  <span className={classes.assetsAmount}>
                                    {' '}
                                    {item.event === 'TicketsPurchase' ? (
                                      <>
                                        {transform(
                                          item.data.lottery.countTickets,
                                          2
                                        )}{' '}
                                        {Number(
                                          item.data.lottery.countTickets
                                        ) > 1
                                          ? 'tickets'
                                          : 'ticket'}
                                      </>
                                    ) : (
                                      <>
                                        +{transform(item.data.amount, 2)}
                                        {item.data.amount &&
                                        item.data.amount.includes('.')
                                          ? null
                                          : '.00'}
                                      </>
                                    )}
                                  </span>{' '}
                                  {item.token.symbol
                                    ? item.token.symbol
                                    : item.event === 'ClaimedRewards' &&
                                      !item.data?.incentive?.symbol
                                    ? 'ORB'
                                    : item.data?.incentive?.symbol ===
                                      'LP_TOKEN'
                                    ? 'LP'
                                    : item.data?.incentive?.symbol}
                                </span>
                              </Tooltip>
                            </div>
                          ) : null}
                          <div className={classes.assetTimeInfoTokenName}>
                            {moment(item.createdAt).fromNow()}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </CustomScrollWrap>
        )}
      </div>
    </div>
  )
}

export default Transactions
