import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface marketOverviewState {
  data: {
    totalSupplyAmount: string
    totalBorrowAmount: string
    mostSupply: {
      name: string
      symbol: string
      image: string
      _id: string
    }
    mostBorrow: {
      name: string
      symbol: string
      image: string
      _id: string
    }
  }
}

const initialState: marketOverviewState = {
  data: {
    totalSupplyAmount: '',
    totalBorrowAmount: '',
    mostSupply: {
      name: '',
      symbol: '',
      image: '',
      _id: '',
    },
    mostBorrow: {
      name: '',
      symbol: '',
      image: '',
      _id: '',
    },
  },
}

export const marketOverviewSlice = createSlice({
  name: 'marketOverview',
  initialState,
  reducers: {
    setMarketOverview: (state, action: PayloadAction<any>) => {
      state.data = action.payload
    },
  },
})

export const { setMarketOverview } = marketOverviewSlice.actions

export default marketOverviewSlice.reducer
