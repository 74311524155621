import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import wallet from '../../img/wallet-add.svg'
import swapGray from '../../img/swap-gray.svg'
import ChartComponent from '../Chart'
import { useDispatch, useSelector } from 'react-redux'
import { transform } from '../../factory/bigNumber'
import whiteWallet from '../../img/wallet-add-white.svg'
import { Tooltip } from 'antd'
import Skeleton from 'react-loading-skeleton'
import { setAsset } from '../../redux/asset'
import supplyBox from '../../img/supplyBox.svg'
import borrowBox from '../../img/BorrowBox.svg'
import { totalIncentives } from '../../utils'

const styles = createUseStyles({
  information: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    zIndex: 1,
    padding: [15, 20, 30],
    borderRadius: 12,
    boxShadow: 'inset 1px 1px 1px #42426a',
    cursor: 'default',
  },
  informationTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  informationWrapper: {},
  informationTitle: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#FFFFFF',
  },
  buttonWrapper: {
    display: 'flex',
    gap: 32,
    alignItems: 'center',
    cursor: 'pointer',
  },
  addToWalletWrapper: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',

    '&:$addToWalletImg': {},
    '&:$addToWalletImgWhite': {},
    '&:$addToWalletText': {},
    '&:hover $addToWalletImgWhite': {
      display: 'block',
    },
    '&:hover $addToWalletImg': {
      display: 'none',
    },
    '&:hover $addToWalletText': {
      color: 'white',
    },
  },
  addToWalletText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
  },
  swapText: {
    extend: 'addToWalletText',
    color: '#A8A8B2',
  },
  swapToken: {
    extend: 'addToWalletWrapper',
    textDecoration: 'none',
  },
  supplyWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  totalSupply: {
    display: 'flex',
    gap: 15,
    alignItems: 'center',
    paddingBottom: 15,
  },
  supplyBlockWrapper: {
    display: 'flex',
  },
  supplyBlock: {
    width: '50%',
  },
  subText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
    paddingBottom: 5,
  },
  mainText: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
    cursor: 'default',
  },
  marketInformationWrapper: {
    paddingTop: 15,
    width: '50%',
  },
  marketInformationSubTextWrapper: {
    paddingBottom: 10,
  },
  marketInformationSubText: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#A8A8B2',
    textDecorationLine: 'underline',
    cursor: 'default',
  },
  marketInformationMainText: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    paddingLeft: 5,
    cursor: 'default',
  },
  hrWrapper: {
    display: 'flex',
    gap: 20,
    paddingBottom: 40,
  },
  hr: {
    backgroundColor: '#4B567D',
    width: 1,
    height: 40,
    marginTop: 10,
  },
  marketInformationLastText: {
    paddingBottom: 20,
  },
  addToWalletImg: {
    display: 'block',
  },
  addToWalletImgWhite: {
    display: 'none',
  },
  widthMobileTooltip: {},
  totalSupplyWrapper: {},
  chartWrapper: {
    display: 'flex',
    gap: 140,
  },
  chartWrapperNoConnected: {
    display: 'flex',
    gap: 335,
  },
  tooltipWithRewards: {
    width: '165px',
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipWithRewardsLong: {
    extend: 'tooltipWithRewards',
    minWidth: '165px',
    width: '100%',
  },
  tooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '4px',
  },
  tooltipItemText: {
    color: '#A8A8B2',
    fontWeight: 400,
    fontSize: '14px',
  },
  tooltipItemTextLong: {
    extend: 'tooltipItemText',
    marginRight: '10px',
  },
  tooltipItemNumber: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: '14px',
  },
  tooltipItemTextSign: {
    color: '#5493F7',
    fontWeight: 500,
    marginRight: '4px',
  },
  tooltipLine: {
    width: '100%',
    height: '1px',
    borderBottom: 'none',
    border: 'none',
    backgroundColor: '#4B567D',
    marginTop: '8px',
    marginBottom: '8px',
  },
  tooltipItemTotal: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewards: {
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipItemWithoutRewards: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewardsLong: {
    extend: 'tooltipWithoutRewards',
    // minWidth: '165px',
    // width: '100%',
  },

  lastTooltipItem: {
    extend: 'tooltipItem',
    marginBottom: '0px',
  },
  lastTooltipItemLong: {
    extend: 'tooltipItemLong',
    marginBottom: '0px',
  },
  noTexttooltipItemWithoutRewards: {
    extend: 'tooltipItemWithoutRewards',
    justifyContent: 'center',
  },
  '@media (max-width: 1250px)': {
    information: {
      paddingTop: 30,
      paddingBottom: 30,
      paddingLeft: 20,
      paddingRight: 20,
    },
    totalSupply: {
      display: 'block',
    },
    informationTitleWrapper: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 30,
      alignItems: 'start',
      gap: 12,
      justifyContent: 'space-between',
    },
    informationTitle: {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '27px',
      color: '#FFFFFF',
    },
    informationWrapper: {
      display: 'flex',
      gap: 20,
    },
    supplyBlockWrapper: {
      display: 'block',
    },
    supplyBlock: {
      width: '100%',
    },
    mainText: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#FFFFFF',
      paddingBottom: 5,
    },
    marketInformationWrapper: {
      paddingTop: 10,
      width: '100%',
    },
    hrWrapper: {
      display: 'flex',
      gap: 20,
      paddingBottom: 20,
    },
  },
  '@media (max-width: 730px)': {
    information: {
      borderRadius: '0px 0 12px 12px',
    },
    informationTitleWrapper: {
      alignItems: 'start',
    },
    informationWrapper: {
      display: 'block',
    },
    widthMobileTooltip: {
      maxWidth: '200px !important',
    },
  },
})

interface Props {
  addToken: (a: any) => void
}
const Information: React.FC<Props> = ({ addToken }) => {
  const [fetching, setFetching] = useState(true)

  const classes = styles()

  const dispatch = useDispatch()

  const { asset } = useSelector((state: any) => state.assetReducer)
  const { user } = useSelector((state: any) => state.userReducer)

  useEffect(() => {
    return () => {
      dispatch(setAsset({}))
    }
  }, [])

  useEffect(() => {
    if (!asset._id) {
      setFetching(true)
      return
    }
    setFetching(false)
  }, [asset, user.address])

  const usdReserves = asset.totalReserves * asset.lastPrice
  const usdLiquidity = asset.liquidity * asset.lastPrice

  const apy = () => {
    const totalBorrow =
      totalIncentives(asset.incentives, 'borrow') - parseFloat(asset.borrowRate)

    if (totalBorrow > 1000) {
      return '+ <1000'
    }
    if (totalBorrow > 0) {
      return '+' + transform(totalBorrow.toString(), 2)
    }
    return transform(totalBorrow.toString(), 2)
  }

  return (
    <div className={classes.information}>
      <div className={classes.informationTitleWrapper}>
        {fetching ? (
          <Skeleton
            baseColor="#4B567D"
            highlightColor="#5B6796"
            width="150px"
            height="20px"
            count={1}
            borderRadius="32px"
          />
        ) : (
          <div className={classes.informationTitle}>
            {asset.symbol} Information
          </div>
        )}
        <div className={classes.buttonWrapper}>
          {fetching ? (
            <Skeleton
              baseColor="#4B567D"
              highlightColor="#5B6796"
              width="140px"
              height="20px"
              count={1}
              borderRadius="32px"
            />
          ) : (
            asset.symbol !== 'GLMR' && (
              <div className={classes.addToWalletWrapper}>
                <img className={classes.addToWalletImg} src={wallet} alt="" />
                <img
                  className={classes.addToWalletImgWhite}
                  src={whiteWallet}
                  alt=""
                />
                <div
                  onClick={() =>
                    addToken({
                      address: asset.tokenAddress,
                      symbol: asset.symbol,
                      decimals: asset.tokenDecimal,
                      image: asset.image,
                    })
                  }
                  className={classes.addToWalletText}
                >
                  ADD TO WALLET
                </div>
              </div>
            )
          )}
          <div>
            {fetching ? (
              <Skeleton
                baseColor="#4B567D"
                highlightColor="#5B6796"
                width="140px"
                height="20px"
                count={1}
                borderRadius="32px"
              />
            ) : (
              <a
                className={[classes.addToWalletWrapper, classes.swapToken].join(
                  ' '
                )}
                href="https://app.stellaswap.com/exchange/swap"
                target="_blank"
              >
                <img src={swapGray} alt="" />
                <div className={classes.swapText}>SWAP {asset.symbol}</div>
              </a>
            )}
          </div>
        </div>
      </div>
      <div className={classes.informationWrapper}>
        <div>
          <div>
            <div>
              {window.innerWidth > 1250 ? (
                <div
                  className={
                    user.connected
                      ? classes.chartWrapper
                      : classes.chartWrapperNoConnected
                  }
                >
                  <div className={classes.totalSupply}>
                    <img src={supplyBox} alt="" />
                    <div className={classes.totalSupplyWrapper}>
                      <div className={classes.subText}>Total Supply</div>
                      {fetching ? (
                        <Skeleton
                          baseColor="#4B567D"
                          highlightColor="#5B6796"
                          width="150px"
                          height="20px"
                          count={1}
                          borderRadius="32px"
                        />
                      ) : (
                        <div className={classes.mainText}>
                          {transform(asset.totalSupply)} {asset.symbol}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={classes.totalSupply}>
                    <img src={borrowBox} alt="" />
                    <div className={classes.totalSupplyWrapper}>
                      <div className={classes.subText}>Total Borrow</div>
                      {fetching ? (
                        <Skeleton
                          baseColor="#4B567D"
                          highlightColor="#5B6796"
                          width="150px"
                          height="20px"
                          count={1}
                          borderRadius="32px"
                        />
                      ) : (
                        <div className={classes.mainText}>
                          {transform(asset.totalBorrow)} {asset.symbol}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <ChartComponent />
            <div className={classes.supplyBlockWrapper}>
              <div className={classes.supplyBlock}>
                {window.innerWidth < 1251 ? (
                  <div className={classes.totalSupply}>
                    <div className={classes.subText}>Total Supply</div>
                    {fetching ? (
                      <Skeleton
                        baseColor="#4B567D"
                        highlightColor="#5B6796"
                        width="150px"
                        height="20px"
                        count={1}
                        borderRadius="32px"
                      />
                    ) : (
                      <div className={classes.mainText}>
                        {transform(asset.totalSupply)} {asset.symbol}
                      </div>
                    )}
                  </div>
                ) : null}
                <div className={classes.hrWrapper}>
                  <div>
                    <div className={classes.subText}>Supply APY</div>
                    {fetching ? (
                      <Skeleton
                        baseColor="#4B567D"
                        highlightColor="#5B6796"
                        width="150px"
                        height="20px"
                        count={1}
                        borderRadius="32px"
                      />
                    ) : (
                      <div className={classes.mainText}>
                        {asset.incentives?.length
                          ? '+' +
                            (totalIncentives(asset.incentives, 'supply') +
                              parseFloat(asset.supplyRate) >
                            1000
                              ? '<1000'
                              : transform(
                                  (
                                    totalIncentives(
                                      asset.incentives,
                                      'supply'
                                    ) + parseFloat(asset.supplyRate)
                                  ).toString(),
                                  2
                                ))
                          : '+' + transform(asset.supplyRate, 2)}
                        %
                      </div>
                    )}
                  </div>
                  {window.innerWidth > 1250 ? (
                    <div className={classes.hr}></div>
                  ) : null}
                  {window.innerWidth > 1250 ? (
                    <div>
                      <div className={classes.subText}>Borrow APY</div>
                      {fetching ? (
                        <Skeleton
                          baseColor="#4B567D"
                          highlightColor="#5B6796"
                          width="150px"
                          height="20px"
                          count={1}
                          borderRadius="32px"
                        />
                      ) : (
                        <div className={classes.mainText}>
                          <div className={classes.mainText}>
                            {asset.incentives?.length
                              ? apy()
                              : -transform(asset.borrowRate, 2)}
                            %
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                {window.innerWidth < 1251 ? (
                  <div className={classes.totalSupply}>
                    <div className={classes.subText}>Total Borrow</div>
                    {fetching ? (
                      <Skeleton
                        baseColor="#4B567D"
                        highlightColor="#5B6796"
                        width="150px"
                        height="20px"
                        count={1}
                        borderRadius="32px"
                      />
                    ) : (
                      <div className={classes.mainText}>
                        {transform(asset.totalBorrow)} {asset.symbol}
                      </div>
                    )}
                  </div>
                ) : null}
                <div className={classes.hrWrapper}>
                  {window.innerWidth < 1251 ? (
                    <div>
                      <div className={classes.subText}>Borrow APY</div>
                      {fetching ? (
                        <Skeleton
                          baseColor="#4B567D"
                          highlightColor="#5B6796"
                          width="150px"
                          height="20px"
                          count={1}
                          borderRadius="32px"
                        />
                      ) : (
                        <div className={classes.mainText}>
                          {asset.incentives?.length
                            ? apy()
                            : -transform(asset.borrowRate, 2)}
                          %
                        </div>
                      )}
                    </div>
                  ) : null}
                  {/*<div className={classes.hr}></div>*/}
                  {/*<div>*/}
                  {/*  <div className={classes.subText}>Distribution APY</div>*/}
                  {/*  <div className={classes.mainText}>24.15%</div>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.supplyWrapper}>
          <div>
            <div className={classes.subText}>Market Information</div>
            <div className={classes.supplyBlockWrapper}>
              {fetching ? (
                <div style={{ width: '50%' }}>
                  <Skeleton
                    baseColor="#4B567D"
                    highlightColor="#5B6796"
                    width="150px"
                    height="20px"
                    count={5}
                    borderRadius="32px"
                    style={{ marginBottom: 10 }}
                  />
                </div>
              ) : (
                <div className={classes.marketInformationWrapper}>
                  <div className={classes.marketInformationSubTextWrapper}>
                    <Tooltip
                      overlayInnerStyle={{ boxShadow: 'none' }}
                      color="none"
                      placement="top"
                      title={
                        <div className={classes.tooltipWithoutRewards}>
                          <div
                            className={classes.noTexttooltipItemWithoutRewards}
                          >
                            {/* <span className={classes.tooltipItemText}>Balance</span> */}
                            <span>
                              The current price for this asset from the DIA
                              Oracle Feed
                            </span>
                          </div>
                        </div>
                      }
                      className={classes.marketInformationSubText}
                    >
                      Price:
                    </Tooltip>
                    <span className={classes.marketInformationMainText}>
                      {/* <Tooltip
                      placement="top"
                      title="The current price for this asset from the Chainlink Oracle Feed"
                      color="#222"
                    > */}
                      ${transform(asset.lastPrice, 2)}
                      {/* </Tooltip> */}
                    </span>
                  </div>
                  <div className={classes.marketInformationSubTextWrapper}>
                    <Tooltip
                      overlayInnerStyle={{ boxShadow: 'none' }}
                      color="none"
                      title={
                        <div className={classes.tooltipWithoutRewards}>
                          <div
                            className={classes.noTexttooltipItemWithoutRewards}
                          >
                            {/* <span className={classes.tooltipItemText}>Balance</span> */}
                            <span>
                              The amount that can be borrowed against this asset
                              when it's supplied
                            </span>
                          </div>
                        </div>
                      }
                      className={classes.marketInformationSubText}
                    >
                      Collateral Factor:
                    </Tooltip>
                    <span className={classes.marketInformationMainText}>
                      {transform(asset.collateralFactor, 2)}%
                    </span>
                  </div>
                  <div className={classes.marketInformationSubTextWrapper}>
                    <Tooltip
                      overlayInnerStyle={{ boxShadow: 'none' }}
                      color="none"
                      title={
                        <div className={classes.tooltipWithoutRewards}>
                          <div
                            className={classes.noTexttooltipItemWithoutRewards}
                          >
                            {/* <span className={classes.tooltipItemText}>Balance</span> */}
                            <span>
                              {`The amount of ${asset.symbol} available to borrow`}
                            </span>
                          </div>
                        </div>
                      }
                      className={classes.marketInformationSubText}
                    >
                      Liquidity:
                    </Tooltip>
                    <span className={classes.marketInformationMainText}>
                      {transform(asset.liquidity, 2)} {asset.symbol}
                    </span>
                  </div>
                  <div className={classes.marketInformationSubTextWrapper}>
                    <Tooltip
                      overlayInnerStyle={{ boxShadow: 'none' }}
                      color="none"
                      title={
                        <div className={classes.tooltipWithoutRewards}>
                          <div
                            className={classes.noTexttooltipItemWithoutRewards}
                          >
                            {/* <span className={classes.tooltipItemText}>Balance</span> */}
                            <span>
                              {`The ratio of supplied ${asset.symbol} to borrowed ${asset.symbol}`}
                            </span>
                          </div>
                        </div>
                      }
                      className={classes.marketInformationSubText}
                    >
                      Utilization:
                    </Tooltip>
                    <span className={classes.marketInformationMainText}>
                      {transform(asset.utilization, 2)}%
                    </span>
                  </div>
                  <div>
                    <Tooltip
                      overlayInnerStyle={{ boxShadow: 'none' }}
                      color="none"
                      title={
                        <div className={classes.tooltipWithoutRewards}>
                          <div
                            className={classes.noTexttooltipItemWithoutRewards}
                          >
                            {/* <span className={classes.tooltipItemText}>Balance</span> */}
                            <span>
                              {`The amount of ${asset.symbol} that the protocol has earned in fees`}
                            </span>
                          </div>
                        </div>
                      }
                      className={classes.marketInformationSubText}
                    >
                      Reserves:
                    </Tooltip>
                    <span className={classes.marketInformationMainText}>
                      {transform(asset.totalReserves)} {asset.symbol}
                    </span>
                  </div>
                </div>
              )}
              {fetching ? (
                <div style={{ width: '50%' }}>
                  <Skeleton
                    baseColor="#4B567D"
                    highlightColor="#5B6796"
                    width="150px"
                    height="20px"
                    count={5}
                    borderRadius="32px"
                    style={{ marginBottom: 10 }}
                  />
                </div>
              ) : (
                <div className={classes.marketInformationWrapper}>
                  <div>
                    <div className={classes.marketInformationSubTextWrapper}>
                      <Tooltip
                        overlayInnerStyle={{ boxShadow: 'none' }}
                        color="none"
                        title={
                          <div className={classes.tooltipWithoutRewards}>
                            <div
                              className={
                                classes.noTexttooltipItemWithoutRewards
                              }
                            >
                              {/* <span className={classes.tooltipItemText}>Balance</span> */}
                              <span>
                                {`The number of addresses that are supplying ${asset.symbol} to the market for borrowing`}
                              </span>
                            </div>
                          </div>
                        }
                        className={classes.marketInformationSubText}
                      >
                        Number of Suppliers:
                      </Tooltip>
                      <span className={classes.marketInformationMainText}>
                        {transform(asset.countSuppliers)}
                      </span>
                    </div>
                    <div className={classes.marketInformationSubTextWrapper}>
                      <Tooltip
                        overlayInnerStyle={{ boxShadow: 'none' }}
                        color="none"
                        title={
                          <div className={classes.tooltipWithoutRewards}>
                            <div
                              className={
                                classes.noTexttooltipItemWithoutRewards
                              }
                            >
                              {/* <span className={classes.tooltipItemText}>Balance</span> */}
                              <span>
                                {`The number of addresses that are borrowing ${asset.symbol} from the market`}
                              </span>
                            </div>
                          </div>
                        }
                        className={classes.marketInformationSubText}
                      >
                        Number of Borrowers:
                      </Tooltip>
                      <span className={classes.marketInformationMainText}>
                        {transform(asset.countBorrowers)}
                      </span>
                    </div>
                    <div className={classes.marketInformationSubTextWrapper}>
                      <Tooltip
                        overlayInnerStyle={{ boxShadow: 'none' }}
                        color="none"
                        title={
                          <div className={classes.tooltipWithoutRewards}>
                            <div
                              className={
                                classes.noTexttooltipItemWithoutRewards
                              }
                            >
                              {/* <span className={classes.tooltipItemText}>Balance</span> */}
                              <span>
                                The existing market liquidity depth, in USD
                              </span>
                            </div>
                          </div>
                        }
                        className={classes.marketInformationSubText}
                      >
                        Liquidity (USD):
                      </Tooltip>
                      <span className={classes.marketInformationMainText}>
                        ${transform(usdLiquidity.toString(), 2)}
                      </span>
                    </div>
                    <div className={classes.marketInformationSubTextWrapper}>
                      <Tooltip
                        overlayInnerStyle={{ boxShadow: 'none' }}
                        color="none"
                        title={
                          <div className={classes.tooltipWithoutRewards}>
                            <div
                              className={
                                classes.noTexttooltipItemWithoutRewards
                              }
                            >
                              {/* <span className={classes.tooltipItemText}>Balance</span> */}
                              <span>
                                The portion of interest set aside to the
                                reserves Docs Link
                              </span>
                            </div>
                          </div>
                        }
                        className={classes.marketInformationSubText}
                      >
                        Reserve Factor:
                      </Tooltip>
                      <span className={classes.marketInformationMainText}>
                        {transform(asset.reserveFactor, 2)}%
                      </span>
                    </div>
                    <div className={classes.marketInformationLastText}>
                      <Tooltip
                        overlayInnerStyle={{ boxShadow: 'none' }}
                        color="none"
                        title={
                          <div className={classes.tooltipWithoutRewards}>
                            <div
                              className={
                                classes.noTexttooltipItemWithoutRewards
                              }
                            >
                              {/* <span className={classes.tooltipItemText}>Balance</span> */}
                              <span>
                                {`The amount of reserved ${asset.symbol}, denominated in USD`}
                              </span>
                            </div>
                          </div>
                        }
                        className={classes.marketInformationSubText}
                      >
                        Reserves (USD):
                      </Tooltip>
                      <span className={classes.marketInformationMainText}>
                        ${transform(usdReserves.toString(), 2)}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={classes.subText}>Market Status</div>
            <div className={classes.supplyBlockWrapper}>
              <div className={classes.marketInformationWrapper}>
                {fetching ? (
                  <div style={{ width: '50%' }}>
                    <Skeleton
                      baseColor="#4B567D"
                      highlightColor="#5B6796"
                      width="150px"
                      height="20px"
                      count={1}
                      borderRadius="32px"
                      style={{ marginBottom: 10 }}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <div className={classes.marketInformationSubTextWrapper}>
                        <Tooltip
                          overlayInnerStyle={{ boxShadow: 'none' }}
                          color="none"
                          title={
                            <div className={classes.tooltipWithoutRewards}>
                              <div
                                className={
                                  classes.noTexttooltipItemWithoutRewards
                                }
                              >
                                {/* <span className={classes.tooltipItemText}>Balance</span> */}
                                <span>
                                  If the market supply is toggled off, no more
                                  liquidity of this type can be added to the
                                  protocol
                                </span>
                              </div>
                            </div>
                          }
                          className={classes.marketInformationSubText}
                        >
                          Supply Enabled:
                        </Tooltip>
                        <span className={classes.marketInformationMainText}>
                          {!asset.supplyPaused ? 'Yes' : 'No'}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={classes.marketInformationWrapper}>
                {fetching ? (
                  <div style={{ width: '50%' }}>
                    <Skeleton
                      baseColor="#4B567D"
                      highlightColor="#5B6796"
                      width="150px"
                      height="20px"
                      count={1}
                      borderRadius="32px"
                      style={{ marginBottom: 10 }}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <div className={classes.marketInformationSubTextWrapper}>
                        <Tooltip
                          overlayInnerStyle={{ boxShadow: 'none' }}
                          color="none"
                          title={
                            <div className={classes.tooltipWithoutRewards}>
                              <div
                                className={
                                  classes.noTexttooltipItemWithoutRewards
                                }
                              >
                                {/* <span className={classes.tooltipItemText}>Balance</span> */}
                                <span>
                                  If the market supply is toggled off, no more
                                  liquidity of this type can be borrowed by
                                  anyone
                                </span>
                              </div>
                            </div>
                          }
                          className={classes.marketInformationSubText}
                        >
                          Borrow Enabled:
                        </Tooltip>
                        <span className={classes.marketInformationMainText}>
                          {!asset.borrowPaused ? 'Yes' : 'No'}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Information
