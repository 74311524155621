import React from 'react'
import { createUseStyles } from 'react-jss'
import { TraitInfo } from '../../redux/userNfts'

const styles = createUseStyles({
  mainWrapper: {
    position: 'relative',
    background: '#4B567D',
    width: 360,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    height: 613,
  },
  mainRareWrapper: {
    position: 'relative',
    background: 'linear-gradient(45.31deg, #7D63F8 0%, #5493F7 100%)',
    width: 360,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    height: 613,
  },
  cardWrapper: {
    width: 350,
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    boxSizing: 'border-box',
    position: 'relative',
    zIndex: 1,
    // border: '5px solid #4b567d',
  },
  rareWrapper: {
    extend: 'cardWrapper',
    background:
      'linear-gradient(180deg, rgba(29, 40, 77, 0.80) 0%, rgba(30, 30, 64, 0.80) 100%);',
  },
  rarBg: {
    position: 'absolute',
    background: 'linear-gradient(45deg, #7D63F8 0%, #5493F7 100%)',
    filter: 'blur(60px)',
    width: 300,
    height: 550,
    borderRadius: 12,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  cardImg: {
    width: 350,
    height: 350,
    borderRadius: [7, 7, 0, 0],
  },
  cardProperties: {
    padding: [20, 20, 10, 20],
    boxSizing: 'border-box',
  },
  cardTitle: {
    margin: 0,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 20,
  },
  cardSubText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#A8A8B2',
  },
  cardMainText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
  },
  cardRarityText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#AB84FF',
  },
  cardOriginalText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
  },
  cardProperty: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10,
  },
  rarityTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 20,
  },
  rarityWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
  },
  hr: {
    background: '#4B567D',
    width: 1,
    height: 24,
  },
  '@media (max-width: 1250px)': {
    mainWrapper: {
      position: 'relative',
      background: '#4B567D',
      width: 335,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 12,
      height: 588,
    },
    mainRareWrapper: {
      position: 'relative',
      background: 'linear-gradient(45.31deg, #7D63F8 0%, #5493F7 100%)',
      width: 335,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 12,
      height: 588,
    },
    cardTitle: {
      margin: 0,
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '19px',
      lineHeight: '30px',
      color: '#FFFFFF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: 20,
    },
    cardWrapper: {
      width: 325,
    },
    rareWrapper: {
      width: 325,
    },
    cardImg: {
      width: 325,
      height: 325,
    },
  },
  '@media (max-width: 730px)': {
    mainWrapper: {
      position: 'relative',
      background: '#4B567D',
      width: 340,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 12,
      height: 593,
    },
    mainRareWrapper: {
      position: 'relative',
      background: 'linear-gradient(45.31deg, #7D63F8 0%, #5493F7 100%)',
      width: 340,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 12,
      height: 593,
    },
    cardWrapper: {
      width: 330,
    },
    rareWrapper: {
      width: 330,
    },
    cardImg: {
      width: 330,
      height: 330,
    },
  },
})

interface Props {
  img: string
  title: string
  bg: TraitInfo
  skin: TraitInfo
  face: TraitInfo
  helmet: TraitInfo
  intercom: TraitInfo
  tokenId: number
}
const NFTGalleryCard: React.FC<Props> = ({
  img,
  title,
  bg,
  skin,
  face,
  helmet,
  intercom,
  tokenId,
}) => {
  const classes = styles()
  const faceRarity = +face.rarity > 0
  const bodyRarity = +skin.rarity > 2.43
  const helmetRarity = +helmet.rarity > 2.91
  const bgRarity = +bg.rarity > 2.04
  const intercomRarity = +intercom.rarity > 8

  return (
    <div
      className={intercomRarity ? classes.mainWrapper : classes.mainRareWrapper}
    >
      <div
        className={intercomRarity ? classes.cardWrapper : classes.rareWrapper}
      >
        <img className={classes.cardImg} src={img} alt="" />
        <div className={classes.cardProperties}>
          <p className={classes.cardTitle}>{`${title} #${tokenId}`}</p>
          <div className={classes.cardProperty}>
            <div className={classes.cardSubText}>Face</div>
            <div
              className={
                faceRarity ? classes.cardOriginalText : classes.cardRarityText
              }
            >
              {face.name}
            </div>
          </div>
          <div className={classes.cardProperty}>
            <div className={classes.cardSubText}>Body</div>
            <div
              className={
                bodyRarity ? classes.cardOriginalText : classes.cardRarityText
              }
            >
              {skin.name}
            </div>
          </div>
          <div className={classes.cardProperty}>
            <div className={classes.cardSubText}>Helmet</div>
            <div
              className={
                helmetRarity ? classes.cardOriginalText : classes.cardRarityText
              }
            >
              {helmet.name}
            </div>
          </div>
          <div className={classes.cardProperty}>
            <div className={classes.cardSubText}>Intercom</div>
            <div
              className={
                intercomRarity
                  ? classes.cardOriginalText
                  : classes.cardRarityText
              }
            >
              {intercom.name}
            </div>
          </div>
          <div className={classes.cardProperty}>
            <div className={classes.cardSubText}>Background</div>
            <div
              className={
                bgRarity ? classes.cardOriginalText : classes.cardRarityText
              }
            >
              {bg.name}
            </div>
          </div>
        </div>
      </div>
      {!intercomRarity && <div className={classes.rarBg}></div>}
    </div>
  )
}
export default NFTGalleryCard
