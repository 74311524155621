import React, { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { requestNFT } from '../../factory/axios'
import AwardIcon from '../../img/award.svg'
import NoteIcon from '../../img/note.svg'

const styles = createUseStyles({
  infoWrapper: {
    fontFamily: 'Poppins',
    width: 750,
    height: 116,
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    borderRadius: 12,
    marginBottom: 90,
    display: 'flex',
    alignItems: 'center',
  },
  infoBlock: {
    width: '50%',
    height: 54,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  borderRight: {
    borderRight: '1px solid #3B4363',
  },
  iconBorder: {
    width: 54,
    height: 54,
    borderRadius: 12,
    border: '1px solid #3B4363',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  total: {
    marginLeft: 12,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  totalTitle: {
    margin: 0,
    fontSize: 14,
    fontWeight: 500,
    color: '#A8A8B2',
  },
  totalCount: {
    margin: 0,
    fontSize: 20,
    fontWeight: 600,
    color: '#FFFFFF',
  },
  '@media (min-width: 1251px)': {
    infoWrapper: {
      margin: '0 auto',
      marginBottom: 90,
    },
  },
  '@media (max-width: 800px)': {
    infoWrapper: {
      width: '95%',
    },
  },
  '@media (max-width: 767px)': {
    iconBorder: {
      display: 'none',
    },
  },
})

const ORBITER_NFT_API_URL = process.env.REACT_APP_X_ORBITER_NFT_API_URL
const NFTInfo = () => {
  const classes = styles()

  const [supply, setSupply] = useState({ totalMinted: 0, maxSupply: 0 })

  useEffect(() => {
    requestNFT({
      method: 'get',
      url: `${ORBITER_NFT_API_URL}/user-nft/supply`,
    })
      .then((req) => setSupply(req.data))
      .catch((e) => console.error(e))
  }, [])

  return (
    <div className={classes.infoWrapper}>
      <div className={`${classes.infoBlock} ${classes.borderRight}`}>
        <div className={classes.iconBorder}>
          <img src={AwardIcon} alt="award" />
        </div>
        <div className={classes.total}>
          <p className={classes.totalTitle}>Total minted</p>
          <p className={classes.totalCount}>
            {supply ? supply.totalMinted.toLocaleString('en') : 0}
          </p>
        </div>
      </div>
      <div className={classes.infoBlock}>
        <div className={classes.iconBorder}>
          <img src={NoteIcon} alt="note" />
        </div>
        <div className={classes.total}>
          <p className={classes.totalTitle}>Total supply</p>
          <p className={classes.totalCount}>
            {supply ? supply.maxSupply.toLocaleString('en') : 0}
          </p>
        </div>
      </div>
    </div>
  )
}

export default NFTInfo
