import React, { useEffect } from 'react'
import { useTimer } from 'react-timer-hook'
import { createUseStyles } from 'react-jss'

interface Props {
  expiryTimestamp?: any
  tokenInfoBlock?: boolean
  updateRound: () => void
  withoutDate?: boolean
  // text:any
}

const styles = createUseStyles({
  timerWrapper: {
    display: 'flex',
    gap: 2,
  },
})

const CountDownTimer: React.FC<Props> = ({
  expiryTimestamp,
  tokenInfoBlock,
  updateRound,
  withoutDate,
}) => {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp: new Date(expiryTimestamp),
    autoStart: true,
    onExpire: () => {
      updateRound()
    },
  })

  const classes = styles()

  const formatTime = (time: number) => {
    return String(time).padStart(2, '0')
  }

  useEffect(() => {
    start()
  }, [])

  useEffect(() => {
    restart(expiryTimestamp)
  }, [expiryTimestamp])

  return (
    <>
      {withoutDate ? (
        <></>
      ) : (
        <div>
          {tokenInfoBlock ? (
            <>
              <span>{days}</span>D :{' '}
              <span className="count-block-first">{formatTime(hours)}</span> :{' '}
              <span className="count-block-first">{formatTime(minutes)}</span>:{' '}
              <span className="count-block-first">{formatTime(seconds)}</span>
            </>
          ) : (
            <div className={classes.timerWrapper}>
              {days > 0 && `${days}D : `}
              <span className="count-block">{formatTime(hours)}</span> :{' '}
              <span className="count-block">{formatTime(minutes)}</span> :{' '}
              <span className="count-block">{formatTime(seconds)}</span>
            </div>
          )}
        </div>
      )}
    </>
  )
}
export default CountDownTimer
