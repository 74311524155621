import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sortedCompositions } from 'src/utils/sortedComposition'
export interface compositionsState {
  data: {
    supplied: [
      {
        token: {
          _id: string
          name: string
          symbol: string
          image: string
          color: string
        }
        percent: string
      }
    ]
    borrowed: [
      {
        token: {
          _id: string
          name: string
          symbol: string
          image: string
          color: string
        }
        percent: string
      }
    ]
  }
}

const initialState: compositionsState = {
  data: {
    supplied: [
      {
        token: {
          _id: '',
          name: '',
          symbol: '',
          image: '',
          color: '',
        },
        percent: '',
      },
    ],
    borrowed: [
      {
        token: {
          _id: '',
          name: '',
          symbol: '',
          image: '',
          color: '',
        },
        percent: '',
      },
    ],
  },
}

export const compositionsSlice = createSlice({
  name: 'compositions',
  initialState,
  reducers: {
    setCompositions: (state, action: PayloadAction<any>) => {
      const sortedSupplied = sortedCompositions(action.payload.supplied)
      const sortedBorrow = sortedCompositions(action.payload.borrowed)
      action.payload.supplied = sortedSupplied
      action.payload.borrowed = sortedBorrow

      state.data = action.payload
    },
  },
})
export const { setCompositions } = compositionsSlice.actions

export default compositionsSlice.reducer
