import { createUseStyles } from 'react-jss'
import Header from '../components/Header/Header'
import MarketOverview from '../components/MarketOverview/MarketOverview'
import Balances from '../components/Balances/Balances'
import Supply from '../components/Supply/Supply'
import Transactions from '../components/Transactions/Transactions'
import Supplied from '../components/Supplied/Supplied'
import Composition from '../components/Composition/Composition'
import Information from '../components/Information/Information'
import React, { useEffect, useState } from 'react'
import CollateralModal from '../components/CollateralModal/CollateralModal'
import WithdrawModal from '../components/WithdrawModal/WithdrawModal'
// @ts-ignore
import bg from '../img/bg.png'
import NavMenu from '../components/NavMenu/NavMenu'
import NeedHelpButton from '../components/NeedHelpButton/NeedHelpButton'
import Assets from '../components/Assets/Assets'
import { useSelector } from 'react-redux'
import WrongNetworkModal from '../components/WrongNetworkModal/WrongNetworkModal'
import MarketOverviewNoConnected from '../components/MarketOverviewNoConnected/MarketOverviewNoConnected'
import RepayModal from '../components/RepayModal/RepayModal'
import useWindowSize from '../utils/resizeHook'
import TestNet from '../components/TestNet/TestNet'
import { paramsMovr } from '../utils/networkConst'
import Footer from '../components/Footer/Footer'
import ClaimModal from '../components/ClaimModal/ClaimModal'

const actualApiFor = process.env.REACT_APP_X_ORBITER_ACTIVE_API_FOR

const styles = createUseStyles({
  bgColor: {
    backgroundColor: '#1B1824',
  },
  allContent: {
    display: 'none',
  },
  allContentWisible: {
    display: 'block',
  },
  bg: {
    position: 'absolute',
    width: '100%',
    height: '1173px',
    zIndex: 0,
  },
  wrapperHeader: {
    width: '100%',
    position: 'fixed',
    zIndex: 5,
    backgroundColor: '#171932',
    backdropFilter: 'blur(60px)',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  wrapperSmallHeader: {
    extend: 'wrapperHeader',
  },
  wrapper: {
    position: 'relative',
    margin: '0 auto',
    maxWidth: 1140,
    paddingBottom: 75,
    paddingTop: actualApiFor === 'dev' ? 163 : 163,
  },
  hr: {
    color: '#42424E',
    backgroundColor: '#42424E',
    height: 1,
    border: 'none',
    margin: 0,
    width: '100%',
    top: 105,
    zIndex: 1,
    position: 'fixed',
  },
  smallHr: {
    extend: 'hr',
    top: 80,
  },
  contentWrapper: {
    display: 'flex',
    paddingTop: 30,
  },
  contentBlockWrapper: {
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    padding: 20,
    marginTop: 35,
  },
  topLeftSideContent: {
    width: '50%',
    display: 'flex',
  },
  assetDesktop: {
    display: 'block',
  },
  topRightSideContent: {
    width: '50%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
  },
  leftMiddleContent: {
    height: 'fit-content',
    width: '360px',
    paddingRight: 30,
    display: 'flex',
  },
  rightMiddleContent: {
    width: 750,
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
  },
  bottomContentWrapper: {
    display: 'flex',
    paddingBottom: 30,
  },
  bottomLeftSideContent: {
    width: 750,
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
  },
  bottomRightSideContent: {
    width: 364,
    paddingLeft: 30,
    display: 'flex',
  },
  composition: {
    display: 'flex',
    gap: 30,
    paddingBottom: 30,
  },
  notConnectedWrapper: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  topContentWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    height: 214,
    gap: 30,
    paddingBottom: 30,
  },
  middleContentWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  middleContentWrapperNoConnected: {
    width: '100%',
    display: 'block',
  },
  rightMiddleContentNoConnected: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
  },
  assetsMobile: {
    display: 'none',
  },
  '@media (max-width: 1250px)': {
    wrapperHeader: {
      width: '100%',
      position: 'fixed',
      backgroundColor: '#171932',
      backdropFilter: 'blur(60px)',
      zIndex: 5,
      left: '50%',
      transform: 'translateX(-50%)',
      userSelect: 'none',
    },
    wrapper: {
      maxWidth: 700,
      userSelect: 'none',
      paddingBottom: 100,
    },
    contentedWrapper: {
      display: 'block',
    },
    notConnectedWrapper: {
      paddingTop: 20,
      paddingBottom: 20,
    },
    topLeftSideContent: {
      paddingRight: 0,
      width: 340,
      display: 'flex',
    },
    topRightSideContent: {
      width: 340,
      flexDirection: 'column',
      justifyContent: 'space-between',
      display: 'flex',
    },
    topContentWrapper: {
      gap: 20,
      height: 238,
      flexDirection: 'row',
      paddingBottom: 0,
    },
    middleContentWrapper: {
      paddingBottom: 0,
    },
    leftMiddleContent: {
      display: 'none',
    },
    rightMiddleContent: {
      width: '100%',
    },
    composition: {
      gap: 20,
      paddingBottom: 0,
    },
    bottomContentWrapper: {
      justifyContent: 'space-between',
      width: '100%',
      paddingBottom: 0,
      gap: '20px',
    },
    bottomLeftSideContent: {
      width: 340,
    },
    contentWrapper: {
      width: '100%',
      paddingTop: 20,
    },
    bottomRightSideContent: {
      width: 340,
      paddingLeft: 0,
    },
    assetsMobile: {
      display: 'none',
    },
    middleContentWrapperNoConnected: {
      paddingTop: 20,
    },
  },
  '@media (max-width: 730px)': {
    wrapperHeader: {},
    wrapper: {
      maxWidth: 340,
      paddingBottom: 78,
      paddingTop: actualApiFor !== 'dev' && 185,
    },
    topContentWrapper: {
      display: 'block',
      height: 436,
      paddingTop: actualApiFor === 'dev' ? 20 : 0,
    },
    assetDesktop: {
      display: 'none',
    },
    topLeftSideContent: {
      paddingRight: 0,
      width: '100%',
      display: 'block',
      marginBottom: 20,
    },
    notConnectedWrapper: {
      paddingTop: 20,
      paddingBottom: 0,
    },
    topRightSideContent: {
      width: '100%',
    },
    leftMiddleContent: {
      display: 'none',
    },
    rightMiddleContent: {
      width: '100%',
    },
    composition: {
      flexDirection: 'column',
    },
    bottomLeftSideContent: {
      display: 'block',
    },
    bottomContentWrapper: {
      paddingBottom: 0,
      display: 'block',
    },
    contentWrapper: {
      display: 'block',
      paddingTop: 20,
    },
    bottomRightSideContent: {
      flexDirection: 'column',
      gap: 20,
    },
    assetsMobile: {
      paddingTop: 20,
      display: 'block',
    },
  },
})

const WalletType = {
  metamask: 'metamask',
  walletconnect: 'walletconnect',
}

interface Props {
  removeWalletData: () => void
  handleOpen: () => void
  connectWallet: (a: string, b: boolean) => void
  handleConnect: () => void
  openSwitchNetworkModal: (a: boolean) => void
  removeWalletType: () => void
  addToken: (a: any) => void
  setWalletType: () => void
  getWalletType: () => void
  switchNetwork: (params: any) => void
  getWalletData: () => void
  createProviderWalletConnect: () => void
  createProviderMetamask: () => void
  open: boolean
  showSwitchNetworkModal: boolean
  setShowSwitchNetworkModal: (a: boolean) => void
}

const Main: React.FC<Props> = ({
  setShowSwitchNetworkModal,
  showSwitchNetworkModal,
  open,
  connectWallet,
  openSwitchNetworkModal,
  handleOpen,
  removeWalletData,
  switchNetwork,
  addToken,
}) => {
  const [smallHeader, setSmallHeader] = useState(false)
  const [openCollateralModal, setOpenCollateralModal] = useState({
    open: false,
    token: '',
    tokenId: '',
    isEnable: false,
  })
  const [openWithdrawModal, setOpenWithdrawModal] = useState({
    open: false,
    token: {},
  })

  const [openRepayModal, setOpenRepayModal] = useState({
    open: false,
    token: {},
  })

  const { user } = useSelector((state: any) => state.userReducer)

  const loading = useSelector((state: any) => state.loadingReducer)

  const openClaimModal = useSelector(
    (state: any) => state.claimModalReducer.open
  )

  const { isDesktop: desktop, isTablet: tablet } = useWindowSize()

  const classes = styles()

  useEffect(() => {
    window.scrollTo(0, 0)
    const connectedWalletType = getWalletType()

    if (
      connectedWalletType === WalletType.walletconnect &&
      !localStorage.getItem('walletconnect')
    ) {
      removeWalletType()
      return
    }

    connectedWalletType && connectWallet(connectedWalletType, false)
  }, [])

  useEffect(() => {
    if (!user.chainId) return

    if (user.chainId !== paramsMovr.chainId) {
      openSwitchNetworkModal(true)
    } else {
      const body = document.body
      body.style.height = ''
      body.style.overflowY = ''
      setShowSwitchNetworkModal(false)
    }
  }, [user.chainId])

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
  })

  const listenScrollEvent = () => {
    let valueForScroll = actualApiFor === 'dev' ? 50 : 25
    if (window.scrollY > valueForScroll) {
      setSmallHeader(true)
    } else {
      setSmallHeader(false)
    }
  }
  const getWalletType = () => {
    return localStorage.getItem('wallet-type')
  }

  const removeWalletType = () => {
    localStorage.removeItem('wallet-type')
  }

  return (
    <>
      {/* {loading.loading && <Loader />} */}
      <div
        className={
          loading.loading ? classes.allContent : classes.allContentWisible
        }
      >
        <div className={classes.bgColor}>
          <img src={bg} className={classes.bg} alt="" />
          <div
            className={
              smallHeader ? classes.wrapperSmallHeader : classes.wrapperHeader
            }
          >
            <Header
              switchNetwork={switchNetwork}
              removeWalletData={removeWalletData}
              handleOpen={handleOpen}
              open={open}
              smallHeader={smallHeader}
              connectWallet={connectWallet}
              showSwitchNetworkModal={showSwitchNetworkModal}
            />
          </div>
          <hr className={smallHeader ? classes.smallHr : classes.hr} />
          {actualApiFor === 'dev' ? <TestNet /> : null}
          {open ? (
            <NavMenu smallHeader={smallHeader} handleOpen={handleOpen} />
          ) : (
            <></>
          )}
          <div className={classes.wrapper}>
            {user.connected && !showSwitchNetworkModal ? (
              <div>
                <div className={classes.contentWrapper}>
                  <div className={classes.topContentWrapper}>
                    <div className={classes.topLeftSideContent}>
                      <Balances />
                    </div>
                    <div className={classes.topRightSideContent}>
                      <MarketOverview />
                    </div>
                  </div>
                </div>

                <div className={classes.assetDesktop}>
                  <Assets />
                </div>

                <div className={classes.contentWrapper}>
                  <div className={classes.assetsMobile}>
                    <Assets />
                  </div>
                  <div className={classes.middleContentWrapper}>
                    <div className={classes.leftMiddleContent}>
                      <Supply />
                    </div>
                    <div className={classes.rightMiddleContent}>
                      <Information addToken={addToken} />
                    </div>
                  </div>
                </div>
                <div></div>
                <div className={classes.contentWrapper}>
                  <div className={classes.bottomContentWrapper}>
                    <div className={classes.bottomLeftSideContent}>
                      {desktop ? (
                        <>
                          <div className={classes.composition}>
                            <Composition
                              supply={true}
                              title="Supply Composition"
                            />
                            <Composition
                              title="Borrow Composition"
                              supply={false}
                            />
                          </div>
                          <Supplied
                            setOpenWithdrawModal={setOpenWithdrawModal}
                            setOpenCollateralModal={setOpenCollateralModal}
                            setOpenRepayModal={setOpenRepayModal}
                          />
                        </>
                      ) : (
                        <Supply />
                      )}
                    </div>
                    <div className={classes.bottomRightSideContent}>
                      {tablet ? (
                        <Transactions />
                      ) : (
                        <>
                          <div className={classes.contentWrapper}>
                            <div className={classes.composition}>
                              <Composition
                                supply={true}
                                title="Supply Composition"
                              />
                              <Composition
                                title="Borrow Composition"
                                supply={false}
                              />
                            </div>
                          </div>
                          <Supplied
                            setOpenCollateralModal={setOpenCollateralModal}
                            setOpenWithdrawModal={setOpenWithdrawModal}
                            setOpenRepayModal={setOpenRepayModal}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {desktop ? null : (
                  <>
                    {tablet && (
                      <div className={classes.contentWrapper}>
                        <div className={classes.composition}>
                          <Composition
                            supply={true}
                            title="Supply Composition"
                          />
                          <Composition
                            title="Borrow Composition"
                            supply={false}
                          />
                        </div>
                      </div>
                    )}
                    <div className={classes.contentWrapper}>
                      {tablet ? (
                        <Supplied
                          setOpenCollateralModal={setOpenCollateralModal}
                          setOpenWithdrawModal={setOpenWithdrawModal}
                          setOpenRepayModal={setOpenRepayModal}
                        />
                      ) : (
                        <Transactions />
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className={classes.notConnectedWrapper}>
                <MarketOverviewNoConnected connected={user.connected} />
                <div className={classes.notConnectedWrapper}>
                  <Assets />
                </div>
                <div className={classes.rightMiddleContentNoConnected}>
                  <Information addToken={addToken} />
                </div>
              </div>
            )}
            <NeedHelpButton />
          </div>
        </div>
        <Footer />
        {showSwitchNetworkModal && user.connected && (
          <WrongNetworkModal switchNetwork={switchNetwork} />
        )}
        {openCollateralModal.open && (
          <CollateralModal
            openCollateralModal={openCollateralModal}
            setOpenCollateralModal={setOpenCollateralModal}
          />
        )}
        {openWithdrawModal.open && (
          <WithdrawModal
            openWithdrawModal={openWithdrawModal}
            setOpenWithdrawModal={setOpenWithdrawModal}
          />
        )}
        {openRepayModal.open && (
          <RepayModal
            openRepayModal={openRepayModal}
            setOpenRepayModal={setOpenRepayModal}
          />
        )}
        {openClaimModal ? <ClaimModal addToken={addToken} /> : null}
      </div>
    </>
  )
}

export default Main
