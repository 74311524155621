import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import xORBIcon from '../../img/xORB.svg'
import CustomScrollWrap from '../CustomScrollWrap'
import onCompositionImg from '../../img/emoji-sad.svg'
import { requestNFT } from '../../factory/axios'
import { fromBN, transform } from '../../factory/bigNumber'
import dayjs from 'dayjs'
//@ts-ignore
import animationData from '../../lotties/notification'

import relativeTime from 'dayjs/plugin/relativeTime'
import Lottie from 'lottie-react'
import uniqid from 'uniqid'
import {
  deleteNotifications,
  setNotifications,
} from '../../redux/notifications'
import { claimRedemptionNotification } from '../../utils/notificationTexts'
import { Web3Context } from '../../context'

import { Contract } from 'web3-eth-contract'
import BigNumber from 'bignumber.js'

// extend dayjs with relativeTime plugin
dayjs.extend(relativeTime)

const styles = createUseStyles({
  cardWrapper: {
    borderRadius: 12,
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.80) 0%, rgba(53, 53, 81, 0.80) 100%)',
    boxShadow: '1px 1px 1px 0px #42426A inset',
    backdropFilter: 'backdrop-filter: blur(25px)',
    padding: 20,
  },
  cardTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
    paddingBottom: 20,
  },
  scrollWrapper: {
    display: 'flex',
    gap: 10,
    flexDirection: 'column',
  },
  itemCardWrapper: {
    padding: 15,
    width: 288,
    height: 50,
    borderRadius: 12,
    border: '1px solid #4B567D',
    display: 'flex',
    gap: 15,
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    '& $claimButtonWrapper': {},
    '& $smallDropDownMore': {},
    '&:hover $claimButtonWrapper': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  cardImgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardImg: {
    width: 28,
    height: 28,
  },
  cardTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  mainText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: 'normal',
    color: '#FFFFFF',
  },
  subText: {
    fontWeight: 500,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: 'Poppins',
  },
  climeText: {
    fontWeight: 500,
    color: '#53CBC8',
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: 'Poppins',
  },
  cancelText: {
    fontWeight: 500,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: 'Poppins',
  },
  claimButton: {
    display: 'flex',
    width: 42,
    height: 21,
    padding: '5px 28px 4px 28px',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: 8,
    border: '1px solid #53CBC8',

    fontWeight: 600,
    color: '#53CBC8',
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    cursor: 'pointer',
    marginRight: 15,
  },
  cancelButton: {
    display: 'flex',
    width: 42,
    height: 21,
    padding: '5px 28px 4px 28px',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: 8,
    border: '1px solid #A8A8B2',

    fontWeight: 600,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    cursor: 'pointer',
    marginRight: 15,
  },
  claimButtonWrapper: {
    background:
      'linear-gradient(270deg, #323A57 44.27%, rgba(50, 58, 87, 0.00) 100%)',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    width: 303,
    height: 80,
    borderRadius: 12,
    visibility: 'hidden',
  },
  noCompositionsWrapper: {
    display: 'flex',
    width: 320,
    height: 500,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 'auto',
  },
  noCompositionsImg: {
    width: 40,
    height: 40,
    paddingBottom: 15,
  },
  noCompositions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noCompositionsMainText: {
    color: '#A8A8B2',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  noCompositionsSubText: {
    extend: 'noCompositionsMainText',
    fontWeight: 400,
    color: '#A8A8B2',
  },
  margin10: {},
  itemCardWrapperMobile: {
    padding: 15,
    width: 268,
    height: 90,
    borderRadius: 12,
    border: '1px solid #4B567D',
    display: 'flex',
    gap: 15,
    position: 'relative',
    cursor: 'pointer',
  },
  cardImgWrapperMobile: {},
  cardTextWrapperMobile: {},
  claimButtonWrapperMobile: {
    marginTop: 7,
  },
  cardTextButtonWrapper: {},
  loadingWrapper: {
    width: 160,
    height: 160,
  },
  '@media (max-width: 1250px)': {
    cardWrapper: {
      borderRadius: 12,
      background:
        'linear-gradient(180deg, rgba(49, 57, 84, 0.80) 0%, rgba(53, 53, 81, 0.80) 100%)',
      boxShadow: '1px 1px 1px 0px #42426A inset',
      backdropFilter: 'backdrop-filter: blur(25px)',
      padding: 20,
      width: '100%',
    },
    cardImg: {
      width: 24,
      height: 24,
    },
    itemCardWrapper: {
      padding: [18, 15],
      width: 630,
      height: 22,
      borderRadius: 12,
      border: '1px solid #4B567D',
      display: 'flex',
      gap: 10,
      alignItems: 'center',
      position: 'relative',
    },
    claimButtonWrapper: {
      background: 'inherit',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      position: 'relative',
      top: 0,
      width: 100,
      height: 'auto',
      borderRadius: 12,
      visibility: 'unset',
    },
    cardTextWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    claimButton: {
      margin: 0,
    },
    cancelButton: {
      margin: 0,
    },
    margin10: {
      marginRight: 10,
    },
    noCompositionsWrapper: {
      display: 'flex',
      height: 153,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      margin: 'auto',
    },
    climeText: {
      fontWeight: 500,
      color: '#53CBC8',
      fontSize: '16px',
      lineHeight: '21px',
      fontFamily: 'Poppins',
    },
    cardTitle: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: 'normal',
      color: '#FFFFFF',
      paddingBottom: 20,
    },
  },
  '@media (max-width: 730px)': {
    cardWrapper: {
      borderRadius: 12,
      background:
        'linear-gradient(180deg, rgba(49, 57, 84, 0.80) 0%, rgba(53, 53, 81, 0.80) 100%)',
      boxShadow: '1px 1px 1px 0px #42426A inset',
      backdropFilter: 'backdrop-filter: blur(25px)',
      padding: 20,
      width: 300,
    },
    noCompositionsWrapper: {
      display: 'flex',
      height: 500,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      margin: 'auto',
      width: 'auto',
    },
    itemCardWrapper: {
      padding: 15,
      width: 288,
      height: 50,
      borderRadius: 12,
      border: '1px solid #4B567D',
      display: 'flex',
      gap: 15,
      alignItems: 'center',
      position: 'relative',
      cursor: 'pointer',
    },
    cardTextWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },
    climeText: {
      fontWeight: 500,
      color: '#53CBC8',
      fontSize: '16px',
      lineHeight: '21px',
      fontFamily: 'Poppins',
    },
    cardTitle: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: 'normal',
      color: '#FFFFFF',
      paddingBottom: 20,
    },
    cardImg: {
      width: 28,
      height: 28,
    },
    itemCardWrapperMobile: {
      padding: 15,
      width: 268,
      height: 90,
      borderRadius: 12,
      border: '1px solid #4B567D',
      display: 'flex',
      gap: 5,
      position: 'relative',
      cursor: 'pointer',
    },
  },
})
const ORBITER_NFT_API_URL = process.env.REACT_APP_X_ORBITER_NFT_API_URL

interface IProps {
  setOpen: (value: any) => void
  data: any
  loading: boolean
  convertContract: Contract | null
  setData: (value: any) => void
  getBalance: () => void
  setRedemption: (value: string) => void
}

const Vesting = ({
  setOpen,
  data,
  loading,
  convertContract,
  setData,
  getBalance,
  setRedemption,
}: IProps) => {
  const classes = styles()
  const { user } = useSelector((state: any) => state.userReducer)
  const { web3 } = useContext(Web3Context)

  const dispatch = useDispatch()

  const isTablet = window.innerWidth < 1250 && window.innerWidth > 730
  const isMobile = window.innerWidth >= 730
  const currentTimeStamp = new Date().getTime()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  const claimRedemption = async (index: number) => {
    let result
    const transactionsId = uniqid()
    dispatch(
      setNotifications({
        type: 'info',
        text: claimRedemptionNotification.textInfoRedeemClaim,
        link: null,
      })
    )

    setOpen({
      open: false,
      value: '',
      index: 0,
    })

    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await (convertContract as Contract).methods
        .claim(index)
        .estimateGas({
          from: user.address,
        })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      result = await (convertContract as Contract).methods
        .claim(index)
        .send({
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
          from: user.address,
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: claimRedemptionNotification.textSubmittedRedeemClaim,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          text: claimRedemptionNotification.textSuccessRedeemClaim,
          link: result.transactionHash,
        })
      )
      setTimeout(() => {
        requestNFT({
          method: 'get',
          url: `${ORBITER_NFT_API_URL}/x-orb/${user.address}`,
        }).then((req) => {
          setData(req.data.redemptions)
          setRedemption(new BigNumber(req.data.totalRedemption).toFixed(0))
        })
      }, 5000)
      getBalance()
      console.log('convertOrb', result)
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: claimRedemptionNotification.textErrorRedeemClaim,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const getTime = (time: number) => {
    const futureTimeStamp = time
    const timeDifference = futureTimeStamp - currentTimeStamp

    const millisecondsInMinute = 60 * 1000
    const millisecondsInHour = 60 * millisecondsInMinute
    const millisecondsInDay = 24 * millisecondsInHour

    if (timeDifference > 0) {
      const days = Math.floor(timeDifference / millisecondsInDay)
      const hours = Math.floor(
        (timeDifference % millisecondsInDay) / (60 * 60 * 1000)
      )
      const minutes = Math.floor(
        (timeDifference % millisecondsInHour) / millisecondsInMinute
      )

      return (
        <div
          className={[classes.cancelText, classes.margin10].join(' ')}
        >{`Time left ${days}D ${hours}H ${minutes}M`}</div>
      )
    } else if (timeDifference < 0) {
      return (
        <div className={[classes.climeText, classes.margin10].join(' ')}>
          Ready to claim
        </div>
      )
    }
  }

  const renderButton = (value: string, time: number, index: number) => {
    const timeDifference = time - currentTimeStamp

    if (timeDifference > 0) {
      return (
        <div
          onClick={() =>
            setOpen({
              open: true,
              value: value,
              index,
            })
          }
          className={classes.cancelButton}
        >
          Cancel
        </div>
      )
    } else {
      return (
        <div
          className={classes.claimButton}
          onClick={() => claimRedemption(index)}
        >
          Claim
        </div>
      )
    }
  }

  const Card = ({
    amount,
    endTime,
    index,
  }: {
    amount: string
    endTime: number
    index: number
  }) => {
    return isMobile ? (
      <div className={classes.itemCardWrapper}>
        <div className={classes.cardImgWrapper}>
          <img className={classes.cardImg} src={xORBIcon} alt="" />
        </div>
        <div className={classes.cardTextWrapper}>
          <div className={classes.mainText}>
            {transform(fromBN(amount, 18), 4)}
          </div>
          {getTime(endTime)}
        </div>
        <div className={classes.claimButtonWrapper}>
          {renderButton(amount, endTime, index)}
        </div>
      </div>
    ) : (
      <div className={classes.itemCardWrapperMobile}>
        <div className={classes.cardImgWrapperMobile}>
          <img className={classes.cardImg} src={xORBIcon} alt="" />
        </div>
        <div className={classes.cardTextButtonWrapper}>
          <div className={classes.cardTextWrapperMobile}>
            <div className={classes.mainText}>
              {transform(fromBN(amount, 18), 4)}
            </div>
            <div className={[classes.climeText, classes.margin10].join(' ')}>
              {getTime(endTime)}
            </div>
          </div>
          <div className={classes.claimButtonWrapperMobile}>
            {renderButton(amount, endTime, index)}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.cardWrapper}>
      <div className={classes.cardTitle}>Vesting</div>
      <CustomScrollWrap mathod={null} height={isTablet ? 153 : 505}>
        <div className={classes.scrollWrapper}>
          {!user.connected || !data?.length ? (
            <div className={classes.noCompositionsWrapper}>
              <img
                className={classes.noCompositionsImg}
                src={onCompositionImg}
                alt=""
              />
              <div className={classes.noCompositions}>
                <div className={classes.noCompositionsMainText}>
                  There's nothing here yet
                </div>
              </div>
            </div>
          ) : loading ? (
            <div className={classes.noCompositionsWrapper}>
              <div className={classes.loadingWrapper}>
                <Lottie {...defaultOptions} height={22} width={22} />
              </div>
            </div>
          ) : (
            <>
              {data?.map((item: any, index: number) => {
                return (
                  <Card
                    key={index}
                    index={index}
                    amount={item.amount}
                    endTime={+item.endTime * 1000}
                  />
                )
              })}
            </>
          )}
        </div>
      </CustomScrollWrap>
    </div>
  )
}

export default Vesting
