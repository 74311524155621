import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch } from 'react-redux'
import { deleteNotifications } from 'src/redux/notifications'
import Lottie from 'lottie-react'
import animationData from '../../lotties/notification'

// @ts-ignore
import gif from '../../img/loaderNotifications.png'
import * as process from 'process'

const styles = createUseStyles({
  modalInfo: {
    animation: 'slowVisibility 0.3s ease-in',
    borderRadius: '12px',
    boxShadow: '0 10px 24px rgba(0, 0, 0, 0.21)',
    color: '#333',
    cursor: 'pointer',
    display: 'flex',
    lineHeight: '25px',
    maxWidth: '330px',
    zIndex: '1000',
    backdropFilter: 'blur(25px)',
    minHeight: '84px',
    background: 'green',
    marginBottom: '20px',
  },
  text: {
    whiteSpace: 'pre-line',
  },
  modalLogo: {
    width: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalText: {
    width: '260px',
    justifyContent: 'center',
    display: 'flex',
    fontWeight: '500',
    fontSize: '16px',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '25px',
    flexDirection: 'column',
  },
  modalTextWithLink: {
    extend: 'modalText',
    margin: 'auto',
    display: 'block',
  },
  modalLink: {
    color: 'white',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  success: {
    background: 'rgba(83, 203, 200, 0.2)',
    border: '1px solid #53CBC8',
  },
  error: {
    background: 'rgba(247, 84, 84, 0.2)',
    border: '1px solid #F75454',
  },
  info: {
    background: 'rgba(84, 147, 247, 0.2)',
    border: '1px solid #5493F7',
  },
  animationLogo: {
    display: 'block',
    width: '34px',
    height: '34px',
  },
  closeNotifications: {
    fontSize: '18px',
    fontWeight: 600,
    opacity: '0.8',
    position: 'absolute',
    top: '0px',
    right: '8px',
    transform: 'rotate(45deg)',
    color: 'white',
  },
  hideModal: {
    display: 'none',
  },
})
interface Props {
  text: string
  type: string
  id: string
  link: string | null
  isPending: boolean
  explorerUrl?: string
  button?: boolean
}

const NotificationsItem: React.FC<Props> = ({
  text,
  type,
  id,
  link,
  explorerUrl = null,
  isPending,
  button,
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const classes = styles()
  const dispatch = useDispatch()

  const closeNotifications = (id: any) => {
    dispatch(deleteNotifications(id))
  }

  useEffect(() => {
    if (isPending) {
      return
    }
    setTimeout(() => {
      closeNotifications(id)
    }, 15000)
  }, [id])

  return (
    <div
      className={[
        classes.modalInfo,
        text === '' && classes.hideModal,
        type === 'success'
          ? classes.success
          : type === 'error'
          ? classes.error
          : classes.info,
      ].join(' ')}
    >
      <span
        className={classes.closeNotifications}
        onClick={() => closeNotifications(id)}
      >
        +
      </span>
      <div className={classes.modalLogo}>
        <Lottie {...defaultOptions} height={42} width={42} />
        {/* <img className={classes.animationLogo} src={gif} alt="animationLogo" /> */}
      </div>
      <div className={link ? classes.modalTextWithLink : classes.modalText}>
        <span className={classes.text}>{text}&nbsp;</span>
        {link && (
          <a
            className={classes.modalLink}
            target="_blank"
            href={
              explorerUrl
                ? `${explorerUrl}/tx/${link}`
                : process.env.REACT_APP_X_ORBITER_ACTIVE_API_FOR === 'dev'
                ? `https://moonbase.moonscan.io/tx/${link}`
                : `https://moonbeam.moonscan.io/tx/${link}`
            }
          >
            Transaction ↗
          </a>
        )}
        {button && (
          <span
            className={classes.modalLink}
            onClick={() => {
              window.location.reload()
            }}
          >
            Please reload the page.
          </span>
        )}
      </div>
    </div>
  )
}

export default NotificationsItem
