import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { findMovrIndex, findMovrIndexFauset } from 'src/utils'
export interface assetState {
  data: [
    {
      _id: string
      name: string
      symbol: string
      fullName: string
      oTokenDecimal: number
      tokenDecimal: number
      oTokenAddress: string
      tokenAddress: string
      typeNetwork: string
      collateralFactor: number
      reserveFactor: number
      exchangeRate: string
      supplyRate: string
      borrowRate: string
      totalSupply: string
      totalBorrow: string
      totalReserves: string
      lastPrice: string
      liquidity: string
      image: string
      color: string
      countSuppliers: number
      countBorrowers: number
      utilization: number
      supplyPaused: boolean
      borrowPaused: boolean
      createdAt: string
      updatedAt: string
    }
  ]
}

const initialState: assetState = {
  data: [
    {
      _id: '',
      name: '',
      fullName: '',
      symbol: '',
      oTokenDecimal: 0,
      tokenDecimal: 0,
      oTokenAddress: '',
      tokenAddress: '',
      typeNetwork: '',
      collateralFactor: 0,
      reserveFactor: 0,
      exchangeRate: '',
      supplyRate: '',
      borrowRate: '',
      totalSupply: '',
      totalBorrow: '',
      totalReserves: '',
      lastPrice: '',
      liquidity: '',
      image: '',
      color: '',
      countSuppliers: 0,
      countBorrowers: 0,
      utilization: 0,
      supplyPaused: true,
      borrowPaused: true,
      createdAt: '',
      updatedAt: '',
    },
  ],
}

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setAssets: (state, action: PayloadAction<any>) => {
      state.data = action.payload
    },
  },
})

export const { setAssets } = assetsSlice.actions

export default assetsSlice.reducer
