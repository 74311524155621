import React, { useContext, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
// @ts-ignore
import Checkbox from 'react-custom-checkbox'
import ButtonIcon from '../../img/card-receive.svg'
import closeIcon from '../../img/close-circle.svg'
import checkBoxIcon from '../../img/CheckBoxTrue.svg'
import cEthAbi from '../../contracts/cEthAbi.abi'
import cErcAbi from '../../contracts/cErcAbi.abi'
import { fromBn, toBn } from 'evm-bn'
import { Web3Context } from '../../context'
import { useDispatch, useSelector } from 'react-redux'
import { transform } from '../../factory/bigNumber'
import {
  deleteNotifications,
  setNotifications,
} from '../../redux/notifications'
import { withdrawNotification } from '../../utils/notificationTexts'
import { Asset } from '../Assets/Assets'
import erc20Abi from '../../contracts/erc20Abi.abi'
import refetch from '../../utils/Refetch'
import { commify, numberToStringInput, positionHealthColor } from '../../utils'
import { Tooltip } from 'antd'
import uniqid from 'uniqid'
import usePositionHealth from '../../hooks/usePositionHealth'
import { request } from '../../factory/axios'
import BigNumber from 'bignumber.js'
import bigDecimal from 'js-big-decimal'

const styles = createUseStyles({
  modalBg: {
    width: '100%',
    background: 'rgb(0 0 0 / 70%)',
    height: '100vh',
    position: 'fixed',
    top: 0,
    zIndex: 15,
  },
  collateralModal: {
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    backdropFilter: 'blur(25px)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    padding: [30, 20, 20],
    position: 'absolute',
    marginLeft: 'auto',
    width: 560,
    marginRight: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    borderRadius: '12px',
    cursor: 'default',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '30px',
    color: '#FFFFFF',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  inputDescription: {},
  inputWrapper: {
    backgroundColor: '#1F1F2D',
    borderRadius: 12,
    width: '100%',
    marginBottom: 8,
    boxSizing: 'border-box',
    padding: [10, 15],
  },
  inputDescriptionText: {
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
  },
  inputPlaceholderText: {
    whiteSpace: 'nowrap',
    width: '100%',
    backgroundColor: '#1F1F2D',
    border: 'none',
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 400,
    color: '#fff',

    '&:placeholder': {
      color: '#7D7E83',
    },
  },
  infoText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#A8A8B2',
  },
  withdrawMainTextBlock: {
    paddingTop: 20,
  },
  withdrawTextBlock: {
    paddingTop: 20,
  },
  withdrawTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  withdrawSubText: {
    color: '#A8A8B2',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  withdrawMainText: {
    extend: 'borrowSubText',
    color: '#FFFFFF',
    fontWeight: 600,
  },
  positionHealth: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 5,
  },
  red: {
    width: 4,
    height: 14,
    backgroundColor: '#E2006E',
    borderRadius: '52px',
    marginRight: 5,
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  hr: {
    backgroundColor: '#A8A8B2',
    width: 1,
    height: 16,
  },
  depositSelectWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  depositSelectButton: {
    fontFamily: 'Poppins',
    cursor: 'pointer',
    backgroundColor: '#1F1F2D',
    width: 52,
    height: 22,
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 42,
  },
  borrowInformationBlock: {
    paddingTop: 20,
  },
  borrowInformationText: {
    color: '#A8A8B2',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
  },
  borrowInformationTextYellow: {
    color: '#FBC928',
  },
  borrowInformationCheckBoxWrapper: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 20,
    alignItems: 'center',
    gap: 10,
  },
  borrowInformationCheckBoxText: {
    color: '#5493F7',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
  },
  borrowInformationCheckBox: {
    width: 21,
    height: 21,
  },
  depositButton: {
    backgroundColor: '#5493F7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0',
    width: '320px',
    margin: '40px auto 0',
    border: 'none',
    borderRadius: 12,
    cursor: 'pointer',
  },
  depositButtonDisable: {
    extend: 'depositButton',
    backgroundColor: '#44445B',
    cursor: 'not-allowed',
  },
  depositButtonText: {
    fontFamily: 'Poppins',
    paddingLeft: 10,
    lineHeight: '27px',
    fontSize: '18px',
    fontWeight: 600,
    color: '#FFFFFF',
  },
  tooltipWithRewards: {
    width: '165px',
    width: 'fit-content',
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipWithRewardsLong: {
    extend: 'tooltipWithRewards',
    minWidth: '165px',
    width: '100%',
  },
  tooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '4px',
  },
  tooltipItemText: {
    color: '#A8A8B2',
    fontWeight: 400,
    fontSize: '14px',
  },
  tooltipItemTextLong: {
    extend: 'tooltipItemText',
    marginRight: '10px',
  },
  tooltipItemNumber: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: '14px',
  },
  tooltipItemTextSign: {
    color: '#5493F7',
    fontWeight: 500,
    marginRight: '4px',
  },
  tooltipLine: {
    width: '100%',
    height: '1px',
    borderBottom: 'none',
    border: 'none',
    backgroundColor: '#4B567D',
    marginTop: '8px',
    marginBottom: '8px',
  },
  tooltipItemTotal: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewards: {
    padding: '12px 16px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
  },
  tooltipItemWithoutRewards: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0px',
  },
  tooltipWithoutRewardsLong: {
    extend: 'tooltipWithoutRewards',
    // minWidth: '165px',
    // width: '100%',
  },

  lastTooltipItem: {
    extend: 'tooltipItem',
    marginBottom: '0px',
  },
  lastTooltipItemLong: {
    extend: 'tooltipItemLong',
    marginBottom: '0px',
  },
  noTexttooltipItemWithoutRewards: {
    extend: 'tooltipItemWithoutRewards',
    justifyContent: 'center',
  },
  '@media (max-width: 1250px)': {
    withdrawTextBlock: {
      paddingTop: 10,
    },
  },
  '@media (max-width: 730px)': {
    collateralModal: {
      width: 300,
    },
    title: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '27px',
    },
    depositButton: {
      width: 300,
    },
    withdrawSubText: {
      fontSize: '14px',
    },
    depositButtonDisable: {
      width: 300,
    },
  },
})

interface Props {
  openWithdrawModal: {
    open: boolean
    token: any
  }
  setOpenWithdrawModal: (a: { open: boolean; token: any }) => void
}

const WithdrawModal: React.FC<Props> = ({
  openWithdrawModal,
  setOpenWithdrawModal,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [checkboxValue, setCheckboxValue] = useState(false)
  const [tokenContract, setTokenContract] = useState(null)
  const [marketContract, setMarketContract] = useState(null)
  const [isMainToken, setIsMainToken] = useState(false)
  const [tokenBalance, setTokenBalance] = useState<any>('')
  const [maxWithdrawEmount, setmaxWithdrawEmount] = useState('')

  const { web3 } = useContext(Web3Context)
  const { user } = useSelector((state: any) => state.userReducer)
  const { data: assets } = useSelector((state: any) => state.assetsReducer)
  const { userBalances } = useSelector(
    (state: any) => state.userBalancesReducer
  )
  const { asset } = useSelector((state: any) => state.assetReducer)

  const withdrawAsset = assets.find(
    (asset: any) => asset._id === openWithdrawModal.token.token._id
  )

  const zeroInInput = !(inputValue.replace(/[\s,]/g, '') == 0)

  const classes = styles()

  const dispatch = useDispatch()

  const { percentage, coefficient, moreThen80Present } = usePositionHealth(
    'withdraw',
    inputValue,
    userBalances.totalBorrowed,
    userBalances.totalCollateral,
    withdrawAsset.lastPrice,
    withdrawAsset.collateralFactor,
    userBalances.totalSupplied,
    userBalances.availableToBorrow,
    openWithdrawModal.token.value
  )

  const SuppliedAfterWithdraw =
    openWithdrawModal.token.value - +inputValue.replace(/[\s,]/g, '')

  const disableButton =
    !!inputValue &&
    (!moreThen80Present || checkboxValue) &&
    !(+inputValue.replace(/[\s,]/g, '') > +maxWithdrawEmount) &&
    +inputValue.replace(/[\s,]/g, '') !== 0

  useEffect(() => {
    request({
      method: 'get',
      path: `assets/${user.address}/estimateMaxWithdrawal/${withdrawAsset._id}`,
    }).then((res) => {
      setmaxWithdrawEmount(res.data.data.max)
    })
    if (!withdrawAsset._id) return

    setIsMainToken(!withdrawAsset.tokenAddress)

    defineContracts(withdrawAsset, !withdrawAsset.tokenAddress)
    setInputValue('')
  }, [])

  useEffect(() => {
    if (!tokenContract || !user.address) return

    getTokenBalance()
  }, [tokenContract, user.address])

  useEffect(() => {
    setCheckboxValue(false)
  }, [moreThen80Present, inputValue])

  const defineContracts = async (asset: Asset, isMainToken: boolean) => {
    const tokenContract = new web3.eth.Contract(erc20Abi, asset.tokenAddress)
    setTokenContract(tokenContract)

    const marketContract = new web3.eth.Contract(
      isMainToken ? cEthAbi : cErcAbi,
      asset.oTokenAddress
    )
    setMarketContract(marketContract)
  }
  const handleCloseModal = () => {
    const body = document.body
    body.style.height = ''
    body.style.overflowY = ''
    setOpenWithdrawModal({ ...openWithdrawModal, open: false })
  }

  const getTokenBalance = async () => {
    try {
      let result

      if (isMainToken) {
        result = await web3.eth.getBalance(user.address)
      } else {
        result = await tokenContract.methods.balanceOf(user.address).call()
      }
      setTokenBalance(fromBn(result, withdrawAsset.tokenDecimal).toString())
    } catch (error) {
      console.log(error)
    }
  }

  const withdraw = async (amount: any) => {
    handleCloseModal()
    const transactionsId = uniqid()
    dispatch(
      setNotifications({
        type: 'info',
        text: withdrawNotification.textInfoWithdraw,
        link: null,
      })
    )

    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await marketContract.methods
        .redeemUnderlying(toBn(amount, withdrawAsset.tokenDecimal).toString())
        .estimateGas({
          from: user.address,
        })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      const result = await marketContract.methods
        .redeemUnderlying(toBn(amount, withdrawAsset.tokenDecimal).toString())
        .send({
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
          from: user.address,
        })
        .on('transactionHash', (hash: string) => {
          if (hash) {
            dispatch(
              setNotifications({
                type: 'info',
                text: withdrawNotification.textSubmittedWithdraw,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
          }
        })
      dispatch(deleteNotifications(transactionsId))
      setTimeout(() => {
        refetch(dispatch, user.address, asset)
      }, 5000)
      dispatch(
        setNotifications({
          type: 'success',
          text: withdrawNotification.textSuccessWithdraw,
          link: result.transactionHash,
        })
      )
      console.log('redeem', result)
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: withdrawNotification.textErrorWithdraw,
          link: null,
        })
      )
      console.log('error', error)
    }
  }

  const enterPercentInInput = (percent: number) => {
    const availableToWithdrawAssetBN = new BigNumber(maxWithdrawEmount)

    // @ts-ignore
    setInputValue(
      commify(bigDecimal.multiply(availableToWithdrawAssetBN, percent))
    )
  }

  return (
    <div
      className={classes.modalBg}
      onClick={(e) => {
        e.target === e.currentTarget && handleCloseModal()
      }}
    >
      <div className={classes.collateralModal}>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>
            Withdraw your {openWithdrawModal.token.token.symbol}
          </div>
          <div>
            <img
              className={classes.closeIcon}
              onClick={handleCloseModal}
              src={closeIcon}
              alt=""
            />
          </div>
        </div>
        <div className={classes.inputWrapper}>
          <div className={classes.inputDescription}>
            <div className={classes.inputDescriptionText}>Withdraw</div>
          </div>
          <div className={classes.inputDescription}>
            <input
              type="text"
              className={classes.inputPlaceholderText}
              placeholder={'Enter the amount'}
              style={{ outline: 'none' }}
              value={inputValue}
              onChange={(e) =>
                setInputValue(
                  `${commify(
                    `${e.target.value
                      .replace(/[^0-9.]/g, '')
                      .replace(/(\..*)\./g, '$1')}`
                  )}`
                )
              }
            />
          </div>
        </div>
        <div className={classes.depositSelectWrapper}>
          <div
            className={classes.depositSelectButton}
            onClick={() => enterPercentInInput(0.25)}
          >
            25%
          </div>
          <div
            className={classes.depositSelectButton}
            onClick={() => enterPercentInInput(0.5)}
          >
            50%
          </div>
          <div
            className={classes.depositSelectButton}
            onClick={() => enterPercentInInput(0.75)}
          >
            75%
          </div>
          <div
            className={classes.depositSelectButton}
            onClick={() => enterPercentInInput(1)}
          >
            MAX
          </div>
        </div>
        <div className={classes.withdrawTextBlock}>
          <div className={classes.withdrawTextWrapper}>
            <div className={classes.withdrawSubText}>Current Health</div>
            <div className={classes.withdrawMainText}>
              <div className={classes.positionHealth}>
                <div className={classes.wrap}>
                  <div
                    className={classes.red}
                    style={{
                      backgroundColor: positionHealthColor(
                        +userBalances.positionHealth.percentage
                      ),
                    }}
                  ></div>
                  <div>
                    {Math.round(userBalances.positionHealth.coefficient * 100) /
                      100}
                  </div>
                </div>
                <div className={classes.hr}></div>
                <div>
                  {Math.round(userBalances.positionHealth.percentage * 100) /
                    100}
                  %
                </div>
              </div>
            </div>
          </div>
          <div className={classes.withdrawTextBlock}>
            <div className={classes.withdrawTextWrapper}>
              <span className={classes.withdrawSubText}>
                Currently Deposited ({openWithdrawModal.token.token.symbol})
              </span>
              <span className={classes.withdrawMainText}>
                <Tooltip
                  overlayInnerStyle={{ boxShadow: 'none' }}
                  color="none"
                  placement="top"
                  title={
                    <div className={classes.tooltipWithoutRewards}>
                      <div className={classes.noTexttooltipItemWithoutRewards}>
                        {/* <span className={classes.tooltipItemText}>Balance</span> */}
                        <span>
                          {commify(
                            transform(
                              numberToStringInput(
                                +openWithdrawModal.token.value
                              ),
                              6
                            )
                          )}
                        </span>
                      </div>
                    </div>
                  }
                >
                  {transform(openWithdrawModal.token.value)}
                </Tooltip>
              </span>
            </div>
            <div className={classes.withdrawTextWrapper}>
              <span className={classes.withdrawSubText}>
                Currently Holdings ({openWithdrawModal.token.token.symbol})
              </span>
              <span className={classes.withdrawMainText}>
                <Tooltip
                  overlayInnerStyle={{ boxShadow: 'none' }}
                  color="none"
                  placement="top"
                  title={
                    <div className={classes.tooltipWithoutRewards}>
                      <div className={classes.noTexttooltipItemWithoutRewards}>
                        {/* <span className={classes.tooltipItemText}>Balance</span> */}
                        <span>
                          {commify(
                            transform(numberToStringInput(+tokenBalance), 6)
                          ) || '0'}
                        </span>
                      </div>
                    </div>
                  }
                >
                  {transform(tokenBalance)}
                </Tooltip>
              </span>
            </div>
          </div>
          <div className={classes.withdrawTextBlock}>
            <div className={classes.withdrawTextWrapper}>
              <span className={classes.withdrawSubText}>
                Supplied after withdraw ({openWithdrawModal.token.token.symbol})
              </span>
              <span className={classes.withdrawMainText}>
                {!!inputValue &&
                zeroInInput &&
                +inputValue.replace(/[\s,]/g, '') <=
                  +openWithdrawModal.token.value ? (
                  <Tooltip
                    overlayInnerStyle={{ boxShadow: 'none' }}
                    color="none"
                    placement="top"
                    title={
                      <div className={classes.tooltipWithoutRewards}>
                        <div
                          className={classes.noTexttooltipItemWithoutRewards}
                        >
                          {/* <span className={classes.tooltipItemText}>Balance</span> */}
                          <span>
                            {commify(
                              transform(
                                numberToStringInput(SuppliedAfterWithdraw),
                                6
                              )
                            )}
                          </span>
                        </div>
                      </div>
                    }
                  >
                    {transform(SuppliedAfterWithdraw)}
                  </Tooltip>
                ) : (
                  'N/A'
                )}
              </span>
            </div>
            <div className={classes.withdrawTextWrapper}>
              <span className={classes.withdrawSubText}>
                Health after withdraw
              </span>
              <div className={classes.withdrawMainText}>
                {inputValue && zeroInInput ? (
                  <div className={classes.positionHealth}>
                    <div className={classes.wrap}>
                      <div
                        className={classes.red}
                        style={{
                          backgroundColor: positionHealthColor(+percentage),
                        }}
                      ></div>
                      <div>{transform(coefficient.toString(), 2)}</div>
                    </div>
                    <div className={classes.hr}></div>
                    <div>{transform(percentage.toString(), 2)}%</div>
                  </div>
                ) : (
                  'N/A'
                )}
              </div>
            </div>
          </div>
        </div>
        {moreThen80Present && inputValue && zeroInInput ? (
          <div className={classes.borrowInformationBlock}>
            <span className={classes.borrowInformationText}>
              Please be{' '}
              <span className={classes.borrowInformationTextYellow}>
                very careful
              </span>{' '}
              about borrowing more than 80% of your collateral value. By doing
              so you run the{' '}
              <span className={classes.borrowInformationTextYellow}>
                significant risk
              </span>{' '}
              of being liquidated.
            </span>
            <div className={classes.borrowInformationCheckBoxWrapper}>
              <Checkbox
                onChange={() => setCheckboxValue(!checkboxValue)}
                borderColor="#5493F7"
                checked={checkboxValue}
                icon={<img src={checkBoxIcon} alt="" />}
              />
              <div className={classes.borrowInformationCheckBoxText}>
                I know what I’m doing
              </div>
            </div>
          </div>
        ) : null}
        <button
          disabled={!disableButton}
          onClick={() => withdraw(inputValue.replace(/[\s,]/g, ''))}
          className={
            disableButton ? classes.depositButton : classes.depositButtonDisable
          }
        >
          <img src={ButtonIcon} />
          <span className={classes.depositButtonText}>
            WITHDRAW {openWithdrawModal.token.token.symbol}
          </span>
        </button>
      </div>
    </div>
  )
}

export default WithdrawModal
