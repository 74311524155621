import React, { useContext, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import xORBIcon from '../../img/xORB.svg'
import ORBIcon from '../../img/ORB_logo.svg'
import coinIcon from '../../img/coin.svg'
import infoIcon from '../../img/NFT/info-circle.svg'
import Skeleton from 'react-loading-skeleton'
import { commify } from '../../utils'
import BigNumber from 'bignumber.js'
import { Tooltip } from 'antd'
import { setOpenWalletModal } from '../../redux/loading'
import { transform, fromBN } from '../../factory/bigNumber'
import uniqid from 'uniqid'
import {
  deleteNotifications,
  setNotifications,
} from '../../redux/notifications'
import { redeemNotification } from '../../utils/notificationTexts'
import { toBn } from 'evm-bn'
import { Web3Context } from '../../context'
import { requestNFT } from '../../factory/axios'

import { Contract } from 'web3-eth-contract'

const styles = createUseStyles({
  mainTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '40px',
    lineHeight: '60px',
    color: '#FFFFFF',
    paddingBottom: 10,
  },
  imgWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  icon: {
    width: 28,
    height: 28,
  },
  mainText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: 'normal',
    color: '#FFFFFF',
  },
  xORBText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: 'normal',
    color: '#FFFFFF',
  },
  cardWrapper: {
    borderRadius: 12,
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.80) 0%, rgba(53, 53, 81, 0.80) 100%)',
    boxShadow: '1px 1px 1px 0px #42426A inset',
    backdropFilter: 'backdrop-filter: blur(25px)',
    padding: 20,
  },
  inputWrapper: {
    backgroundColor: '#1F1F2D',
    borderRadius: 12,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    marginBottom: 15,
    boxSizing: 'border-box',
    padding: [10, 15],
    height: 68,
  },
  inputDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'end',
  },
  inputDescriptionText: {
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: 'Poppins',
  },
  cursorDisable: {
    cursor: 'not-allowed',
  },
  inputPlaceholderText: {
    whiteSpace: 'nowrap',
    width: '100%',
    backgroundColor: '#1F1F2D',
    border: 'none',
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 400,
    color: '#7D7E83',
    fontFamily: 'Poppins',
  },
  inputText: {
    extend: 'inputPlaceholderText',
    cursor: 'text',
    color: '#FFFFFF',
  },
  inputAvaliableValue: {
    extend: 'inputPlaceholderText',
    width: 'inherit',
    display: 'flex',
    flexDirection: 'column-reverse',
    color: '#5493F7',
    fontWeight: 600,
    alignItems: 'flex-end',
  },
  inputAvaliableValueUSD: {
    extend: 'inputPlaceholderText',
    color: '#A8A8B2',
    display: 'flex',
    width: 106,
    justifyContent: 'end',
    fontWeight: 400,
  },
  depositSelectWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    paddingBottom: 30,
  },
  depositSelectButton: {
    fontFamily: 'Poppins',
    cursor: 'pointer',
    backgroundColor: '#1F1F2D',
    width: 52,
    height: 22,
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 42,
  },
  tokenValueWrapper: {
    display: 'flex',
    justifyContent: 'end',
  },
  cardTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
    paddingBottom: 5,
  },
  cardDescription: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 'normal',
    color: '#A8A8B2',
    paddingBottom: 20,
  },
  hr: {
    borderBottom: 'none',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    height: 1,
    background: '#4B567D',
  },
  recieveBlock: {
    flexDirection: 'column',
    display: 'flex',
    gap: 5,
  },
  activeButton: {
    width: 320,
    height: 60,
    backgroundColor: '#5493F7',

    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    border: 0,
    borderRadius: 12,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    marginTop: 20,
    cursor: 'pointer',
  },
  disableButton: {
    width: 320,
    height: 60,
    backgroundColor: '#44445B',

    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    border: 0,
    borderRadius: 12,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    marginTop: 20,
    cursor: 'not-allowed',
  },
  subText: {
    fontWeight: 500,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: 'Poppins',
  },
  redeemMenuTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  redeemMenu: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    paddingTop: 20,
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  daysButtonActive: {
    width: 65,
    padding: '9px 17px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    // background: 'rgba(59, 69, 100, 0.80)',
    background: '#5493F7',
    textAlign: 'center',

    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 'normal',
    color: '#FFFFFF',
    cursor: 'pointer',
  },
  daysButtonDisable: {
    width: 65,
    padding: '9px 17px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    background: 'rgba(59, 69, 100, 0.80)',
    textAlign: 'center',

    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 'normal',
    color: '#FFFFFF',
    cursor: 'pointer',
  },
  redeemRatioWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tooltipWrapper: {
    padding: '12px 15px',
    background: '#1F1F2D',
    borderRadius: '12px',
    height: '100%',
    width: 300,
  },
  tooltipMainText: {
    fontWeight: 500,
    color: '#FFF',
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: 'Poppins',
  },
  tooltipSubText: {
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: 'Poppins',
  },
  tooltipHr: {
    borderBottom: 'none',
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
    height: 1,
    background: '#4B567D',
  },
  pointer: {
    cursor: 'pointer',
  },
  infoIcon: {
    width: 18,
    height: 18,
  },
  '@media (max-width: 1250px)': {
    cardWrapper: {
      borderRadius: 12,
      background:
        'linear-gradient(180deg, rgba(49, 57, 84, 0.80) 0%, rgba(53, 53, 81, 0.80) 100%)',
      boxShadow: '1px 1px 1px 0px #42426A inset',
      backdropFilter: 'backdrop-filter: blur(25px)',
      padding: 20,
      width: 300,
    },
    activeButton: {
      width: 300,
      height: 60,
      backgroundColor: '#5493F7',

      color: '#FFFFFF',
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '27px',
      border: 0,
      borderRadius: 12,
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
      marginTop: 20,
      cursor: 'pointer',
    },
    disableButton: {
      width: 300,
      height: 60,
      backgroundColor: '#44445B',

      color: '#FFFFFF',
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '27px',
      border: 0,
      borderRadius: 12,
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
      marginTop: 20,
      cursor: 'not-allowed',
    },
    daysButtonActive: {
      width: 70,
      padding: '9px 10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      // background: 'rgba(59, 69, 100, 0.80)',
      background: '#5493F7',
      textAlign: 'center',

      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: 'normal',
      color: '#FFFFFF',
      cursor: 'pointer',
    },
    daysButtonDisable: {
      width: 70,
      padding: '9px 10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      background: 'rgba(59, 69, 100, 0.80)',
      textAlign: 'center',

      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: 'normal',
      color: '#FFFFFF',
      cursor: 'pointer',
    },
    buttonsWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 15,
    },
    cardTitle: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: 'normal',
      color: '#FFFFFF',
      paddingBottom: 5,
    },
  },
  '@media (max-width: 730px)': {
    cardTitle: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: 'normal',
      color: '#FFFFFF',
      paddingBottom: 5,
    },
    mainText: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: 'normal',
      color: '#FFFFFF',
    },
  },
})

const ORBITER_NFT_API_URL = process.env.REACT_APP_X_ORBITER_NFT_API_URL
const CONVERT_CONTRACT = process.env.REACT_APP_X_ORBITER_CONVERT_CONTRACT

interface IProps {
  userXORBBalance: string
  convertContract: Contract | null
  getBalance: () => void
  orbContract: Contract | null
  setData: (value: string) => void
  setRedemption: (value: string) => void
}

const Redeem = ({
  userXORBBalance,
  convertContract,
  getBalance,
  orbContract,
  setData,
  setRedemption,
}: IProps) => {
  const [inputValue, setInputValue] = useState('')
  const [button, setButton] = useState(2)
  const [tokenAllowance, setTokenAllowance] = useState('0')
  const classes = styles()
  const { user } = useSelector((state: any) => state.userReducer)
  const { web3 } = useContext(Web3Context)

  const fetching = false

  const disabled = !userXORBBalance || userXORBBalance === '0'

  const disabledButton =
    !disabled &&
    !!inputValue &&
    +inputValue.replace(/[\s,]/g, '') !== 0 &&
    !(+inputValue.replace(/[\s,]/g, '') > +userXORBBalance)

  const isDescktop = window.innerWidth >= 1250

  const dispatch = useDispatch()

  const enterPersentInInput = (persent: number) => {
    const tokenBalance = userXORBBalance
    const availableToSupplyAssetBN = new BigNumber(tokenBalance)

    setInputValue(
      commify(availableToSupplyAssetBN.multipliedBy(persent).toString())
    )
  }
  const orbOutput = () => {
    if (!inputValue) {
      return '0'
    } else {
      return (
        +inputValue.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') *
        (+getRedeemRatio() ? +getRedeemRatio() / 100 : 100 / 100)
      )
    }
  }

  useEffect(() => {
    if (orbContract) {
      checkAllowance()
    }
    setInputValue('')
  }, [user.address, orbContract])

  const checkAllowance = async () => {
    try {
      const result = await (orbContract as Contract).methods
        .allowance(user.address, CONVERT_CONTRACT)
        .call()

      setTokenAllowance(fromBN(result, 18).toString())
    } catch (error) {
      console.log(error)
    }
  }

  const approve = async () => {
    const transactionsId = uniqid()

    const infinity =
      '115792089237316195423570985008687907853269984665640564039457.584007913129639935'

    setInputValue('')
    dispatch(
      setNotifications({
        type: 'info',
        text: redeemNotification.textInfoApproval,
        link: null,
      })
    )
    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await (orbContract as Contract).methods
        .approve(
          CONVERT_CONTRACT,
          toBn(`${inputValue.replace(/[\s,]/g, '')}`, 18).toString()
        )
        .estimateGas({
          from: user.address,
        })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      const result = await (orbContract as Contract).methods
        .approve(CONVERT_CONTRACT, toBn(`${infinity}`, 18).toString())
        .send({
          from: user.address,
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: redeemNotification.textSubmittedApproval,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          text: redeemNotification.textSuccessApproval,
          link: result.transactionHash,
        })
      )
      redeemOrb()
      console.log('approve:', result)
      checkAllowance()
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: redeemNotification.textErrorApproval,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const redeemOrb = async () => {
    let result
    const transactionsId = uniqid()
    dispatch(
      setNotifications({
        type: 'info',
        text: redeemNotification.textInfoRedeem,
        link: null,
      })
    )

    setInputValue('')

    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await (convertContract as Contract).methods
        .redeem(
          toBn(
            `${inputValue.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')}`,
            18
          ).toString(),
          button
        )
        .estimateGas({
          from: user.address,
        })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      result = await (convertContract as Contract).methods
        .redeem(
          toBn(
            `${inputValue.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')}`,
            18
          ).toString(),
          button
        )
        .send({
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
          from: user.address,
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: redeemNotification.textSubmittedRedeem,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          text: redeemNotification.textSuccessRedeem,
          link: result.transactionHash,
        })
      )
      getBalance()
      setTimeout(() => {
        requestNFT({
          method: 'get',
          url: `${ORBITER_NFT_API_URL}/x-orb/${user.address}`,
        }).then((req) => {
          setData(req.data.redemptions)
          setRedemption(new BigNumber(req.data.totalRedemption).toFixed(0))
        })
      }, 5000)
      checkAllowance()
      console.log('convertOrb', result)
      setInputValue('')
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: redeemNotification.textErrorRedeem,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const aprruveAndRedeem = async () => {
    if (
      +tokenAllowance >=
        +inputValue.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') ||
      button === 0
    ) {
      redeemOrb()
    } else {
      await approve()
    }
  }

  const renderButton = () => {
    if (!user.connected) {
      return (
        <div
          onClick={() => dispatch(setOpenWalletModal(true))}
          className={classes.activeButton}
        >
          Connect Wallet
        </div>
      )
    } else if (
      +inputValue.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') >
        +tokenAllowance &&
      button !== 0
    ) {
      return (
        <button
          disabled={!disabledButton}
          onClick={() => aprruveAndRedeem()}
          className={
            !disabledButton ? classes.disableButton : classes.activeButton
          }
        >
          <img src={coinIcon} alt="" />
          <span>APPROVE & Redeem</span>
        </button>
      )
    } else {
      return (
        <button
          disabled={!disabledButton}
          onClick={() => aprruveAndRedeem()}
          className={
            !disabledButton ? classes.disableButton : classes.activeButton
          }
        >
          <img src={coinIcon} alt="" />
          <span>Redeem</span>
        </button>
      )
    }
  }

  const getRedeemRatio = () => {
    if (button === 0) {
      return '10'
    } else if (button === 1) {
      return '50'
    } else {
      return '100'
    }
  }

  return (
    <div className={classes.cardWrapper}>
      <div className={classes.cardTitle}>Redeem ORB</div>
      <div className={classes.cardDescription}>
        Redeem your xORB back into ORB over a vesting period
      </div>
      <div>
        {fetching ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Skeleton
              baseColor="#4B567D"
              highlightColor="#5B6796"
              width="300px"
              height="70px"
              count={1}
              borderRadius="22px"
            />{' '}
          </div>
        ) : (
          <div className={classes.inputWrapper}>
            <input
              type="text"
              className={classes.inputText}
              placeholder={'Enter the amount'}
              style={{ outline: 'none' }}
              value={inputValue}
              onChange={(e) =>
                setInputValue(
                  `${commify(
                    `${e.target.value
                      .replace(/[^0-9.]/g, '')
                      .replace(/(\..*)\./g, '$1')}`
                  )}`
                )
              }
            />
          </div>
        )}
        <div className={classes.depositSelectWrapper}>
          <div
            className={classes.depositSelectButton}
            onClick={() => enterPersentInInput(0.25)}
          >
            25%
          </div>
          <div
            className={classes.depositSelectButton}
            onClick={() => enterPersentInInput(0.5)}
          >
            50%
          </div>
          <div
            className={classes.depositSelectButton}
            onClick={() => enterPersentInInput(0.75)}
          >
            75%
          </div>
          <div
            className={classes.depositSelectButton}
            onClick={() => enterPersentInInput(1)}
          >
            MAX
          </div>
        </div>
      </div>
      <div className={classes.recieveBlock}>
        <span className={classes.subText}>xORB Balance</span>
        <div className={classes.imgWrapper}>
          <img src={xORBIcon} alt="" />
          <span className={classes.xORBText}>
            {transform(userXORBBalance, 4)} xORB
          </span>
        </div>
      </div>
      <div className={classes.redeemMenu}>
        <div className={classes.redeemMenuTitleWrapper}>
          <div className={classes.subText}>Redeem duration</div>
          <Tooltip
            className={[classes.imgWrapper, classes.pointer].join(' ')}
            placement={isDescktop ? 'bottomLeft' : 'leftTop'}
            overlayInnerStyle={{ boxShadow: 'none' }}
            color="none"
            title={
              <div className={classes.tooltipWrapper}>
                <div>
                  <div className={classes.tooltipMainText}>
                    {'0 days -> 10%'}
                  </div>
                  <div className={classes.tooltipSubText}>
                    Instant conversion without vesting
                  </div>
                </div>
                <div className={classes.tooltipHr}></div>
                <div>
                  <div className={classes.tooltipMainText}>
                    {'30 days -> 50%'}
                  </div>
                  <div className={classes.tooltipSubText}>
                    If you cancel vesting, you will get back 50% of your xORB
                  </div>
                </div>
                <div className={classes.tooltipHr}></div>
                <div>
                  <div className={classes.tooltipMainText}>
                    {'90 days -> 100%'}
                  </div>
                  <div className={classes.tooltipSubText}>
                    You can cancel vesting without any losses
                  </div>
                </div>
              </div>
            }
          >
            <img className={classes.infoIcon} src={infoIcon} alt="" />
            <span className={classes.subText}>Ratios</span>
          </Tooltip>
        </div>
        <div className={classes.buttonsWrapper}>
          <div
            className={
              user.connected && button === 0
                ? classes.daysButtonActive
                : classes.daysButtonDisable
            }
            onClick={() => user.connected && setButton(0)}
          >
            0 days
          </div>
          <div
            className={
              user.connected && button === 1
                ? classes.daysButtonActive
                : classes.daysButtonDisable
            }
            onClick={() => user.connected && setButton(1)}
          >
            30 days
          </div>
          <div
            className={
              user.connected && button === 2
                ? classes.daysButtonActive
                : classes.daysButtonDisable
            }
            onClick={() => user.connected && setButton(2)}
          >
            90 days
          </div>
        </div>
      </div>
      <div className={classes.hr}></div>
      <div className={classes.recieveBlock}>
        <div className={classes.redeemRatioWrapper}>
          <div className={classes.subText}>Redeem ratio</div>
          <div className={classes.mainText}>{getRedeemRatio()}%</div>
        </div>
        <div className={classes.redeemRatioWrapper}>
          <div className={classes.subText}>ORB output</div>
          <div className={classes.imgWrapper}>
            <img src={ORBIcon} alt="" />
            <span className={classes.mainText}>
              {transform(orbOutput().toString(), 4)} ORB
            </span>
          </div>
        </div>
      </div>
      {renderButton()}
    </div>
  )
}

export default Redeem
