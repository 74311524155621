import { configureStore } from '@reduxjs/toolkit'
import marketOverviewSlice from './marketOverview'
import loadingSlice from './loading'
import userSlice from './user'
import transactionsSlice from './transactions'
import compositionsSlice from './compositions'
import assetsSlice from './assets'
import assetSlice from './asset'
import assetBalanceSlice from './assetsBalance'
import userBalancesSlice from './userBalances'
import notificationsSlice from './notifications'
import userAssetsSlice from './userAssets'
import tabsControllerSlice from './tadsController'
import chainIdSlice from './chainId'
import claimModalSlice from './claimModal'
import userNftsSlice from './userNfts'
import ratesSlice from './rates'
import earnCardStateSlice from './earnCardState'
import swingSwapModalSlice from './swingSwapModal'

export const store = configureStore({
  reducer: {
    marketOverviewReducer: marketOverviewSlice,
    loadingReducer: loadingSlice,
    userReducer: userSlice,
    userBalancesReducer: userBalancesSlice,
    transactionsReducer: transactionsSlice,
    compositionsReducer: compositionsSlice,
    assetReducer: assetSlice,
    assetsReducer: assetsSlice,
    userAssetsReducer: userAssetsSlice,
    assetsBalanceReducer: assetBalanceSlice,
    notificationsReducer: notificationsSlice,
    tabsControllerReducer: tabsControllerSlice,
    chainIdReducer: chainIdSlice,
    claimModalReducer: claimModalSlice,
    userNftsReducer: userNftsSlice,
    ratesReducer: ratesSlice,
    earnCardStateReducer: earnCardStateSlice,
    swingSwapReducer: swingSwapModalSlice,
  },
})
