import React from 'react'
import { createUseStyles } from 'react-jss'
import logo from '../../img/logo.svg'
import smallLogo from '../../img/smallLogo.svg'
import vector from '../../img/HeaderArrowDown.svg'
import menu from '../../img/menu.svg'
import openMenu from '../../img/open_menu.svg'
import WalletButton from '../WalletButton/WalletButton'
import { Link } from 'react-router-dom'
import NetworkDropdown from '../NeworkDropdown/NetworkDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { setOpen } from '../../redux/claimModal'
import { moonBaseAlfaNFT } from '../../utils/chainConfig'
import RatesDropdown from '../RatesDropdown/RatesDropdown'
import useWindowSize from 'src/utils/resizeHook'
import { setIsOpen } from 'src/redux/swingSwapModal'

const actualApiFor = process.env.REACT_APP_X_ORBITER_ACTIVE_API_FOR

const styles = createUseStyles({
  header: {
    display: 'flex',
    height: 104,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: '#171932',
    backdropFilter: 'blur(60px)',
    margin: '0 auto',
    maxWidth: 1360,
    padding: [0, 30],
  },
  smallHeader: {
    extend: 'header',
    height: 80,
  },
  navBlock: {
    display: 'flex',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    width: 750,
    gap: 20,
    color: '#A8A8B2',
  },
  navLink: {
    color: 'inherit',
    textDecoration: 'none',
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
    },
  },
  activeLink: {
    extend: 'navLink',
    fontWeight: 600,
    color: '#FFFFFF',
  },
  dropDown: {
    display: 'flex',
    gap: 5,
    position: 'relative',
    cursor: 'pointer',
    alignItems: 'center',
    transition: ['color'],
    transitionDuration: 300,
    '& $dropDownBridge': {},
    '& $smallDropDownBridge': {},
    '&:hover $dropDownBridge': {
      opacity: 1,
      visibility: 'visible',
    },
    '&:hover $smallDropDownBridge': {
      opacity: 1,
      visibility: 'visible',
    },
    '&:hover $dropdownArrow': {
      transform: 'rotate(-180deg)',
    },
    '&:hover': {
      color: '#fff',
    },
  },
  dropDownBridge: {
    transition: 'visibility 0.5s, opacity 0.5s',
    opacity: 0,
    position: 'absolute',
    width: 172,
    height: 68,
    padding: [20, 25, 20],
    background:
      'linear-gradient(180deg, rgba(49, 57, 84) 0%, rgba(53, 53, 81) 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    top: 40,
    left: 0,
    zIndex: 10,
    visibility: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  smallDropDownBridge: {
    extend: 'dropDownBridge',
    top: 40,
  },
  dropDownBridgeContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bridgeLink: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    textDecoration: 'none',
    color: '#A8A8B2',
    transition: ['color'],
    transitionDuration: 300,
    background: 'transparent',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    '&:hover': {
      color: '#fff',
    },
    '&:hover $dropDownHr': {
      backgroundColor: '#fff',
    },
  },
  dropdownArrow: {
    extend: 'bridgeLink',
  },

  swapDropDown: {
    display: 'flex',
    gap: 5,
    position: 'relative',
    cursor: 'pointer',
    alignItems: 'center',
    transition: ['color'],
    transitionDuration: 300,
    '& $swapDropDownWrapper': {},
    '& $smallSwapDropDownWrapper': {},
    '&:hover $swapDropDownWrapper': {
      opacity: 1,
      visibility: 'visible',
    },
    '&:hover $smallSwapDropDownWrapper': {
      opacity: 1,
      visibility: 'visible',
    },
    '&:hover': {
      color: '#fff',
    },
    '&:hover $dropdownArrow': {
      transform: 'rotate(-180deg)',
    },
  },
  swapDropDownWrapper: {
    transition: 'visibility 0.5s, opacity 0.5s',
    opacity: 0,
    position: 'absolute',
    width: 165,
    height: 163,
    padding: [20],
    background:
      'linear-gradient(180deg, rgba(49, 57, 84) 0%, rgba(53, 53, 81) 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    top: 40,
    left: 0,
    zIndex: 10,
    visibility: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  smallSwapDropDownWrapper: {
    extend: 'swapDropDownWrapper',
    top: 40,
  },
  earnDropDown: {
    display: 'flex',
    gap: 5,
    position: 'relative',
    cursor: 'pointer',
    alignItems: 'center',
    transition: ['color'],
    transitionDuration: 300,
    '& $earnDropDownWrapper': {},
    '& $smallEarnDropDownWrapper': {},
    '&:hover $earnDropDownWrapper': {
      opacity: 1,
      visibility: 'visible',
    },
    '&:hover $smallEarnDropDownWrapper': {
      opacity: 1,
      visibility: 'visible',
    },
    '&:hover $dropdownArrow': {
      transform: 'rotate(-180deg)',
    },
    '&:hover': {
      color: '#fff',
    },
  },
  earnDropDownWrapper: {
    extend: 'swapDropDownWrapper',
    height: 'auto',
    width: 120,
  },
  smallEarnDropDownWrapper: {
    extend: 'swapDropDownWrapper',
    height: 'auto',
    width: 120,
    top: 40,
  },
  earnDropDownTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  earnDropDownTitleActive: {
    extend: 'earnDropDownTitle',
    fontWeight: 600,
    color: '#FFFFFF',
  },

  secondDropDown: {
    display: 'flex',
    gap: 5,
    position: 'relative',
    cursor: 'pointer',
    alignItems: 'center',
    transition: ['color'],
    transitionDuration: 300,
    '& $dropDownMore': {},
    '& $smallDropDownMore': {},
    '&:hover $dropDownMore': {
      opacity: 1,
      visibility: 'visible',
    },
    '&:hover $smallDropDownMore': {
      opacity: 1,
      visibility: 'visible',
    },
    '&:hover': {
      color: '#fff',
    },
  },
  secondDropDownDisabled: {
    display: 'flex',
    gap: 5,
    position: 'relative',
    cursor: 'not-allowed',
    alignItems: 'center',
    transition: ['color'],
    transitionDuration: 300,
    '& $dropDownMore': {},
    '& $smallDropDownMore': {},
    '&:hover $dropDownMore': {
      opacity: 1,
      visibility: 'visible',
    },
    '&:hover $smallDropDownMore': {
      opacity: 1,
      visibility: 'visible',
    },
    '&:hover': {
      color: '#fff',
    },
  },
  dropDownMore: {
    transition: 'visibility 0.5s, opacity 0.5s',
    opacity: 0,
    position: 'absolute',
    width: 155,
    // height: 112,
    padding: [20, 25, 20],
    background:
      'linear-gradient(180deg, rgba(49, 57, 84) 0%, rgba(53, 53, 81) 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    top: 40,
    left: 0,
    zIndex: 10,
    visibility: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  smallDropDownMore: {
    extend: 'dropDownMore',
    top: 40,
  },
  dropDownMoreContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  isDisabled: {
    color: '#545151',
    cursor: 'not-allowed',
    opacity: '0.5',
    textDecoration: 'none',
    position: 'relative',
  },
  blueHr: {
    top: '130%',
    position: 'absolute',
    width: 20,
    height: 4,
    backgroundColor: '#5493F7',
    borderRadius: 37,
  },
  smallBlueHr: {
    top: '120%',
    position: 'absolute',
    width: 20,
    height: 4,
    backgroundColor: '#5493F7',
    borderRadius: 37,
  },
  walletButton: {
    width: 165,
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    borderRadius: 15,
    paddingTop: 9,
    paddingBottom: 9,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    boxShadow: 'inset 1px 1px 1px #5C668C',
    backdropFilter: 'blur(25px)',
  },
  walletIcon: {
    paddingLeft: 14,
    paddingRight: 10,
  },
  walletText: {
    paddingRight: 15,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'white',
  },
  walletNotConnectedText: {
    paddingLeft: 22,
    paddingRight: 22,
    fontSize: '16px',
    lineHeight: '24px',
    extend: 'walletText',
  },
  dashboard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  menuWrapper: {},
  menu: {
    display: 'none',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    color: '#FFFFFF',
  },
  menuText: {
    display: 'none',
  },
  logo: {
    marginRight: 43,
  },
  smallLogo: {
    marginRight: 60,
    width: 54,
    height: 48,
  },
  smallLogoMobile: {
    marginRight: 60,
    width: 48,
    height: 48,
  },
  connectedModal: {
    width: 280,
    height: 177,
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    position: 'absolute',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    top: 111,
    padding: 20,
    right: 0,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  connectedModalRef: {
    display: 'contents',
  },
  connectedModalHeader: {
    display: 'flex',
    paddingBottom: 10,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  connectedModalContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  connectedModalMainText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'white',
  },
  connectedModalSubText: {
    extend: 'connectedModalMainText',
    color: '#A8A8B2',
  },
  connectedModalContent: {
    justifyContent: 'space-between',
    display: 'flex',
    paddingBottom: 10,
  },
  hr: {
    margin: 0,
    borderBottom: 'none',
    width: '100%',
  },
  connectedModalButton: {
    display: 'flex',
    paddingBottom: 10,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  modal: {
    display: 'none',
    position: 'fixed',
    zIndex: 3,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  testnet: {
    textDecoration: 'none',
    position: 'relative',
    color: 'red',
    cursor: 'default',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  dropDownHr: {
    height: 4,
    width: 4,
    borderRadius: 32,
    backgroundColor: '#A8A8B2',
  },
  dropDownItemWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    fontWeight: 400,
  },
  crosChainText: {
    paddingTop: 10,
    color: '#6170A8',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    lineHeight: '21px',
  },
  '@media (max-width: 1359px)': {
    header: {
      justifyContent: 'space-between',
    },
    navBlock: {
      display: 'none',
    },
    menuText: {
      display: 'block',
    },
    logo: {
      width: 112,
      height: 68,
      margin: 0,
    },
    smallLogo: {
      margin: 0,
    },
    smallLogoMobile: {
      margin: 0,
    },
    smallHeader: {
      justifyContent: 'space-between',
    },
    menu: {
      width: 'auto',
      display: 'flex',
      gap: 10,
      alignItems: 'center',
    },
    menuWrapper: {},
  },
  '@media (max-width: 730px)': {
    menu: {
      width: 34,
      height: 34,
    },
    logo: {
      width: 92,
      height: 52,
    },
    walletIcon: {
      padding: 0,
    },
    walletButton: {
      width: 34,
      height: 34,
      padding: 0,
      borderRadius: 10,
    },
    smallHeader: {
      maxWidth: 340,
      padding: [0, 10],
    },
    header: {
      maxWidth: 360,
      padding: [0, 10],
      height: 104,
    },
    menuText: {
      display: 'none',
    },
    walletText: {
      display: 'none',
    },
    menuWrapper: {},
  },
})

interface Props {
  removeWalletData: () => void
  handleOpen: () => void
  connectWallet: (a: string, b: boolean) => void
  open: boolean
  showSwitchNetworkModal: boolean
  smallHeader: boolean
  switchNetwork: (params: any) => void
  isNftPage?: boolean
}

const Header: React.FC<Props> = ({
  removeWalletData,
  handleOpen,
  connectWallet,
  open,
  showSwitchNetworkModal,
  smallHeader,
  switchNetwork,
  isNftPage,
}) => {
  const classes = styles()
  const windowInnerWidth = window.innerWidth
  const { user } = useSelector((state: any) => state.userReducer)
  const { currentWidth } = useWindowSize()
  const dispatch = useDispatch()

  const openSwingWidget = () => {
    dispatch(setIsOpen(true))
  }

  return (
    <header className={smallHeader ? classes.smallHeader : classes.header}>
      <div>
        {currentWidth <= 730 ? (
          <>
            <Link to="/">
              <img
                onClick={() => open && handleOpen()}
                className={classes.smallLogoMobile}
                src={smallLogo}
                alt="logo"
              />
            </Link>
          </>
        ) : (
          <>
            <Link to="/">
              <img
                onClick={() => open && handleOpen()}
                className={smallHeader ? classes.smallLogo : classes.logo}
                src={smallHeader ? smallLogo : logo}
                alt="logo"
              />
            </Link>
          </>
        )}
      </div>
      <div className={classes.navBlock}>
        <div
          className={window.location.pathname === '/' ? classes.activeLink : ''}
        >
          <div className={classes.dashboard}>
            <Link className={classes.navLink} to="/">
              Dashboard
            </Link>
            {window.location.pathname === '/' && (
              <div
                className={smallHeader ? classes.smallBlueHr : classes.blueHr}
              ></div>
            )}
          </div>
        </div>

        <div className={classes.swapDropDown} onClick={openSwingWidget}>
          Swap
        </div>

        <div
          className={
            window.location.pathname === '/xORB' ? classes.activeLink : ''
          }
        >
          <div className={classes.dashboard}>
            <Link className={classes.navLink} to={'/xORB'}>
              xORB
            </Link>
            {window.location.pathname === '/xORB' && (
              <div
                className={smallHeader ? classes.smallBlueHr : classes.blueHr}
              ></div>
            )}
          </div>
        </div>

        <div
          className={
            window.location.pathname === '/farms' ? classes.activeLink : ''
          }
        >
          <div className={classes.dashboard}>
            <Link className={classes.navLink} to={'/farms'}>
              Farms
            </Link>
            {window.location.pathname === '/farms' && (
              <div
                className={smallHeader ? classes.smallBlueHr : classes.blueHr}
              ></div>
            )}
          </div>
        </div>

        <span className={classes.dropDown}>
          NFT <img className={classes.dropdownArrow} src={vector} alt="" />
          <div
            className={
              smallHeader ? classes.smallDropDownBridge : classes.dropDownBridge
            }
          >
            <div className={classes.dropDownBridgeContent}>
              <Link to={'/whiskers'} className={classes.bridgeLink}>
                <div className={classes.dropDownItemWrapper}>
                  <div className={classes.dropDownHr}></div>
                  Mint Whiskers
                </div>
              </Link>
            </div>
            <div className={classes.dropDownBridgeContent}>
              <Link to={'/staking'} className={classes.bridgeLink}>
                <div className={classes.dropDownItemWrapper}>
                  <div className={classes.dropDownHr}></div>
                  Stake Whiskers
                </div>
              </Link>
            </div>
          </div>
        </span>

        {!moonBaseAlfaNFT ? (
          <div className={classes.dashboard}>
            <a
              href="https://app.orbiter.one/lottery"
              className={classes.navLink}
            >
              Lottery
            </a>
          </div>
        ) : (
          <div className={classes.isDisabled}>Lottery</div>
        )}

        <span className={classes.dropDown}>
          Bridge <img className={classes.dropdownArrow} src={vector} alt="" />
          <div
            className={
              smallHeader ? classes.smallDropDownBridge : classes.dropDownBridge
            }
          >
            <div className={classes.dropDownBridgeContent}>
              <a
                href="https://app.orionbridge.xyz/"
                target="_blank"
                className={classes.bridgeLink}
                rel="noreferrer"
              >
                <div className={classes.dropDownItemWrapper}>
                  <div className={classes.dropDownHr}></div>
                  Orion Bridge&nbsp;(ORB)
                </div>
              </a>
            </div>
            <div className={classes.dropDownBridgeContent}>
              <a
                href="https://apps.moonbeam.network/moonbeam"
                target="_blank"
                className={classes.bridgeLink}
                rel="noreferrer"
              >
                <div className={classes.dropDownItemWrapper}>
                  <div className={classes.dropDownHr}></div>
                  XCM Transfer
                </div>
              </a>
            </div>
          </div>
        </span>

        {user.connected && (
          <div
            onClick={() => {
              dispatch(setOpen(true))
            }}
            className={classes.navLink}
          >
            Rewards
          </div>
        )}
        {/* <div
          className={
            window.location.pathname === '/staking' ? classes.activeLink : ''
          }
        >
          <div className={classes.dashboard}>
            <Link className={classes.navLink} to={'/staking'}>
              Stake&nbsp;NFT
            </Link>
            {window.location.pathname === '/staking' && (
              <div
                className={smallHeader ? classes.smallBlueHr : classes.blueHr}
              ></div>
            )}
          </div>
        </div> */}
        {/*<span*/}
        {/*  className={[*/}
        {/*    moonBaseAlfaNFT*/}
        {/*      ? classes.secondDropDown*/}
        {/*      : classes.secondDropDownDisabled,*/}
        {/*  ].join(' ')}*/}
        {/*>*/}
        {/*    <>*/}
        {/*      Earn <img className={classes.bridgeLink} src={vector} />*/}
        {/*      <div*/}
        {/*        className={*/}
        {/*          smallHeader ? classes.smallDropDownMore : classes.dropDownMore*/}
        {/*        }*/}
        {/*      >*/}
        {/*        <div className={classes.dropDownMoreContent}>*/}
        {/*          <Link to="/staking" className={classes.bridgeLink}>*/}
        {/*            <div className={classes.dropDownItemWrapper}>*/}
        {/*              <div className={classes.dropDownHr}></div>*/}
        {/*              Staking*/}
        {/*            </div>*/}
        {/*          </Link>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </>*/}
        {/*</span>*/}
        {actualApiFor === 'dev' && (
          <div
            className={
              window.location.pathname === '/faucet' ? classes.activeLink : ''
            }
          >
            <div className={classes.dashboard}>
              <Link className={classes.navLink} to="/faucet">
                Faucet
              </Link>
              {window.location.pathname === '/faucet' && (
                <div
                  className={smallHeader ? classes.smallBlueHr : classes.blueHr}
                ></div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className={classes.buttonWrapper}>
        <RatesDropdown />
        {user.connected && (
          <NetworkDropdown
            switchNetwork={switchNetwork}
            smallHeader={smallHeader}
            isNftPage={isNftPage}
          />
        )}
        <WalletButton
          smallHeader={smallHeader}
          showSwitchNetworkModal={showSwitchNetworkModal}
          removeWalletData={removeWalletData}
          windowInnerWidth={windowInnerWidth}
          connectWallet={connectWallet}
          isNftPage={isNftPage}
        />
        <div className={classes.menuWrapper}>
          <div className={classes.menu} onClick={() => handleOpen()}>
            <img src={open ? openMenu : menu} />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
