import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface modalState {
  open: boolean
}

const initialState: modalState = {
  open: false,
}

export const claimModalSlice = createSlice({
  name: 'claimModal',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<any>) => {
      state.open = action.payload
    },
  },
})

export const { setOpen } = claimModalSlice.actions

export default claimModalSlice.reducer
