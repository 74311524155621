import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface earnCardState {
  open: {
    '12h': boolean
    '24h': boolean
    '7d': boolean
  }
}

const initialState: earnCardState = {
  open: {
    '12h': false,
    '24h': false,
    '7d': false,
  },
}

export const earnCardState = createSlice({
  name: 'earnCardState',
  initialState,
  reducers: {
    setEarnCardState: (state, action: PayloadAction<any>) => {
      state.open = action.payload
    },
  },
})

export const { setEarnCardState } = earnCardState.actions

export default earnCardState.reducer
