import { transform } from '../factory/bigNumber'

const usePositionHealth = (
  type,
  inputValue,
  totalBorrowed,
  totalCollateral,
  usdPrice,
  collateralFactor,
  totalSupply,
  availableToBorrow,
  CurrentlyDeposited
) => {
  const inputInUsd = +`${inputValue}`.split(',').join('') * +usdPrice

  let newBorrowed
  let percentage
  let coefficient

  switch (true) {
    case type === 'borrow':
      newBorrowed = +totalBorrowed + inputInUsd
      percentage = (newBorrowed / totalCollateral) * 100
      coefficient = totalCollateral / newBorrowed
      break
    case type === 'repay':
      newBorrowed = +totalBorrowed - inputInUsd
      percentage = (newBorrowed / totalCollateral) * 100
      coefficient = totalCollateral / newBorrowed
      break
    case type === 'supply':
      const newSupplyCollateral =
        +totalCollateral + inputInUsd * (collateralFactor / 100)
      percentage = (+totalBorrowed / newSupplyCollateral) * 100
      coefficient = newSupplyCollateral / +totalBorrowed
      break
    case type === 'withdraw':
      const currentlyDepositedUSD = +CurrentlyDeposited * +usdPrice
      const newTotalCollateral =
        totalCollateral -
        currentlyDepositedUSD * (collateralFactor / 100) +
        (currentlyDepositedUSD - inputInUsd) * (collateralFactor / 100)
      percentage = (+totalBorrowed / newTotalCollateral) * 100
      coefficient = newTotalCollateral / +totalBorrowed
      break
    default:
      break
  }

  percentage =
    percentage < 1 || !percentage
      ? 0
      : percentage > 100
      ? 100
      : transform(percentage, 2)

  coefficient =
    coefficient > 100 || !coefficient
      ? 100
      : coefficient < 0
      ? 0
      : transform(coefficient, 2)

  const moreThen80Present = percentage >= 80

  return {
    percentage,
    coefficient,
    moreThen80Present,
  }
}

export default usePositionHealth
