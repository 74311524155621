import React, { useContext, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import 'react-loading-skeleton/dist/skeleton.css'

import usdc from '../../img/USDC.svg'
import zkSynk from '../../img/zksync era.svg'
import arbitrum from '../../img/arbitrum-logo.svg'
import arrowBlue from '../../img/arrow-right-blue.svg'
import arrowGreen from '../../img/arrow-right-green.svg'
import addIcon from '../../img/add-square-green.svg'
import arrow from '../../img/arrowEarn.svg'
import info from '../../img/NFT/info-circle.svg'
import { activeTabsEarn } from '../../redux/tadsController'
import { Web3Context } from '../../context'
import { useDispatch, useSelector } from 'react-redux'
import poolAbi from '../../contracts/erc20Abi.abi'
import farmingPoolAbi12h from '../../contracts/farmingPool12h.abi'
import farmingPoolAbi from '../../contracts/farmingPool.abi'
import { fromBn, toBn } from 'evm-bn'
import BigNumber from 'bignumber.js'
import { commify } from '../../utils'
import bigDecimal from 'js-big-decimal'
import uniqid from 'uniqid'
import {
  deleteNotifications,
  setNotifications,
} from '../../redux/notifications'
import {
  poolNotification,
  supplyNotification,
} from '../../utils/notificationTexts'
import {
  paramsArbitrumTest,
  paramsMovr,
  paramsZkSyncTest,
} from '../../utils/networkConst'
import { transform } from '../../factory/bigNumber'
import { Tooltip } from 'antd'
import CountDownTimer from '../CountDownTimer/CountDownTimer'
import { setEarnCardState } from '../../redux/earnCardState'

import { setOpenWalletModal } from '../../redux/loading'

const styles = createUseStyles({
  earnCard: {
    width: 300,
    border: '1px solid #4B567D',
    borderRadius: 12,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
  earnCardOpen: {
    extend: 'earnCard',
    height: 86,
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  imgWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  imgTopWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  titleText: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
  },
  mainText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
  },
  subText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
    fontFamily: 'Poppins',
  },
  smallTimer: {
    width: 190,

    position: 'absolute',
    left: '50%',
    bottom: -17,
    transform: 'translate(-50%, 0)',
    borderRadius: 74,
    border: '1px solid #5493F7',
    background:
      'linear-gradient(180deg, rgba(49, 57, 84) 0%, rgba(53, 53, 81, 0.80) 100%)',
    display: 'flex',
    gap: 5,
    padding: [4, 10],
  },
  orbText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
  },
  mainStakeText: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
  },
  mainStakeTextActive: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#A8A8B2',
    fontFamily: 'Poppins',
  },
  openImg: {
    width: 28,
    height: 28,
  },
  getOrbWrapper: {
    display: 'flex',
    gap: 30,
    paddingBottom: 32,
  },
  greenText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#53CBC8',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
  },
  blueText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#5493F7',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
  },
  hr: {
    height: 0,
    width: 300,
    borderTop: '1px solid #4B567D',
    margin: '0 auto',
  },
  hrSmall: {
    height: 0,
    width: 260,
    borderTop: '1px solid #4B567D',
    margin: '0 auto',
  },
  harvestButton: {
    width: 260,
    height: 60,
    backgroundColor: '#44445B',

    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    border: 0,
    borderRadius: 12,
    textTransform: 'uppercase',
  },
  lockButton: {
    extend: 'greenText',
    backgroundColor: 'inherit',
    border: '1px solid #53CBC8',
    borderRadius: 8,
    width: 260,
    height: 30,
    marginTop: 5,
  },
  enableButton: {
    extend: 'harvestButton',
    backgroundColor: '#5493F7',
  },
  earnCardWrapperOpen: {
    display: 'block',
  },
  earnCardWrapper: {
    display: 'none',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10,
    flexDirection: 'column',
  },
  lockButtonWrapper: {
    extend: 'buttonWrapper',
    paddingTop: 20,
  },
  earnedOrbWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [20, 20, 0, 20],
  },
  infoIconWrapper: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
  },
  apyWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 20,
    paddingLeft: 20,
  },
  cardEarnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    paddingBottom: 20,
  },
  stakeTabsWrapper: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 20,
    paddingBottom: 30,
  },
  blueHr: {
    width: 20,
    height: 4,
    backgroundColor: '#5493F7',
    borderRadius: 37,
    margin: ' 5px auto 0',
  },
  stakeWrapper: {
    padding: 20,
  },
  inputWrapper: {
    backgroundColor: '#1F1F2D',
    borderRadius: 12,
    width: 260,
    height: 68,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 15,
    boxSizing: 'border-box',
    padding: [10, 15],
  },
  inputDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'end',
  },
  inputDescriptionText: {
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
  },
  cursorDisable: {
    cursor: 'not-allowed',
  },
  inputPlaceholderText: {
    whiteSpace: 'nowrap',
    width: '235px',
    backgroundColor: '#1F1F2D',
    border: 'none',
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 400,
    color: '#7D7E83',
  },
  inputText: {
    extend: 'inputPlaceholderText',
    cursor: 'text',
    color: '#FFFFFF',
  },
  inputAvaliableValue: {
    extend: 'inputPlaceholderText',
    width: 'inherit',
    display: 'flex',
    flexDirection: 'column-reverse',
    color: '#5493F7',
    fontWeight: 600,
    alignItems: 'flex-end',
  },
  inputAvaliableValueUSD: {
    extend: 'inputPlaceholderText',
    color: '#A8A8B2',
    display: 'flex',
    width: 106,
    justifyContent: 'end',
    fontWeight: 400,
  },
  depositSelectWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    paddingBottom: 30,
  },
  depositSelectButton: {
    fontFamily: 'Poppins',
    cursor: 'pointer',
    backgroundColor: '#1F1F2D',
    width: 52,
    height: 22,
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    borderRadius: 42,
  },
  depositSelectButtonDisabled: {
    backgroundColor: '#1F1F2D',
    border: 'none',
    width: 52,
    height: 22,
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 42,
    cursor: 'not-allowed',
  },
  rewardsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
  },
  rewardsTitle: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    paddingBottom: 22,
  },
  userBalanceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    paddingBottom: 10,
  },
  balanceMainText: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
  },
  balanceSubText: {
    color: '#A8A8B2',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
  },
  rewardsEmountWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    paddingBottom: 20,
  },
  orbEmoutCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  orbEmountImgWrapper: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },
  tokenIcon: {
    width: 16,
    height: 16,
  },
  claimButton: {
    extend: 'harvestButton',
    width: 260,
    height: 60,
    marginTop: 10,
    backgroundColor: '#5493F7',
  },
  claimLPButton: {
    extend: 'harvestButton',
    width: 260,
    height: 60,
    backgroundColor: '#5493F7',
    marginBottom: 20,
  },
  imgTokenWrapper: {
    position: 'relative',
    width: 50,
    height: 50,
    marginRight: 15,
  },
  tokenImgTop: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  tokenImgBottom: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  tokenImg: {
    width: 24,
    height: 24,
  },
  paddingBottomFive: {
    paddingBottom: 5,
  },
  subTextBig: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#A8A8B2',
    fontFamily: 'Poppins',
    paddingRight: 5,
  },
  mainSmallText: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
  },
  mainBigText: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
  },
  switchTextBlue: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#5493F7',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  switchTextGreen: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#53CBC8',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  flexWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  stakeTab: {
    cursor: 'pointer',
  },
  stakeTabActive: {
    cursor: 'pointer',
    paddingBottom: 9,
  },
  tabText: {
    extend: 'mainText',
    color: '#A8A8B2',
  },
  smallTimerStyle: {
    width: 80,
  },
  toolTipWrapper: {
    background: '#1F1F2D',
    borderRadius: 12,
    padding: [12, 16, 7],
    width: 162,
  },
  tooltipLine: {
    width: '100%',
    height: '1px',
    borderBottom: 'none',
    border: 'none',
    backgroundColor: '#4B567D',
    marginTop: '8px',
    marginBottom: '8px',
  },
  tooltipItemTextSign: {
    color: '#5493F7',
    fontWeight: 500,
    marginRight: '4px',
  },
  tooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 5,
  },
  tooltipMainText: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
  },
  tooltipSubText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A8A8B2',
  },
  pointer: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  claimButtonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    marginTop: 'auto',
  },
  claimButtonDisable: {
    extend: 'harvestButton',
    width: 260,
    height: 60,
    backgroundColor: '#44445B',
    cursor: 'not-allowed',
    marginTop: 10,
  },
  timerTextWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  timerWrapper: {
    marginBottom: 0,
    margin: 'auto',
  },
  mainSmallTextBlue: {
    fontWeight: 600,
    fontSize: '16px',
    width: 75,
    display: 'flex',
    // justifyContent: 'flex-end',
    lineHeight: '24px',
    color: '#5493F7',
    fontFamily: 'Poppins',
  },
  readyToClaimWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  disableButton: {
    extend: 'harvestButton',
    width: 260,
    height: 60,
    backgroundColor: '#44445B',
    cursor: 'not-allowed',
  },
  poolsLableWrapper: {
    position: 'absolute',
    top: 0,
    left: -10,
    background: 'linear-gradient(44deg, #00E0FC 0%, #5493F7 100%)',
    borderRadius: 74,
    padding: [4, 10],
  },
  poolsLableText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
    textTransform: 'uppercase',
  },
  poolDescriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    alignItems: 'start',
  },
  poolDescriptionTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: 'normal',
    color: '#FFFFFF',
  },
  poolDescriptionInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  poolDescriptionInfo24h: {
    display: 'flex',
    gap: 40,
    justifyContent: 'space-between',
  },
  poolDescriptionMainText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 'normal',
    color: '#A8A8B2',
  },
  poolDescriptionBlueText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 'normal',
    color: '#5493F7',
  },
  poolDescriptionGreenText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 'normal',
    color: '#53CBC8',
  },
  poolDescriptionTipWrapper: {
    paddingBottom: 30,
  },
  poolDescriptionMainTextWidth: {
    width: 356,
  },
  poolDescriptionInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
})
interface Props {
  data: any
  pool12Hours: any
  nextTime: any
  switchNetwork: any
  refetch: any
  title: any
}

enum CardNetwork {
  MOONBEAM = 'MOONBEAM',
  ARBITRUM = 'ARBITRUM',
  ZKSYNC = 'ZKSYNC',
}
const EarnCardMobile: React.FC<Props> = ({
  data,
  pool12Hours,
  nextTime,
  switchNetwork,
  refetch,
  title,
  currentNetwork,
  firstNetwork,
  secondNetwork,
  setFirstNetwork,
  setSecondNetwork,
  switchNetworkLocal,
}) => {
  const [openCard, setOpenCard] = useState(null)
  const [activeTab, setActiveTab] = useState<activeTabsEarn>(
    activeTabsEarn.stake
  )
  const [poolContract, setPoolContract] = useState(null)
  const [farmingPoolContract, setFarmingPoolContract] = useState(null)
  const [claimRewardsDisableButton, setClaimRewardsDisableButton] =
    useState(false)
  const [transactionInProgres, setTransactionInProgres] = useState(false)
  const [claimLpDisableButton, setClaimLpDisableButton] = useState(false)
  const [userBalance, setUserBalance] = useState<any>(null)
  const [userStaked, setUserStaked] = useState<any>(null)
  const [unstakeLocked, setUnstakeLocked] = useState(null)
  const [userTokenAllowance, setTokenAllowance] = useState<any>(null)
  const [inputValue, setInputValue] = useState('')

  const classes = styles()

  const { web3 } = useContext(Web3Context)
  const { user } = useSelector((state) => state.userReducer)
  const { open } = useSelector((state) => state.earnCardStateReducer)

  const dispatch = useDispatch()

  const infinity =
    '115792089237316195423570985008687907853269984665640564039457.584007913129639935'

  const disabledButton = (balance) => {
    if (user.chainId !== +data.networkId) {
      return false
    }
    const disabled = !balance || balance === '0'
    return (
      !transactionInProgres &&
      !disabled &&
      !!inputValue &&
      +inputValue.replace(/[\s,]/g, '') !== 0 &&
      !(+inputValue.replace(/[\s,]/g, '') > balance)
    )
  }

  const getClaimRewardsDisableButton = async (force = false) => {
    if (+data.networkId !== user.chainId) {
      setClaimRewardsDisableButton(true)
      return
    }
    const getRewardsAmount = (): number => {
      return data.rewards.reduce(
        (accumulator, item) => +accumulator + +item.availableToClaim,
        0
      )
    }
    if (getRewardsAmount() === 0) {
      setClaimRewardsDisableButton(true)
      return
    }

    if (force) {
      setClaimRewardsDisableButton(false)

      return
    }

    try {
      const lastClaim = await farmingPoolContract.methods
        .lastClaim(user.address)
        .call()

      const getClaimCycleTime = await farmingPoolContract.methods
        .getClaimCycleTime()
        .call()

      setClaimRewardsDisableButton(lastClaim > getClaimCycleTime)
    } catch (error) {}
  }

  const getClaimLpDisableButton = async (
    unstakeLocked: null | undefined | string
  ) => {
    if (+data.networkId !== user.chainId) {
      setClaimLpDisableButton(true)
      return
    }

    if (unstakeLocked === '0') {
      setClaimLpDisableButton(true)
      return
    }
    try {
      const lastUnstake = await farmingPoolContract.methods
        .lastUnstake(user.address)
        .call()

      const getClaimCycleTime = await farmingPoolContract.methods
        .getClaimCycleTime()
        .call()

      setClaimLpDisableButton(lastUnstake > getClaimCycleTime)
    } catch (error) {}
  }

  const isApproveNeed = userTokenAllowance >= inputValue

  useEffect(() => {
    if (data?.farmingPool) {
      defineContracts(data?.xOrb, data?.farmingPool)
    }
  }, [data, currentNetwork, user])

  useEffect(() => {
    if (data?.farmingPool) {
      defineContracts(data?.xOrb, data?.farmingPool)
    }
    setOpenCard(open[`${title}-${data?.name}`])
  }, [])

  useEffect(() => {
    setOpenCard(open[`${title}-${data?.name}`])
  }, [open])

  useEffect(() => {
    if (poolContract) {
      getBalance()
      checkAllowance()
    }
  }, [poolContract, user])

  useEffect(() => {
    if (farmingPoolContract) {
      getUserStaked()
      !pool12Hours && getUnstakeLocked()
      getClaimRewardsDisableButton()
    }
  }, [farmingPoolContract, user])

  useEffect(() => {
    if (farmingPoolContract) {
      !pool12Hours &&
        getUnstakeLocked().then((result) => getClaimLpDisableButton(result))
    }
  }, [farmingPoolContract, user, data])

  useEffect(() => {
    if (farmingPoolContract) {
      setTimeout(() => {
        getClaimRewardsDisableButton(true)
      }, 5000)
    }
  }, [nextTime])

  useEffect(() => {
    setInputValue('')
  }, [activeTab, user.chainId])

  useEffect(() => {
    setActiveTab(activeTabsEarn.stake)
  }, [currentNetwork, user.chainId])

  const defineContracts = async (poolAddress, farmingPoolAddress) => {
    const poolContract = new web3.eth.Contract(poolAbi, poolAddress)
    setPoolContract(poolContract)

    const farmingPoolContract = new web3.eth.Contract(
      pool12Hours ? farmingPoolAbi12h : farmingPoolAbi,
      farmingPoolAddress
    )
    setFarmingPoolContract(farmingPoolContract)
  }

  const getBalance = async () => {
    try {
      const result = await poolContract.methods.balanceOf(user.address).call()

      setUserBalance(fromBn(result, 18).toString())
    } catch (error) {
      console.log(error)
    }
  }

  const checkAllowance = async () => {
    try {
      const result = await poolContract.methods
        .allowance(user.address, data.farmingPool)
        .call()

      setTokenAllowance(fromBn(result, 18).toString())
    } catch (error) {
      console.log(error)
    }
  }

  const enterPersentInInput = (percent: number, balance) => {
    const availableToSupplyAssetBN = new BigNumber(balance)

    setInputValue(
      commify(bigDecimal.multiply(availableToSupplyAssetBN, percent))
    )
  }

  const checkTabs = (tab: activeTabsEarn) => {
    return activeTab === tab
  }

  const confirmUnstake = async () => {
    const transactionsId = uniqid()
    setTransactionInProgres(true)
    setClaimLpDisableButton(true)

    setInputValue('')
    dispatch(
      setNotifications({
        type: 'info',
        text: poolNotification.textInfoClaimLp,
        link: null,
      })
    )
    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await farmingPoolContract.methods
        .confirmUnstake()
        .estimateGas({
          from: user.address,
        })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      const result = await farmingPoolContract.methods
        .confirmUnstake()
        .send({
          from: user.address,
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                explorerUrl: data.explorer,
                text: poolNotification.textSubmittedClaimLp,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      setTimeout(() => setTransactionInProgres(false), 1000)
      getUnstakeLocked().then((result) => getClaimLpDisableButton(result))
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          explorerUrl: data.explorer,
          text: poolNotification.textSuccessClaimLp,
          link: result.transactionHash,
        })
      )

      console.log('claimLp:', result)
    } catch (error) {
      setTimeout(() => setTransactionInProgres(false), 1000)
      getUnstakeLocked().then((result) => getClaimLpDisableButton(result))
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: poolNotification.textErrorClaimLp,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const approve = async () => {
    const transactionsId = uniqid()
    setTransactionInProgres(true)

    setInputValue('')
    dispatch(
      setNotifications({
        type: 'info',
        text: poolNotification.textInfoApproval,
        link: null,
      })
    )
    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await poolContract.methods
        .approve(data?.farmingPool, toBn(`${infinity}`, 18).toString())
        .estimateGas({
          from: user.address,
        })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      const result = await poolContract.methods
        .approve(data?.farmingPool, toBn(`${infinity}`, 18).toString())
        .send({
          from: user.address,
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: poolNotification.textSubmittedApproval,
                explorerUrl: data.explorer,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          text: poolNotification.textSuccessApproval,
          link: result.transactionHash,
          explorerUrl: data.explorer,
        })
      )
      setTransactionInProgres(false)
      console.log('approve:', result)
      checkAllowance()
      await stakeTokens(inputValue.replace(/[\s,]/g, ''))
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      setTransactionInProgres(false)
      dispatch(
        setNotifications({
          type: 'error',
          text: poolNotification.textErrorApproval,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const stakeTokens = async (amount: string) => {
    setTransactionInProgres(true)
    let result
    const transactionsId = uniqid()
    dispatch(
      setNotifications({
        type: 'info',
        text: poolNotification.textInfoStake,
        link: null,
      })
    )

    setInputValue('')

    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await farmingPoolContract.methods
        .stakeTokens(toBn(`${amount}`, 18).toString())
        .estimateGas({
          from: user.address,
        })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      result = await farmingPoolContract.methods
        .stakeTokens(toBn(`${amount}`, 18).toString())
        .send({
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
          from: user.address,
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: poolNotification.textSubmittedStake,
                explorerUrl: data.explorer,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          explorerUrl: data.explorer,
          text: poolNotification.textSuccessStake,
          link: result.transactionHash,
        })
      )
      setTransactionInProgres(false)
      refetch()
      getUserStaked()
      getBalance()
      console.log('Stake', result)
      setInputValue('')
    } catch (error) {
      setTransactionInProgres(false)
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: poolNotification.textErrorStake,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const unstakeTokens = async (amount: string) => {
    setTransactionInProgres(true)
    let result
    const transactionsId = uniqid()
    dispatch(
      setNotifications({
        type: 'info',
        text: poolNotification.textInfoUnstake,
        link: null,
      })
    )

    setInputValue('')

    try {
      if (pool12Hours) {
        let gasPrice = await web3.eth.getGasPrice()

        gasPrice = +gasPrice
        gasPrice += gasPrice

        let gasLimit = await farmingPoolContract.methods
          .unstakeTokens(toBn(`${amount}`, 18).toString())
          .estimateGas({
            from: user.address,
          })

        gasLimit = +gasLimit
        gasLimit += gasLimit

        result = await farmingPoolContract.methods
          .unstakeTokens(toBn(`${amount}`, 18).toString())
          .send({
            gasLimit: web3.utils.toHex(Math.round(gasLimit)),
            gasPrice: web3.utils.toHex(Math.round(gasPrice)),
            from: user.address,
          })
          .on(
            'transactionHash',
            (hash: string) =>
              hash &&
              dispatch(
                setNotifications({
                  type: 'info',
                  explorerUrl: data.explorer,
                  text: poolNotification.textSubmittedUnstake,
                  link: hash,
                  id: transactionsId,
                  isPending: true,
                })
              )
          )
      } else {
        let gasPrice = await web3.eth.getGasPrice()

        gasPrice = +gasPrice
        gasPrice += gasPrice

        let gasLimit = await farmingPoolContract.methods
          .unstakeRequest(toBn(`${amount}`, 18).toString())
          .estimateGas({
            from: user.address,
          })

        gasLimit = +gasLimit
        gasLimit += gasLimit

        result = await farmingPoolContract.methods
          .unstakeRequest(toBn(`${amount}`, 18).toString())
          .send({
            gasLimit: web3.utils.toHex(Math.round(gasLimit)),
            gasPrice: web3.utils.toHex(Math.round(gasPrice)),
            from: user.address,
          })
          .on(
            'transactionHash',
            (hash: string) =>
              hash &&
              dispatch(
                setNotifications({
                  type: 'info',
                  explorerUrl: data.explorer,
                  text: poolNotification.textSubmittedUnstake,
                  link: hash,
                  id: transactionsId,
                  isPending: true,
                })
              )
          )
      }
      dispatch(deleteNotifications(transactionsId))
      setTransactionInProgres(false)
      dispatch(
        setNotifications({
          type: 'success',
          explorerUrl: data.explorer,
          text: poolNotification.textSuccessUnstakeXORB,
          link: result.transactionHash,
        })
      )
      refetch()
      getUserStaked()
      getBalance()
      console.log('unstakeTokens', result)
      setInputValue('')
    } catch (error) {
      setTransactionInProgres(false)
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: supplyNotification.textErrorDeposit,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const claim = async () => {
    const transactionsId = uniqid()
    setClaimRewardsDisableButton(true)
    setTransactionInProgres(true)

    dispatch(
      setNotifications({
        type: 'info',
        text: poolNotification.textInfoClaim,
        link: null,
      })
    )
    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await farmingPoolContract.methods.claim().estimateGas({
        from: user.address,
      })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      const result = await farmingPoolContract.methods
        .claim()
        .send({
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
          from: user.address,
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                explorerUrl: data.explorer,
                text: poolNotification.textSubmittedClaim,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      refetch()
      setTimeout(() => setTransactionInProgres(false), 1000)
      getClaimRewardsDisableButton()
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          explorerUrl: data.explorer,
          text: poolNotification.textSuccessClaim,
          link: result.transactionHash,
        })
      )
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      setTimeout(() => setTransactionInProgres(false), 1000)
      getClaimRewardsDisableButton()
      dispatch(
        setNotifications({
          type: 'error',
          text: poolNotification.textErrorClaim,
          link: null,
        })
      )
      console.log('error', error)
    }
  }

  const getUnstakeLocked = async () => {
    if (!pool12Hours) {
      try {
        const result = await farmingPoolContract.methods
          .unstakeLocked(user.address)
          .call()

        setUnstakeLocked(fromBn(result, 18).toString())
        return fromBn(result, 18).toString()
      } catch (error) {
        console.log(error)
      }
    }
  }

  const getUserStaked = async () => {
    try {
      const result = await farmingPoolContract.methods
        .userTotalStaked(user.address)
        .call()

      setUserStaked(fromBn(result, 18).toString())
    } catch (error) {
      console.log(error)
    }
  }

  const changeNetwork = async (networkToChange, setNetwork, e) => {
    setFarmingPoolContract(null)
    setPoolContract(null)
    switchNetworkLocal(networkToChange, setNetwork)
    e.stopPropagation()
  }

  const approveAndStake = async () => {
    if (!isApproveNeed) {
      await approve()
    }

    if (inputValue.replace(/[\s,]/g, '') <= userTokenAllowance) {
      await stakeTokens(inputValue.replace(/[\s,]/g, ''))
    }
  }

  const renderText = () => {
    if (title === '12h') {
      return (
        <div className={classes.poolDescriptionWrapper}>
          {/* <div className={classes.poolDescriptionTitle}>Simple Farm 🌱</div> */}
          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>
              Claim Frequency:
            </span>{' '}
            You can claim your rewards every 12 hours
          </div>
          {/* <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>
              Timer Reset:
            </span>{' '}
            If you make additional deposits, the 12-hour timer will reset
          </div> */}
          <div
            className={[
              classes.poolDescriptionMainText,
              classes.poolDescriptionTipWrapper,
            ].join(' ')}
          >
            <span className={classes.poolDescriptionGreenText}>
              👉 Quick Tip:
            </span>{' '}
            This is perfect if you like to check in frequently and claim your
            rewards!
          </div>
        </div>
      )
    } else if (title === '24h') {
      return (
        <div className={classes.poolDescriptionWrapper}>
          <div className={classes.poolDescriptionTitle}>Advanced Farm 🌿</div>
          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>
              Earning Start:
            </span>{' '}
            Your earnings kick off at the start of the next cycle
          </div>
          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>Token Lock:</span>{' '}
            When you decide to withdraw your LP tokens, they'll stay locked but
            continue to earn until the end of the current cycle
          </div>
          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>Withdrawal:</span>{' '}
            Your tokens will be available for withdrawal at the start of the
            next cycle
          </div>
          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>
              Claim Frequency:
            </span>{' '}
            You can claim your rewards at the start of each new cycle
          </div>
          <div
            className={[
              classes.poolDescriptionMainText,
              classes.poolDescriptionTipWrapper,
            ].join(' ')}
          >
            <span className={classes.poolDescriptionGreenText}>
              👉 Quick Tip:{' '}
            </span>
            This is great if you're looking for a balance between frequent and
            long-term farming!
          </div>
        </div>
      )
    } else if (title === '7d') {
      return (
        <div className={classes.poolDescriptionWrapper}>
          <div className={classes.poolDescriptionTitle}>Elite Farm 🌳</div>

          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>
              Earning Start:
            </span>{' '}
            Your earnings begin at the start of the next cycle.
          </div>
          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>Token Lock:</span>{' '}
            If you withdraw your LP tokens, they'll be locked until the end of
            the current cycle but will keep earning.
          </div>

          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>Withdrawal:</span>{' '}
            Your tokens will be ready for withdrawal at the start of the next
            cycle.
          </div>
          <div className={[classes.poolDescriptionMainText].join(' ')}>
            <span className={classes.poolDescriptionBlueText}>
              Claim Frequency:
            </span>{' '}
            You can claim your rewards at the start of every 8th cycle.
          </div>
          <div
            className={[
              classes.poolDescriptionMainText,
              classes.poolDescriptionTipWrapper,
            ].join(' ')}
          >
            <span className={classes.poolDescriptionGreenText}>
              👉 Quick Tip:{' '}
            </span>
            Choose this if you're in it for the long haul and want to maximize
            your earnings over time!
          </div>
        </div>
      )
    }
  }

  const renderButton = () => {
    if (!user.connected) {
      return (
        <button
          onClick={() => dispatch(setOpenWalletModal(true))}
          className={classes.enableButton}
        >
          Connect Wallet
        </button>
      )
    }

    if (activeTab === activeTabsEarn.stake) {
      if (isApproveNeed) {
        return (
          <button
            disabled={!disabledButton(userBalance)}
            onClick={() => approveAndStake()}
            className={
              !disabledButton(userBalance)
                ? classes.disableButton
                : classes.enableButton
            }
          >
            Stake
          </button>
        )
      } else
        return (
          <button
            disabled={!disabledButton(userBalance)}
            onClick={() => approveAndStake()}
            className={
              !disabledButton(userBalance)
                ? classes.disableButton
                : classes.enableButton
            }
          >
            Approve & Stake
          </button>
        )
    }
    if (activeTab === activeTabsEarn.unstake) {
      return (
        <button
          disabled={!disabledButton(userStaked)}
          onClick={() => unstakeTokens(inputValue.replace(/[\s,]/g, ''))}
          className={
            !disabledButton(userStaked)
              ? classes.disableButton
              : classes.enableButton
          }
        >
          Unstake
        </button>
      )
    }
  }

  return (
    <div className={classes.earnCard}>
      <div className={classes.poolsLableWrapper}>
        <span className={classes.poolsLableText}>{title}</span>
      </div>
      <div
        className={classes.earnCardWrapperOpen}
        onClick={() =>
          !openCard &&
          dispatch(
            setEarnCardState({ ...open, [`${title}-${data?.name}`]: true })
          )
        }
      >
        <div
          className={classes.cardWrapper}
          onClick={() =>
            openCard &&
            dispatch(
              setEarnCardState({ ...open, [`${title}-${data?.name}`]: false })
            )
          }
        >
          <div className={classes.imgTopWrapper}>
            <div className={classes.imgTokenWrapper}>
              <img
                className={classes.imgTokenWrapper}
                src={data?.xOrbImage}
                alt=""
              />
            </div>
            <div>
              <div className={[classes.titleText].join(' ')}>{data?.name}</div>
              <span className={[classes.subTextBig].join(' ')}>TVL </span>
              <span className={classes.mainSmallText}>${data?.farmingTvl}</span>
            </div>
          </div>
          {/*<img*/}
          {/*  className={classes.openImg}*/}
          {/*  onClick={() => setOpenCard(!openCard)}*/}
          {/*  src={openCard ? minus : plus}*/}
          {/*  alt=""*/}
          {/*/>*/}
        </div>
        <div className={classes.cardEarnWrapper}>
          <div className={classes.apyWrapper}>
            <div className={classes.subText}>You staked</div>
            <div className={classes.orbEmountImgWrapper}>
              <span className={classes.mainText}>
                {user.connected ? transform(userStaked, 6) : '-'}
              </span>
            </div>
          </div>
          <div className={classes.apyWrapper}>
            <div className={classes.orbEmountImgWrapper}>
              <img src={data.networkImage} alt="" />
              <span className={classes.subText}>{data.network} APR</span>
            </div>
            <Tooltip
              overlayInnerStyle={{ boxShadow: 'none' }}
              color="none"
              title={
                <div className={classes.toolTipWrapper}>
                  {data.rewards?.map((el) => {
                    return (
                      <div className={classes.tooltipItem}>
                        <div className={classes.tooltipSubText}>
                          <span className={classes.tooltipItemTextSign}>+</span>
                          {el.symbol} APR
                        </div>
                        <div className={classes.tooltipMainText}>
                          {transform(el.APY, 2)}%
                        </div>
                      </div>
                    )
                  })}
                  <hr className={classes.tooltipLine} />
                  <div className={classes.tooltipItem}>
                    <div className={classes.tooltipSubText}>Total APR</div>
                    <div className={classes.tooltipMainText}>
                      {transform(data.totalAPY, 2)}%
                    </div>
                  </div>
                </div>
              }
            >
              <div className={classes.imgWrapper}>
                <span className={classes.mainText}>
                  {transform(data.totalAPY, 2)}%
                </span>
                <img src={info} alt="" />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={classes.hrSmall}></div>
      <div onClick={() => setOpenCard(open[`${title}-${data?.name}`])}>
        {/* <div className={classes.flexWrapper}> */}
        {/*  <div>*/}
        {/*    <div>*/}
        {/*      <img src={data.networkImage} alt="" />*/}
        {/*    </div>*/}
        {/*    <div className={classes.subText}>*/}
        {/*      {' '}*/}
        {/*      {data.network} APY{' '}*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className={classes.mainBigText}>*/}
        {/*    {data.totalAPY}%*/}
        {/*  </div>*/}
        {/*  <div*/}
        {/*    className={classes.imgWrapper}*/}
        {/*    onClick={(e) => changeNetwork(firstNetwork, setFirstNetwork, e)}*/}
        {/*  >*/}
        {/*    <span className={classes.switchTextGreen}>SWITCH</span>*/}
        {/*    <img src={arrowGreen} alt="" />*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className={classes.flexWrapper}>*/}
        {/*  <div>*/}
        {/*    <div>*/}
        {/*      <img src={data.networkImage} alt="" />*/}
        {/*    </div>*/}
        {/*    <div className={classes.subText}>*/}
        {/*      {' '}*/}
        {/*      {data.network} APY{' '}*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className={classes.mainBigText}>*/}
        {/*    {data[secondNetwork].totalAPY}%*/}
        {/*  </div>*/}
        {/*  <div*/}
        {/*    className={classes.imgWrapper}*/}
        {/*    onClick={(e) => changeNetwork(secondNetwork, setSecondNetwork, e)}*/}
        {/*  >*/}
        {/*    <span className={classes.switchTextBlue}>SWITCH</span>*/}
        {/*    <img src={arrowBlue} alt="" />*/}
        {/*  </div>*/}
        {/* </div> */}
        {!openCard && pool12Hours ? (
          <div className={classes.smallTimer}>
            <span className={classes.subText}>Time to Claim</span>{' '}
            <span
              className={[classes.orbText, classes.smallTimerStyle].join(' ')}
            >
              <CountDownTimer
                updateRound={refetch}
                expiryTimestamp={nextTime}
                withoutDate={false}
              />
            </span>
          </div>
        ) : null}
      </div>
      {/* <div className={openCard ? classes.hr : classes.earnCardWrapper}></div> */}
      <div
        className={
          openCard ? classes.earnCardWrapperOpen : classes.earnCardWrapper
        }
      >
        <div className={classes.stakeWrapper}>
          {/*<div className={classes.stakeTabsWrapper}>*/}
          {/*  <div className={classes.stakeTab}>*/}
          {/*    <div className={classes.mainStakeText}>Stake</div>*/}
          {/*    <div className={classes.blueHr}></div>*/}
          {/*  </div>*/}
          {/*  <div className={classes.stakeTab}>*/}
          {/*    <div className={classes.mainStakeText}>Unstake</div>*/}
          {/*    <div className={classes.blueHr}></div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={classes.stakeTabsWrapper}>
            <div
              className={
                checkTabs(activeTabsEarn.stake)
                  ? classes.stakeTab
                  : classes.stakeTabActive
              }
              onClick={() => setActiveTab(activeTabsEarn.stake)}
            >
              <div
                className={
                  checkTabs(activeTabsEarn.stake)
                    ? classes.mainStakeText
                    : classes.mainStakeTextActive
                }
              >
                Stake
              </div>
              {checkTabs(activeTabsEarn.stake) && (
                <div className={classes.blueHr}></div>
              )}
            </div>
            <div
              className={
                checkTabs(activeTabsEarn.unstake)
                  ? classes.stakeTab
                  : classes.stakeTabActive
              }
              onClick={() => setActiveTab(activeTabsEarn.unstake)}
            >
              <div
                className={
                  checkTabs(activeTabsEarn.unstake)
                    ? classes.mainStakeText
                    : classes.mainStakeTextActive
                }
              >
                Unstake
              </div>
              {checkTabs(activeTabsEarn.unstake) && (
                <div className={classes.blueHr}></div>
              )}
            </div>
          </div>
          <div className={classes.inputWrapper}>
            <div className={classes.inputDescription}>
              {/*{disabled ? (*/}
              {/*    <span*/}
              {/*        className={[*/}
              {/*          classes.inputPlaceholderText,*/}
              {/*          classes.cursorDisable,*/}
              {/*        ].join(' ')}*/}
              {/*    >*/}
              {/*        You have no <a>123</a>*/}
              {/*      </span>*/}
              {/*) : (*/}
              <input
                type="text"
                className={classes.inputText}
                placeholder={'Enter the amount'}
                style={{ outline: 'none' }}
                value={inputValue}
                onChange={(e) =>
                  setInputValue(
                    `${commify(
                      `${e.target.value
                        .replace(/[^0-9.]/g, '')
                        .replace(/(\..*)\./g, '$1')}`
                    )}`
                  )
                }
              />
              {/*)}*/}
            </div>
          </div>
          {user.connected ? (
            <div className={classes.depositSelectWrapper}>
              <button
                disabled={
                  checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked
                }
                className={
                  (checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked)
                    ? classes.depositSelectButtonDisabled
                    : classes.depositSelectButton
                }
                onClick={() =>
                  enterPersentInInput(
                    0.25,
                    checkTabs(activeTabsEarn.stake) ? userBalance : userStaked
                  )
                }
              >
                25%
              </button>
              <button
                disabled={
                  checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked
                }
                className={
                  (checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked)
                    ? classes.depositSelectButtonDisabled
                    : classes.depositSelectButton
                }
                onClick={() =>
                  enterPersentInInput(
                    0.5,
                    checkTabs(activeTabsEarn.stake) ? userBalance : userStaked
                  )
                }
              >
                50%
              </button>
              <button
                disabled={
                  checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked
                }
                className={
                  (checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked)
                    ? classes.depositSelectButtonDisabled
                    : classes.depositSelectButton
                }
                onClick={() =>
                  enterPersentInInput(
                    0.75,
                    checkTabs(activeTabsEarn.stake) ? userBalance : userStaked
                  )
                }
              >
                75%
              </button>
              <button
                disabled={
                  checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked
                }
                className={
                  (checkTabs(activeTabsEarn.stake) ? !userBalance : !userStaked)
                    ? classes.depositSelectButtonDisabled
                    : classes.depositSelectButton
                }
                onClick={() =>
                  enterPersentInInput(
                    1,
                    checkTabs(activeTabsEarn.stake) ? userBalance : userStaked
                  )
                }
              >
                MAX
              </button>
            </div>
          ) : null}
          <div className={classes.userBalanceWrapper}>
            {checkTabs(activeTabsEarn.stake) ? (
              <div className={classes.userBalanceWrapper}>
                <div className={classes.subTextBig}>Balance</div>
                <div className={classes.imgWrapper}>
                  <span className={classes.mainSmallText}>
                    {transform(userBalance, 6)} {data?.name}{' '}
                  </span>
                  <span className={classes.subTextBig}>
                    {userBalance &&
                      `($${transform(
                        (userBalance * +data.xOrbRate).toString(),
                        2
                      )})`}
                  </span>
                </div>
              </div>
            ) : (
              <div className={classes.userBalanceWrapper}>
                <div className={classes.subTextBig}>You staked</div>
                <div className={classes.imgWrapper}>
                  <span className={classes.mainSmallText}>
                    {transform(userStaked, 6)} {data?.name}{' '}
                  </span>
                  <span className={classes.subTextBig}>
                    {' '}
                    {userStaked &&
                      `($${transform(
                        (userStaked * +data.xOrbRate).toString(),
                        2
                      )})`}
                  </span>
                </div>
              </div>
            )}
            {renderButton()}
          </div>
        </div>
        <div className={openCard ? classes.hr : classes.earnCardWrapper}></div>
        <div className={classes.rewardsWrapper}>
          <div className={classes.rewardsTitle}>Rewards</div>
          {/*<div className={classes.getOrbWrapper}>*/}
          {/*  <a*/}
          {/*    className={[classes.imgWrapper, classes.pointer].join(' ')}*/}
          {/*    href={data[currentNetwork]?.buyOrbiterLink}*/}
          {/*    target="_blank"*/}
          {/*  >*/}
          {/*    <img src={arrow} alt="" />*/}
          {/*    <span className={classes.blueText}>Buy ORB</span>*/}
          {/*  </a>*/}
          {/*  <a*/}
          {/*    href={data[currentNetwork]?.liquidityPoolDex}*/}
          {/*    target="_blank"*/}
          {/*    className={[classes.imgWrapper, classes.pointer].join(' ')}*/}
          {/*  >*/}
          {/*    <img src={addIcon} alt="" />*/}
          {/*    <span className={classes.greenText}>Add Liquidity</span>*/}
          {/*  </a>*/}
          {/*</div>*/}
          <div className={classes.rewardsEmountWrapper}>
            {data?.rewards.map((el) => {
              return (
                <div className={classes.orbEmoutCard}>
                  <div className={classes.subText}>{el.symbol} Rewards</div>
                  <div className={classes.orbEmountImgWrapper}>
                    <img className={classes.tokenIcon} src={el.image} alt="" />
                    <div className={classes.balanceMainText}>
                      {user.connected ? el.availableToClaim : '-'}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          {renderText()}
          {!pool12Hours ? (
            <div className={classes.userBalanceWrapper}>
              <div className={classes.readyToClaimWrapper}>
                <div className={classes.subTextBig}>Ready to withdraw</div>
                <div className={classes.imgWrapper}>
                  <span className={classes.mainSmallText}>
                    {transform(unstakeLocked, 6)}
                  </span>
                  <span className={classes.subTextBig}>
                    {unstakeLocked &&
                      `($${transform(
                        (unstakeLocked * +data.xOrbRate).toString(),
                        2
                      )})`}
                  </span>
                </div>
              </div>
              <div className={classes.claimButtonsWrapper}>
                <button
                  disabled={transactionInProgres || claimLpDisableButton}
                  onClick={() => confirmUnstake()}
                  className={
                    transactionInProgres || claimLpDisableButton
                      ? classes.claimButtonDisable
                      : classes.claimButton
                  }
                >
                  WITHDRAW LP
                </button>
              </div>
            </div>
          ) : (
            <div className={classes.timerWrapper}>
              <div className={classes.timerTextWrapper}>
                <div className={classes.mainSmallText}>Time to claim</div>
                <div className={classes.mainSmallTextBlue}>
                  <CountDownTimer
                    updateRound={refetch}
                    expiryTimestamp={nextTime}
                    withoutDate={false}
                  />
                </div>
              </div>
              <div className={classes.subText}>
                This reward can be claimed once every 12 hours
              </div>
            </div>
          )}
          <button
            disabled={
              transactionInProgres ||
              claimRewardsDisableButton ||
              !user.connected
            }
            onClick={() => claim()}
            className={
              transactionInProgres ||
              claimRewardsDisableButton ||
              !user.connected
                ? classes.claimButtonDisable
                : classes.claimButton
            }
          >
            CLAIM Rewards
          </button>
        </div>
      </div>
    </div>
  )
}
export default EarnCardMobile
