import React, { useContext, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import xORBIcon from '../../img/xORB.svg'
import ORBIcon from '../../img/ORB_logo.svg'
import coinIcon from '../../img/coin.svg'
import Skeleton from 'react-loading-skeleton'
import { commify } from '../../utils'
import BigNumber from 'bignumber.js'
import { setOpenWalletModal } from '../../redux/loading'
import { Web3Context } from '../../context'
import uniqid from 'uniqid'
import {
  deleteNotifications,
  setNotifications,
} from '../../redux/notifications'
import { convertNotification } from '../../utils/notificationTexts'
import { toBn } from 'evm-bn'
import { transform } from '../../factory/bigNumber'

import { Contract } from 'web3-eth-contract'

const styles = createUseStyles({
  mainTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '40px',
    lineHeight: '60px',
    color: '#FFFFFF',
    paddingBottom: 10,
  },
  imgWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  icon: {
    width: 28,
    height: 28,
  },
  mainText: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: 'normal',
    color: '#FFFFFF',
  },
  cardWrapper: {
    borderRadius: 12,
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.80) 0%, rgba(53, 53, 81, 0.80) 100%)',
    boxShadow: '1px 1px 1px 0px #42426A inset',
    backdropFilter: 'backdrop-filter: blur(25px)',
    padding: 20,
  },
  inputWrapper: {
    backgroundColor: '#1F1F2D',
    borderRadius: 12,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    marginBottom: 15,
    boxSizing: 'border-box',
    padding: [10, 15],
    height: 68,
  },
  inputDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'end',
  },
  inputDescriptionText: {
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
  },
  cursorDisable: {
    cursor: 'not-allowed',
  },
  inputPlaceholderText: {
    whiteSpace: 'nowrap',
    width: '100%',
    backgroundColor: '#1F1F2D',
    border: 'none',
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 400,
    color: '#7D7E83',
  },
  inputText: {
    extend: 'inputPlaceholderText',
    cursor: 'text',
    color: '#FFFFFF',
  },
  inputAvaliableValue: {
    extend: 'inputPlaceholderText',
    width: 'inherit',
    display: 'flex',
    flexDirection: 'column-reverse',
    color: '#5493F7',
    fontWeight: 600,
    alignItems: 'flex-end',
  },
  inputAvaliableValueUSD: {
    extend: 'inputPlaceholderText',
    color: '#A8A8B2',
    display: 'flex',
    width: 106,
    justifyContent: 'end',
    fontWeight: 400,
  },
  depositSelectWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    paddingBottom: 30,
  },
  depositSelectButton: {
    fontFamily: 'Poppins',
    cursor: 'pointer',
    backgroundColor: '#1F1F2D',
    width: 52,
    height: 22,
    fontWeight: 400,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 42,
  },
  tokenValueWrapper: {
    display: 'flex',
    justifyContent: 'end',
  },
  cardTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
    paddingBottom: 5,
  },
  cardDescription: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 'normal',
    color: '#A8A8B2',
    paddingBottom: 20,
  },
  hr: {
    borderBottom: 'none',
    width: '100%',
    marginTop: 113,
    marginBottom: 23,
    height: 1,
    background: '#4B567D',
  },
  recieveBlock: {
    flexDirection: 'column',
    display: 'flex',
    gap: 5,
  },
  activeButton: {
    width: 320,
    height: 60,
    backgroundColor: '#5493F7',

    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    border: 0,
    borderRadius: 12,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    marginTop: 20,
    cursor: 'pointer',
  },
  disableButton: {
    width: 320,
    height: 60,
    backgroundColor: '#44445B',

    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    border: 0,
    borderRadius: 12,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    marginTop: 20,
    cursor: 'not-allowed',
  },
  subText: {
    fontWeight: 500,
    color: '#A8A8B2',
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: 'Poppins',
  },
  orbIcon: {
    width: 20,
    height: 20,
  },
  '@media (max-width: 1250px)': {
    cardWrapper: {
      borderRadius: 12,
      background:
        'linear-gradient(180deg, rgba(49, 57, 84, 0.80) 0%, rgba(53, 53, 81, 0.80) 100%)',
      boxShadow: '1px 1px 1px 0px #42426A inset',
      backdropFilter: 'backdrop-filter: blur(25px)',
      padding: 20,
      width: 300,
    },
    activeButton: {
      width: 300,
      height: 60,
      backgroundColor: '#5493F7',

      color: '#FFFFFF',
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '27px',
      border: 0,
      borderRadius: 12,
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
      marginTop: 20,
      cursor: 'pointer',
    },
    disableButton: {
      width: 300,
      height: 60,
      backgroundColor: '#44445B',

      color: '#FFFFFF',
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '27px',
      border: 0,
      borderRadius: 12,
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
      marginTop: 20,
      cursor: 'not-allowed',
    },
    cardTitle: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: 'normal',
      color: '#FFFFFF',
      paddingBottom: 5,
    },
  },
  '@media (max-width: 730px)': {
    hr: {
      borderBottom: 'none',
      width: '100%',
      marginTop: 40,
      marginBottom: 20,
      height: 1,
      background: '#4B567D',
    },
    cardTitle: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: 'normal',
      color: '#FFFFFF',
      paddingBottom: 5,
    },
  },
})

interface IProps {
  convertContract: Contract | null
  orbBalance: string
  getBalance: () => void
}

const Convert = ({ convertContract, orbBalance, getBalance }: IProps) => {
  const [inputValue, setInputValue] = useState('')
  const classes = styles()
  const { user } = useSelector((state: any) => state.userReducer)
  const { web3 } = useContext(Web3Context)

  const dispatch = useDispatch()

  const fetching = false

  const disabled = !orbBalance || orbBalance === '0'

  const disabledButton =
    !disabled &&
    !!inputValue &&
    +inputValue.replace(/[\s,]/g, '') !== 0 &&
    !(+inputValue.replace(/[\s,]/g, '') > +orbBalance)

  const enterPersentInInput = (persent: number) => {
    const tokenBalance = orbBalance
    const availableToSupplyAssetBN = new BigNumber(tokenBalance)

    setInputValue(
      commify(availableToSupplyAssetBN.multipliedBy(persent).toString())
    )
  }

  useEffect(() => {
    setInputValue('')
  }, [user.address])

  const convertOrb = async () => {
    let result
    const transactionsId = uniqid()
    dispatch(
      setNotifications({
        type: 'info',
        text: convertNotification.textInfoConvert,
        link: null,
      })
    )

    setInputValue('')

    try {
      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice

      let gasLimit = await (convertContract as Contract).methods
        .convert(
          toBn(
            `${inputValue.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')}`,
            18
          ).toString()
        )
        .estimateGas({
          from: user.address,
        })

      gasLimit = +gasLimit
      gasLimit += gasLimit

      result = await (convertContract as Contract).methods
        .convert(
          toBn(
            `${inputValue.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')}`,
            18
          ).toString()
        )
        .send({
          gasLimit: web3.utils.toHex(Math.round(gasLimit)),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
          from: user.address,
        })
        .on(
          'transactionHash',
          (hash: string) =>
            hash &&
            dispatch(
              setNotifications({
                type: 'info',
                text: convertNotification.textSubmittedConvert,
                link: hash,
                id: transactionsId,
                isPending: true,
              })
            )
        )
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'success',
          text: convertNotification.textSuccessConvert,
          link: result.transactionHash,
        })
      )
      getBalance()
      console.log('convertOrb', result)
      setInputValue('')
    } catch (error) {
      dispatch(deleteNotifications(transactionsId))
      dispatch(
        setNotifications({
          type: 'error',
          text: convertNotification.textErrorConvert,
          link: null,
        })
      )
      console.log(error)
    }
  }

  const renderButton = () => {
    if (!user.connected) {
      return (
        <div
          onClick={() => dispatch(setOpenWalletModal(true))}
          className={classes.activeButton}
        >
          Connect Wallet
        </div>
      )
    } else {
      return (
        <button
          disabled={!disabledButton}
          onClick={() => convertOrb()}
          className={
            !disabledButton ? classes.disableButton : classes.activeButton
          }
        >
          <img src={coinIcon} alt="" />
          <span>CONVERT</span>
        </button>
      )
    }
  }

  return (
    <div className={classes.cardWrapper}>
      <div className={classes.cardTitle}>Convert to xORB</div>
      <div className={classes.cardDescription}>
        Convert your ORB to xORB 1:1 and get exclusive farming pool
      </div>
      <div>
        {fetching ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Skeleton
              baseColor="#4B567D"
              highlightColor="#5B6796"
              width="300px"
              height="70px"
              count={1}
              borderRadius="22px"
            />{' '}
          </div>
        ) : (
          <div className={classes.inputWrapper}>
            <input
              type="text"
              className={classes.inputText}
              placeholder={'Enter the amount'}
              style={{ outline: 'none' }}
              value={inputValue}
              onChange={(e) =>
                setInputValue(
                  `${commify(
                    `${e.target.value
                      .replace(/[^0-9.]/g, '')
                      .replace(/(\..*)\./g, '$1')}`
                  )}`
                )
              }
            />
          </div>
        )}
        <div className={classes.depositSelectWrapper}>
          <div
            className={classes.depositSelectButton}
            onClick={() => enterPersentInInput(0.25)}
          >
            25%
          </div>
          <div
            className={classes.depositSelectButton}
            onClick={() => enterPersentInInput(0.5)}
          >
            50%
          </div>
          <div
            className={classes.depositSelectButton}
            onClick={() => enterPersentInInput(0.75)}
          >
            75%
          </div>
          <div
            className={classes.depositSelectButton}
            onClick={() => enterPersentInInput(1)}
          >
            MAX
          </div>
        </div>
      </div>
      <div className={classes.recieveBlock}>
        <span className={classes.subText}>ORB Balance</span>
        <div className={classes.imgWrapper}>
          <img className={classes.orbIcon} src={ORBIcon} alt="" />
          <span className={classes.mainText}>
            {user.connected ? transform(orbBalance, 4) : '0'} ORB
          </span>
        </div>
      </div>
      <div className={classes.hr}></div>
      <div className={classes.recieveBlock}>
        <span className={classes.subText}>You will recieve</span>
        <div className={classes.imgWrapper}>
          <img src={xORBIcon} alt="" />
          <span className={classes.mainText}>
            {transform(
              inputValue.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'),
              4
            )}{' '}
            xORB
          </span>
        </div>
      </div>
      {renderButton()}
    </div>
  )
}

export default Convert
