import React from 'react'
import { createUseStyles } from 'react-jss'
import buttonIcon from '../../img/mobileButton.svg'

const styles = createUseStyles({
  button: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    backgroundColor: 'inherit',
    borderColor: '#53CBC8',
    padding: '2px 16px',
    color: '#53CBC8',
    borderRadius: 10,
    borderWidth: 1,
    cursor: 'pointer',
  },
  iconButton: {
    display: 'none',
  },
  '@media (max-width: 1250px)': {
    button: {
      display: 'block',
    },
    iconButton: {
      display: 'none',
    },
  },
  '@media (max-width: 730px)': {
    button: {
      display: 'none',
    },
    iconButton: {
      display: 'block',
    },
  },
})

interface Props {
  onClick: any
}

const WithdrawButton: React.FC<Props> = ({ onClick }) => {
  const classes = styles()
  return (
    <div>
      <button className={classes.button} onClick={onClick}>
        WITHDRAW
      </button>
      <img
        className={classes.iconButton}
        onClick={onClick}
        src={buttonIcon}
        alt=""
      />
    </div>
  )
}

export default WithdrawButton
