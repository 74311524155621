import { request } from '../factory/axios'
import { setCompositions } from '../redux/compositions'
import { setAssets } from '../redux/assets'
import { findAssetIndex } from './index'
import { setAsset } from '../redux/asset'
import { setUserAssets } from '../redux/userAssets'
import { setMarketOverview } from '../redux/marketOverview'
import { setTransactions } from '../redux/transactions'
import { setUserBalances } from '../redux/userBalances'
import { Asset } from '../components/Assets/Assets'
export default function refetch(dispatch: any, address: any, asset: Asset) {
  Promise.all([
    // request({
    //   method: 'get',
    //   path: 'assets',
    // }).then(({ data: { data } }) => {
    //   dispatch(setAssets(data))
    //   const currentAsset = findAssetIndex(data, asset)
    //   dispatch(setAsset(data[currentAsset]))
    // }),
    request({
      method: 'get',
      path: `assets/${address}`,
    }).then((res) => dispatch(setUserAssets(res.data.data))),
    request({
      method: 'get',
      path: 'markets/overview',
    }).then((res) => dispatch(setMarketOverview(res.data.data))),
    request({
      method: 'get',
      path: `transactions/${address}`,
    }).then((res) => dispatch(setTransactions(res.data.data))),
    request({
      method: 'get',
      path: `users/${address}`,
    }).then((res) => dispatch(setUserBalances(res.data.data))),
    request({
      method: 'get',
      path: `assets/${address}/composition`,
    }).then((res) => dispatch(setCompositions(res.data.data))),
    request({
      method: 'get',
      path: `assets/${address}/balance`,
    }).then((res) => {
      const test = res.data.data.map((el: any) => {
        return { ...el.token, walletBalance: el.walletBalance }
      })
      dispatch(setAssets(test))
    }),
    request({
      method: 'get',
      path: 'assets',
    }).then(({ data: { data } }) => {
      const currentAsset = findAssetIndex(data, asset)
      dispatch(setAsset(data[currentAsset]))
    }),
  ])
}
