import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export enum activeTabs {
  supply = 'supply',
  borrow = 'borrow',
}

export enum activeTabsEarn {
  stake = 'stake',
  unstake = 'unstake',
}
export interface tabsControllerState {
  tabs: {
    supplyActiveTab: activeTabs
    suppliedActiveTab: activeTabs
  }
}

const initialState: tabsControllerState = {
  tabs: {
    supplyActiveTab: activeTabs.supply,
    suppliedActiveTab: activeTabs.supply,
  },
}

export const tabsControllerSlice = createSlice({
  name: 'tabsController',
  initialState,
  reducers: {
    setTabs: (state, action: PayloadAction<any>) => {
      state.tabs = {
        ...state.tabs,
        ...action.payload,
      }
    },
  },
})

export const { setTabs } = tabsControllerSlice.actions

export default tabsControllerSlice.reducer
