import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import 'react-loading-skeleton/dist/skeleton.css'
import EarnCard from '../EarnCard/EarnCard'
import EarnCardTablet from '../EarnCard/EarnCardTablet'
import EarnCardMobile from '../EarnCard/EarnCardMobile'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import EarnCardNotConnected from '../EarnCard/EarnCardNotConnected'
import EarnCardTabletNotConnected from '../EarnCard/EarnCardTabletNotConnected'
import EarnCardMobileNotConnected from '../EarnCard/EarnCardMobileNotConnected'
import {
  paramsArbitrumTest,
  paramsMovr,
  paramsZkSyncTest,
} from '../../utils/networkConst'
import EarnxORBCard from '../EarnCard/EarnxORBCard'
import EarnxORBCardTablet from '../EarnCard/EarnxORBCardTablet'
import EarnxORBCardMobile from '../EarnCard/EarnxORBCardMobile'

const styles = createUseStyles({
  faucetBlock: {
    background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    padding: '20px 0 20px 0',
    width: '100%',
    fontFamily: 'Poppins',
  },
  titeBlock: {
    display: 'flex',
    padding: '0px 20px 20px 20px',
  },
  titleText: {
    width: '60%',
    fontWeight: 600,
    fontSize: '20px',
    color: '#FFFFFF',
    cursor: 'default',
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 20,
  },
  earnCard: {
    width: 1060,
    border: '1px solid #4B567D',
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    marginRight: 20,
    marginLeft: 20,
  },
  '@media (max-width: 1250px)': {
    earnCard: {
      width: 600,
      border: '1px solid #4B567D',
      borderRadius: 12,
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      marginRight: 20,
      marginLeft: 20,
    },
    titeBlock: {
      display: 'flex',
      padding: '0px 20px 29px 20px',
    },
    faucetBlock: {
      background: 'linear-gradient(180deg, #313954 0%, #353551 100%)',
      boxShadow: 'inset 1px 1px 1px #42426A',
      backdropFilter: 'blur(25px)',
      borderRadius: 12,
      padding: '20px 0 20px 0',
      width: '100%',
      fontFamily: 'Poppins',
    },
  },
  '@media (max-width: 730px)': {
    earnCard: {
      width: 240,
      border: '1px solid #4B567D',
      borderRadius: 12,
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      marginRight: 20,
      marginLeft: 20,
    },
  },
})
interface Props {
  switchNetwork: any
  data: any
  refetch: any
  dataXORB: any
}

const earnCard = (
  data,
  switchNetwork,
  refetch,
  user,
  currentNetwork,
  firstNetwork,
  secondNetwork,
  setFirstNetwork,
  setSecondNetwork,
  switchNetworkLocal,
  dataXORB
) => {
  const classes = styles()
  const isTablet = window.innerWidth < 1250 && window.innerWidth > 730
  const isMobile = window.innerWidth < 730
  if (!data || currentNetwork !== 'MOONBEAM')
    return (
      <div className={classes.cardWrapper}>
        <div className={classes.earnCard}>
          <Skeleton
            baseColor="#4B567D"
            highlightColor="#5B6796"
            width={isTablet ? '600px' : isMobile ? '240px' : '1060px'}
            height={isTablet ? '124px' : isMobile ? '300px' : '53px'}
            count={1}
          />
        </div>
        <div className={classes.earnCard}>
          <Skeleton
            baseColor="#4B567D"
            highlightColor="#5B6796"
            width={isTablet ? '600px' : isMobile ? '240px' : '1060px'}
            height={isTablet ? '124px' : isMobile ? '300px' : '53px'}
            count={1}
          />
        </div>
        {/* <div className={classes.earnCard}>
          <Skeleton
            baseColor="#4B567D"
            highlightColor="#5B6796"
            width={isTablet ? '600px' : isMobile ? '240px' : '1060px'}
            height={isTablet ? '124px' : isMobile ? '300px' : '53px'}
            count={1}
          />
        </div>
        <div className={classes.earnCard}>
          <Skeleton
            baseColor="#4B567D"
            highlightColor="#5B6796"
            width={isTablet ? '600px' : isMobile ? '240px' : '1060px'}
            height={isTablet ? '124px' : isMobile ? '300px' : '53px'}
            count={1}
          />
        </div> */}
      </div>
    )

  if (window.innerWidth > 1250 && !user.connected) {
    return (
      <div className={classes.cardWrapper}>
        {dataXORB ? (
          <EarnxORBCard
            data={dataXORB}
            pool12Hours={true}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="12h"
            currentNetwork={currentNetwork}
          />
        ) : null}
        {data?.pools.pool12Hours ? (
          <EarnCardNotConnected
            data={data?.pools.pool12Hours}
            pool12Hours={true}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="12h"
          />
        ) : null}

        {/* {data?.pools.pool24Hours ? (
          <EarnCardNotConnected
            data={data?.pools.pool24Hours}
            pool12Hours={false}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="24h"
          />
        ) : null}

        {data?.pools.pool7Days ? (
          <EarnCardNotConnected
            data={data?.pools.pool7Days}
            pool12Hours={false}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="7d"
          />
        ) : null} */}
      </div>
    )
  } else if (
    window.innerWidth <= 1250 &&
    window.innerWidth > 730 &&
    !user.connected
  ) {
    return (
      <div className={classes.cardWrapper}>
        {dataXORB ? (
          <EarnxORBCardTablet
            data={dataXORB}
            pool12Hours={true}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="12h"
            currentNetwork={currentNetwork}
          />
        ) : null}
        {data?.pools?.pool12Hours ? (
          <EarnCardTabletNotConnected
            data={data?.pools.pool12Hours}
            pool12Hours={true}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="12h"
          />
        ) : null}

        {/* {data?.pools.pool24Hours ? (
          <EarnCardTabletNotConnected
            data={data?.pools.pool24Hours}
            pool12Hours={false}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="24h"
          />
        ) : null}

        {data?.pools.pool7Days ? (
          <EarnCardTabletNotConnected
            data={data?.pools.pool7Days}
            pool12Hours={false}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="7d"
          />
        ) : null} */}
      </div>
    )
  } else if (!user.connected) {
    return (
      <div className={classes.cardWrapper}>
        {dataXORB ? (
          <EarnxORBCardMobile
            data={dataXORB}
            pool12Hours={true}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="12h"
            currentNetwork={currentNetwork}
          />
        ) : null}
        {data?.pools?.pool12Hours ? (
          <EarnCardMobileNotConnected
            data={data?.pools.pool12Hours}
            pool12Hours={true}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="12h"
          />
        ) : null}

        {/* {data?.pools.pool24Hours ? (
          <EarnCardMobileNotConnected
            data={data?.pools.pool24Hours}
            pool12Hours={false}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="24h"
          />
        ) : null}

        {data?.pools.pool7Days ? (
          <EarnCardMobileNotConnected
            data={data?.pools.pool7Days}
            pool12Hours={false}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="7d"
          />
        ) : null} */}
      </div>
    )
  }

  if (window.innerWidth > 1250) {
    return (
      <div className={classes.cardWrapper}>
        {dataXORB ? (
          <EarnxORBCard
            data={dataXORB}
            pool12Hours={true}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="12h"
            currentNetwork={currentNetwork}
          />
        ) : null}

        {data?.pools?.pool12Hours ? (
          <EarnCard
            data={data?.pools.pool12Hours}
            pool12Hours={true}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="12h"
            currentNetwork={currentNetwork}
            firstNetwork={firstNetwork}
            secondNetwork={secondNetwork}
            setFirstNetwork={setFirstNetwork}
            setSecondNetwork={setSecondNetwork}
            switchNetworkLocal={switchNetworkLocal}
          />
        ) : null}

        {/* {data?.pools.pool24Hours ? (
          <EarnCard
            data={data?.pools.pool24Hours}
            pool12Hours={false}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            index={'second'}
            title="24h"
            currentNetwork={currentNetwork}
            firstNetwork={firstNetwork}
            secondNetwork={secondNetwork}
            setFirstNetwork={setFirstNetwork}
            setSecondNetwork={setSecondNetwork}
            switchNetworkLocal={switchNetworkLocal}
          />
        ) : null}

        {data?.pools.pool7Days ? (
          <EarnCard
            data={data?.pools.pool7Days}
            pool12Hours={false}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="7d"
            currentNetwork={currentNetwork}
            firstNetwork={firstNetwork}
            secondNetwork={secondNetwork}
            setFirstNetwork={setFirstNetwork}
            setSecondNetwork={setSecondNetwork}
            switchNetworkLocal={switchNetworkLocal}
          />
        ) : null} */}
      </div>
    )
  } else if (window.innerWidth <= 1250 && window.innerWidth > 730) {
    return (
      <div className={classes.cardWrapper}>
        {dataXORB ? (
          <EarnxORBCardTablet
            data={dataXORB}
            pool12Hours={true}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="12h"
            currentNetwork={currentNetwork}
          />
        ) : null}
        {data?.pools?.pool12Hours ? (
          <EarnCardTablet
            data={data?.pools.pool12Hours}
            pool12Hours={true}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="12h"
            currentNetwork={currentNetwork}
            firstNetwork={firstNetwork}
            secondNetwork={secondNetwork}
            setFirstNetwork={setFirstNetwork}
            setSecondNetwork={setSecondNetwork}
            switchNetworkLocal={switchNetworkLocal}
          />
        ) : null}

        {/* {data?.pools.pool24Hours ? (
          <EarnCardTablet
            data={data?.pools.pool24Hours}
            pool12Hours={false}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="24h"
            currentNetwork={currentNetwork}
            firstNetwork={firstNetwork}
            secondNetwork={secondNetwork}
            setFirstNetwork={setFirstNetwork}
            setSecondNetwork={setSecondNetwork}
            switchNetworkLocal={switchNetworkLocal}
          />
        ) : null}

        {data?.pools.pool7Days ? (
          <EarnCardTablet
            data={data?.pools.pool7Days}
            pool12Hours={false}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="7d"
            currentNetwork={currentNetwork}
            firstNetwork={firstNetwork}
            secondNetwork={secondNetwork}
            setFirstNetwork={setFirstNetwork}
            setSecondNetwork={setSecondNetwork}
            switchNetworkLocal={switchNetworkLocal}
          />
        ) : null} */}
      </div>
    )
  } else {
    return (
      <div className={classes.cardWrapper}>
        {dataXORB ? (
          <EarnxORBCardMobile
            data={dataXORB}
            pool12Hours={true}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="12h"
            currentNetwork={currentNetwork}
          />
        ) : null}

        {data?.pools?.pool12Hours ? (
          <EarnCardMobile
            data={data?.pools.pool12Hours}
            pool12Hours={true}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="12h"
            currentNetwork={currentNetwork}
            firstNetwork={firstNetwork}
            secondNetwork={secondNetwork}
            setFirstNetwork={setFirstNetwork}
            setSecondNetwork={setSecondNetwork}
            switchNetworkLocal={switchNetworkLocal}
          />
        ) : null}

        {/* {data?.pools.pool24Hours ? (
          <EarnCardMobile
            data={data?.pools.pool24Hours}
            pool12Hours={false}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="24h"
            currentNetwork={currentNetwork}
            firstNetwork={firstNetwork}
            secondNetwork={secondNetwork}
            setFirstNetwork={setFirstNetwork}
            setSecondNetwork={setSecondNetwork}
            switchNetworkLocal={switchNetworkLocal}
          />
        ) : null}

        {data?.pools.pool7Days ? (
          <EarnCardMobile
            data={data?.pools.pool7Days}
            pool12Hours={false}
            nextTime={data?.v2NextTime}
            switchNetwork={switchNetwork}
            refetch={refetch}
            title="7d"
            currentNetwork={currentNetwork}
            firstNetwork={firstNetwork}
            secondNetwork={secondNetwork}
            setFirstNetwork={setFirstNetwork}
            setSecondNetwork={setSecondNetwork}
            switchNetworkLocal={switchNetworkLocal}
          />
        ) : null} */}
      </div>
    )
  }
}

enum CardNetwork {
  MOONBEAM = 'MOONBEAM',
  ARBITRUM = 'ARBITRUM',
  ZKSYNC = 'ZKSYNC',
}

const Earn: React.FC<Props> = ({ switchNetwork, data, refetch, dataXORB }) => {
  const [currentNetwork, setCurrentNetwork] = useState(CardNetwork.MOONBEAM)
  const [firstNetwork, setFirstNetwork] = useState(CardNetwork.ARBITRUM)
  const [secondNetwork, setSecondNetwork] = useState(CardNetwork.ZKSYNC)

  const classes = styles()
  const { user } = useSelector((state: any) => state.userReducer)

  useEffect(() => {
    if (user.address) {
      if (user.chainId === paramsMovr.chainId) {
        setCurrentNetwork(CardNetwork.MOONBEAM)
        setFirstNetwork(CardNetwork.ARBITRUM)
        setSecondNetwork(CardNetwork.ZKSYNC)
      } else if (user.chainId === paramsArbitrumTest.chainId) {
        setCurrentNetwork(CardNetwork.ARBITRUM)
        setFirstNetwork(CardNetwork.MOONBEAM)
        setSecondNetwork(CardNetwork.ZKSYNC)
      } else if (user.chainId === paramsZkSyncTest.chainId) {
        setCurrentNetwork(CardNetwork.ZKSYNC)
        setFirstNetwork(CardNetwork.MOONBEAM)
        setSecondNetwork(CardNetwork.ARBITRUM)
      }
    }
  }, [user])

  const switchNetworkLocal = async (networkToChange, setNetwork) => {
    if (networkToChange === CardNetwork.MOONBEAM) {
      switchNetwork(paramsMovr, () =>
        setCurrentNetwork((prevState) => {
          setNetwork(prevState)
          return networkToChange
        })
      )
    } else if (networkToChange === CardNetwork.ARBITRUM) {
      switchNetwork(paramsArbitrumTest, () =>
        setCurrentNetwork((prevState) => {
          setNetwork(prevState)
          return networkToChange
        })
      )
    } else if (networkToChange === CardNetwork.ZKSYNC) {
      switchNetwork(paramsZkSyncTest, () =>
        setCurrentNetwork((prevState) => {
          setNetwork(prevState)
          return networkToChange
        })
      )
    }
  }

  return (
    <div className={classes.faucetBlock}>
      <div className={classes.titeBlock}>
        <span className={classes.titleText}>Pools</span>
      </div>
      <div className={classes.cardWrapper}>
        {earnCard(
          data,
          switchNetwork,
          refetch,
          user,
          currentNetwork,
          firstNetwork,
          secondNetwork,
          setFirstNetwork,
          setSecondNetwork,
          switchNetworkLocal,
          dataXORB
        )}
      </div>
    </div>
  )
}
export default Earn
