import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { findMovrIndexFauset } from '../utils'

export interface assetBalanceState {
  data: [
    {
      token: {
        image: string
        symbol: string
        name: string
      }
      walletBalance: string
    }
  ]
}

const initialState: assetBalanceState = {
  data: [
    {
      token: {
        image: '',
        symbol: '',
        name: '',
      },
      walletBalance: '',
    },
  ],
}

export const assetBalanceSlice = createSlice({
  name: 'assetBalance',
  initialState,
  reducers: {
    setAssetBalance: (state, action: PayloadAction<any>) => {
      // // We always want to have MOVR asset in the first position, because it is the main currency of the site
      const movr = findMovrIndexFauset(action.payload)

      const firstEl = action.payload[0]

      action.payload[0] = action.payload[movr]

      action.payload[movr] = firstEl

      state.data = action.payload
    },
  },
})
export const { setAssetBalance } = assetBalanceSlice.actions

export default assetBalanceSlice.reducer
