import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

export interface TraitInfo {
  name: string
  rarity: string
}

export interface INft {
  imageLink: string
  bg: TraitInfo
  skin: TraitInfo
  face: TraitInfo
  helmet: TraitInfo
  intercom: TraitInfo
  name: string
  stakingTime: number
  tokenId: number
  isLocked: boolean
}
export interface nftsState {
  nft: [
    {
      imageLink: string
      bg: TraitInfo
      skin: TraitInfo
      face: TraitInfo
      helmet: TraitInfo
      intercom: TraitInfo
      name: string
      stakingTime: number
      tokenId: number
      isLocked: boolean
    }
  ]
  nftGallery: [
    {
      imageLink: string
      bg: TraitInfo
      skin: TraitInfo
      face: TraitInfo
      helmet: TraitInfo
      intercom: TraitInfo
      name: string
      stakedDate: number
      tokenId: number
      isLocked: boolean
    }
  ]
}

const initialState: nftsState = {
  nft: [
    {
      imageLink: '',
      bg: {
        name: '',
        rarity: '',
      },
      skin: {
        name: '',
        rarity: '',
      },
      face: {
        name: '',
        rarity: '',
      },
      helmet: {
        name: '',
        rarity: '',
      },
      intercom: {
        name: '',
        rarity: '',
      },
      name: '',
      stakingTime: 1,
      tokenId: 1,
      isLocked: false,
    },
  ],
  nftGallery: [
    {
      imageLink: '',
      bg: {
        name: '',
        rarity: '',
      },
      skin: {
        name: '',
        rarity: '',
      },
      face: {
        name: '',
        rarity: '',
      },
      helmet: {
        name: '',
        rarity: '',
      },
      intercom: {
        name: '',
        rarity: '',
      },
      name: '',
      stakedDate: 1,
      tokenId: 1,
      isLocked: false,
    },
  ],
}

export const userNftsSlice = createSlice({
  name: 'userNfts',
  initialState,
  reducers: {
    setUserNfts: (state, action: PayloadAction<any>) => {
      const sorttedById = _.sortBy(action.payload, ['isLocked', 'tokenId'])
      state.nft = sorttedById.sort((a, b) => b.isLocked - a.isLocked)
    },
    setUserGalleryNfts: (state, action: PayloadAction<any>) => {
      const sorttedById = _.sortBy(action.payload, ['tokenId'])
      state.nftGallery = sorttedById
    },
  },
})

export const { setUserNfts, setUserGalleryNfts } = userNftsSlice.actions

export default userNftsSlice.reducer
