import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import StakingOverview from '../StakingOverview/StakingOverview'
import MyNFTS from '../MyNFTS/MyNFTS'
import MyNFTSNoConnected from '../MyNFTSNoConnected/MyNFTSNoConnected'
import { useDispatch, useSelector } from 'react-redux'
import { requestNFT } from '../../factory/axios'
import { setUserNfts } from '../../redux/userNfts'
import ClaimNftRewards from '../ClaimNftRewards/ClaimNftRewards'
import { useNavigate } from 'react-router-dom'
import { moonBaseAlfaNFT } from 'src/utils/chainConfig'

const styles = createUseStyles({
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  mainTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '40px',
    lineHeight: '60px',
    color: '#FFFFFF',
    paddingBottom: 10,
  },
  supTitle: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '32px',
    color: '#A8A8B2',
    width: 780,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    paddingBottom: 40,
  },
  mainBlock: {
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    backdropFilter: 'blur(25px)',
    borderRadius: 12,
    padding: '30px 0px',
    paddingBottom: 30,
    display: 'block',
    width: '100%',
    fontFamily: 'Poppins',
  },
  overviewWrapper: {
    display: 'flex',
    gap: 30,
  },
  '@media (max-width: 1250px)': {
    mainTitle: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '32px',
      lineHeight: '48px',
      color: '#FFFFFF',
      paddingBottom: 10,
    },
    supTitle: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '29px',
      color: '#A8A8B2',
      width: 700,
    },
    overviewWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
    },
  },
  '@media (max-width: 730px)': {
    mainTitle: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '30px',
      color: '#FFFFFF',
      paddingBottom: 10,
    },
    supTitle: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '26px',
      color: '#A8A8B2',
      width: 330,
    },
  },
})

const ORBITER_NFT_API_URL = process.env.REACT_APP_X_ORBITER_NFT_API_URL

const StakingNft = () => {
  const classes = styles()
  let navigate = useNavigate()
  const [loadingNFT, setLoadingNFT] = useState(true)
  const [loading, setLoading] = useState(true)
  const [rewards, setRewards] = useState(null)
  const [stakingInfo, setStakingInfo] = useState(null)
  const [loadingORB, setLoadingORB] = useState(true)

  const { user } = useSelector((state) => state.userReducer)
  const { nft } = useSelector((state: any) => state.userNftsReducer)
  const actualApiFor = process.env.REACT_APP_X_ORBITER_ACTIVE_API_FOR

  const dispatch = useDispatch()

  useEffect(() => {
    if (user.address) {
      requestNFT({
        method: 'get',
        url: `${ORBITER_NFT_API_URL}/user-nft/list/${user.address}?limit=9999`,
      })
        .then((req) => dispatch(setUserNfts(req.data.result)))
        .finally(() => setLoadingNFT(false))
      requestNFT({
        method: 'get',
        url: `${ORBITER_NFT_API_URL}/user-nft/staking-rewards/${user.address}`,
      })
        .then((req) => setRewards(req.data.rewards))
        .finally(() => setLoadingORB(false))
    }
    requestNFT({
      method: 'get',
      url: `${ORBITER_NFT_API_URL}/user-nft/staking`,
    })
      .then((req) => setStakingInfo(req.data))
      .finally(() => setLoadingNFT(false))
  }, [user.address])

  const getReward = (asset: string) => {
    const reward = rewards?.find((el) => el.symbol === asset)
    return reward
  }

  return (
    <div className={classes.mainWrapper}>
      <div
        className={classes.titleWrapper}
        style={{ paddingTop: actualApiFor === 'dev' ? 42 : 0 }}
      >
        <div className={classes.mainTitle}>Whiskers Staking</div>
        <div className={classes.supTitle}>
          Stake your Whiskers NFTs to earn a multitude of incentives, including
          ORB tokens, as well as promotional rewards such as USDC and GLMR.
          Looking ahead, stakers can anticipate earning a portion of the revenue
          generated by the Orbiter One
        </div>
      </div>
      <div className={classes.overviewWrapper}>
        <ClaimNftRewards stakedInfo={stakingInfo} />
        <StakingOverview
          loading={loading}
          stakingInfo={stakingInfo}
          rewards={rewards}
          getReward={getReward}
          loadingORB={loadingORB}
          setLoadingORB={setLoadingORB}
          setRewards={setRewards}
        />
      </div>
      {user.connected && nft.length ? (
        <MyNFTS
          setLoading={setLoadingNFT}
          loading={loadingNFT}
          setStakingInfo={setStakingInfo}
        />
      ) : (
        <MyNFTSNoConnected />
      )}
    </div>
  )
}

export default StakingNft
